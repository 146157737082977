angular.module('gettext').run([
  'gettextCatalog',
  function(gettextCatalog) {
    /* jshint -W100 */
    gettextCatalog.setStrings('en', {
      alarm: { alarmCard: ['alarm', 'alarms'], $$noContext: 'alarm' },
      '(0 - 23)': '(0 - 23)',
      '(0 - 59)': '(0 - 59)',
      '(1 - 12)': '(1 - 12)',
      '(1 - 31)': '(1 - 31)',
      '(1 - 7)': '(1 - 7)',
      '(Active)': '(Active)',
      '(Drag and drop to select an element)':
        '(Drag and drop to select an element)',
      '(Variable names must be surrounded by quotes)':
        '(Variable names must be surrounded by quotes)',
      '(default)': '(default)',
      '+ (0 - 23)': '+ (0 - 23)',
      '+ (0 - 59)': '+ (0 - 59)',
      '+ (1 - 31)': '+ (1 - 31)',
      '+ (1 - 7)': '+ (1 - 7)',
      '+ Add': '+ Add',
      '+ Add Arithmetic Expression': '+ Add Arithmetic Expression',
      '+ Add Asset': '+ Add Asset',
      '+ Add Boundary': '+ Add Boundary',
      '+ Add Cost centre': '+ Add Cost centre',
      '+ Add Counter Type': '+ Add Counter Type',
      '+ Add Cumulative Expression': '+ Add Cumulative Expression',
      '+ Add Location': '+ Add Location',
      '+ Add Owner': '+ Add Owner',
      '+ Add a Rule': '+ Add Rule',
      '+ Add a comparable column': '+ Add a comparable column',
      '+ Add alarm': '+ Add alarm',
      '+ Add attribute': '+ Add Attribute',
      '+ Add column': '+ Add column',
      '+ Add default mapping rule': '+ Add default mapping rule',
      '+ Add display field': '+ Add display field',
      '+ Add format mapping rule': '+ Add format mapping rule',
      '+ Add layout': '+ Add layout',
      '+ Add procedure': '+ Add procedure',
      '+ Add register': '+ Add register',
      '+ Add regular expression mapping rule':
        '+ Add regular expression mapping rule',
      '+ Add time series': '+ Add time series',
      '+ Add variable': '+ Add Variable',
      '+ Hour (0 - 23)': '+ Hour (0 - 23)',
      '+ Minute (0 - 59)': '+ Minute (0 - 59)',
      '+ add a new group': '+ Add Group',
      '+ add arithmetic expression': '+ add arithmetic expression',
      '+ add time series': '+ add time series',
      '0 seconds': '0 seconds',
      '1 day': ['1 day', '{{days}} days'],
      '1 hour': ['1 hour', '{{hours}} hours'],
      '1 minute': ['1 minute', '{{minutes}} minutes'],
      '1 month': ['1 month', '{{months}} months'],
      '1 second': ['1 second', '{{seconds}} seconds'],
      '1 year': ['1 year', '{{years}} years'],
      ':': ':',
      '<b>active</b>': '<b>active</b>',
      '<b>inactive</b>': '<b>inactive</b>',
      '<span class="badge triggers-an-error" ng-class="{\'float-right\' : mappingRule.isManualIntervention}">Triggers an error</span>':
        '<span class="badge triggers-an-error" ng-class="{\'float-right\' : mappingRule.isManualIntervention}">Triggers an error</span>',
      '<span translate>+ Add a Rule</span>':
        '<span translate>+ Add a Rule</span>',
      '<span translate>+ Add alarm</span>':
        '<span translate>+ Add alarm</span>',
      '<span translate>Add a Limit Alarm</span>':
        '<span translate>Add a Limit Alarm</span>',
      '<span translate>Add a Rule</span>':
        '<span translate>+ Add a Rule</span>',
      '<span translate>Error loading card</span>':
        '<span translate>Error loading card</span>',
      '<strong>Total without VAT</strong>':
        '<strong>Total without VAT</strong>',
      '?': '?',
      APR: 'APR',
      AVG: 'AVG',
      Absence: 'Absence',
      'Absent Personnel': 'Absent Personnel',
      'Absent personnel': 'Absent Personnel',
      Accept: 'Accept',
      'Accepted at': 'Accepted at',
      Accumulators: 'Accumulators',
      Action: 'Action',
      'Action Status': 'Action Status',
      'Action Statuses': 'Action Statuses',
      'Action status': 'Action status',
      Actions: 'Actions',
      'Active Comparable column': 'Active Comparable column',
      Activity: 'Activity',
      Actual: 'Actual',
      Add: 'Add',
      'Add Asset Availability': 'Add Asset Availability',
      'Add Child Node': 'Add Child Node',
      'Add Location': 'Add Location',
      'Add Lower Limit': 'Add Lower Limit',
      'Add M & T Analysis': 'Add M & T Analysis',
      'Add Manual Input Time Series': 'Add Manual Input Time Series',
      'Add Rated Power': 'Add Rated Power',
      'Add Sort': 'Add Sort',
      'Add Step': 'Add Step',
      'Add Task': 'Add Task',
      'Add Times Series': 'Add Times Series',
      'Add Upper Limit': 'Add Upper Limit',
      'Add User': 'Add User',
      'Add Value Card': 'Add Value Card',
      'Add a Limit Alarm': 'Add Alarm',
      'Add a Rule': 'Add Rule',
      'Add a boundary': '+ Add Boundary',
      'Add a filter': 'Add Filter',
      'Add alarms': 'Add alarms',
      'Add an alarm': 'Add Alarm',
      'Add analysis': 'Add analysis',
      'Add asset to measuring point': 'Add asset to measuring point',
      'Add attribute': '+ Add Attribute',
      'Add card': 'Add card',
      'Add cost centre to asset': 'Add cost centre to asset',
      'Add cost centre to measuring point':
        'Add cost centre to measuring point',
      'Add counter type to measuring point':
        'Add counter type to measuring point',
      'Add date': 'Add date',
      'Add element': 'Add element',
      'Add flow': 'Add flow',
      'Add group': 'Add group',
      'Add iframe': 'Add iframe',
      'Add image': 'Add image',
      'Add invoice overview': 'Add invoice overview',
      'Add location': 'Add location',
      'Add location to asset': 'Assign Location to Asset',
      'Add locations by cost centre map': 'Add locations by cost centre map',
      'Add locations map': 'Add locations map',
      'Add main cost centre to location': 'Add main cost centre to location',
      'Add new attribute': '+ Add Attribute',
      'Add new configuration': 'Add new configuration',
      'Add new user emails': 'Add user',
      'Add owner to location': 'Add owner to location',
      'Add owner to measuring point': 'Add owner to measuring point',
      'Add schedule classification': 'Add schedule classification',
      'Add series': 'Add series',
      'Add tab': 'Add tab',
      'Add time series': 'Add time series',
      'Add time series group': 'Add time series group',
      'Add to group': 'Add to group',
      'Add user': 'Add user',
      'Add values': 'Add values',
      'Add variable': '+ Add Variable',
      'Add visualization': 'Add visualization',
      'Add weather': 'Add weather',
      Addition: 'Addition',
      'Additional properties': 'Additional properties',
      Address: 'Address',
      'Adds neighbouring expressions': 'Sums adjacent expressions',
      Advanced: 'Advanced',
      Afternoon: 'Afternoon',
      Aggregation: 'Aggregation',
      'Aggregation Group': 'Aggregation Group',
      'Aggregation Groups': 'Aggregation Groups',
      'Aggregation Type': 'Aggregation Type',
      'Aggregation group': 'Aggregation Group',
      'Aggregation type': 'Aggregation Type',
      Alarm: 'Alarm',
      'Alarm Category': 'Alarm Category',
      'Alarm Data': 'Alarm Data',
      'Alarm Definition': 'Alarm Definition',
      'Alarm Definitions': 'Alarm Definitions',
      'Alarm Type': 'Alarm Type',
      'Alarm Types': 'Alarm Types',
      'Alarm after timeout': 'Timeout alarm',
      'Alarm configuration': 'Alarm configuration',
      'Alarm settings': 'Alarm Settings',
      'Alarm types': 'Alarm Types',
      'Alarm was successfully accepted!': 'Alarm was successfully accepted.',
      'Alarm was successfully assigned!': 'Alarm was successfully assigned.',
      'Alarm was successfully resolved!': 'Alarm was successfully resolved.',
      Alarms: 'Alarms',
      'Alarms and Rules': 'Alarms and Rules',
      'Alarms and rules': 'Alarms and rules',
      'Alarms overview': 'Alarms overview',
      'Alarms:': 'Alarms',
      Aligment: 'Alignment',
      'All series': 'All series',
      Allocation: 'Allocation',
      'Allocator Kind': 'Allocator Kind',
      'Allocator Type': 'Allocator Type',
      'Allocator value': 'Allocator Value',
      'Allowed entities': 'Allowed entities',
      'Allowed varaible names': 'Allowed Variable Names',
      'Always valid': 'Always valid',
      Amount: 'Amount',
      'Amount left': 'Amount left',
      Amounts: 'Amounts',
      Analyses: 'Analyses',
      Analysis: 'Analysis',
      'Analysis Type': 'Analysis Type',
      'Analysis doesn\'t have any required filters defined':
        'Analysis doesn\'t have any required filters defined',
      Analytics: 'Analytics',
      And: 'And',
      Application: 'Application',
      'Apply power of right-side neighbouring number (or expression) to the left side neighbouring number (or expression).':
        'Apply power of right-side neighbouring number (or expression) to the left side neighbouring number (or expression).',
      'Apply to all entities:': 'Apply to all entities:',
      Apr: 'Apr',
      April: 'April',
      'Are you sure that you want to change dashboard card size?':
        'Are you sure that you want to change dashboard card size?',
      'Are you sure that you want to remove layout?':
        'Are you sure that you want to remove layout?',
      'Are you sure you want to cancel?': 'Are you sure you want to cancel?',
      'Are you sure you want to continue? All unsaved data will be lost.':
        'Are you sure you want to continue? All unsaved data will be lost.',
      'Are you sure you want to delete': 'Are you sure you want to delete?',
      'Are you sure you want to delete Manual Input Time Series?':
        'Are you sure you want to delete Manual Input Time Series?',
      'Are you sure you want to delete this item?':
        'Are you sure you want to delete this item?',
      'Are you sure you want to regenerate credentials?':
        'Are you sure you want to regenerate credentials?',
      'Are you sure you want to reload values? All unsaved data will be lost.':
        'Are you sure you want to continue? All unsaved data will be lost.',
      'Are you sure you want to remove all direct accesses?':
        'Are you sure you want to remove all direct accesses?',
      'Are you sure you want to remove all items?':
        'Are you sure you want to remove all items?',
      'Are you sure you want to remove the item?':
        'Are you sure you want to remove the item?',
      'Are you sure you want to remove this classification?':
        'Are you sure you want to remove this classification?',
      'Are you sure you want to remove this direct access?':
        'Are you sure you want to remove this direct access?',
      'Are you sure you want to remove this group?':
        'Are you sure you want to remove this group?',
      'Are you sure you want to remove this mapping rule?':
        'Are you sure you want to remove this mapping rule?',
      'Are you sure you want to remove this {{name}}?':
        'Are you sure you want to remove this {{name}}?',
      'Are you sure you want to remove user from group?':
        'Are you sure you want to remove user from group?',
      'Are you sure you want to reset password? User will have to set new password at the next login.':
        'Are you sure you want to reset password? User will have to set new password at the next login.',
      'Are you sure you want to unpair measurement and GIS object?':
        'Are you sure you want to unpair measurement and GIS object?',
      'Are your sure that you want to relist values? All changed date will be lost':
        'Are your sure that you want to relist values? All changed date will be lost',
      Argument: 'Argument',
      'Argument value': 'Argument value',
      'Arithmetic expressions': 'Arithmetic Expressions',
      'As it was stored on the date': 'As it was stored on the date',
      Asset: 'Asset',
      'Asset Availability': 'Asset Availability',
      'Asset Availability Event': 'Asset Availability Event',
      'Asset Brand': 'Asset Brand',
      'Asset Brands': 'Asset Brands',
      'Asset Group': 'Asset Group',
      'Asset Groups': 'Asset Groups',
      'Asset Model': 'Asset Model',
      'Asset Models': 'Asset Models',
      'Asset Parent': 'Asset Parent',
      'Asset Status': 'Asset Status',
      'Asset Statuses': 'Asset Statuses',
      'Asset Type': 'Asset Type',
      'Asset Types': 'Asset Types',
      'Asset brand': 'Asset Brand',
      'Asset doesn\'t have availability yet':
        'Asset doesn\'t have availability yet',
      'Asset group': 'Asset group',
      'Asset has no subsidiary assets': 'Asset has no child assets.',
      'Asset owner': 'Asset Owner',
      'Asset ownership': 'Asset Ownership',
      'Asset type': 'Asset Type',
      'Asset type attributes': 'Asset type attributes',
      Assets: 'Assets',
      'Assets configuration': 'Assets Configuration',
      Assign: 'Assign',
      'Assign Alarm': 'Assign Alarm',
      'Assigned Personnel': 'Assigned Personnel',
      'Assigned personnel': 'Assigned Personnel',
      Assignee: 'Assignee',
      'Assignment is not allowed': 'Assignment is not allowed',
      'Assignment is not allowed.': 'Assignment is not allowed.',
      'At least one default filter must be selected':
        'At least one default filter must be selected',
      'At least one trigger has to be enabled':
        'At least one trigger has to be enabled',
      Attribute: 'Attribute',
      'Attribute {{ $index + 1 }}': 'Attribute {{ $index + 1 }}',
      Attributes: 'Attributes',
      August: 'August',
      Author: 'Author',
      'Authorized Groups': 'Authorized Groups',
      'Authorized Users': 'Authorized Users',
      'Authorized personnel': 'Authorized Personnel',
      Available: 'Available',
      Avg: 'Avg',
      Back: 'Back',
      Bad: 'Bad',
      'Bad-Request': 'Invalid Request',
      'Base line': 'Baseline',
      Baseline: 'Baseline',
      'Baseline function': 'Baseline Function',
      'Baseline {{equation}}': 'Baseline {{equation}}',
      Baselines: 'Baselines',
      'Basic Map': 'Basic Map',
      'Basic Map filters': 'Basic Map filters',
      Batch: 'Batch',
      'Batch interval in days': 'Batch interval in days',
      'Batch interval in hours': 'Batch interval in hours',
      'Batch interval in minutes': 'Batch interval in minutes',
      'Batch interval in seconds': 'Batch interval in seconds',
      'Billing Kind': 'Billing Kind',
      'Billing Kind Configurations': 'Billing Kind Configurations',
      'Billing Kinds': 'Billing Kinds',
      'Billing Type': 'Billing Type',
      'Billing Type Configurations': 'Billing Type Configurations',
      'Billing Types': 'Billing Types',
      'Billing data': 'Billing data',
      'Billing date': 'Billing Date',
      'Billing date *': 'Billing Date',
      'Billing date from': 'Billing date from',
      'Billing date from must occur before billing date to.':
        'Billing date from must occur before billing date to.',
      'Billing date time range must be less then two years.':
        'Billing date time range must be less then two years.',
      'Billing date to': 'Billing date to',
      'Billing date:': 'Billing Date:',
      'Billing kinds': 'Billing Kinds',
      Bold: 'Bold',
      'Book value': 'Book Value',
      'Book value was successfully distributed':
        'Book value was successfully distributed.',
      'Book value: {{bookValue}}': 'Book value: {{bookValue}}',
      Boolean: 'Boolean',
      'Both coordinates must be selected in order to save them.':
        'Both coordinates must be selected in order to save them.',
      Boundaries: 'Boundaries',
      'Boundary Type': 'Boundary Type',
      Brand: 'Brand',
      'Browse Query': 'Browse Query',
      Buffer: 'Buffer',
      'Bullet list': 'Bullet list',
      'Business Partner': 'Business Partner',
      'Business Partner Tree View': 'Business Partner Tree View',
      'Business Partners': 'Business Partners',
      'Business partner': 'Business Partner',
      'Business partner billing item detail':
        'Business partner billing item detail',
      'Business partners': 'Business Partners',
      'CO per kWh': 'CO per kWh',
      'CO2 per kWh': 'CO2 per kWh',
      'CRON EXPRESSION': 'CRON EXPRESSION',
      'CSV was successfully uploaded': 'CSV was successfully uploaded',
      'Calculate baseline': 'Calculate baseline',
      'Calculated Value': 'Calculated value',
      'Calculated count': 'Number of calculated values',
      'Calculated flow': 'Calculated flow',
      'Calculated value': 'Calculated value',
      Calculation: 'Calculation',
      Calculations: 'Calculations',
      'Can\t update detail invoice because there is no detail price list id':
        'Can\t update detail invoice because there is no detail price list id',
      'Can\'t find method for entity {{entity}} and method {{method}}':
        'Can\'t find method for entity {{entity}} and method {{method}}',
      Cancel: 'Cancel',
      'Cancel updating time series group': 'Cancel updating time series group',
      'Card size changed': 'Card size changed',
      Center: 'Center',
      'Change Password': 'Change Password',
      'Change Price List': 'Change Price List',
      'Change counter': 'Change counter',
      'Change counter for {{measuringpointName}}':
        'Change counter for {{measuringpointName}}',
      'Change password': 'Change password',
      'Changes were successfully changed': 'Changes were successful.',
      'Chart To Table Ratio': 'Chart To Table Ratio',
      'Chart Type': 'Chart Type',
      'Chart filters': 'Chart filters',
      'Chart settings': 'Chart Settings',
      'Chart type': 'Chart Type',
      'Check box': 'Check box',
      'Check your URL / link and try again.':
        'Check your URL / link and try again.',
      'Child assets': 'Child Assets',
      'Child measuring points': 'Child Measuring Points',
      'Choose Step': 'Choose Step',
      'Choose Task': 'Choose Task',
      'Choose a time interval': 'Select Time Interval',
      'Choose an element type': 'Select Element Type',
      'Choose database type': 'Choose database type',
      'Choose display field': 'Choose display field',
      'Choose file type': 'Choose file type',
      'Choose opc type': 'Choose opc type',
      'Choose sop procedure': 'Select SOP Procedure',
      'Choose sop type': 'Select SOP Type',
      'Choose webservice type': 'Choose webservice type',
      Class: 'Class',
      Clear: 'Clear',
      'Clear all ({{vm.items ? vm.items.length : \'0\'}})':
        'Clear all ({{vm.items ? vm.items.length : \'0\'}})',
      'Clear filters': 'Clear filters',
      'Click to upload': 'Click to upload',
      'Client ID': 'Client ID',
      'Close interval': 'Close interval',
      Code: 'Code',
      'Collection Friendly Name': 'Collection Friendly Name',
      'Collection friendly name': 'Collection Friendly Name',
      Color: 'Color',
      Columns: 'Columns',
      'Columns number': 'Number of Columns',
      Comment: 'Comment',
      Community: 'Community',
      'Community Name': 'Community Name',
      'Community name': 'Community Name',
      Company: 'Company',
      'Company Resources': 'Company Resources',
      'Company Structure': 'Company Structure',
      'Company Structure Node': 'Company Structure Node',
      'Company Structure Node Parent': 'Company Structure Node Parent',
      'Company Structure Node Tree View': 'Company Structure Node Tree View',
      'Company Structure Nodes': 'Company Structure Nodes',
      'Company resources': 'Company Resources',
      'Comparable column': 'Column for Comparison',
      'Comparable columns': 'Columns for Comparison',
      'Compare Operations': 'Compare Operators',
      Completed: 'Completed',
      'Component creator': 'Component Creator',
      'Computes the power of a number.': 'Returns the power of a number.',
      Conditions: 'Conditions',
      'Configuration to create new tag is missing.':
        'Configuration to create new tag is missing.',
      'Configuration updated.': 'Configuration updated.',
      'Configurations updated.': 'Configurations updated.',
      'Confirm new password': 'Confirm new password',
      Confirmation: 'Confirmation',
      'Connected external datasources': 'Connected external datasources',
      Connection: 'Connection',
      'Connection config is missing': 'Connection config is missing',
      'Connection configuration connection id':
        'Tango Agent connection configuration',
      'Connection configuration id': 'External datasource',
      'Connection configuration tag': 'Connection configuration tag',
      'Connection configuration type': 'External datasource type',
      'Connection settings': 'Connection Settings',
      'Connection type': 'Connection Type',
      Connectors: 'Connectors',
      Consistency: 'Consistency',
      Consumption: 'Consumption',
      'Consumption date': 'Consumption date',
      'Content notification': 'Content notification',
      'Content notification users': 'Content notification users',
      'Content responsible person': 'Content responsible person',
      'Copy history flow to live flow': 'Copy history flow to live flow',
      'Copy link to clipboard and close': 'Copy link to clipboard and close',
      'Copy live flow to history flow': 'Copy live flow to history flow',
      'Cosine function.': 'Cosine Function',
      'Cost Centre': 'Cost Centre',
      'Cost Centre Tree View': 'Cost Centre Tree View',
      'Cost Centre Type': 'Cost Centre Type',
      'Cost Centres': 'Cost Centres',
      'Cost centre': 'Cost centre',
      'Cost centre type': 'Cost centre type',
      'Cost centres': 'Cost centres',
      'Could not calculate the input.': 'Could not calculate the input.',
      'Couldn\'t duplicate invoice dates': 'Couldn\'t duplicate invoice dates',
      'Couldn\'t fetch organization': 'Couldn\'t fetch organization.',
      'Couldn\'t init GIS sdk': 'Couldn\'t init GIS sdk',
      'Couldn\'t initialize card. Dashboard card type doesn\'t exist.':
        'Couldn\'t initialize card. Dashboard card type doesn\'t exist.',
      'Couldn\'t remove main Dashboard': 'Couldn\'t remove main Dashboard',
      'Couldn\'t reset gis token': 'Couldn\'t reset gis token.',
      'Couldn\'t set main Dashboard': 'Couldn\'t set main Dashboard',
      'Couldn\'t update next reading consumption try to update it manually.':
        'Couldn\'t update next reading consumption try to update it manually.',
      'Couldn\'t upload values for': 'Couldn\'t upload values for',
      Count: 'Count',
      'Count with events': 'Number of values with events',
      'Counter Type': 'Counter Type',
      'Counter Type Configuration': 'Counter Type Configuration',
      'Counter Types': 'Counter Types',
      'Counter read out': 'Counter read out',
      'Counter replacement date': 'Counter replacement date',
      'Counter type has no registers': 'Counter type has no registers',
      'Counter types': 'Counter types',
      Country: 'Country',
      Create: 'Create',
      'Create GIS user': 'Create GIS User',
      'Create M&T analysis flow': 'Create M&T analysis flow',
      'Create an absence': 'Create an absence',
      'Create an assignment': 'New Assignment',
      'Create calculated flow': 'Create calculated flow',
      'Create domain': 'New Domain',
      'Create external reader flow': 'Create external reader flow',
      'Create external writer flow': 'Create external writer flow',
      'Create maintenance flow': 'Create maintenance flow',
      'Create new base line target function':
        'Create new base line target function',
      'Create new item': 'Create new item',
      'Create one.': 'Create one.',
      'Create time series': ['Create time series', 'Create time series'],
      'Create value': 'Create value',
      Created: 'Created',
      'Created Notification': 'Created Notification',
      'Created Notifications': 'Created Notifications',
      'Creation type': 'Creation type',
      'Cron expression': 'Cron Expression',
      'Cron expression literal': 'Cron Expression Literal',
      'Cumulative expressions': 'Cumulative expressions',
      Currency: 'Currency',
      'Current Assignee': 'Current Assignee',
      'Current Version': 'Current Version',
      'Current assignee': 'Current Assignee',
      'Current item': 'Current item',
      Custom: 'Custom',
      Customer: 'Customer',
      'Customer:': 'Customer:',
      DEC: 'DEC',
      'Daily setup': 'Daily Setup',
      Dashboard: 'Dashboard',
      'Dashboard Playlist': 'Dashboard Playlist',
      'Dashboard Playlists': 'Dashboard Playlists',
      'Dashboard card successfully saved.':
        'Dashboard card successfully saved.',
      'Dashboard card was successfully added to the dashboard.':
        'Dashboard card was successfully added to the dashboard.',
      'Dashboard does not exist': 'Dashboard does not exist',
      'Dashboard updated': 'Dashboard updated',
      Dashboards: 'Dashboards',
      Data: 'Data',
      'Data Approximation': 'Data Approximation',
      'Data Interpolation Type': 'Data Interpretation Type',
      'Data Sampling Type': 'Data Sampling Type',
      'Data Type': 'Data Type',
      'Data Visualization': 'Data Visualization',
      'Data approximation': 'Data Approximation',
      'Data is loading': 'Data is loading',
      'Data is loading ...': 'Data is loading ...',
      'Data point format': 'Data Point Format',
      'Data point value should be indicated as':
        'Data point value should be indicated as ',
      'Data type': 'Data Type',
      'Data visualization': 'Data visualization',
      Database: 'Database',
      'Database connection configuration': 'Database Connection Configuration',
      'Database name': 'Database Name',
      'Database type': 'Database Type',
      Databases: 'Databases',
      Dataset: 'Dataset',
      'Dataset doesn\'t have any default filters. Select another dataset':
        'Dataset doesn\'t have any default filters. Select another dataset',
      Datasets: 'Datasets',
      Date: 'Date',
      'Date Filter Type': 'Date Time Filter Type',
      'Date Finished': 'Date Finished',
      'Date Range': 'Date Range',
      'Date Time Filter Type': 'Date Time Filter Type',
      'Date column': 'Date Column',
      'Date column name': 'Timestamp Column Name',
      'Date does not match cron expression':
        'Date does not match cron expression',
      'Date finished': 'Date finished',
      'Date for calculation of regression':
        'Date for calculation of regression',
      'Date for calculation of regression function from':
        'Date for calculation of regression function from',
      'Date for calculation of regression function to':
        'Date for calculation of regression function to',
      'Date is not valid': 'Date is not valid',
      'Date is too early! (The earliest date is\r\n                          {{historyItem.editValidFrom | luxonDate}})':
        'Date is too early! (The earliest date is\n                          {{historyItem.editValidFrom | luxonDate}})',
      'Date is too early! (The earliest date is {{vm.configuration.earliestDate |\r\n    luxonDate}})':
        'Date is too early! (The earliest date is {{vm.configuration.earliestDate|\n    luxonDate}})',
      'Date is too late! (The latest date is\r\n                          {{historyItem.editValidTo | luxonDate}})':
        'Date is too late! (The latest date is\n                          {{historyItem.editValidTo | luxonDate}})',
      'Date is too late! (The latest date is {{vm.configuration.latestDate |\r\n    luxonDate}})':
        'Date is too late! (The latest date is {{vm.configuration.latestDate |\n    luxonDate}})',
      'Date of data reading': 'Date of data reading',
      'Date of data recording': 'Date of data recording',
      'Date of data recording:': 'Date of data recording:',
      Dates: 'Dates',
      Day: 'Day',
      'Day and time in month': 'Day and Time in Month',
      'Day format is not right.': 'Day Format is not valid.',
      'Day interval quantity missing.': 'Day Interval Value is missing.',
      'Day of month (1 - 31)': 'Day of month (1 - 31)',
      Days: 'Days',
      Dec: 'Dec',
      December: 'December',
      'Default Value Type': 'Default Value Type',
      'Default display type': 'Default display type',
      'Default mapping rule': 'Default mapping rule',
      'Default mapping rule is required.': 'Default mapping rule is required.',
      'Default physical quantity': 'Default physical quantity',
      'Default specification': 'Default schedule classification',
      'Default value': 'Default value',
      Delete: 'Delete',
      'Delete absence': 'Delete absence',
      'Delete assignment': 'Delete assignment',
      'Delete element': 'Delete Element',
      Delimiter: 'Delimiter',
      Delta: 'Delta',
      'Demo Editor': 'Demo Editor',
      'Demo Preview': 'Demo Preview',
      'Depreciation Period': 'Depreciation Period',
      'Depreciation period': 'Depreciation period',
      Description: 'Description',
      'Deselect all': 'Deselect all',
      'Detail invoice': 'Detail invoice',
      'Detail invoice service date to': 'Service date to',
      'Detail invoice total': 'Detail invoice total',
      'Detail invoice values order was successfully updated':
        'Detail invoice values order was successfully updated',
      'Detail invoice values sum must be less than detail invoice book value.':
        'Detail invoice values sum must be less than detail invoice book value.',
      'Detailed view': 'Detailed view',
      'Device heartbeats': 'Device heartbeats',
      'Direct Access': 'Direct Access',
      'Disable cache': 'Disable cache',
      'Disable filter': 'Disable Filter',
      Display: 'Display',
      'Display field': 'Display Field',
      'Display field name': 'Display Field Name',
      'Display horizontally': 'Display horizontally',
      'Display type': 'Display type',
      'Display types': 'Display types',
      Distribute: 'Distribute',
      Distributer: 'Distributor',
      'Distributer price list': 'Distributor Price List',
      'Distributer/Dobavitelj': 'Distributor / Supplier',
      Distributor: 'Distributor',
      'Distributor Code': 'Distributor\'s Code',
      'Distributor code': 'Distributor\'s Code',
      'Distributor/Supplier': 'Distributor/Supplier',
      'Divides neighbouring expressions': 'Divides adjacent expressions.',
      Division: 'Division',
      'Do you want to delete absence?': 'Do you want to delete absence?',
      'Do you want to delete assignment?': 'Do you want to delete assignment?',
      'Document Classification': 'Document Classification',
      'Document Classifications': 'Document Classifications',
      Domain: 'Domain',
      'Domain Collection': 'Domain Collection',
      'Domain Collections': 'Domain Collections',
      'Domain Configuration Form': 'Domain Configuration Form',
      'Domain Configurations': 'Domain Configurations',
      'Domain Field': 'Domain Field',
      'Domain Fields': 'Domain Fields',
      'Domain Fields Configuration': 'Domain Field Configuration',
      'Domain Fields Configuration Form': 'Domain Field Configuration Form',
      'Domain attribute': 'Domain Attribute',
      Domains: 'Domains',
      Double: 'Double',
      Download: 'Download',
      'Drag Item Here': 'Drag Item Here',
      'Drag a time series into this field or add it manually to begin with arranging':
        'Drag a time series into this field or add it manually to begin with arranging',
      'Drag file here or': 'Drag file here or',
      Duplicate: 'Duplicate',
      'Duplicate Analysis': 'Duplicate Analysis',
      'Duplicate Business partner': 'Duplicate Business partner',
      'Duplicate Invoice': 'Duplicate Invoice',
      'Duplicate Location': 'Duplicate Location',
      'Duplicate M&T analysis flow': 'Duplicate M&T analysis flow',
      'Duplicate Measuring Point': 'Duplicate Measuring Point',
      'Duplicate Price List': 'Duplicate Price List',
      'Duplicate Project': 'Duplicate Project',
      'Duplicate Rule': 'Duplicate Rule',
      'Duplicate analysis': 'Duplicate analysis',
      'Duplicate asset': 'Duplicate asset',
      'Duplicate calculated flow': 'Duplicate calculated flow',
      'Duplicate external reader flow': 'Duplicate external reader flow',
      'Duplicate external writer flow': 'Duplicate external writer flow',
      'Duplicate maintenance flow': 'Duplicate maintenance flow',
      'Duplicate mapping rule': 'Duplicate mapping rule',
      'Duplicate master invoice': 'Duplicate master invoice',
      'Duplicate measuring point': 'Duplicate Measuring Point',
      'Duplicate price list': 'Duplicate Price List',
      'Duplicate report': 'Duplicate report',
      'Duplicate step': 'Duplicate Step',
      'Duplicate task': 'Duplicate Task',
      'Duplicate time series': 'Duplicate time series',
      'Duplicate time series configuration':
        'Duplicate time series configuration',
      'Duplicate time series data from selected time series properties':
        'Duplicate time series data from selected time series properties',
      'Duplicating alarm definition': 'Duplicating alarm definition',
      'Duplicating standard operating procedure':
        'Duplicating standard operating procedure',
      Dynamic: 'Dynamic',
      'Dynamic Attribute': 'Dynamic Attribute',
      'Dynamic Attributes': 'Dynamic Attributes',
      'Dynamic attributes': 'Dynamic Attributes',
      'E-mail': 'E-mail',
      'EOM Code': 'EOM Code',
      EQUALS: 'EQUALS',
      'EXPRESSION PREVIEW': 'EXPRESSION PREVIEW',
      Edit: 'Edit',
      'Edit / Delete': 'Edit / Delete',
      'Edit Action Status': 'Edit Action Status',
      'Edit Alarm Type': 'Edit Alarm Type',
      'Edit Asset Brand': 'Edit Asset Brand',
      'Edit Asset Group': 'Edit Asset Group',
      'Edit Asset Model': 'Edit Asset Model',
      'Edit Asset Status': 'Edit Asset Status',
      'Edit Billing Kind': 'Edit Billing Kind',
      'Edit Billing Type': 'Edit Billing Type',
      'Edit Business partner': 'Edit Business Partner',
      'Edit Company Structure Node': 'Edit Company Structure Node',
      'Edit Cost Centre': 'Edit Cost Centre',
      'Edit Counter Type': 'Edit Counter Type',
      'Edit Database': 'Edit Database',
      'Edit Document Classification': 'Edit Document Classification',
      'Edit Domain': 'Edit Domain',
      'Edit Domain Collection': 'Edit Domain Collection',
      'Edit Domain Field': 'Edit Domain Field',
      'Edit Energy Management Item': 'Edit Energy Management Item',
      'Edit Energy Source Type': 'Edit Energy Source Type',
      'Edit External Datasource': 'Edit External Datasource',
      'Edit File': 'Edit File',
      'Edit Invoice Detail': 'Edit Invoice Detail',
      'Edit Location': 'Edit Location',
      'Edit Location Classification': 'Edit Location Classification',
      'Edit Location Status': 'Edit Location Status',
      'Edit M & T Analysis': 'Edit M & T Analysis',
      'Edit Manual Input Time Series': 'Edit Manual Input Time Series',
      'Edit Measurement Unit': 'Edit Measurement Unit',
      'Edit National Holiday': 'Edit National Holiday',
      'Edit OPC': 'Edit OPC',
      'Edit Ownership': 'Edit Ownership',
      'Edit Physical Quantity': 'Edit Physical Quantity',
      'Edit Price List': 'Edit Price List',
      'Edit Price List Item': 'Edit Price List Item',
      'Edit Privileges': 'Edit Privileges',
      'Edit Profit Centre': 'Edit Profit Centre',
      'Edit Project': 'Edit Project',
      'Edit Project Field': 'Edit Project Field',
      'Edit Project Type': 'Edit Project Type',
      'Edit Rated Power': 'Edit Rated Power',
      'Edit Response Interval': 'Edit Response Interval',
      'Edit Rule': 'Edit Rule',
      'Edit Scheduler': 'Edit Schedule',
      'Edit Tango Agent Connection Config':
        'Edit Tango Agent Connection Configuration',
      'Edit Tariff': 'Edit Tariff',
      'Edit Time Series': 'Edit Time Series',
      'Edit Time Series Group Type': 'Edit Time Series Group Type',
      'Edit Value Card': 'Edit Value Card',
      'Edit Web Service': 'Edit Web Service',
      'Edit aggregation group': 'Edit Aggregation Group',
      'Edit alarm definition': 'Edit Alarm Definition',
      'Edit alarms': 'Edit alarms',
      'Edit analysis': 'Edit Analysis',
      'Edit asset': 'Edit asset',
      'Edit asset type': 'Edit Asset Type',
      'Edit calculation': 'Edit Calculation',
      'Edit card': 'Edit card',
      'Edit column': 'Edit Column',
      'Edit dashboard': 'Edit Dashboard',
      'Edit dashboard playlist': 'Edit Dashboard Playlist',
      'Edit dataset': 'Edit Dataset',
      'Edit dynamic attribute': 'Edit dynamic attribute',
      'Edit expression': 'Edit Expression',
      'Edit group': 'Edit group',
      'Edit iframe': 'Edit iframe',
      'Edit image': 'Edit image',
      'Edit invoice detail value': 'Edit Invoice Details Value',
      'Edit invoice overview': 'Edit invoice overview',
      'Edit location': 'Edit location',
      'Edit location type': 'Edit Location Type',
      'Edit locations by cost centre map': 'Edit locations by cost centre map',
      'Edit locations map': 'Edit locations map',
      'Edit manual input': 'Edit manual input',
      'Edit mapping rule': 'Edit mapping rule',
      'Edit master invoice': 'Edit Invoice Header',
      'Edit measuring point': 'Edit measuring point',
      'Edit measuring point type': 'Edit Measuring Point Type',
      'Edit notification': 'Edit Notification',
      'Edit order': 'Edit order',
      'Edit price list': 'Edit Price List',
      'Edit price list item': 'Edit Price List Item',
      'Edit price list item group': 'Edit price list item group',
      'Edit report': 'Edit Report',
      'Edit settings': 'Edit settings',
      'Edit severity': 'Edit Severity',
      'Edit standard operating procedure': 'Edit standard operating procedure',
      'Edit step': 'Edit step',
      'Edit task': 'Edit task',
      'Edit template': 'Edit Template',
      'Edit time interval filter': 'Edit Time Interval Filter',
      'Edit time series group': 'Edit time series group',
      'Edit time series type': 'Edit time series type',
      'Edit user': 'Edit User',
      'Edit values': 'Edit values',
      'Edit visualization': 'Edit visualization',
      'Edit weather': 'Edit weather',
      Editor: 'Editor',
      Effort: 'Effort',
      'Element type': 'Element Type',
      'Element type not recognized': 'Element type not recognized',
      Email: 'Email',
      'Email content': 'Email content',
      'Email notification': 'Email notification',
      'Enable cache': 'Enable cache',
      'Enable edit permissions': 'Enable edit permissions',
      'Enable filter': 'Enable Filter',
      Enabled: 'Enabled',
      'End date': 'End Date',
      'End date:': 'End Date:',
      'End of Billing Period': 'End of Billing Period',
      'End of Project': 'End of Project',
      'End of billing period': 'End of billing period',
      'End of project': 'End of project',
      Ended: 'Ended',
      'Ending On': 'Ending On',
      'Energy Management Group Configuration':
        'Energy Management Group Configuration',
      'Energy Management Item': 'Energy Management Item',
      'Energy Management Items': 'Energy Management Items',
      'Energy Manager': 'Energy Manager',
      'Energy Source Type': 'Energy Source Type',
      'Energy Source Types': 'Energy Source Types',
      'Energy Sources Types Configuration': 'Energy Source Types Configuration',
      'Energy management': 'Energy Management',
      'Energy management group': 'Energy Management Group',
      'Energy management groups': 'Energy Management Groups',
      'Energy management item': 'Energy management item',
      'Energy management system.': 'Energy Management System',
      'Energy manager': 'Energy manager',
      'Energy managment groups': 'Energy Managment Groups',
      'Energy source type': 'Energy Source Type',
      'Energy source type is missing physical data':
        'Energy source type is missing physical data',
      'Enter URL (/example/path)': 'Enter URL (/example/path)',
      'Enter any string': 'Enter any string',
      'Enter date': 'Enter Date',
      'Enter decimal.': 'Enter decimal.',
      'Enter email and press enter or tab':
        'Enter email and press enter or tab',
      'Enter integer': 'Enter integer',
      'Enter time (hh:mm:ss)': 'Enter time (hh:mm:ss)',
      'Enter year': 'Enter year',
      'Enter your comment here.': 'Enter your comment here.',
      Entity: 'Entity',
      'Entity Tags': 'Entity Tags',
      'Entity instances': 'Entity instances',
      'Equal to': 'Equal to',
      Equality: 'Equality',
      Equals: 'Equals',
      'Error at creating new task': 'Error creating new task',
      'Error loading': 'Error loading',
      'Error loading card': 'Error loading card',
      'Error loading task': 'Error loading task',
      'Error on date initialization: Time Series is missing a data scheduler.':
        'Error on date initialization: Time Series is missing a data scheduler.',
      'Error while fetching M&T analysis': 'Error while fetching M&T analysis',
      'Error while fetching chart data': 'Error while fetching chart data',
      'Error: Missing template': 'Error: Missing template',
      Evening: 'Evening',
      'Event type': 'Event type',
      Every: 'Every',
      'Every day of the week': 'Every day of the week',
      'Every month': 'Every month',
      'Exact Interval': 'Exact interval',
      'Exact times': 'Exact times',
      'Exception Handler': 'Exception Handler',
      'Exception handler': 'Exception Handler',
      'Expected format dd.mm.yyyy': 'Expected format dd.mm.yyyy',
      'Expected format hh:mm:ss': 'Expected format hh:mm:ss',
      'Expected format: {{ msg }}.': 'Expected format: {{ msg }}.',
      Expression: 'Expression',
      'Expression *': 'Expression *',
      'Expression Preview': 'Expression Preview',
      'Expression is loading, this may take a while.':
        'Expression is loading, this may take a while.',
      'Expression preview': 'Expression Preview',
      'Expression {{error.indexInExpression + 1}} >\r\n          {{(error.indexOfExpression == 0) ? \'Left side\' : \'Right Side\'}}: at\r\n          {{error.position}}':
        'Expression {{ error.indexInExpression + 1}} > {{ (\n          error.indexOfExpression == 0 ) ? \'Left side\' : \'Right Side\' }}: at {{\n          error.position }}',
      'Expression:': 'Expression:',
      Expressions: 'Expressions',
      External: 'External',
      'External  datasource': 'External datasource',
      'External Code': 'External Code',
      'External Datasource': 'External Datasource',
      'External Datasource Type': 'External Datasource Type',
      'External Datasource Version': 'External Datasource Version',
      'External Datasources': 'External Datasources',
      'External Reader flow': 'External reader flow',
      'External code': 'External code',
      'External datasource': 'External datasource',
      'External datasource Type': 'External datasource Type',
      'External datasource credentials': 'External datasource credentials',
      'External datasource job': 'External datasource job',
      'External properties': 'External Properties',
      'External reader flow': 'External reader flow',
      'External writer flow': 'External writer flow',
      'Extrapolated count': 'Number of extrapolated values',
      Extrapolation: 'Extrapolation',
      'Extrapolation Functions': 'Extrapolation Function',
      FALSE: 'FALSE',
      FEB: 'FEB',
      'Factor K': 'Factor K',
      'Factor N': 'Factor N',
      'Failed to fetch current user': 'Failed to fetch current user',
      'Failed to fetch scheduler': 'Failed to fetch scheduler',
      'Failed to fetch tariffs': 'Failed to fetch tariffs',
      'Failed to fetch time series types': 'Failed to fetch time series types',
      False: 'False',
      Feb: 'Feb',
      February: 'February',
      'Field name': 'Field Name',
      'Field type': 'Field Type',
      File: 'File',
      'File Uploader': 'File Uploader',
      'File connection configuration': 'File Connection Configuration',
      'File type': 'File Type',
      'File was successfully deleted': 'File was successfully deleted',
      'File was successfully updated': 'File was successfully updated.',
      Files: 'Files',
      'Files were successfully uploaded': 'Files were successfully uploaded',
      'Fill in at least one field of email, notification or sms content.':
        'Fill in at least one field of email, notification or sms content.',
      Filter: 'Filter',
      'Filter enabled': 'Filter enabled',
      'Filter entities by name': 'Filter entities by name',
      'Filter type': 'Filter Type',
      'Filter type should be selected': 'Filter Type should be selected',
      'Filter type:': 'Filter Type',
      Filters: 'Filters',
      'Final Counter read out': 'Final Counter read out',
      'Final value': 'Final value',
      'Finalize invoice': 'Finalize invoice',
      First: 'First',
      Fixed: 'Fixed',
      'Fixed Filter': 'Fixed Filter',
      Float: 'Float',
      'Flow Type': 'Flow Type',
      Font: 'Font',
      'Font color': 'Font color',
      Forbidden: 'Forbidden',
      'Forecast temperature': 'Forecast temperature',
      Format: 'Format',
      'Format mapping rule is required.': 'Format mapping rule is required.',
      'Format mapping rules': 'Format mapping rules',
      'Formula input': 'Formula Input',
      Fri: 'Fri',
      Friday: 'Friday',
      'Friendly Name': 'Friendly Name',
      'Friendly name': 'Friendly Name',
      From: 'From',
      'From date can not be prior to start date of asset availability ()':
        'From date can not be prior to start date of asset availability ()',
      'From date must be prior valid to date':
        'From date must be prior valid to date',
      'From/to range should be within date limits of external reader flow configuration':
        'From/to range should be within date limits of external reader flow configuration.',
      'Function to get connection config is missing':
        'Function to get connection config is missing',
      Functions: 'Functions',
      Future: 'Future',
      'Future input': 'Future Input',
      'Future interval': 'Future Interval',
      'Future interval in days': 'Future interval in days',
      'Future interval in hours': 'Future interval in hours',
      'Future interval in minutes': 'Future interval in minutes',
      'Future interval in seconds': 'Future interval in seconds',
      GIS: 'GIS',
      'GIS Map': 'GIS Map',
      'GIS Maps': 'GIS Maps',
      General: 'General',
      Geolocation: 'Geolocation',
      'Geolocation X': 'Geolocation X',
      'Geolocation Y': 'Geolocation Y',
      'Get total items': 'Get total items',
      'Gis Map': 'GIS Map',
      'Gis map': 'GIS map',
      'Gis user groups were successfully updated.':
        'Gis user groups were successfully updated.',
      'Gis user was successfully created.':
        'Gis user was successfully created.',
      Good: 'Good',
      'Greater than': 'Greater than',
      'Greater than or equal': 'Greater than or equal',
      'Greater than or equals': 'Greater than or equals',
      Group: 'Group',
      'Group Parent': 'Group Parent',
      'Group Privilege Management': 'Privilege Management',
      'Group access': 'Group Access',
      'Group by:': 'Group by:',
      'Group entity': 'Group entity',
      Groups: 'Groups',
      'Guaranteed Price': 'Guaranteed Price',
      'Has address': 'Has Address',
      'Has usage': 'Has usage',
      'Header 1': 'Header 1',
      'Header 2': 'Header 2',
      'Heartbeat history': 'Heartbeat History',
      'Hide chart': 'Hide chart',
      'Hide energy values': 'Hide energy values',
      'Hide history': 'Hide history',
      'Hide seen notifications': 'Hide seen notifications',
      'Highcharts initialization failed.': 'Highcharts initialization failed.',
      'History data job': 'History Data Job',
      'History flow': 'History flow',
      'History flow mapping rule': 'History flow mapping rule',
      'History flow tag': 'History flow tag',
      'History flow tango agent connection configuration':
        'History flow tango agent connection configuration',
      'History job request successfully sent':
        'History job request successfully sent',
      'History time count': 'History time count',
      Hour: 'Hour',
      'Hour format is not right': 'Hour format is not valid.',
      'Hour interval quantity missing.': 'Hour Interval Range is missing.',
      Hours: 'Hours',
      'House number': 'House Number',
      'Hyperbolic cosine function.': 'Hyperbolic cosine function.',
      'Hyperbolic sine function.': 'Hyperbolic sine function.',
      'Hyperbolic tangent function.': 'Hyperbolic tangent function.',
      'I have safely stored the secret': 'I have safely stored the secret',
      IF: 'IF',
      Icon: 'Icon',
      Icons: 'Icons',
      'If the format of received value is not appropriate, specify the appropriate format to remap it using a predefined function. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'If the format of received value is not appropriate, specify the appropriate format to remap it using a predefined function. If this should trigger an error, alarm or signal a manual check, flag it so.',
      'If you think this is an error please contact your administrator.':
        'If you think this is an error please contact your administrator.',
      'Image was successfully removed.': 'Image was successfully removed.',
      'Image was successfully uploaded.': 'Image was successfully uploaded.',
      Images: 'Images',
      'Implicit operation {{functionName2}} is not allowed.':
        'Implicit operation {{functionName2}} is not allowed.',
      'Implicit operation {{functionName}} is not allowed.':
        'Implicit operation {{functionName}} is not allowed.',
      'Import CSV': 'Import CSV',
      'In order to add items, you have to select them using autocomplete or dialog selector':
        'In order to add items, you have to select them using autocomplete or dialog selector',
      'In range': 'In range',
      'Inactive Assets': 'Inactive assets',
      'Inactive Counter Types': 'Inactive Counter Types',
      'Inactive Measuring Point Allocators':
        'Inactive Measuring Point Allocators',
      'Inactive Time Series': ['Inactive time series', 'Inactive time series'],
      'Inactive assets': 'Inactive assets',
      'Inactive business partners': 'Inactive business partners',
      'Inactive cost centres': 'Inactive cost centres',
      'Inactive locations': 'Inactive locations',
      'Inactive main cost centes': 'Inactive main cost centes',
      'Inactive measuring points': 'Inactive measuring points',
      'Inactive measuringpoints': 'Inactive measuring points',
      'Inactive owners': 'Inactive owners',
      'Include end': 'Include End',
      'Include first month': 'Include First Month',
      'Include first year': 'Include First Year',
      'Include last month': 'Include Last Month',
      'Include last year': 'Include Last Year',
      'Include start': 'Include Start',
      'Include value on the edge': 'Include value on the edge',
      'Inclusion type': 'Inclusion Type',
      'Incompatible data type': 'Incompatible data type',
      'Indicates order of execution.': 'Indicates execution order.',
      'Indirect Access': 'Indirect Access',
      'Indirect access items were successfully added.':
        'Indirect access items were successfully added.',
      'Ingestion type': 'Ingestion type',
      'Initial batch interval': 'Initial batch interval',
      'Initial value': 'Initial value',
      'Input Data Type': 'Input Data Type',
      'Input Source': 'Input Source',
      'Input Time Series': ['Input time series', 'Input time series'],
      'Input data type': 'Input Data Type',
      'Input expression': 'Input Expression',
      'Input source': 'Input Source',
      'Input time series': ['Input time series', 'Input time series'],
      'Input time series configurations': 'Active time series configuration',
      'Input time series for X axis': 'Input time series for X axis',
      'Input time series for Y axis': 'Input time series for Y axis',
      'Input value': 'Input Value',
      'Input value represents a value received. Select a function to transform it to the desired output. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'Input value represents a value received. Select a function to transform it to the desired output. If this should trigger an error, alarm or signal a manual check, flag it so.',
      'Input will be saved as regular expression...':
        'Input will be saved as regular expression.',
      'Insert image': 'Insert image',
      'Installation Number': 'Installation Number',
      'Installation number': 'Installation number',
      Integer: 'Integer',
      Integration: 'Integration',
      'Integration Methods': 'Integration Methods',
      'Integration timestamp': 'Integration timestamp',
      Integrity: 'Integrity',
      'Integrity value': 'Integrity',
      Internal: 'Internal',
      'Internal Order': 'Internal Order',
      'Interpolated count': 'Number of interpolated values',
      Interpolation: 'Interpolation',
      'Interpolation Functions': 'Interpolation Function',
      'Interpretation type': 'Data interpretation type',
      Interval: 'Interval',
      'Interval from': 'Interval from',
      'Interval is already taken': 'Interval is already taken',
      'Interval quantity missing.': 'Interval Range is missing.',
      'Interval to': 'Interval to',
      'Interval unit missing.': 'Interval Unit is missing.',
      'Invalid Data': 'Invalid Data',
      'Invalid configuration.': 'Invalid configuration.',
      'Invalid date': 'Invalid date',
      'Invalid filter configuration': 'Invalid filter configuration',
      'Invalid formatting at position {{x}}.':
        'Invalid formatting at position {{x}}.',
      'Invalid number.': 'Invalid number.',
      'Invalid phone number. (+386 12 312 312)':
        'Invalid phone number. (+386 12 312 312)',
      'Invalid time': 'Invalid time',
      'Invalid value': 'Invalid value',
      'Inverse cosine (arccosine) function.': 'Arccosine',
      'Inverse sine (arcsine) function.': 'Inverse sine (arcsine) function.',
      'Inverse tangent (arctan) function.':
        'Inverse tangent (arctan) function.',
      'Invocie Type': 'Invoice Type',
      Invoice: 'Invoice',
      'Invoice Detail': 'Invoice Detail',
      'Invoice Details': 'Invoice Details',
      'Invoice Issuer': 'Invoice Issuer',
      'Invoice Status': 'Invoice Status',
      'Invoice Type': 'Invoice Type',
      'Invoice detail values': 'Invoice detail values',
      'Invoice detail values successfully saved!':
        'Invoice Details values successfully saved.',
      'Invoice for {{month}} is closed. Reopen invoice in order to edit values':
        'Invoice for {{month}} is closed. Reopen invoice in order to edit values',
      'Invoice has been successfully finalized':
        'Invoice has been successfully finalized',
      'Invoice has been successfully opened':
        'Invoice has been successfully opened',
      'Invoice has no valid price list detail':
        'Invoice has no valid price list detail',
      'Invoice is not closed': 'Invoice is not closed',
      'Invoice issuer': 'Invoice issuer',
      'Invoice issuer:': 'Invoice issuer:',
      'Invoice number': 'Invoice Number',
      'Invoice overview': 'Invoice Overview',
      'Invoice status': 'Invoice status',
      'Invoice type': 'Invoice type',
      'Invoice validity': 'Invoice validity',
      Invoices: 'Invoices',
      'Invoices Overview': 'Invoices Overview',
      'Invoices overview': 'Invoices Overview',
      IoT: 'IoT',
      'Irregular sampling type is not compatible with selected trigger type':
        'Irregular sampling type is not compatible with selected trigger type',
      'Is standard': 'Is standard',
      Issuer: 'Issuer',
      Italic: 'Italic',
      'Item group': 'Item Group',
      'Item has no connected entities for display.':
        'Item has no connected entities for display.',
      'Item was successfully created': 'Item was successfully created',
      'Item was successfully duplicated': 'Item was successfully duplicated',
      'Item was successfully removed': 'Item was successfully removed',
      'Item was successfully removed.': 'Item was successfully removed.',
      'Item was successfully updated': 'Item was successfully updated',
      Items: 'Items',
      JAN: 'JAN',
      JUL: 'JUL',
      JUN: 'JUN',
      Jan: 'Jan',
      January: 'January',
      Job: 'Job',
      'Job Results': 'Job Results',
      'Job name': 'Job Name',
      'Job types': 'Job types',
      Jobs: 'Jobs',
      Jul: 'Jul',
      July: 'July',
      Jun: 'Jun',
      June: 'June',
      K: 'K',
      Key: 'Key',
      'Key was copied to clipboard.': 'Key was copied to clipboard.',
      Kind: 'Mode',
      Language: 'Language',
      Last: 'Last ',
      'Last 3 months': 'Last 3 months',
      'Last N month': 'Last N month',
      'Last N months': 'Last N months',
      'Last N years': 'Last N years',
      'Last day': 'Last day',
      'Last half a year': 'Last half a year',
      'Last heartbeat': 'Last Heartbeat',
      'Last hour': 'Last hour',
      'Last measurement time': 'Last Time Series Value Timestamp',
      'Last month': 'Last month',
      'Last value': 'Last Value',
      'Last value at': 'Last Value at',
      'Last week': 'Last week',
      'Last year': 'Last year',
      Lastname: 'Lastname',
      'Layout was successfully removed': 'Layout was successfully removed.',
      'Less than': 'Less than',
      'Less than or equal': 'Less than or equal',
      'Less than or equals': 'Less than or equals',
      Limit: 'Limit',
      'Limit history': 'Limit history',
      'Limit reached': 'Limit reached',
      Link: 'Link',
      'Link to dashboard': 'Link to dashboard',
      'Linked Dashboard': 'Linked Dashboard',
      'Links to dashboard': 'Links to dashboard',
      List: 'List',
      'Live Flow': 'Live Flow',
      'Live flow': 'Live flow',
      'Live flow mapping rule': 'Live flow mapping rule',
      'Live flow read type': 'Live flow read type',
      'Live flow tag': 'Live flow tag',
      'Load more': 'Load more',
      'Load more <span ng-if="vm.moreLeft">({{vm.moreLeft}})</span>':
        'Load more <span ng-if="vm.moreLeft">({{vm.moreLeft}})</span>',
      Loading: 'Loading',
      'Loading ...': 'Loading ...',
      'Loading M&T analysis...': 'Loading M&T analysis ...',
      'Loading additional data ...': 'Loading additional data ...',
      'Loading location ...': 'Loading location ...',
      'Loading registers ...': 'Loading registers ...',
      'Loading values': 'Loading values',
      Location: 'Location',
      'Location Classification': 'Location Classification',
      'Location Classifications': 'Location Classifications',
      'Location Cost Centres': 'Location Cost Centres',
      'Location Country': 'Location Country',
      'Location Owner': 'Location Owner',
      'Location Ownership': 'Location Ownership',
      'Location Status': 'Location Status',
      'Location Statuses': 'Location Statuses',
      'Location Tree View': 'Location Tree View',
      'Location Type': 'Location Type',
      'Location Types': 'Location Types',
      'Location classification': 'Location Classification',
      'Location external ID': 'Location External ID',
      'Location has no subsidiary locations':
        'Location has no child locations.',
      'Location link': 'Location link',
      'Location links': 'Location links',
      'Location map': 'Location map',
      'Location ownership': 'Location Ownership',
      'Location status': 'Location Status',
      'Location type': 'Location Type',
      'Location type attributes': 'Location type attributes',
      Locations: 'Locations',
      'Locations Overview': 'Locations Overview',
      Lock: 'Lock',
      'Logical Operations': 'Logical Operators',
      Logout: 'Sign out',
      Logs: 'Logs',
      'Looks like you have no main dashboard.':
        'Looks like you have no main dashboard.',
      'Lower Limit %': 'Lower limit %',
      'Lower Limit Alarm': 'Lower Limit Alarm',
      'Lower Limits': 'Lower limits',
      'M&T analysis flow': 'M & T Analysis flow',
      MAJ: 'MAY',
      MAR: 'MAR',
      'MT timeSeries is missing date for calculation from value':
        'MT analysis is missing date for calculation from value',
      'MT timeSeries is missing date for calculation to value':
        'MT analysis is missing date for calculation to value',
      Main: 'Main',
      'Main Cost Centre': 'Main Cost Centre',
      'Main cost centre': 'Main cost centre',
      'Main dashboard': 'Main Dashboard',
      'Maintenance Flow': 'Maintenance flow',
      'Maintenance flow': 'Maintenance flow',
      'Maintenance flow type': 'Maintenance flow type',
      Maj: 'May',
      'Make sure asset type is selected.': 'Make sure Asset Type is selected.',
      'Make sure location type is selected.':
        'Make sure Location Type is selected.',
      'Make sure measuringpoint type is selected.':
        'Make sure measuringpoint type is selected.',
      'Make sure time series type is selected.':
        'Make sure Time Series Type is selected.',
      'Manual Input': 'Manual Input',
      'Manual Input -': 'Manual Input -',
      'Manual input': 'Manual Input',
      'Manual input form has no time series':
        'Manual input form has no time series.',
      'Manual intervention': 'Manual Intervention',
      'Manual reading cannot be entered because the invoice is already closed for the current billing period {{month}}.':
        'Manual reading cannot be entered because the invoice is already closed for the current billing period {{month}}.',
      'Manual reading cannot be entered because the reading date cannot be greater than today\'s date - {{date | luxonDate:\'short\'}}':
        'Manual reading cannot be entered because the reading date cannot be greater than today\'s date - {{date | luxonDate:\'short\'}}',
      'Manual reading cannot be entered because the value of the entered reading is greater than the next reading.':
        'Manual reading cannot be entered because the value of the entered reading is greater than the next reading.',
      'Manual reading cannot be entered because the value of the entered reading is less than the previous reading.':
        'Manual reading cannot be entered because the value of the entered reading is less than the previous reading.',
      'Manual reading cannot be entered because there is already a closed invoice for {{month}} {{year}}.':
        'Manual reading cannot be entered because there is already a closed invoice for {{month}} {{year}}.',
      'Manual reading cannot be entered because there is no main billing measuring point at the current measuring point.':
        'Manual reading cannot be entered because there is no main billing measuring point at the current measuring point.',
      'Manual reading cannot be entered since the difference between the consumption date and reading date should not exceed 1 month.':
        'Manual reading cannot be entered since the difference between the consumption date and reading date should not exceed 1 month.',
      'Manual readings': 'Manual readings',
      'Manual readings cannot be saved.': 'Manual readings cannot be saved.',
      'Manual readings for {{measuringpointName}}':
        'Manual readings for {{measuringpointName}}',
      'Mapping Function': 'Mapping Function',
      'Mapping Rule': 'Mapping Rule',
      'Mapping Rule Item': 'Mapping Rule Item',
      'Mapping Rules': 'Mapping Rules',
      'Mapping Type': 'Mapping Type',
      'Mapping functions': 'Mapping functions',
      'Mapping rule': 'Mapping Rule',
      Maps: 'Maps',
      Mar: 'Mar',
      March: 'March',
      'Master Invoice': 'Master Invoice',
      'Master Price List': 'Master Price List',
      'Master invoice': 'Invoice header',
      'Match is required': 'Match is required',
      'Math expression must contain at least one valid variable name.':
        'Math expression must contain at least one valid variable name.',
      Max: 'Max',
      'Max input length is {{vm.configuration.maxLength}}.':
        'Max input length is {{vm.configuration.maxLength}}.',
      'Max number of items is {{vm.configuration.maxItems}}.':
        'Max number of items is {{vm.configuration.maxItems}}.',
      'Max timeout': 'Max timeout',
      'Max value is {{configuration.max}}.':
        'Max value is {{configuration.max}}.',
      'Max value is {{historyItem.formConfig.max}}.':
        'Max value is {{historyItem.formConfig.max}}.',
      'Max value is {{vm.configuration.max}}.':
        'Max value is {{vm.configuration.max}}.',
      'Max value must be lower than {{vm.configuration.max}}.':
        'Max value must be lower than {{vm.configuration.max}}.',
      'Max year value is {{vm.maxYear}}.': 'Max year value is {{vm.maxYear}}.',
      'Maximum Capacity': 'Maximum Capacity',
      'Maximum Seconds since last Heartbeat':
        'Maximum Seconds since last Heartbeat',
      'Maximum allowed value': 'Maximum allowed value',
      'Maximum capacity': 'Maximum Capacity',
      'Maximum number of chips reached.': 'Maximum number of chips reached.',
      'Maximum value': 'Maximum Value',
      May: 'May',
      'Measurement Unit': 'Measurement Unit',
      'Measurement Units': 'Measurement Units',
      'Measurement unit': 'Unit of Measurement',
      'Measurement units': 'Units of Measurement',
      'Measuring Point': 'Measuring Point',
      'Measuring Point Allocators': 'Measuring Point Allocators',
      'Measuring Point Class': 'Measuring Point Class',
      'Measuring Point Kind': 'Measuring Point Mode',
      'Measuring Point Mode': 'Measuring Point Mode',
      'Measuring Point Type': 'Measuring Point Type',
      'Measuring Point Types': 'Measuring Point Types',
      'Measuring Points': 'Measuring Points',
      'Measuring point': 'Measuring Point',
      'Measuring point class': 'Measuring Point Class',
      'Measuring point has no child measuring points.':
        'Measuring point has no child measuring points.',
      'Measuring point kind': 'Measuring Point Mode',
      'Measuring point type': 'Measuring Point Type',
      'Measuring point type attributes': 'Measuring point type attributes',
      'Measuring points': 'Measuring Points',
      Measuringpoint: 'Measuring Point',
      'Measuringpoint Class': 'Measuring Point Class',
      'Measuringpoint has no counter types':
        'Measuringpoint has no counter types',
      Member: 'Member',
      Members: 'Members',
      'Membership updated.': 'Membership updated.',
      'Meta data': 'Meta Data',
      Metrics: 'Metrics',
      Min: 'Min',
      'Min 1, Max 10000': 'Min 1, Max 10000',
      'Min input length is {{vm.configuration.minLength}}.':
        'Min input length is {{vm.configuration.minLength}}.',
      'Min number of items is {{vm.configuration.minItems}}.':
        'Min value is {{vm.configuration.min}}.',
      'Min value is {{configuration.min}}.':
        'Min value is {{configuration.min}}.',
      'Min value is {{historyItem.formConfig.min}}.':
        'Min value is {{historyItem.formConfig.min}}.',
      'Min value is {{vm.configuration.min}}.':
        'Min value is {{vm.configuration.min}}.',
      'Min value must be higher than {{vm.configuration.min}}.':
        'Min value is {{vm.configuration.min}}.',
      'Min year value is {{vm.minYear}}.': 'Min year value is {{vm.minYear}}.',
      'Minimum allowed value': 'Minimum allowed value',
      'Minimum value': 'Minimum value',
      'Minute interval quantity missing.': 'Minute Interval Value is missing.',
      Minutes: 'Minutes',
      'Missing inputTimeSeriesForXAxis': 'Missing inputTimeSeriesForXAxis',
      'Missing inputTimeSeriesForYAxis': 'Missing inputTimeSeriesForYAxis',
      'Missing item content.': 'Missing item content.',
      'Missing validity from date.': 'Missing validity from date.',
      'Missing validity to date.': 'Missing validity to date.',
      'Missing variable name.': 'Missing variable name.',
      Model: 'Model',
      'Model number': 'Model Number',
      Modules: 'Modules',
      Modulo: 'Modulo',
      Mon: 'Mon',
      Monday: 'Monday',
      Monitoring: 'Monitoring',
      Month: 'Month',
      'Month interval quantity missing.': 'Month Interval Value is missing.',
      Monthly: 'Monthly',
      Months: 'Months',
      'More actions': 'More actions',
      Morning: 'Morning',
      'Move time series from preview to schedule classifications':
        'Move time series from preview to schedule classifications',
      'Mt analysis flow': 'Mt analysis flow',
      Multiplication: 'Multiplication',
      'Multiplies neighbouring expressions': 'Multiplies Adjacent Expressions',
      'Must be after previous date ""': 'Must be after previous date ""',
      'Must be before or todays date': 'Must be before or todays date',
      'Must be before todays date': 'Must be before todays date',
      'Must be greater than X values from':
        'Must be greater than X values from',
      'Must be greater than minimum value':
        'Must be greater than minimum value',
      'Must be less than X values to': 'Must be less than X values to',
      'Must be less than maximum value': 'Must be less than maximum value',
      'Must be resolved by': 'Must be resolved by',
      N: 'N',
      'N times': 'N times',
      NO: 'NO',
      NOV: 'NOV',
      'NOx per kWh': 'NOx per kWh',
      Name: 'Name',
      'Name or Description': 'Name or Description',
      'Name or last name': 'Name or last name',
      'Name:': 'Name:',
      'National Holiday': 'National Holiday',
      'National Holidays': 'National Holidays',
      'National holidays': 'National Holidays',
      Navigation: 'Navigation',
      'New Action Status': 'New Action Status',
      'New Aggregation': 'New Aggregation',
      'New Alarm Definition': 'New Alarm Definition',
      'New Alarm Type': 'New Alarm Type',
      'New Analysis': 'New Analysis',
      'New Asset': 'New Asset',
      'New Asset Brand': 'New Asset Brand',
      'New Asset Group': 'New Asset Group',
      'New Asset Model': 'New Asset Model',
      'New Asset Status': 'New Asset Status',
      'New Asset Type': 'New Asset Type',
      'New Billing Kind': 'New Billing Kind',
      'New Billing Type': 'New Billing Type',
      'New Business Partner': 'New Business Partner',
      'New Business partner': 'New Business Partner',
      'New Calculation': 'New Calculation',
      'New Company Structure Node': 'New Company Structure Node',
      'New Cost Centre': 'New Cost Centre',
      'New Counter Type': 'New Counter Type',
      'New Dashboard': 'New Dashboard',
      'New Database': 'New Database',
      'New Dataset': 'New Dataset',
      'New Date': 'New Date',
      'New Document Classification': 'New Document Classification',
      'New Domain': 'New Domain',
      'New Domain Attribute': 'New Domain Attribute',
      'New Domain Collection': 'New Domain Collection',
      'New Domain Field': 'New Domain Field',
      'New Energy Management Item': 'New Energy Management Item',
      'New Energy Source Type': 'New Energy Source Type',
      'New Expression': 'New Expression',
      'New External Datasource': 'New External Datasource',
      'New File': 'New File',
      'New Group': 'New Group',
      'New Invoice': 'New Invoice',
      'New Invoice Detail': 'New Invoice Detail',
      'New Job': 'New Job',
      'New Location': 'New Location',
      'New Location Classification': 'New Location Classification',
      'New Location Status': 'New Location Status',
      'New Location Type': 'New Location Type',
      'New Mapping Rule': 'New Mapping Rule',
      'New Measurement Unit': 'New Measurement Unit',
      'New Measuring Point': 'New Measuring Point',
      'New Measuring Point Type': 'New Measuring Point Type',
      'New National Holiday': 'New National Holiday',
      'New Notification': 'New Notification',
      'New OPC': 'New OPC',
      'New Ownership': 'New Ownership',
      'New Physical Quantity': 'New Physical Quantity',
      'New Playlist': 'New Playlist',
      'New Price List': 'New Price List',
      'New Price List Item': 'New Price List Item',
      'New Profit Centre': 'New Profit Centre',
      'New Project': 'New Project',
      'New Project Field': 'New Project Field',
      'New Project Type': 'New Project Type',
      'New Rated Power': 'New Rated Power',
      'New Report': 'New Report',
      'New Response Interval': 'New Response Interval',
      'New Rule': 'New Rule',
      'New SOP': 'New SOP',
      'New Schedule': 'New Schedule',
      'New Scheduler': 'New Schedule',
      'New Severity': 'New Severity',
      'New Step': 'New Step',
      'New Tango Agent Connection Config':
        'New Tango Agent Connection Configuration',
      'New Tango Agent Connection Configuration':
        'New Tango Agent Connection Configuration',
      'New Tariff': 'New Tariff',
      'New Task': 'New Task',
      'New Time Interval Filter': 'New Time Interval Filter',
      'New Time Series': 'New Time Series',
      'New Time Series Group Type': 'New Time Series Group Type',
      'New Time Series Type': 'New Time Series Type',
      'New Time Series Value': 'New Time Series Value',
      'New Web Service': 'New Web Service',
      'New action': 'New action',
      'New aggregation group': 'New Aggregation Group',
      'New alarm definition': 'New Alarm Definition',
      'New analysis': 'New Analysis',
      'New asset': 'New asset',
      'New asset type': 'New Asset Type',
      'New assignee': 'New assignee',
      'New calculation': 'New Calculation',
      'New column': 'New Column',
      'New dashboard': 'New Dashboard',
      'New dashboard playlist': 'New Dashboard Playlist',
      'New dataset': 'New Dataset',
      'New dynamic attribute': 'New dynamic attribute',
      'New expression': 'New Expression',
      'New group': 'New Group',
      'New invoice detail value': 'New invoice detail value',
      'New item': 'New item',
      'New location type': 'New Location Type',
      'New manual input': 'New Manual Input',
      'New mapping rule': 'New mapping rule',
      'New master invoice': 'New Master Invoice',
      'New measuring point': 'New Measuring Point',
      'New measuring point type': 'New Measuring Point Type',
      'New notification': 'New notification',
      'New password': 'New password',
      'New price list item': 'New Price List Item',
      'New price list item group': 'New price list item group',
      'New report': 'New Report',
      'New standard operating procedure': 'New Standard Operating Procedure',
      'New step': 'New Step',
      'New task': 'New Task',
      'New time interval filter': 'New Time Interval Filter',
      'New time series type': 'New time series type',
      'New value': 'New Value',
      Next: 'Next',
      'Next date': 'Next date',
      'Next step': 'Next step',
      Night: 'Night',
      No: 'No',
      'No SOP are connected to this definition.':
        'No SOPs are connected with this definition.',
      'No address and geolocation found.': 'No Address and Geolocation found.',
      'No address found at this location. Set it manually.':
        'No address found at this location. Set it manually.',
      'No alarms': 'No alarms',
      'No attributes yet.': 'No attributes yet.',
      'No connected attributes.': 'No connected attributes.',
      'No content available': 'No content available',
      'No data to display.': 'No data to display.',
      'No date': 'No date',
      'No elements yet.': 'No elements yet.',
      'No entity found': 'No entity found',
      'No gis token found': 'No gis token found.',
      'No heartbeat yet': 'No Heartbeat',
      'No images found for this asset.': 'No images found for this asset.',
      'No images found for this location.':
        'No images found for this location.',
      'No item found.': 'No items found.',
      'No item is currently selected': 'No item is currently selected',
      'No items found': 'No items found.',
      'No items found.': 'No items found.',
      'No items were changed to update': 'No items were changed to update',
      'No location is currently selected': 'No location is currently selected',
      'No modules found.': 'No modules found.',
      'No options available': 'No options available',
      'No options to select from': 'There are no options to select from.',
      'No path found.': 'No path found.',
      'No properties': 'No properties',
      'No properties.': 'No properties',
      'No results': 'No results',
      'No results found for your address. Set coordinates manually.':
        'No results found for your address. Set coordinates manually.',
      'No rule selected': 'No rule selected',
      'No tag is selected': 'No tag is selected',
      'No value': 'No value',
      'No value to display': 'No value to display',
      'No value to display.': 'No value to display.',
      'No value.': 'No value',
      'No values': 'No values',
      'No values on this interval': 'No values on this interval',
      None: 'None',
      'None found.': 'None found.',
      'Normalized Service date from': 'Validity interval from',
      'Normalized Service date to': 'Validity interval to',
      'Not a positive number.': 'Not a positive number.',
      'Not a whole number.': 'Not a whole number.',
      'Not equal': 'Not equal',
      'Not equal to': 'Not equal to',
      'Not periodic': 'Not periodic',
      'Not-Found': 'Not Found',
      Notice: 'Notice',
      'Notification Details': 'Notification Details',
      'Notification content': 'Notification content',
      'Notification services': 'Notification Services',
      'Notification via E-mail': 'Notification via E-mail',
      'Notification via SMS': 'Notification via SMS',
      Notifications: 'Notifications',
      Nov: 'Nov',
      November: 'November',
      'Number of Tariffs': 'Number of Tariffs',
      'Number of tariffs': 'Number of tariffs',
      'Number of values': 'Number of values',
      'Number should be an integer': 'Value should be an integer.',
      OK: 'OK',
      OKT: 'OKT',
      OPC: 'OPC',
      'OPC connection configuration': 'OPC Connection Configuration',
      OPCs: 'OPCs',
      Oct: 'Oct',
      October: 'October',
      'Offset value': 'Offset value',
      'Oh no! Something went wrong :(': 'Something went wrong.',
      'Oh no! The page you are trying to reach does not exist :(':
        'The page you are trying to reach does not exist.',
      'Oh no! You do not have permissions to see this item :(':
        'You do not have permissions to see this item.',
      Ok: 'Ok',
      Okt: 'Okt',
      'On duty': 'On duty',
      'Once per month': 'Once per Month',
      'Only one main schedule classification is allowed':
        'Only one main schedule classification is allowed',
      'Open invoice': 'Open invoice',
      'Open time series filters': 'Open time series filters',
      'Opening counter read out': 'Opening counter read out',
      'Operation {{functionName2}} is not allowed.':
        'Operation {{functionName2}} is not allowed.',
      'Operation {{functionName}} is not allowed.':
        'Operation {{functionName}} is not allowed.',
      'Operational Support Price': 'Operational Support Cost',
      Operations: 'Operations',
      Operator: 'Operator',
      Or: 'Or',
      Order: 'Order',
      'Ordered list': 'Ordered list',
      Organizations: 'Organizations',
      'Output expression': 'Output Expression',
      Owner: 'Owner',
      Ownership: 'Ownership',
      Ownerships: 'Ownerships',
      Page: 'Page',
      Pair: 'Pair',
      'Pair GIS user': 'Pair GIS user',
      'Paired time series type': 'Paired time series type',
      'Pairing settings': 'Pairing settings',
      Parent: 'Parent',
      'Parent Business Partner': 'Parent Business Partner',
      'Parent Company Structure Node': 'Parent Company Structure Node',
      'Parent Location': 'Parent Location',
      'Parent Node': 'Parent Node',
      'Parent business partner': 'Parent business partner',
      'Parent company structure node': 'Parent company structure node',
      'Parent group': 'Parent group',
      'Parent node': 'Parent node',
      'Parent:': 'Parent:',
      Parenthesis: 'Parenthesis',
      'Parse error.': 'Parse error.',
      'Parts per kWh': 'Parts per kWh',
      Password: 'Password',
      'Password confirmation': 'Password confirmation',
      'Password was successfully reset': 'Password was successfully reset.',
      'Password was successfully updated.':
        'Password was successfully updated.',
      'Passwords do not match.': 'Passwords do not match.',
      Path: 'Path',
      'Pattern is not correct': 'Pattern is not correct',
      'Pattern is not correct.\r\n                        ({{historyItem.formConfig.patternExample}})':
        'Pattern is not correct.\n                        ({{historyItem.formConfig.patternExample}})',
      'Pattern is not correct. (04.2018)': 'Pattern is not correct. (04.2018)',
      'Pattern is not correct. ({{configuration.patternExample}})':
        'Pattern is not correct. ({{configuration.patternExample}})',
      'Pattern is not correct. ({{vm.configuration.patternExample}})':
        'Pattern is not correct. ({{vm.configuration.patternExample}})',
      'Pattern is not correct. 22:00': 'Pattern is not correct. 22:00',
      'Pattern is not correct. 23:59:59': 'Pattern is not correct. 23:59:59',
      'Pattern set ({{from}} - {{to}})': 'Pattern set ({{from}} - {{to}})',
      People: 'People',
      'Per page': 'Per page',
      'Performs a logical AND of the two operands.':
        'Performs a logical AND of the two operands.',
      'Performs a logical OR of the two operands.':
        'Performs a logical OR of the two operands.',
      'Period inerval': 'Time Interval',
      'Period interval': 'Time Interval',
      'Period type': 'Period Type',
      Periodic: 'Periodic',
      'Permissions were successfully updated.':
        'Permissions were successfully updated.',
      Person: 'Person',
      'Person responsible': 'Responsible Person',
      Phase: 'Phase',
      Phone: 'Phone',
      'Phone Number': 'Phone Number',
      'Physical Quantities': 'Physical Quantities',
      'Physical Quantity': 'Physical Quantity',
      'Physical information': 'Physical Information',
      'Physical quantity': 'Physical Quantity',
      'Pick a job type': 'Pick a job type',
      'Planned events cannot start prior to two hours from now.':
        'Planned events cannot start prior to two hours from now.',
      'Please select energy source type before selecting order':
        'Please select energy source type before selecting order',
      'Post name': 'Postal Name',
      'Postal number': 'Postal Code',
      Power: 'Power',
      Precision: 'Precision',
      Preset: 'Preset',
      'Press ENTER to confirm input.': 'Press ENTER to confirm input.',
      Preview: 'Preview',
      Previous: 'Previous',
      'Previous Versions': 'Previous Versions',
      'Previous counter read out': 'Previous counter read out',
      'Previous date': 'Previous date',
      'Previous period': 'Previous period',
      'Previous state': 'Previous state',
      'Previous versions': 'Previous versions',
      Price: 'Price',
      'Price List': 'Price List',
      'Price List Detail': 'Price List Detail',
      'Price List Details': 'Price List Details',
      'Price List Item Group Configuration':
        'Price List Item Group Configuration',
      'Price List Item Group Type': 'Price List Item Group Type',
      'Price List Item Groups': 'Price List Item Groups',
      'Price Lists': 'Price Lists',
      'Price list': 'Price List',
      'Price list doesn\'t exist for this issuer, billing date and measuring point.':
        'Price list doesn\'t exist for this issuer, billing date and measuring point.',
      'Price list doesn\'t exist for this supplier, billing date and measuring point.':
        'Price list doesn\'t exist for this supplier, billing date and measuring point.',
      'Price list item': 'Price list item',
      'Price list item groups': 'Price List Item Groups',
      'Price list items': 'Price List Items',
      Primary: 'Primary',
      'Prior to choosing value field name and display field name, make sure to select a domain type':
        'Prior to choosing value field name and display field name, make sure to select a domain type',
      Priority: 'Priority',
      'Privilege Management': 'Privilege Management',
      'Privilege management': 'Privilege management',
      Procedure: 'Procedure',
      Profile: 'Profile',
      'Profit Centre': 'Profit Centre',
      'Profit Centre Tree View': 'Profit Centre Tree View',
      'Profit Centres': 'Profit Centres',
      'Profit centre': 'Profit centre',
      'Profit centres': 'Profit centres',
      Project: 'Project',
      'Project Field': 'Project Field',
      'Project Fields': 'Project Fields',
      'Project Type': 'Project Type',
      'Project Types': 'Project Types',
      'Project field': 'Project field',
      'Project type': 'Project type',
      Projects: 'Projects',
      Properties: 'Properties',
      Protocol: 'Protocol',
      'Provide a different interval': 'Please specify a different interval.',
      'Provide at least one day in the week.':
        'Please specify at least one day in the week.',
      'Provide at least one day.': 'Please specify at least one day.',
      'Provide at least one hour choice.':
        'Please specify at least one hour choice.',
      'Provide at least one hour.': 'Please specify at least one hour.',
      'Provide at least one minute choice.':
        'Please specify at least one minute choice.',
      'Provide at least one minute.': 'Please specify at least one minute.',
      'Provide at least one month.': 'Please specify at least one month.',
      'Provide at least one second.': 'Please specify at least one second.',
      'Provide at least one weekday.': 'Please specify at least one weekday.',
      'Provide day in month.': 'Please specify day in the month.',
      'Provide time in day.': 'Please specify time in the day.',
      Quantity: 'Quantity',
      'Range from *': 'Range from *',
      'Range to *': 'Range to *',
      'Rated Power': 'Rated Power',
      'Rated power': 'Rated power',
      'Rated powers': 'Rated powers',
      'Raw field': 'Raw Field',
      'Raw field name': 'Raw Field Name',
      Read: 'Read',
      'Read Query': 'Read Query',
      'Read Scheduler': 'Read Schedule',
      'Read count': 'Number of read values',
      'Read type': 'Read type',
      'Reading Type': 'Reading Type',
      'Reading date': 'Reading Date',
      'Reading scheduler': 'Read scheduler',
      'Reading type': 'Reading type',
      'Real time count': 'Real time count',
      Recalculate: 'Recalculate',
      'Received Notifications': 'Received Notifications',
      Redirect: 'Redirect',
      'Reduce method': 'Reduce method',
      Refresh: 'Refresh',
      'Refresh interval': 'Refresh interval',
      Refreshing: 'Refreshing',
      'Refreshing values': 'Refreshing values',
      'Regardless of date': 'Regardless of the date',
      Regenerate: 'Regenerate',
      'Regenerate external datasource credentials':
        'Regenerate external datasource credentials',
      'Regenerate normalized dates': 'Invoice validity interval',
      Registers: 'Registers',
      'Regular Expression Mapping Rule': 'Regular Expression Mapping Rule',
      'Related company resources': 'Related Company Resources',
      'Reload manual input values': 'Reload manual input values',
      Remove: 'Remove',
      'Remove Location': 'Remove Location',
      'Remove Sort': 'Remove Sort',
      'Remove Step': 'Remove Step',
      'Remove Task': 'Remove Task',
      'Remove all direct access': 'Remove all direct access',
      'Remove all items from indirect access?':
        'Remove all items from indirect access?',
      'Remove all on page': 'Remove all on page',
      'Remove classification': 'Remove classification',
      'Remove display field': 'Remove display field',
      'Remove group': 'Remove group',
      'Remove indirect access': 'Remove indirect access',
      'Remove item': 'Remove item',
      'Remove layout': 'Remove Layout',
      'Remove mapping rule': 'Remove mapping rule',
      'Remove procedure': 'Remove procedure',
      'Remove role.': 'Remove role',
      'Remove schedule classification': 'Remove schedule classification',
      'Remove selected direct access': 'Remove selected direct access',
      'Remove series': 'Remove series',
      Reorder: 'Reorder',
      Report: 'Report',
      'Report is loading, this may take a while.':
        'Report is loading, this may take a while.',
      Reports: 'Reports',
      'Request failed': 'Request failed',
      Required: 'Required',
      Reselect: 'Reselect',
      Reset: 'Reset',
      'Reset Password': 'Reset Password',
      'Reset zoom': 'Reset zoom',
      'Resolution comment': 'Resolution comment',
      'Resolution in': 'Resolution in',
      Resolve: 'Resolve',
      'Resolve Alarm': 'Resolve Alarm',
      'Resolved at': 'Resolved at',
      Response: 'Response',
      'Response Time': 'Response Time',
      'Response and resolution time': 'Response and Resolution Time',
      'Response time': 'Response Time',
      Responses: 'Responses',
      Responsibility: 'Responsibility',
      'Responsible Person and notifications':
        'Responsible Person and notifications',
      'Responsible person': 'Responsible Person',
      'Responsible personnel': 'Responsible Personnel',
      'Responsible persons and notifications':
        'Responsible persons and notifications',
      'Returns absolute value of input parameter.':
        'Returns absolute value of input parameter.',
      'Returns day of month.': 'Returns day of month.',
      'Returns day of week.': 'Returns day of week.',
      'Returns day of year.': 'Returns day of year.',
      'Returns degree.': 'Returns degrees.',
      'Returns hours.': 'Returns hours.',
      'Returns minutes.': 'Returns minutes.',
      'Returns month.': 'Returns month.',
      'Returns radians.': 'Returns radians.',
      'Returns seconds.': 'Returns seconds.',
      'Returns the arc tangent of the two numbers x and y.':
        'Returns the arc tangent of the two numbers x and y.',
      'Returns the avarage of last number of measurements.':
        'Returns the average of last measurements.',
      'Returns the largest number in arguments.':
        'Returns the largest number in arguments.',
      'Returns the last measurement.': 'Returns the last measurement.',
      'Returns the natural exponential of a number.':
        'Returns the natural exponential of a number.',
      'Returns the natural logarithm of a number to the base 10.':
        'Returns the logarithm of a number to the base 10.',
      'Returns the natural logarithm of a number to the base e (e = 2.71828).':
        'Returns the natural logarithm of a number.',
      'Returns the remaining whole number after division.':
        'Returns the remaining whole number after division.',
      'Returns the smallest number in arguments.':
        'Returns the smallest number in arguments.',
      'Returns the square root of number.':
        'Returns the square root of number.',
      'Returns the value of a number rounded to the nearest integer.':
        'Returns the value of a number rounded to the nearest integer.',
      'Returns true if both operands have the same value.':
        'Returns true if both operands have the same value.',
      'Returns true if the left operand is greater than or equal to the right operand.':
        'Returns True if the left operand is greater than or equal to the right operand.',
      'Returns true if the operands don\'t have the same value.':
        'Returns True if the operands don\'t have the same value.',
      'Returns true if the value on the left is greater than the value on the right.':
        'Returns True if the value on the left is greater than the value on the right.',
      'Returns true if the value on the left is less than the value on the right.':
        'Returns True if the value on the left is less than the value on the right.',
      'Returns year.': 'Returns year.',
      Role: 'Role',
      'Rounds down to nearest natural number.':
        'Rounds down to nearest natural number.',
      'Rounds up to nearest natural number.':
        'Rounds up to nearest natural number.',
      'Rows per page': 'Rows per page',
      Rule: 'Rule',
      'Rule - {{ruleName}}': 'Rule - {{ruleName}}',
      'Rule Item': 'Rule Item',
      'Rule variables': 'Rule variables',
      'Rule {{index}}': 'Rule {{index}}',
      Rules: 'Rules',
      'Rules and alarms': 'Rules and alarms',
      Run: 'Run',
      'Run Analysis': 'Run Analysis',
      'Run Playlist': 'Run Playlist',
      SEP: 'SEP',
      'SO2 per kWh': 'SO2 per kWh',
      SOP: 'SOP',
      'SOP Step': 'SOP Step',
      'SOP Steps': 'SOP Steps',
      'SOP Task': 'SOP Task',
      'SOP Tasks': 'SOP Tasks',
      'Sampling type': 'Data Sampling Type',
      Sat: 'Sat',
      Saturday: 'Saturday',
      Save: 'Save',
      'Save All': 'Save All',
      'Save as': 'Save',
      'Save order': 'Save order',
      'Save time series group': 'Save time series group',
      Scale: 'Scale',
      Schedule: 'Schedule',
      'Schedule Classification': 'Schedule Classification',
      'Schedule classification': 'Schedule classification',
      'Schedule from': 'Scheduled from',
      'Schedule to': 'Scheduled to',
      Scheduler: 'Schedule',
      'Scheduler classification': 'Scheduler classification',
      Schedules: 'Schedules',
      Search: 'Search',
      'Search tags by item name': 'Search tags by item name',
      'Search tags by name': 'Search tags by name',
      'Search tags by tag id': 'Search tags by tag id',
      'Second interval quantity missing.': 'Seconds Interval Value is missing.',
      Seconds: 'Seconds',
      'Secret was copied to clipboard.': 'Secret was copied to clipboard.',
      Seen: 'Seen',
      Select: 'Select',
      'Select Aggregation Type': 'Select Aggregation Type',
      'Select Alarm Type': 'Select Alarm Type',
      'Select Allocator Kind': 'Select Allocator Kind',
      'Select Allocator Type': 'Select Allocator Type',
      'Select Asset': 'Select Asset',
      'Select Asset Brand': 'Select Asset Brand',
      'Select Asset Group': 'Select Asset Group',
      'Select Asset Status': 'Select Asset Status',
      'Select Asset Type': 'Select Asset Type',
      'Select Assignee': 'Select Assignee',
      'Select Billing Kind': 'Select Billing Kind',
      'Select Billing Type': 'Select Billing Type',
      'Select Business Partner': 'Select Business Partner',
      'Select Connection': 'Select Connection',
      'Select Cost Centre': 'Select Cost Centre',
      'Select Cost centre': 'Select Cost centre',
      'Select Counter type': 'Select Counter Type',
      'Select Country': 'Select Country',
      'Select Currency': 'Select Currency',
      'Select Dashboard': 'Select Dashboard',
      'Select Dataset': 'Select Dataset',
      'Select Default Value Type': 'Select Default Value Type',
      'Select Energy Source Type': 'Select Energy Source Type',
      'Select Field type': 'Select Field Type',
      'Select Filter': 'Select Filter',
      'Select Filter type': 'Select Filter Type',
      'Select Format': 'Select Format',
      'Select GIS user': 'Select GIS user',
      'Select Gis User': 'Select Gis User',
      'Select Location': 'Select Location',
      'Select Location Ownership': 'Select Location Ownership',
      'Select Location Status': 'Select Location Status',
      'Select Location Type': 'Select Location Type',
      'Select Measurement Unit': 'Select Measurement Unit',
      'Select Owner': 'Select Owner',
      'Select Parent': 'Select Parent',
      'Select Parent Document Classification':
        'Select Parent Document Classification',
      'Select Parent Energy Source Type': 'Select Parent Energy Source Type',
      'Select Physical Quantity': 'Select Physical Quantity',
      'Select Physical quantity': 'Select Physical Quantity',
      'Select Profit Centre': 'Select Profit Centre',
      'Select Project': 'Select Project',
      'Select Rated Power': 'Select Rated Power',
      'Select Raw field name': 'Select Raw field name',
      'Select Severity': 'Select Severity',
      'Select Tariff': 'Select Tariff',
      'Select Time Interval': 'Select Time Interval',
      'Select Time Series': 'Select Time Series',
      'Select Time Series Type': 'Select Time Series Type',
      'Select Time series': 'Select Time Series',
      'Select Type': 'Select Type',
      'Select Usage type': 'Select Usage type',
      'Select User': 'Select User',
      'Select Y axis value': 'Select Y axis value',
      'Select a Brand': 'Select a Brand',
      'Select a Model': 'Select a Model',
      'Select a cost centre': 'Select a cost centre',
      'Select a document classification': 'Select a document classification',
      'Select a function': 'Select Function',
      'Select a group': 'Select group',
      'Select a person': 'Select Person',
      'Select absent user': 'Select absent user',
      'Select action status': 'Select action status',
      'Select aggregation group': 'Select Aggregation Group',
      'Select alarm': 'Select Alarm',
      'Select alarms': 'Select alarms',
      'Select alarms from the list below': 'Select alarm from the list below.',
      'Select all': 'Select all',
      'Select an Energy Source Type to display used order values.':
        'Select an Energy Source Type to display used order values.',
      'Select an Ownership Type': 'Select an Ownership Type',
      'Select an asset': 'Select Asset',
      'Select analyses': 'Select analyses',
      'Select analyses from the list below':
        'Select analysis from the list below.',
      'Select another source value ID': 'Select another Data Source Value ID',
      'Select asset': 'Select Asset',
      'Select asset from the list below': 'Select asset from the list below',
      'Select at least one': 'Select al least one',
      'Select attribute': 'Select Attribute',
      'Select business partner': 'Select Business Partner',
      'Select business partner from the list below':
        'Select business partner from the list below',
      'Select cache interval': 'Select cache interval',
      'Select calculation': 'Select calculation',
      'Select card background color': 'Select card background color',
      'Select chart color': 'Select chart color',
      'Select chart type': 'Select Chart Type',
      'Select column': 'Select column',
      'Select company structure node': 'Company structure node',
      'Select comparable column': 'Select column for comparison',
      'Select connected external datasource':
        'Select connected external datasource',
      'Select content person': 'Select content person',
      'Select cost centre': 'Select cost centre',
      'Select cost centre from the list below':
        'Select cost centre from the list below',
      'Select counter type': 'Select Counter Type',
      'Select counter type from the list below':
        'Select counter type from the list below',
      'Select country': 'Select country',
      'Select customer': 'Select Customer',
      'Select dashboard *': 'Select dashboard *',
      'Select dashboard card type': 'Select dashboard card type',
      'Select data types': 'Select Data Type',
      'Select dataset': 'Select Dataset',
      'Select date types': 'Select Date Type',
      'Select day': 'Select day',
      'Select default chart type': 'Select default chart type',
      'Select default physical quantity': 'Select default physical quantity',
      'Select default unit of measurement':
        'Select default unit of measurement',
      'Select display type': 'Select display type',
      'Select distributor': 'Select Distributor',
      'Select domain type': 'Select domain type',
      'Select element': 'Select element',
      'Select energy source from the list below':
        'Select energy source type from the list below.',
      'Select energy source type': 'Select Energy Source Type',
      'Select entity': 'Select Entity',
      'Select expression': 'Select Expression',
      'Select external datasource type': 'Select external datasource type',
      'Select filters': 'Select filters',
      'Select format': 'Select format',
      'Select function': 'Select Function',
      'Select group': 'Select group',
      'Select group entity': 'Select group entity',
      'Select icon': 'Select icon',
      'Select invoice issuer': 'Select invoice issuer',
      'Select issuer': 'Select issuer',
      'Select item': 'Select Item',
      'Select language': 'Select language',
      'Select last 100': 'Select last 100',
      'Select location': 'Select location',
      'Select location classification': 'Select Location Classfication',
      'Select location classification from the list below':
        'Select location classification from the list below',
      'Select location from the list below':
        'Select location from the list below',
      'Select main cost centre': 'Select main cost centre',
      'Select measuring point': 'Select Measuring Point',
      'Select measuring point from the list below':
        'Select measuring point from the list below',
      'Select measuringpoint': 'Select measuring point',
      'Select menu': 'Select menu',
      'Select month': 'Select month',
      'Select new valid dates': 'Select new valid dates',
      'Select number of values': 'Select number of values',
      'Select or enter a new tag': 'Select or enter a new tag',
      'Select organization': 'Select organization',
      'Select owner': 'Select Owner',
      'Select paired time series type': 'Select paired time series type',
      'Select parent': 'Select Parent',
      'Select parent Location': 'Select Parent Location',
      'Select parent asset': 'Select Parent Asset',
      'Select parent company structure node':
        'Select parent company structure node',
      'Select parent hierarchy node': 'Select parent hierarchy node',
      'Select parent measuring point': 'Select Parent Measuring Point',
      'Select period interval': 'Select Time Interval',
      'Select physical quantity': 'Select physical quantity',
      'Select project field': 'Select project field',
      'Select project type': 'Select project type',
      'Select refresh Interval': 'Select refresh Interval',
      'Select report dates': 'Select Report dates',
      'Select rule': 'Select rule',
      'Select schedule classification': 'Select schedule classification',
      'Select slice entity': 'Select slice entity',
      'Select sort type': 'Select sort type',
      'Select source value ID': 'Select source value ID',
      'Select status': 'Result',
      'Select substitute user': 'Select substitute user',
      'Select supplier': 'Select Supplier',
      'Select tag': 'Select tag',
      'Select tag from the list below': 'Select tag from the list below',
      'Select tango agent connection configuration':
        'Select tango agent connection configuration',
      'Select tariff': 'Select tariff',
      'Select time series': 'Select time series',
      'Select time series to add to classification':
        'Select time series to add to classification',
      'Select time series type': 'Select time series type',
      'Select time unit': 'Select time unit',
      'Select type': 'Select Type',
      'Select unit of measurement': 'Select unit of measurement',
      'Select user': 'Select user',
      'Select value field': 'Select Value Field',
      'Select visualization': 'Select visualization',
      'Select visualization from the list below':
        'Select visualization from the list below',
      'Select weather station': 'Select Weather Station',
      'Select x axis values': 'Select x axis values',
      'Select year': 'Select year',
      Selected: 'Selected',
      'Selected Icon:': 'Selected Icon:',
      'Selected date does not correspond to valid External Reader Flow Configuration.':
        'Selected date does not correspond to valid External Reader Flow Configuration.',
      'Selected icon:': 'Selected icon:',
      'Selected period': 'Selected period',
      'Selected series': 'Selected series',
      'Selected theme': 'Selected theme',
      'Selected:': 'Selected:',
      'Selection of instances is currently empty. Click on entity name to add\r\n        instances.':
        'Selection of instances is currently empty. Click on entity name to add\n        instances.',
      'Selector type': 'Selector type',
      'Send E-mail notifications': 'Send E-mail notifications',
      'Send SMS notifications': 'Send SMS notifications',
      Sep: 'Sep',
      September: 'September',
      'Serial number': 'Serial Number',
      Series: 'Series',
      'Series name': 'Series Name',
      'Server Error': 'Server Error',
      'Service Date From': 'Service Date From',
      'Service Date To': 'Service Date To',
      'Service date from': 'Service date from',
      'Service date to': 'Service date to',
      'Set address': 'Set Address',
      'Set coordinates': 'Set Coordinates',
      'Set date time interval': 'Set date time interval',
      'Set validity interval': 'Set validity interval',
      Settings: 'Settings',
      Settlement: 'Settlement',
      'Settlement Name': 'Settlement Name',
      'Settlement name': 'Settlement Name',
      Severities: 'Severities',
      Severity: 'Severity',
      'Share link': 'Share link',
      'Share time series group': 'Share time series group',
      'Show Hierarchy': 'Show Hierarchy',
      'Show asset children': 'Show Child Assets',
      'Show chart': 'Show chart',
      'Show data table': 'Show Data Table',
      'Show energy values': 'Show energy values',
      'Show seen notifications': 'Show seen notifications',
      'Sine function.': 'Sine function',
      Size: 'Size',
      'Slice entity': 'Slice entity',
      'Smart City': 'Smart City',
      'Sms content': 'Sms content',
      'Sms notification': 'Sms notification',
      'Sms receivers': 'Sms receivers',
      Solution: 'Solution',
      'Some items couldn\'t be removed': 'Some items couldn\'t be removed.',
      'Some items couldn\'t be updated': 'Some items couldn\'t be updated.',
      'Something went wrong during file {{fileName}} upload':
        'Something went wrong during file {{fileName}} upload',
      'Something went wrong loading analysis data.':
        'Something went wrong loading analysis data.',
      'Sop Type': 'SOP Type',
      Sort: 'Sort',
      'Sort type': 'Sort type',
      'Source value ID': 'Data Source value ID',
      Stakeholder: 'Stakeholder',
      Stakeholders: 'Stakeholders',
      'Standard Op. Procedures': 'Standard Op. Procedures',
      'Standard Operating Procedure': 'Standard Operating Procedure',
      'Standard Operating Procedures': 'Standard Operating Procedures',
      'Standard operating procedures': 'Standard operating procedures',
      'Standard operational procedure step':
        'Standard operating procedures step',
      'Standard operational procedure task':
        'Standard operating procedures task',
      Start: 'Start',
      'Start date': 'Start Date',
      'Start date must be before End date.':
        'Start Date must be before End Date.',
      'Start date must be before an end date.':
        'Start date must be before an end date.',
      'Start date:': 'Start Date:',
      'Start of Billing Period *': 'Start of Billing Period *',
      'Start of Project *': 'Start of Project *',
      'Start of availability': 'Start of asset availability',
      'Start of billing period': 'Start of billing period',
      'Start of project': 'Start of project',
      Started: 'Started',
      'Starting On': 'Starting On',
      Statistics: 'Statistics',
      Status: 'Status',
      Step: 'Step',
      'Step Details': 'Step Details',
      Steps: 'Steps',
      'Steps of the procedure': 'Procedure steps ',
      Stop: 'Stop',
      'Stream Archive Property': 'Stream Archive Property',
      'Stream Archive property': 'Stream Archive Property',
      Street: 'Street',
      'Street name': 'Street Name',
      String: 'String',
      'Strings are not allowed in expressions. (Regarding {{value}})':
        'Strings are not allowed in expressions. (Regarding {{value}})',
      Subject: 'Subject',
      'Submit job': 'Submit Job',
      Subscript: 'Subscript',
      'Subsidiary locations': 'Child locations',
      Substraction: 'Substraction',
      'Substracts neighbouring expressions': 'Substracts adjacent expressions',
      Sum: 'Sum',
      Sun: 'Sun',
      Sunday: 'Sunday',
      'Superior Location': 'Parent Location',
      Superscript: 'Superscript',
      Supplier: 'Supplier',
      'Supplier Code': 'Supplier\'s Code',
      'Supplier code': 'Supplier\'s Code',
      'Supplier price list': 'Supplier Price List',
      'Supported operators and functions': 'Supported operators and functions',
      Surname: 'Surname',
      Symbol: 'Symbol',
      'Sync external datasource values': 'Sync external datasource values',
      System: 'System',
      'System Tags': 'System tags',
      'System notification users': 'System notification users',
      'System responsible person': 'System responsible person',
      'System tags': 'System tags',
      TIS: 'TIS',
      TRUE: 'TRUE',
      'Tag Selector': 'Tag Selector',
      'Tag column': 'Tag Column',
      'Tag column name': 'Tag Column Name',
      'Tag explorer': 'Tag explorer',
      'Tag on source': 'Tag on source',
      Tags: 'Tags',
      'Tangent function.': 'Tangent function',
      'Tango Agent Configuration Type': 'Tango Agent Configuration Type',
      'Tango Agent Connection': 'Tango Agent Connection Configuration',
      'Tango Agent Connection Config': 'Tango Agent Connection Configuration',
      'Tango Agent Connection Configuration':
        'Tango Agent Connection Configuration',
      'Tango Agent Connection Configurations':
        'Tango Agent Connection Configurations',
      'Tango agent config': 'Tango Agent Connection Configuration',
      'Tango agent connection configuration':
        'Tango agent connection configuration',
      Target: 'Target',
      'Target function': 'Target Function',
      'Target {{equation}}': 'Target {{equation}}',
      Tariff: 'Tariff',
      'Tariff Configurations': 'Tariff Configurations',
      'Tariff:': 'Tariff:',
      Tariffs: 'Tariffs',
      Task: 'Task',
      'Task description': 'Task Description',
      'Task.': 'Task',
      Tasks: 'Tasks',
      TasksTitle: 'Tasks',
      'Tax Number': 'Tax Number',
      'Tax number': 'Tax Number',
      Temperature: 'Temperature',
      Text: 'Text',
      'Text area': 'Text area',
      'Text color': 'Text color',
      'There are currently no order values selected for this Energy Source Type.':
        'There are currently no order values selected for this Energy Source Type.',
      'There are no Aggregation types to select from.':
        'There are no aggregation types to select from.',
      'There are no Cost centres to select.':
        'There are no Cost centres to select.',
      'There are no Cumulative Expressions.':
        'There are no Cumulative Expressions.',
      'There are no Heartbeats bound to this object.':
        'There are no heartbeats bound to this object.',
      'There are no action statuses to select from.':
        'There are no action statuses to select from.',
      'There are no aggregation groups.': 'There are no aggregation groups.',
      'There are no attributes selected': 'There are no attributes selected',
      'There are no authorized groups.': 'There are no authorized groups.',
      'There are no authorized users.': 'There are no authorized users.',
      'There are no calculations.': 'No Calculations.',
      'There are no columns.': 'There are no columns.',
      'There are no comparable columns yet': 'There are no columns to compare.',
      'There are no comparable columns.': 'There are no columns to compare.',
      'There are no data to be saved!': 'There is no data to be saved!',
      'There are no elements to select.': 'There are no elements to select.',
      'There are no expressions yet': 'There are no expressions.',
      'There are no filters selected yet': 'There are no filters selected.',
      'There are no formats to select.': 'There are no formats to select.',
      'There are no groups to select.': 'There are no groups to select.',
      'There are no invoice detail values yet.':
        'There are no invoice detail values.',
      'There are no issuers to select.': 'There are no issuers to select.',
      'There are no items': 'There are no items',
      'There are no items selected': 'No items selected',
      'There are no items to select.': 'There are no items to select.',
      'There are no items yet': 'There are no items',
      'There are no items.': 'No Items found',
      'There are no job types to select from.':
        'There are no job types to select from.',
      'There are no layouts': 'There are no layouts.',
      'There are no manual readings': 'There are no manual readings',
      'There are no map id': 'There are no map id\'s.',
      'There are no more Rules to select': 'There are no more rules to select.',
      'There are no options available': 'There are no options available.',
      'There are no options selected': 'There are no options selected.',
      'There are no organizations to select.':
        'There are no organizations to select.',
      'There are no period intervals.': 'There are no time intervals.',
      'There are no previous values': 'There are no previous values',
      'There are no registers.': 'There are no registers.',
      'There are no schedule classifications':
        'There are no schedule classifications',
      'There are no selected items': 'There are no selected items',
      'There are no tags to select.': 'There are no tags to select.',
      'There are no tags.': 'There are no tags.',
      'There are no tasks': 'There are no tasks',
      'There are no tasks yet': 'There are no tasks yet.',
      'There are no tasks.': 'No tasks found.',
      'There are no time series': 'There are no time series',
      'There are no time series selected': 'There are no time series selected',
      'There are no time series type attributes yet':
        'There are no time series type attributes yet',
      'There are no users to select.': 'There are no users to select.',
      'There are no values': 'There are no values.',
      'There are no values.': 'There are no values.',
      'There are no variable names': 'There are no variable names',
      'There are no variables': 'There are no variables',
      'There are no x axis values.': 'There are no x axis values.',
      'There are too many values for selected period try to reduce selected interval':
        'There are too many values for selected period. Try to reduce selected interval.',
      'There are {{vm.steps.length}} steps bound to this sop.': [
        'There are steps bound to this SOP.',
        'There are {{sop.step.length}} steps bound to this SOP.'
      ],
      'There is no billing data': 'There is no billing data',
      'There is no configuration': 'There is no configuration',
      'There is no currency to select.': 'There is no currency to select.',
      'There is no gis data': 'There is no gis data',
      'There is no price list': 'There is no price list',
      'There is no selected item': 'There is no selected item',
      'There is no tag selected': 'There is no tag selected',
      'There is no time series to generate report':
        'There is no time series to generate report.',
      'There is one standard operating procedure bound to this alarm': [
        'There is one standard operating procedure bound to this alarm',
        'There are {{\n}} standard operating procedures bound to this alarm.'
      ],
      'There should only be 6 sections of cron.':
        'There should only be 6 sections of cron.',
      'There was an error for the following reason: {{status}}':
        'There was an error for the following reason: {{status}}',
      'There were no activities yet.': 'There were no activities.',
      'These credentials are required for installation of TIS client on your device. Make sure you have stored them safely. Device secret will never be shown anymore!':
        'These credentials are required for installation of TIS client on your device. Make sure you have stored them safely. Device secret will never be shown anymore!',
      'These credentials are required for installation of TIS client on your external datasource. Make sure you have stored them safely. External datasource secret will never be shown anymore!':
        'These credentials are required for installation of TIS client on your external datasource. Make sure you have stored them safely. External datasource secret will never be shown anymore!',
      'This attribute hasn\'t been filled in yet.': 'No value entered.',
      'This boundary overlaps with another.':
        'This boundary overlaps with another.',
      'This field is required': 'This field is required.',
      'This field is required.': 'This field is required.',
      'This item has no rules yet.': 'This item has no rules yet.',
      'This manual input has no groups yet':
        'This manual input has no groups yet.',
      'This procedure has no steps.': 'This procedure has no steps.',
      'This value is already taken for the specified energy source type. Please select a different value.':
        'This value is already taken for the specified energy source type. Please select a different value.',
      Thu: 'Thu',
      Thursday: 'Thursday',
      Time: 'Time',
      'Time Filters': 'Time Filters',
      'Time Interval': 'Time Interval',
      'Time Interval Filter': 'Time Interval Filter',
      'Time Interval Filters': 'Time Interval Filters',
      'Time Series': ['Time Series', 'Time Series'],
      'Time Series - External Writer Flows': [
        'Time Series - External Writer Flows',
        'Time Series - External Writer Flows'
      ],
      'Time Series - History': [
        'Time Series - History',
        'Time Series - History'
      ],
      'Time Series - Live': ['Time Series - Live', 'Time Series - Live'],
      'Time Series Group Sandbox': 'Time Series Group Sandbox',
      'Time Series Group Type': 'Time Series Group Type',
      'Time Series Group Types': 'Time Series Group Types',
      'Time Series Groups': 'Time Series Groups',
      'Time Series Type': 'Time Series Type',
      'Time Series Types': 'Time Series Types',
      'Time Series active flow is not mt analysis flow':
        'Time Series active flow is not mt analysis flow',
      'Time Series value was filled with default values':
        'Time Series value was filled with default values',
      'Time Series were successfully created':
        'Time Series were successfully created',
      'Time To Trigger': 'Time to Trigger',
      'Time Zone': 'Time Zone',
      'Time from': 'Time from',
      'Time in day (14:20)': 'Time in day (14:20)',
      'Time interval filter': 'Time interval filter',
      'Time interval filter type': 'Time Interval Filter Type',
      'Time intervals': 'Time intervals',
      'Time is not valid': 'Time is not valid',
      'Time offset': 'Offset',
      'Time period': 'Time period',
      'Time series': ['Time series', 'Time series'],
      'Time series are in different time zones.':
        'Time series are in different time zones.',
      'Time series card': 'Time series card',
      'Time series group preview': 'Time series group preview',
      'Time series group type': 'Time series group type',
      'Time series groups': 'Time series groups',
      'Time series has no configurations yet':
        'Time series has no configurations yet',
      'Time series name': 'Time series name',
      'Time series status': 'Time series status',
      'Time series successfully created!':
        'Time series was successfully created!',
      'Time series successfully updated!':
        'Time series was successfully updated!',
      'Time series type': 'Time series type',
      'Time series type attributes': 'Time series type attributes',
      'Time series type:': 'Time series type:',
      'Time series value was successfully created!':
        'Time series value was successfully created!',
      'Time to': 'Time to',
      'Time to trigger': 'Time to trigger',
      'Time unit': 'Time unit',
      'Time until alarm should be resolved':
        'Time by which the alarm should be resolved',
      'Time zone': 'Time zone',
      Timeliness: 'Timeliness',
      Timeout: 'Timeout',
      'Timeout Alarm': 'Timeout Alarm',
      'Timeout action': 'Timeout action',
      'Timeout alarm': 'Timeout Alarm',
      'Timeout settings': 'Timeout Settings',
      Title: 'Title',
      To: 'To',
      'To continue plase log in.': 'Please login to continue. ',
      'To value should me less than Value from':
        'From value should be less than Value to',
      Today: 'Today',
      'Toggle main dashboard': 'Toggle main dashboard',
      Total: 'Total',
      'Total count': 'Total number of values',
      'Total with VAT': 'Total with VAT',
      'Total with VAT:': 'Total with VAT:',
      'Total with Vat max': 'Total with VAT to',
      'Total with Vat min': 'Total with VAT from',
      'Total without VAT': 'Total without VAT',
      'Total without VAT:': 'Total without VAT:',
      'Total {{total}}{{currency}}. Total amount left {{amountLeft}}{{currency}}':
        'Total {{total}}{{currency}}. Total amount left {{amountLeft}}{{currency}}',
      'Trailing zeroes are not allowed.': 'Trailing zeroes are not allowed.',
      'Transfer To Next Month': 'Transfer To Next Month',
      'Transfer to next month': 'Transfer to next month',
      'Transformation To kWh': 'Transformation To kWh',
      'Transformation to kWh': 'Transformation to kWh',
      'Translations are missing!': 'Translations are missing!',
      'Trigger type': 'Trigger type',
      Triggered: 'Triggered',
      'Triggered Alarm': 'Triggered Alarm',
      'Triggered Alarms': 'Triggered Alarms',
      'Triggered alarm': 'Triggered alarm',
      'Triggered at': 'Triggered at',
      'Triggered at {{entityName}}': 'Triggered at {{entityName}}',
      'Triggers an alarm': 'Triggers an alarm',
      'Triggers an error': 'Triggers an error',
      'Triggers calculation': 'Triggers calculation',
      'Triggers on Holidays': 'Triggers on holidays',
      True: 'True',
      Tue: 'Tue',
      Tuesday: 'Tuesday',
      Type: 'Type',
      'Type:': 'Type',
      URL: 'URL',
      'URL Document': 'URL Document',
      'URL document': 'URL document',
      Undefined: 'Undefined',
      Underline: 'Underline',
      'Unexpected end of expression (character at position {{char}}).':
        'Unexpected end of expression (character at position {{char}}).',
      'Unique Number': 'Unique Number',
      'Unique number': 'Unique Number',
      Unknown: 'Unknown',
      'Unknown Alarm': 'Unknown alarm',
      'Unknown Flow': 'Unknown flow',
      'Unknown Tariff': 'Unknown Tariff',
      'Unknown Time Series': 'Unknown time series',
      'Unknown action for {{measuringpointName}}':
        'Unknown action for {{measuringpointName}}',
      'Unknown allocator type': 'Unknown allocator type',
      'Unknown attribute type': 'Unknown attribute type',
      'Unknown data type': 'Unknown data type',
      'Unknown energy source type': 'Unknown energy source type',
      'Unknown file type': 'Unknown file type',
      'Unknown interpretation type': 'Unknown data interpretation type',
      'Unknown invoice type': 'Unknown invoice type',
      'Unknown maintenance flow type': 'Unknown maintenance flow type',
      'Unknown measuring point': 'Unknown measuring point',
      'Unknown sampling type': 'Unknown data sampling type',
      'Unknown schedule classification': 'Unknown schedule classification',
      'Unknown scheduler': 'Unknown scheduler',
      'Unknown tag': 'Unknown tag',
      'Unknown time series status': 'Unknown time series status',
      'Unknown time zone': 'Unknown time zone',
      'Unknown trigger type': 'Unknown trigger type',
      'Unknown type': 'Unknown type',
      'Unknown user': 'Unknown user',
      'Unknown value': 'Unknown value',
      Unpair: 'Unpair',
      Unseen: 'Unseen',
      Update: 'Update',
      'Update Action': 'Update Action',
      'Update Asset Availability': 'Update Asset Availability',
      'Update M&T analysis flow': 'Update M&T analysis flow',
      'Update Rated Power': 'Update Rated Power',
      'Update an absence': 'Update an absence',
      'Update an assignment': 'Update an assignment',
      'Update calculated flow': 'Update calculated flow',
      'Update domain': 'Edit Domain',
      'Update external reader flow': 'Update external reader flow',
      'Update external writer flow': 'Update external writer flow',
      'Update groups': 'Update groups',
      'Update maintenance flow': 'Update maintenance flow',
      'Update value': 'Update value',
      Updated: 'Updated',
      Upload: 'Upload',
      'Upload CSV file': 'Upload CSV file',
      'Upload file': 'Upload file',
      'Upload image': 'Upload image',
      Uploaded: 'Uploaded',
      Uploading: 'Uploading',
      'Upper Limit %': 'Upper limit %',
      'Upper Limit Alarm': 'Upper Limit Alarm',
      'Upper Limits': 'Upper limits',
      'Usage Type': 'Usage Type',
      'Use aggregation group as x axis': 'Use aggregation group as x axis',
      'Use current time series as input parameter':
        'Use current time series as input parameter',
      'Use security': 'Use Security',
      'Use timeout': 'Use timeout',
      'Used order values': 'Used order values',
      'Used order values for {{name}}': 'Used order values for {{name}}',
      User: 'User',
      'User Entities': 'User Entities',
      'User Group Modules': 'User Group Modules',
      'User Modules': 'User Modules',
      'User Privilege Management': 'User Privilege Management',
      'User access': 'User Access',
      Username: 'Username',
      Users: 'Users',
      'VALIDATION ERRORS': 'VALIDATION ERRORS',
      'Valid From': 'Valid From',
      'Valid To': 'Valid To',
      'Valid from': 'Valid from',
      'Valid from *': 'Valid from *',
      'Valid from can not be prior to start date of asset availability':
        'Valid from can not be prior to start date of asset availability',
      'Valid from date must be before valid to date.':
        'Valid from Date must be before Valid to Date.',
      'Valid from should not intersect with previous valid to':
        'Valid from should not intersect with previous valid to',
      'Valid to': 'Valid to',
      'Valid to *': 'Valid to *',
      'Validation Fail': 'Validation Fail',
      'Validation Success': 'Validation Success',
      'Validity date from should be prior to validity date to.':
        'Validity date from should be prior to validity date to.',
      'Validity interval from': 'Validity interval from',
      'Validity interval to': 'Validity interval to',
      Value: 'Value',
      'Value card was successfully updated':
        'Value card was successfully updated.',
      'Value column': 'Value column',
      'Value column name': 'Value column name',
      'Value details': 'Value details',
      'Value field name': 'Value field name',
      'Value is not a number': 'Value is not a number',
      'Value must be an integer': 'Value must be an integer',
      'Value must be between  and': 'Value must be between  and',
      'Value must be between -100 and 100':
        'Value must be between -100 and 100',
      'Value must be between 0 and 23': 'Value must be between 0 and 23',
      'Value must be between 0 and 52': 'Value must be between 0 and 52',
      'Value must be between 0 and 59': 'Value must be between 0 and 59',
      'Value must be between 1 and 12': 'Value must be between 1 and 12',
      'Value must be between 1 and 31': 'Value must be between 1 and 31',
      'Value must be between 1970 and 2099':
        'Value must be between 1970 and 2099',
      'Value must be equal or greater than':
        'Value must be equal or greater than',
      'Value must be equal or less than': 'Value must be equal or less than',
      'Value should be an integer.': 'Value should be an integer.',
      'Value:': 'Value:',
      Values: 'Values',
      'Values from': 'Values from',
      'Values to': 'Values to',
      'Values were successfully uploaded': 'Values were successfully uploaded',
      'Variable name': 'Variable Name',
      'Variable names must be surrounded by double quotes {{name}}.':
        'Variable names must be surrounded by double quotes {{name}}.',
      'Variable {{name}} is not defined': 'Variable {{name}} is not defined',
      'Variable {{name}} is not defined.': 'Variable {{name}} is not defined.',
      'Variable {{value}} is not defined': 'Variable {{value}} is not defined',
      Variables: 'Variables',
      Version: 'Version',
      'View Counter Type': 'View Counter Type',
      Viewer: 'Viewer',
      Visualization: 'Visualization',
      Visualizations: 'Visualizations',
      WARNING: 'WARNING',
      Warning: 'Warning',
      'Weather item is loading ...': 'Weather item is loading ...',
      'Weather station': 'Weather Station',
      'Weather station code is missing': 'Weather station code is missing',
      'Web Service': 'Web Service',
      'Web Services': 'Web Services',
      'Webservice connection configuration':
        'Web Service Connection Configuration',
      Wed: 'Wed',
      Wednesday: 'Wednesday',
      Week: 'Week',
      'Weekday interval quantity missing.':
        'Weekday Interval Value is missing.',
      Weekdays: 'Weekdays',
      'When expression below will evaluate to true, this rule will trigger notices and / or alarms.':
        'When expression below will evaluate to true, this rule will trigger notices and / or alarms.',
      'When reading data (according to read scheduler), system reads all data between the time of last read data and the current time of reading data. Timestamp of such data is copied from the source data. When reading data for the first time, length of time interval is defined with attribute “First Batch Interval”.':
        'When reading data (according to read scheduler), system reads all data between the time of last read data and the current time of reading data.\nTimestamp of such data is copied from the source data.\nWhen reading data for the first time, length of time interval is defined with attribute “First Batch Interval”.',
      'When reading data (according to read scheduler), system reads all data between the time of read and the time interval defined with attribute “Future interval”. Timestamp of such data is copied from the source data.':
        'When reading data (according to read scheduler), system reads all data between the time of read and the time interval defined with attribute “Future interval”.\nTimestamp of such data is copied from the source data.',
      'When reading data (according to read scheduler), system reads current value at the source (OPC, database, …). Timestamp of such data is always time of read (according to read scheduler).':
        'When reading data (according to read scheduler), system reads current value at the source (OPC, database, …).\nTimestamp of such data is always time of read (according to read scheduler).',
      'When the input value is not in the specified data point format and  none of the above rules apply, use this to remap it to a custom value. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'When the input value is not in the specified data point format and  none of the above rules apply, use this to remap it to a custom value. If this should trigger an error, alarm or signal a manual check, flag it so.',
      'When time series is a trigger, expression below will be evaluated when a new time series value arrives.':
        'When time series is a trigger, expression below will be evaluated when a\n      new time series value arrives.',
      'Would you like to remove value': 'Would you like to remove value',
      'Would you like to return to your main dashboard?':
        'Would you like to return to your main dashboard?',
      'Write Query': 'Write Query',
      'Write back rules': 'Write back rules',
      'Write cancel rules': 'Write cancel rules',
      'Wrong cron format.': 'Wrong cron format.',
      'Wrong data format': 'Wrong data format',
      'Wrong date format': 'Wrong date format',
      'Wrong next date format': 'Wrong next date format',
      'Wrong selected date format': 'Wrong selected date format',
      'X Coordinate': 'X Coordinate',
      'X axis': 'X axis',
      'X values from': 'X values from',
      'X values to': 'X values to',
      'Y Coordinate': 'Y Coordinate',
      'Y axis': 'Y axis',
      'Y axis value': 'Y axis value',
      YES: 'YES',
      Year: 'Year',
      'Year from': 'Year from',
      'Year to': 'Year to',
      Yearly: 'Yearly',
      Yes: 'Yes',
      'You are not allowed to select more than 300 items':
        'You are not allowed to select more than 300 items',
      'You can\'t delete last baseline': 'You can\'t delete last baseline!',
      'You have no menu items': 'You have no menu items',
      'You have no new notifications': 'You have no new notifications',
      'You have to add at least one of the following filters:':
        'You have to add at least one of the following filters:',
      'You have to use at least one variable':
        'You have to use at least one variable',
      'You need to run analysis': 'You need to run analysis',
      'Your are not allowed to edit time series values for this date':
        'Your are not allowed to edit time series values for this date',
      'Zoom to last:': 'Zoom to last:',
      active: 'active',
      add: 'add',
      'add new': 'add new',
      'add users to group': 'add users to group',
      'alarm definition': 'alarm definition',
      all: 'all',
      'all day': 'all day',
      'assign gis pair': 'Assign GIS Pair',
      at: 'at',
      avg: 'Average value',
      bad: 'Bad',
      'billing ASC': 'billing ASC',
      'billing DESC': 'billing DESC',
      cancel: 'cancel',
      circle: 'Circle',
      close: 'close',
      'confirm seen': 'confirm seen',
      connection: 'connection',
      'cost centre': 'cost centre',
      create: 'create',
      'created ASC': 'created ASC',
      'created DESC': 'created DESC',
      d: 'd',
      database: 'database',
      'date should be within external reader flow date limits':
        'Date should be within external reader flow limits.',
      day: 'day',
      days: 'days',
      delete: 'delete',
      'device contacts': 'device contacts',
      display: 'display',
      'displayed horizontally': 'Displayed Horizontally',
      'displayed vertically': 'Displayed Vertically',
      'does not EQUAL': 'does not EQUAL',
      duplicate: 'duplicate',
      edit: 'edit',
      entity_name: 'entity_name',
      'external datasource': 'external datasource',
      'fetch failed': 'fetch failed',
      'file settings': 'file settings',
      filter: 'filter',
      freehand: 'Freehand',
      'get values': 'get values',
      'gis groups': 'gis groups',
      good: 'Good',
      'group name': 'Group Name',
      'hh:mm:ss': 'hh:mm:ss',
      'hide address': 'hide address',
      hour: 'hour',
      hours: 'hours',
      icon: 'icon',
      if: 'if',
      'in progress': 'in progress',
      inactive: 'inactive',
      input: 'input',
      'is LESS than': 'is LESS than',
      'is LESS than OR equals': 'is LESS than OR equals',
      'is MORE than': 'is MORE than',
      'is MORE than OR equals': 'is MORE than OR equals',
      kWh: 'kWh',
      'last N months': 'last N months',
      'last N years': 'last N years',
      lastname: 'lastname',
      'less than a second': 'less than a second',
      list: 'list',
      litres: 'litres',
      'load more ...': 'load more ...',
      location: 'location',
      login: 'login',
      'mapping rule': 'mapping rule',
      max: 'Maximum value',
      min: 'Minimum value',
      minute: 'minute',
      month: 'month',
      months: 'months',
      'name ASC': 'name ASC',
      'name DESC': 'name DESC',
      'new component': 'new component',
      'new visualization': 'new visualization',
      'number ASC': 'Number ASC',
      'number DESC': 'Number DESC',
      'occured ASC': 'occurred ASC',
      'occured DESC': 'occurred DESC',
      of: 'of',
      ok: 'ok',
      'open dialog': 'open dialog',
      'open location tree': 'open location tree',
      'out of': 'out of',
      page: 'page',
      'per page': 'per page',
      'person Responsible': 'Responsible Person',
      point: 'Point',
      polygon: 'Polygon',
      'price list': 'price list',
      properties: 'properties',
      read: 'read',
      remove: 'remove',
      'remove file': 'remove file',
      return: 'return',
      'returns true if the left operand is less than or equal to the right operand.':
        'Returns True if the left operand is less than or equal to the right operand.',
      root: 'root',
      rule: 'rule',
      'rule expression': 'rule expression',
      s: 's',
      save: 'save',
      search: 'Search',
      sec: 'sec',
      second: '0 seconds',
      'seen first': 'seen first',
      'select alarm': 'select Alarm',
      'select rule': 'select rule',
      'set from address': 'set from address',
      settings: 'settings',
      stakeholderName: 'stakeholderName',
      'standard operating procedure': 'standard operating procedure',
      step: 'step',
      submeterMeasuringPointName: 'submeterMeasuringPointName',
      substitute: 'substitute',
      'tango agent connection configurations':
        'tango agent connection configurations',
      translate: 'translate',
      'tree view': 'tree view',
      unknown: 'unknown',
      'unseen first': 'unseen first',
      update: 'update',
      username: 'username',
      'value format must be hh:mm:ss (23:59:59)':
        'value format must be hh:mm:ss (23:59:59)',
      week: 'week',
      'works at': 'works at',
      year: 'year',
      years: 'years',
      'yyyy-mm-dd': 'yyyy-mm-dd',
      '{{ item.title | translate}}': '{{ item.title | translate}}',
      '{{ model.communityName || \'None\' }}':
        '{{ model.communityName || \'None\' }}',
      '{{ model.literalAddress || \'None\' }}':
        '{{ model.literalAddress || \'None\' }}',
      '{{ model.settlementName || \'None\' }}':
        '{{ model.settlementName || \'None\' }}',
      '{{ vm.values[badge.param] | numberFormat | translate}}':
        '{{ vm.values[badge.param] | numberFormat | translate}}',
      '{{alarmName}} at {{timeSeriesName}}':
        '{{alarmName}} at {{timeSeriesName}}',
      '{{elementType}} unknown parameter': '{{elementType}} unknown parameter',
      '{{entityName}}': '{{entityName}}',
      '{{functionName}} is not a valid function.':
        '{{functionName}} is not a valid function.',
      '{{notCreated}} out of {{invoiceListDetailsNumber}} detail invoices couldn\'t be duplicated.':
        '{{notCreated}} out of {{invoiceListDetailsNumber}} invoices couldn\'t be duplicated.',
      '{{options.empty | translate}}': '{{options.empty | translate}}',
      '{{pos}} should be on interval [0 - 23].':
        '{{pos}} should be on interval [0 - 23].',
      '{{pos}} should be on interval [0 - 31].':
        '{{pos}} should be on interval [0 - 31].',
      '{{pos}} should be on interval [0 - 59].':
        '{{pos}} should be on interval [0 - 59].',
      '{{pos}} should be on interval [0 - 7].':
        '{{pos}} should be on interval [0 - 7].',
      '{{pos}} should be on interval [1 - 12].':
        '{{pos}} should be on interval [1 - 12].',
      '{{timeSeriesId}} Some values couldn\'t be saved. Values Inserted - {{valuesInserted}}, Values Discarded {{valuesDiscarded}}':
        '{{timeSeriesId}} Some values couldn\'t be saved. Values Inserted - {{valuesInserted}}, Values Discarded {{valuesDiscarded}}',
      '{{value}} last months': '{{value}} last months',
      '{{value}} last years': '{{value}} last years',
      '{{vm.limits[key].title | translate}}':
        '{{vm.limits[key].title | translate}}'
    })
    gettextCatalog.setStrings('hr', {
      alarm: { alarmCard: ['alarm', 'alarm', 'alarm'], $$noContext: 'alarm' },
      '(0 - 23)': '(0 - 23)',
      '(0 - 59)': '(0 - 59)',
      '(1 - 12)': '(1 - 12)',
      '(1 - 31)': '(1 - 31)',
      '(1 - 7)': '(1 - 7)',
      '(Active)': '(Aktivno)',
      '(Drag and drop to select an element)':
        '(Povucite i ispustite da biste odabrali element)',
      '(Variable names must be surrounded by quotes)':
        '(Imena varijabli moraju biti okružena navodnicima)',
      '(default)': 'Zadano',
      '+ (0 - 23)': '+ (0 - 23)',
      '+ (0 - 59)': '+ (0 - 59)',
      '+ (1 - 31)': '+ (1 - 31)',
      '+ (1 - 7)': '+ (1 - 7)',
      '+ Add': '+ Dodaj',
      '+ Add Arithmetic Expression': '+ Dodaj aritmetički izraz',
      '+ Add Asset': '+ Dodaj sredstvo',
      '+ Add Boundary': '+ Dodaj granicu',
      '+ Add Cost centre': '+ Dodaj mjesto troška',
      '+ Add Counter Type': '+ Dodaj vrstu brojača',
      '+ Add Cumulative Expression': '+ Dodaj kumulativni izraz',
      '+ Add Location': '+ Dodaj lokaciju',
      '+ Add Owner': '+ Dodaj vlasnika',
      '+ Add a Rule': '+ Dodaj pravilo',
      '+ Add a comparable column': '+ Dodaj usporedivi stupac',
      '+ Add alarm': '+ Dodaj alarm',
      '+ Add attribute': '+ Dodaj atribut',
      '+ Add column': '+ Dodaj stupac',
      '+ Add default mapping rule': '+ Dodaj zadano pravilo preslikavanja',
      '+ Add display field': '+ Dodaj polje prikaza',
      '+ Add format mapping rule': '+ Dodaj format pravila preslikavanja',
      '+ Add layout': '+ Dodaj izgled',
      '+ Add procedure': '+ Dodaj postupak',
      '+ Add register': '+ Dodaj registar',
      '+ Add regular expression mapping rule':
        '+ Dodaj pravilo mapiranja regularnih izraza',
      '+ Add time series': '+ Dodaj vremenski niz',
      '+ Hour (0 - 23)': '+ Sat (0 - 23)',
      '+ Minute (0 - 59)': '+ Minuta (0 - 59)',
      '+ add a new group': '+ Dodaj novu grupu',
      '+ add arithmetic expression': '+ dodaj aritmetički izraz',
      '+ add time series': '+ dodaj vremenski niz',
      '0 seconds': '0 sekundi',
      '1 day': ['1 dan', '{{days}} dana', '{{days}} dana'],
      '1 hour': ['1 sat', '{{hours}} sata', '{{hours}} sati'],
      '1 minute': ['1 minuta', '{{minutes}} minute', '{{minutes}} minuta'],
      '1 month': ['1 mjesec', '{{month}} mjeseca', '{{month}} mjeseci'],
      '1 second': ['1 sekunda', '{{seconds}} sekunde', '{{seconds}} sekundi'],
      '1 year': ['1 godina', '{{years}} godine', '{{years}} godina'],
      ':': ':',
      '<b>active</b>': '<b>aktivan</b>',
      '<b>inactive</b>': '<b>neaktivan</b>',
      '<span class="badge triggers-an-error" ng-class="{\'float-right\' : mappingRule.isManualIntervention}">Triggers an error</span>':
        '<span class="badge triggers-an-error" ng-class="{\'float-right\' : mappingRule.isManualIntervention}">Triggers an error</span>',
      '<span translate>+ Add a Rule</span>':
        '<span translate = \\ ""> + Dodaj pravilo</ span>',
      '<span translate>+ Add alarm</span>':
        '<span translate="">+ Dodaj alarm</span>',
      '<span translate>Add a Limit Alarm</span>':
        '<span translate="">Dodaj granicu alarma</span>',
      '<span translate>Add a Rule</span>':
        '<span translate="">Dodaj pravilo</span>',
      '<span translate>Error loading card</span>':
        '<span translate="">Greška učitavanja kartice</span>',
      '<strong>Total without VAT</strong>': '<strong>Ukupno bez PDV</strong>',
      '?': '?',
      APR: 'travanj',
      AVG: 'AVG',
      Absence: 'Odsutnost',
      'Absent Personnel': 'Odsutno osoblje',
      'Absent personnel': 'Odsutno osoblje',
      Accept: 'Prihvatiti',
      'Accepted at': 'Prihvaćeno u',
      Accumulators: 'Akumulatori',
      Action: 'Radnja',
      'Action Status': 'Status radnje',
      'Action Statuses': 'Statusi aktivnosti',
      'Action status': 'Status radnje',
      Actions: 'Akcije',
      'Active Comparable column': 'Aktivna usporedba kolona',
      Activity: 'Aktivnost',
      Actual: 'stvaran',
      Add: 'Dodati',
      'Add Asset Availability': 'Dodaj dostupnost imovine',
      'Add Child Node': 'Dodaj podređeni čvor',
      'Add Location': 'Dodaj lokaciju',
      'Add Lower Limit': 'Dodaj donju granicu',
      'Add M & T Analysis': 'Dodaj M&T analizu',
      'Add Manual Input Time Series': 'Dodaj ručni upis vremenske serije',
      'Add Rated Power': 'Dodaj nazivnu snagu',
      'Add Sort': 'Dodaj sortiranje',
      'Add Step': 'Dodaj korak',
      'Add Task': 'Dodaj zadatak',
      'Add Times Series': 'Dodaj vremensku seriju',
      'Add Upper Limit': 'Dodaj gornju granica u %',
      'Add User': 'Dodaj korisnika',
      'Add Value Card': 'Dodaj karticu vrijednosti',
      'Add a Limit Alarm': 'Dodaj granični alarm',
      'Add a Rule': 'Dodaj pravilo',
      'Add a boundary': 'Dodaj granicu',
      'Add a filter': 'Dodaj filter',
      'Add alarms': 'Dodaj alarme',
      'Add an alarm': 'Dodaj alarm',
      'Add analysis': 'Dodaj analizu',
      'Add asset to measuring point': 'Dodaj imovinu u mjernu točku',
      'Add attribute': 'Dodaj atribut',
      'Add card': 'Dodaj karticu',
      'Add cost centre to asset': 'Dodaj mjesto troška u imovinu',
      'Add cost centre to measuring point':
        'Dodaj mjesto troška na mjernu točku',
      'Add counter type to measuring point': 'Dodaj mjerni tip u mjernu točku',
      'Add element': 'Dodaj element',
      'Add flow': 'Dodaj tijek',
      'Add group': 'Dodaj grupu',
      'Add iframe': 'Dodaj iframe',
      'Add image': 'Dodaj sliku',
      'Add invoice overview': 'Dodaj pregled računa',
      'Add location': 'Dodaj lokaciju',
      'Add location to asset': 'Dodaj lokaciju imovini',
      'Add locations by cost centre map': 'Dodaj lokacije prema mjestu troška',
      'Add locations map': 'Dodaj kartu lokaciju',
      'Add main cost centre to location':
        'Dodaj glavno mjesto troška na lokaciju',
      'Add new attribute': 'Dodaj novi atribut',
      'Add new configuration': 'Dodaj novu konfiguraciju',
      'Add new user emails': 'Dodaj nove korisničke e-pošte',
      'Add owner to location': 'Dodaj vlasnika na lokaciju',
      'Add owner to measuring point': 'Dodaj vlasnika u mjernu točku',
      'Add schedule classification': 'Dodaj klasifikaciju rasporeda',
      'Add series': 'Dodaj serije',
      'Add tab': 'Dodaj tab',
      'Add time series': 'Dodaj vremensku seriju',
      'Add time series group': 'Dodaj grupu vremenske serije',
      'Add to group': 'Dodaj u grupu',
      'Add user': 'Dodaj korisnika',
      'Add values': 'Dodaj vrijednosti',
      'Add variable': 'Dodaj varijablu',
      'Add visualization': 'Dodaj vizualizaciju',
      'Add weather': 'Dodaj vrijeme',
      Addition: 'Dodatak',
      Address: 'Adresa',
      'Adds neighbouring expressions': 'Dodaj susjedne izraze',
      Advanced: 'Napredna',
      Afternoon: 'Poslijepodne',
      Aggregation: 'sakupljanje',
      'Aggregation Group': 'Skupina za združivanje',
      'Aggregation Groups': 'Skupine združivanja',
      'Aggregation Type': 'Tip združivanja',
      'Aggregation group': 'Skupina združivanja',
      'Aggregation type': 'Tip združivanja',
      Alarm: 'Alarm',
      'Alarm Category': 'Kategorija alarmu',
      'Alarm Data': 'Podaci o alarmu',
      'Alarm Definition': 'Definicija alarma',
      'Alarm Definitions': 'Definicije alarma',
      'Alarm Type': 'Vrsta alarma',
      'Alarm Types': 'Vrste alarma',
      'Alarm after timeout': 'Alarm nakon isteka vremena',
      'Alarm configuration': 'Konfiguracija alarma',
      'Alarm settings': 'Postavke alarma',
      'Alarm types': 'Vrste alarma',
      'Alarm was successfully accepted!': 'Alarm je uspješno prihvaćen!',
      'Alarm was successfully assigned!': 'Alarm je uspješno dodijeljen!',
      'Alarm was successfully resolved!': 'Alarm je uspješno riješen!',
      Alarms: 'Alarmi',
      'Alarms and Rules': 'Alarmi i pravila',
      'Alarms and rules': 'Alarmi i pravila',
      'Alarms overview': 'Pregled alarma',
      'Alarms:': 'Alarmi:',
      Aligment: 'Poravnanje',
      'All series': 'Sve serije',
      Allocation: 'Raspodjela',
      'Allocator Kind': 'Vrsta alokatora',
      'Allocator Type': 'Tip alokatora',
      'Allocator value': 'Vrijednost Allocator Kind',
      'Allowed entities': 'Dopušteni entiteti',
      'Allowed varaible names': 'Dopuštena imena varijabli',
      'Always valid': 'Uvijek vrijedi',
      Amount: 'Iznos',
      'Amount left': 'Preostali iznos',
      Amounts: 'iznosi',
      Analyses: 'Analize',
      Analysis: 'Analiza',
      'Analysis Type': 'Vrsta analize',
      'Analysis doesn\'t have any required filters defined':
        'Analiza nema definirane potrebne filtre',
      Analytics: 'Analitika',
      Application: 'primjena',
      'Apply power of right-side neighbouring number (or expression) to the left side neighbouring number (or expression).':
        'Primijeni snagu desnog susjednog broja (ili izraza) na lijevu stranu bočni susjedni broj (ili izraz)."',
      'Apply to all entities:': 'Primijeni na sve entitete:',
      Apr: 'travanj',
      April: 'travanj',
      'Are you sure that you want to change dashboard card size?':
        'Jeste li sigurni da želite promijeniti veličinu kartice nadzorne ploče?',
      'Are you sure that you want to remove layout?':
        'Jeste li sigurni da želite ukloniti izgled?',
      'Are you sure you want to cancel?':
        'Jeste li sigurni da želite odustati?',
      'Are you sure you want to continue? All unsaved data will be lost.':
        'Jeste li sigurni da želite nastaviti? Svi podaci koje niste sačuvali će biti izgubljeni.',
      'Are you sure you want to delete': 'Jeste li sigurni da želite izbrisati',
      'Are you sure you want to delete Manual Input Time Series?':
        'Jeste li sigurni da želite izbrisati vremenske serije ručnog unosa?',
      'Are you sure you want to delete this item?':
        'Jeste li sigurni da želite izbrisati tu stavku?',
      'Are you sure you want to remove all direct accesses?':
        'Jeste li sigurni da želite ukloniti sve izravne pristupe?',
      'Are you sure you want to remove all items?':
        'Jeste li sigurni da želite ukloniti sve stavke?',
      'Are you sure you want to remove the item?':
        'Jeste li sigurni da želite ukloniti stavku?',
      'Are you sure you want to remove this classification?':
        'Jeste li sigurni da želite ukloniti ovu klasifikaciju?',
      'Are you sure you want to remove this direct access?':
        'Jeste li sigurni da želite ukloniti ovaj izravni pristup?',
      'Are you sure you want to remove this group?':
        'Jeste li sigurni da želite ukloniti ovu grupu?',
      'Are you sure you want to remove this mapping rule?':
        'Jeste li sigurni da želite ukloniti ovo pravilo preslikavanja?',
      'Are you sure you want to remove this {{name}}?':
        'Jeste li sigurni da želite ukloniti ovu {{name}}?',
      'Are you sure you want to remove user from group?':
        'Jeste li sigurni da želite ukloniti korisnika iz grupe?',
      'Are you sure you want to reset password? User will have to set new password at the next login.':
        'Jeste li sigurni da želite poništiti zaporku? Korisnik će morati postaviti novu lozinku pri sljedećoj prijavi.',
      'Are you sure you want to unpair measurement and GIS object?':
        'Jeste li sigurni da želite razdvojiti mjerenje i objekt iz GIS?',
      'Are your sure that you want to relist values? All changed date will be lost':
        'Jeste li sigurni da želite ponovno prikazati vrijednosti? Svi izmijenjeni podaci će biti izgubljeni',
      Argument: 'Argument',
      'Argument value': 'Vrijednost argumenta',
      'Arithmetic expressions': 'Aritmetički izrazi',
      'As it was stored on the date': 'Kao što je bilo pohranjeno na datum',
      Asset: 'Imovina',
      'Asset Availability': 'Dostupnost imovine',
      'Asset Availability Event': 'Događaj dostupnosti imovine',
      'Asset Brand': 'Marka imovine',
      'Asset Brands': 'Marke imovine',
      'Asset Group': 'Grupa imovine',
      'Asset Groups': 'Grupe imovine',
      'Asset Model': 'Model imovine',
      'Asset Models': 'Modeli imovine',
      'Asset Parent': 'Odaberite nadređeno sredstvo',
      'Asset Status': 'Status imovine',
      'Asset Statuses': 'Statusi imovine',
      'Asset Type': 'Vrsta imovine',
      'Asset Types': 'Vrste sredstava',
      'Asset brand': 'Marka imovine',
      'Asset doesn\'t have availability yet': 'sredstvo još nema dostupnost',
      'Asset group': 'Grupa imovine',
      'Asset has no subsidiary assets': 'Imovina nema podružnicu',
      'Asset owner': 'Vlasnik imovine',
      'Asset ownership': 'Vlasništvo nad imovinom',
      'Asset type': 'Vrsta imovine',
      'Asset type attributes': 'Atributi vrste imovine',
      Assets: 'Imovina',
      'Assets configuration': 'Konfiguracija imovine',
      Assign: 'Dodijeliti',
      'Assign Alarm': 'Dodijeli alarm',
      'Assigned Personnel': 'Dodijeljeno osoblje',
      'Assigned personnel': 'Dodijeljeno osoblje',
      Assignee: 'Opunomoćenik',
      'Assignment is not allowed': 'Dodjela nije dopuštena',
      'Assignment is not allowed.': 'Dodjela nije dopuštena',
      'At least one default filter must be selected':
        'Najmanje jedan osnovni filter mora biti izabran',
      'At least one trigger has to be enabled':
        'Najmanje jedan osnovni okidač mora biti izabran',
      Attribute: 'Atribut',
      'Attribute {{ $index + 1 }}': 'Atribut {{$ index + 1}}',
      Attributes: 'Značajke',
      August: 'kolovoz',
      Author: 'Autor',
      'Authorized Groups': 'Ovlaštene grupe',
      'Authorized Users': 'Ovlašteni korisnici',
      'Authorized personnel': 'Ovlašteno osoblje',
      Available: 'Dostupno',
      Avg: 'Prosječni',
      Back: 'Nazad',
      Bad: 'Pogreška',
      'Bad-Request': 'Pogrešni-zahtjev',
      'Base line': 'Osnovna linija',
      Baseline: 'Osnovni',
      'Baseline function': 'Osnovna funkcija',
      'Baseline {{equation}}': 'Osnovna {{equation}}',
      Baselines: 'Osnovne linije',
      'Basic Map': 'Osnovna karta',
      'Basic Map filters': 'Filter osnovne karte',
      Batch: 'serija',
      'Batch interval in days': 'Interval predmemorije u danima',
      'Batch interval in hours': 'Interval predmemorije u satima',
      'Batch interval in minutes': 'Interval predmemorije u minutama',
      'Batch interval in seconds': 'Interval predmemorije u sekundama',
      'Billing Kind': 'Vrsta naplate',
      'Billing Kind Configurations': 'Kofiguracija vrste naplate',
      'Billing Kinds': 'Vrste naplate',
      'Billing Type': 'Tip naplate',
      'Billing Type Configurations': 'Konfiguracije tipa naplate',
      'Billing Types': 'Tipovi naplate',
      'Billing data': 'Podaci naplate',
      'Billing date': 'Datum naplate',
      'Billing date from': 'Datum naplate od',
      'Billing date from must occur before billing date to.':
        'Datum naplate mora biti prije Datum naplate do',
      'Billing date time range must be less then two years.':
        'Vremenski raspon datuma naplate mora biti kraći od dvije godine',
      'Billing date to': 'Datum naplate do',
      'Billing date:': 'Datum naplate:',
      'Billing kinds': 'Vrste naplate',
      Bold: 'odvažan',
      'Book value': 'Knjigovodstvena vrijednost',
      'Book value was successfully distributed':
        'Knjigovodstvena vrijednost uspješno je distribuirana',
      'Book value: {{bookValue}}': 'Knjigovodstvena vrijednost: {{bookValue}}',
      Boolean: 'Booleova',
      'Both coordinates must be selected in order to save them.':
        'Da biste ih spremili, morate odabrati obje koordinate',
      Boundaries: 'Granice',
      Brand: 'Brand',
      'Browse Query': 'Pretražite upit',
      Buffer: 'Međuspremnik',
      'Bullet list': 'Popis s oznakama',
      'Business Partner': 'Poslovni partner',
      'Business Partner Tree View': 'Poslovni partner Tree View',
      'Business Partners': 'Poslovni partneri',
      'Business partner': 'Poslovni partner',
      'Business partner billing item detail':
        'Detalji predmeta naplate poslovnog partnera',
      'Business partners': 'Poslovni partneri',
      'CO per kWh': 'CO po kWh',
      'CO2 per kWh': 'CO2 po kWh',
      'CRON EXPRESSION': 'CRON EXPRESSION',
      'CSV was successfully uploaded': 'CSV je uspješno prenesen',
      'Calculate baseline': 'Izračunaj osnovnu liniju',
      'Calculated Value': 'Izračunata vrijednost',
      'Calculated flow': 'Izračunat tijek',
      Calculation: 'Izračun',
      Calculations: 'Izračuni',
      'Can\t update detail invoice because there is no detail price list id':
        'Ne mogu ažurirati fakturu s detaljima jer ne postoji id cjenika detalja',
      'Can\'t find method for entity {{entity}} and method {{method}}':
        'Ne mogu pronaći metodu za entitet {{entity}} i metodu {{method}}',
      Cancel: 'Otkaži',
      'Cancel updating time series group':
        'Otkaži ažuriranje grupe vremenskih serija',
      'Card size changed': 'Veličina kartice je promijenjena',
      Center: 'Mjesto',
      'Change Password': 'Promijeni zaporku',
      'Change Price List': 'Promijeni cjenik',
      'Change counter': 'Promijeni brojač',
      'Change counter for {{measuringpointName}}':
        'Promijeni brojač za {{measuringpointName}}',
      'Change password': 'Promijeni zaporku',
      'Changes were successfully changed': 'Promjene su uspješno promijenjene',
      'Chart To Table Ratio': 'Omjer grafikona i tablice',
      'Chart Type': 'Vrsta grafikona',
      'Chart filters': 'Filteri grafikona',
      'Chart settings': 'Postavke grafikona',
      'Chart type': 'Vrsta grafikona',
      'Check box': 'Potvrdni okvir',
      'Check your URL / link and try again.':
        'Provjerite svoj URL / vezu i pokušajte ponovo',
      'Child assets': 'Dječja imovina',
      'Child measuring points': 'Dijete mjernih točaka',
      'Choose Step': 'Odaberite korak',
      'Choose Task': 'Odaberite zadatak',
      'Choose a time interval': 'Odaberite vremenski interval',
      'Choose an element type': 'Odaberite vrstu elementa',
      'Choose database type': 'Odaberite vrstu baze podataka',
      'Choose display field': 'Odaberite polje prikaza',
      'Choose file type': 'Odaberite tip datoteke',
      'Choose opc type': 'Odaberite tip opc',
      'Choose sop procedure': 'Izaberite standardni postupak',
      'Choose sop type': 'Odaberite vrstu standardnog postupka',
      'Choose webservice type': 'Odaberite tip web servisa',
      Class: 'klasa',
      Clear: 'Obriši',
      'Clear all ({{vm.items ? vm.items.length : \'0\'}})':
        'Obriši sve ({{vm.items? Vm.items.length: \'0\'}})',
      'Clear filters': 'Obriši filtere',
      'Click to upload': 'Kliknite za prijenos',
      'Client ID': 'ID klijenta',
      'Close interval': 'Interval zatvaranja',
      Code: 'Kodirati',
      'Code:': 'Kodirati:',
      'Collection Friendly Name': 'Prijateljski naziv zbirke',
      'Collection friendly name': 'Prikladno ime za zbirku',
      Color: 'Boja',
      Columns: 'Kolumne',
      'Columns number': 'Broj stupaca',
      Comment: 'Komentar',
      Community: 'Zajednica',
      'Community Name': 'Naziv zajednice',
      'Community name': 'Naziv zajednice',
      Company: 'Društvo',
      'Company Resources': 'Resursi tvrtke',
      'Company Structure': 'Struktura poduzeća',
      'Company Structure Node': 'Čvor strukture poduzeća',
      'Company Structure Node Parent': 'Roditelj čvora strukture poduzeća',
      'Company Structure Node Tree View': 'Čvor strukture poduzeća Tree View',
      'Company Structure Nodes': 'Čvorovi strukture poduzeća',
      'Company resources': 'Resursi tvrtke',
      'Comparable column': 'Usporedni stupac',
      'Comparable columns': 'Usporedivi stupci',
      Completed: 'dovršen',
      'Component creator': 'Stvoritelj komponenti',
      Conditions: 'Uvjeti',
      'Configuration to create new tag is missing.':
        'Nedostaje konfiguracija za stvaranje nove oznake',
      'Configuration updated.': 'Konfiguracija je ažurirana.',
      'Configurations updated.': 'Konfiguracije su ažurirane.',
      'Confirm new password': 'Potvrdi novu lozinku',
      Confirmation: 'Potvrda',
      'Connected external datasources': 'Povezani vanjski podaci',
      Connection: 'Veza',
      'Connection config is missing': 'Konfiguracije mreže nedostaje',
      'Connection settings': 'Postavke veze',
      'Connection type': 'Vrsta veze',
      Connectors: 'Priključci',
      Consistency: 'Dosljednost',
      Consumption: 'Potrošnja',
      'Consumption date': 'Datum potrošnje',
      'Content notification': 'Obavijest o sadržaju',
      'Content notification users': 'Nova obavijest korisnicima',
      'Content responsible person': 'Sadržaj odgovornoj osobi',
      'Copy history flow to live flow': 'Kopirajte protok povijesti u živi tok',
      'Copy link to clipboard and close': 'Copy link to clipboard and close',
      'Copy live flow to history flow': 'Kopirajte tok uživo u tok povijesti',
      'Cosine function.': 'Kosinusna funkcija.',
      'Cost Centre': 'Mjesto troška',
      'Cost Centre Tree View': 'Mjesto troška Tree View',
      'Cost Centre Type': 'Vrsta mjesta troška',
      'Cost Centres': 'Troškovna mjesta',
      'Cost centre': 'Mjesto troška',
      'Cost centre type': 'Vrsta mjesta troška',
      'Cost centres': 'Mjesta troška',
      'Could not calculate the input.': 'Nije moguće izračunati ulaz',
      'Couldn\'t duplicate invoice dates':
        'Nije moguće duplicirati datume računa',
      'Couldn\'t fetch organization': 'Nije moguće dohvatiti organizaciju',
      'Couldn\'t init GIS sdk': 'Nije moguće pokrenuti GIS sdk',
      'Couldn\'t initialize card. Dashboard card type doesn\'t exist.':
        'Kartica nije mogla inicijalizirati. Vrsta nadzorne ploče ne postoji.',
      'Couldn\'t remove main Dashboard':
        'Nije moguće ukloniti glavnu nadzornu ploču',
      'Couldn\'t reset gis token': 'Nije moguće vratiti tokset gis',
      'Couldn\'t set main Dashboard': 'Nije moguće postaviti glavnu ploču',
      'Couldn\'t update next reading consumption try to update it manually.':
        'Nije moguće ažurirati sljedeću potrošnju čitanja, pokušajte je ažurirati ručno',
      'Couldn\'t upload values for': 'Nije moguće prenijeti vrijednosti za',
      Count: 'Broj',
      'Counter Type': 'Vrsta brojača',
      'Counter Type Configuration': 'Konfiguracija vrste brojača',
      'Counter Types': 'Vrste brojača',
      'Counter read out': 'Očitanje brojača',
      'Counter replacement date': 'Zamjenski datum brojača',
      'Counter type has no registers': 'Tip brojača nema registre',
      'Counter types': 'Vrste brojača',
      Country: 'Zemlja',
      Create: 'Stvoriti',
      'Create GIS user': 'Stvorite GIS korisnika',
      'Create an absence': 'Stvorite odsutnost',
      'Create an assignment': 'Stvorite zadatak',
      'Create domain': 'Stvorite domenu',
      'Create new base line target function':
        'Stvorite novu funkciju osnovne linije',
      'Create new item': 'Izradite novi sadržaj',
      'Create one.': 'Stvori jedan.',
      'Create time series': [
        'Stvorite vremenski niz',
        'Stvorite mjerenje',
        'Stvorite mjerenje'
      ],
      'Create value': 'Stvorite vrijednost',
      Created: 'stvoren',
      'Created Notification': 'Napravljena obavijest',
      'Created Notifications': 'Izrađene obavijesti',
      'Cron expression': 'Cron izraz',
      'Cron expression literal': 'Cron izraz literal',
      'Cumulative expressions': 'Kumulativni izrazi',
      Currency: 'Valuta',
      'Current Assignee': 'Trenutni opunomoćenik',
      'Current Version': 'Trenutna verzija',
      'Current assignee': 'Trenutni opunomoćenik',
      'Current item': 'Trenutačna stavka',
      Custom: 'Prilagođen',
      Customer: 'kupac',
      'Customer:': 'Klijent:',
      DEC: 'Prosinac',
      'Daily setup': 'Dnevno postavljanje',
      Dashboard: 'kontrolna ploča',
      'Dashboard Playlist': 'Popis za reprodukciju nadzorne ploče',
      'Dashboard Playlists': 'Popisi za reprodukciju na nadzornoj ploči',
      'Dashboard card successfully saved.':
        'Kartica nadzorne ploče uspješno je spremljena.',
      'Dashboard card was successfully added to the dashboard.':
        'Kartica nadzorne ploče je uspješno dodana na nadzornu ploču',
      'Dashboard does not exist': 'Nadzorna ploča ne postoji',
      'Dashboard updated': 'Nadzorna ploča je ažurirana',
      Dashboards: 'Nadzorna ploča',
      Data: 'Podaci',
      'Data Type': 'Tip podataka',
      'Data Visualization': 'Vizualizacija podataka',
      'Data is loading': 'Podaci se učitavaju',
      'Data is loading ...': 'Podaci se učitavaju ...',
      'Data point format': 'Format podatkovne točke',
      'Data point value should be indicated as':
        'Vrijednost baze podataka mora biti označena kao',
      'Data type': 'Tip podataka',
      'Data visualization': 'Vizualizacija podataka',
      Database: 'Baza podataka',
      'Database connection configuration': 'Konfiguracija veze baze podataka',
      'Database name': 'Naziv baze podataka',
      'Database type': 'Vrsta baze podataka',
      Databases: 'baze podataka',
      Dataset: 'skup podataka',
      'Dataset doesn\'t have any default filters. Select another dataset':
        'Skup podataka nema zadanih filtara. Odaberite drugi skup podataka',
      Datasets: 'Skupovi podataka',
      Date: 'Datum',
      'Date Finished': 'Datum završen',
      'Date Range': 'Raspon datuma',
      'Date column': 'Stupac Datum',
      'Date column name': 'Naziv stupca datuma',
      'Date finished': 'Datum je završen',
      'Date for calculation of regression':
        'Datum za izračun regresijske funkcije',
      'Date for calculation of regression function from':
        'Datum za izračun regresijske funkcije iz',
      'Date for calculation of regression function to':
        'Datum za izračun regresijske funkcije iz',
      'Date is not valid': 'Datum nije valjan',
      'Date of data reading': 'Datum čitanja podataka',
      'Date of data recording': 'Datum snimanja podataka',
      'Date of data recording:': 'Datum snimanja podataka:',
      Dates: 'Datumi',
      Day: 'Dan',
      'Day and time in month': 'Dan i vrijeme u mjesecu',
      'Day format is not right.': 'Dnevni format nije ispravan.',
      'Day interval quantity missing.': 'Nedostaje količina dnevnog intervala.',
      'Day of month (1 - 31)': 'Dan u mjesecu (1 - 31)',
      Days: 'dana',
      Dec: 'prosinca',
      December: 'prosinac',
      'Default Value Type': 'Zadana vrsta vrijednosti',
      'Default display type': 'Zadana vrsta prikaza',
      'Default mapping rule': 'Zadano pravilo mapiranja',
      'Default mapping rule is required.':
        'Zadano pravilo mapiranja je zatraženo.',
      'Default physical quantity': 'Zadana fizikalna veličina',
      'Default specification': 'Zadana specifikacija',
      'Default value': 'Zadana vrijednost',
      Delete: 'Izbrisati',
      'Delete absence': 'Izbriši izostanak',
      'Delete assignment': 'Izbriši dodjelu',
      'Delete element': 'Izbriši element',
      Delimiter: 'graničnik',
      Delta: 'Delta',
      'Demo Editor': 'Demo Editor',
      'Demo Preview': 'Demo pregled',
      'Depreciation Period': 'Razdoblje amortizacije',
      'Depreciation period': 'Razdoblje amortizacije',
      Description: 'Opis',
      'Deselect all': 'Odznači sve',
      'Detail invoice values order was successfully updated':
        'Vrijednost detalja dostavnice uspješno je ažurirana',
      'Detail invoice values sum must be less than detail invoice book value.':
        'Vrijednost pojedinosti fakture mora biti manja od knjigovodstvene vrijednosti pojedinosti fakture.',
      'Detailed view': 'Detaljan pregled',
      'Device heartbeats': 'Zdravlje uređaja',
      'Direct Access': 'Direktni pristup',
      'Disable cache': 'Onemogući cache',
      Display: 'Prikaz',
      'Display field': 'Polje za prikaz',
      'Display field name': 'Prikaži naziv polja',
      'Display horizontally': 'Prikaz vodoravno',
      'Display type': 'Vrsta prikaza',
      'Display types': 'Vrste prikaza',
      Distribute: 'Raspodijeliti',
      Distributer: 'distributer',
      'Distributer price list': 'Cjenik distributera',
      'Distributer/Dobavitelj': 'Distributer / Dobavitelj',
      Distributor: 'Distributer',
      'Distributor Code': 'Kôd distributera',
      'Distributor code': 'Kôd distributera',
      'Distributor/Supplier': 'Distributer / Dobavljač',
      'Divides neighbouring expressions': 'Dijeli susjedne izraze',
      Division: 'Podjela',
      'Do you want to delete absence?': 'Želite li izbrisati izostanak?',
      'Do you want to delete assignment?': 'Želite li izbrisati dodjelu?',
      'Document Classification': 'Klasifikacija dokumenta',
      'Document Classifications': 'Klasifikacije dokumenata',
      Domain: 'Domena',
      'Domain Collection': 'Zbirka domene',
      'Domain Collections': 'Zbirke domena',
      'Domain Configuration Form': 'Obrazac za konfiguraciju domene',
      'Domain Configurations': 'Konfiguracije domene',
      'Domain Field': 'Polje domene',
      'Domain Fields': 'Polja domene',
      'Domain Fields Configuration': 'Konfiguracija polja domene',
      'Domain Fields Configuration Form':
        'Obrazac za konfiguraciju polja polja',
      'Domain attribute': 'Atribut domene',
      Domains: 'Domene',
      Double: 'Double',
      Download: 'Preuzmite',
      'Drag Item Here': 'Povucite stavku ovdje',
      'Drag a time series into this field or add it manually to begin with arranging':
        'Povucite vremensku seriju u ovo polje ili je dodajte ručno za početak slaganja',
      'Drag file here or': 'Povucite datoteku ovdje ili',
      Duplicate: 'Duplicirajte',
      'Duplicate Analysis': 'Duplicirajte analizu',
      'Duplicate Business partner': 'Duplicirajte poslovnog partnera',
      'Duplicate Invoice': 'Duplicirajte dostavnicu',
      'Duplicate Location': 'Duplicirajte lokaciju',
      'Duplicate Measuring Point': 'Duplicirajte točka mjerenja',
      'Duplicate Price List': 'Duplicirajte cjenik',
      'Duplicate Project': 'Duplicirajte projekt',
      'Duplicate Rule': 'Duplicirajte pravilo',
      'Duplicate analysis': 'Duplicirajte analizu',
      'Duplicate asset': 'Duplicirajte sredstvo',
      'Duplicate mapping rule': 'Duplicirajte pravilo mapiranja',
      'Duplicate master invoice': 'Duplicirajte glavnu dostavnicu',
      'Duplicate measuring point': 'Duplicirajte točku mjerenja',
      'Duplicate price list': 'Duplicirajte cjenik',
      'Duplicate report': 'Duplicirajte izvješće',
      'Duplicate step': 'Duplicirajte korak',
      'Duplicate task': 'Duplicirajte zadatak',
      'Duplicate time series': 'Duplicirajte tijek vremena',
      'Duplicate time series configuration':
        'Duplicirajte konfiguraciju tijeka vremena',
      'Duplicate time series data from selected time series properties':
        'Duplicirajte podatke vremenskih serija iz odabranih svojstava vremenskih serija',
      'Duplicating alarm definition': 'Duplicirajte definiciju alarma',
      'Duplicating standard operating procedure':
        'Duplicirajte standardni radni postupak',
      Dynamic: 'Dinamičan',
      'Dynamic Attribute': 'Dinamičko svojstvo',
      'Dynamic Attributes': 'Dinamički atributi',
      'Dynamic attributes': 'Dinamički atributi',
      'E-mail': 'E-mail',
      'EOM Code': 'EOM kod',
      EQUALS: 'jednakima',
      'EXPRESSION PREVIEW': 'PREGLED IZRAZA',
      Edit: 'Uredi',
      'Edit / Delete': 'Uredi / Izbriši',
      'Edit Action Status': 'Uredi status djelovanja',
      'Edit Alarm Type': 'Uredi vrstu alarma',
      'Edit Asset Brand': 'Uredi robnu marku',
      'Edit Asset Group': 'Uredi grupu imovine',
      'Edit Asset Model': 'Uredi model imovine',
      'Edit Asset Status': 'Uredi status imovine',
      'Edit Billing Kind': 'Uredi način naplate',
      'Edit Billing Type': 'Uredi vrstu naplate',
      'Edit Business partner': 'Uredi poslovnog partnera',
      'Edit Company Structure Node': 'Uredi strukturu poduzeća',
      'Edit Cost Centre': 'Uredi mjesto troška',
      'Edit Counter Type': 'Uredi vrstu brojača',
      'Edit Database': 'Uredi bazu podataka',
      'Edit Document Classification': 'Uredi klasifikaciju dokumenta',
      'Edit Domain': 'Uredi domenu',
      'Edit Domain Collection': 'Uredi zbirku domene',
      'Edit Domain Field': 'Uredi polje domene',
      'Edit Energy Management Item': 'Uredi predmet upravljanja energijom',
      'Edit Energy Source Type': 'Uredi vrstu izvora energije',
      'Edit External Datasource': 'Uredi vanjski izvor podataka',
      'Edit File': 'Uredi datoteku',
      'Edit Invoice Detail': 'Uredi pojedinost dostavnice',
      'Edit Location': 'Uredi lokaciju',
      'Edit Location Classification': 'Uredi klasifikaciju lokacije',
      'Edit Location Status': 'Uredi status lokacije',
      'Edit M & T Analysis': 'Uredi M&T analizu',
      'Edit Manual Input Time Series': 'Uredi vremenske serije ručnog unosa',
      'Edit Measurement Unit': 'Uredi mjernu jedinicu',
      'Edit National Holiday': 'Uredi nacionalni praznik',
      'Edit OPC': 'Uredi OPC',
      'Edit Ownership': 'Uredi vlasništvo',
      'Edit Physical Quantity': 'Uredi fizičku količinu',
      'Edit Price List': 'Uredi cjenik',
      'Edit Price List Item': 'Uredi stavku cjenika',
      'Edit Privileges': 'Uredi prava',
      'Edit Profit Centre': 'Uredi profitni centar',
      'Edit Project': 'Uredi projekt',
      'Edit Project Field': 'Uredi polje projekta',
      'Edit Project Type': 'Uredi vrstu projekta',
      'Edit Rated Power': 'Uredi nazivnu snagu',
      'Edit Response Interval': 'Uredi interval odgovora',
      'Edit Rule': 'Uredi pravilo',
      'Edit Scheduler': 'Uredi planer',
      'Edit Tango Agent Connection Config':
        'Uredi konfiguraciju veze Tango Agenta',
      'Edit Tariff': 'Uredi tarifu',
      'Edit Time Series': 'Uredi vremensku seriju',
      'Edit Time Series Group Type': 'Uredi tip grupe vremenske serije',
      'Edit Value Card': 'Uredi karticu vrijednosti',
      'Edit Web Service': 'Uredi web-uslugu',
      'Edit aggregation group': 'Uredi grupu za agregiranje',
      'Edit alarm definition': 'Uredi definiciju alarma',
      'Edit alarms': 'Uredi alarme',
      'Edit analysis': 'Uredi analizu',
      'Edit asset': 'Uredi imovinu',
      'Edit asset type': 'Uredi vrstu imovine',
      'Edit calculation': 'Uredi izračun',
      'Edit card': 'Uredi karticu',
      'Edit column': 'Uredi stupac',
      'Edit dashboard': 'Uredi nadzornu ploču',
      'Edit dashboard playlist': 'Uredi popis za reprodukciju nadzorne ploče',
      'Edit dataset': 'Uredi skup podataka',
      'Edit dynamic attribute': 'Uredi dinamički atribut',
      'Edit expression': 'Uredi izraz',
      'Edit group': 'Uredi grupu',
      'Edit iframe': 'Uredi iframe',
      'Edit image': 'Uređivanje slike',
      'Edit invoice detail value': 'Uredi vrijednost pojedinosti računa',
      'Edit invoice overview': 'Uredi pregled dostavnice',
      'Edit location': 'Uredi lokaciju',
      'Edit location type': 'Uredi vrstu lokacije',
      'Edit locations by cost centre map':
        'Uredi lokacije prema karti mjesta troška',
      'Edit locations map': 'Uredi kartu lokacija',
      'Edit manual input': 'Uredi ručni unos',
      'Edit mapping rule': 'Uredi pravilo mapiranja',
      'Edit master invoice': 'Uredi glavnu dostavnicu',
      'Edit measuring point': 'Uredi mjernu točku',
      'Edit measuring point type': 'Uredi vrstu mjerne točke',
      'Edit notification': 'Uredi obavijest',
      'Edit order': 'Uredi narudžbu',
      'Edit price list': 'Uredi cjenik',
      'Edit price list item': 'Uredi stavku cjenika',
      'Edit price list item group': 'Uredi grupu stavku cjenika',
      'Edit report': 'Uredi izvješće',
      'Edit settings': 'Uredi postavke',
      'Edit severity': 'Uredi ozbiljnost',
      'Edit standard operating procedure': 'Uredi standardni radni postupak',
      'Edit step': 'Uredi korak',
      'Edit task': 'Uredi zadatak',
      'Edit template': 'Uredi predložak',
      'Edit time interval filter': 'Uredi filter vremenskog intervala',
      'Edit time series group': 'Uredi grupu tijeka vremena',
      'Edit time series type': 'Uredi tip tijeka vremena',
      'Edit user': 'Uredi korisnika',
      'Edit values': 'Uredi vrijednosti',
      'Edit visualization': 'Uredi vizualizaciju',
      'Edit weather': 'Uredi vrijeme',
      Editor: 'Urednik',
      Effort: 'Napor',
      'Element type': 'Vrsta elementa',
      'Element type not recognized': 'Vrsta elementa nije prepoznata',
      Email: 'E-pošta',
      'Email content': 'Sadržaj e-pošte',
      'Email notification': 'Obavijest E-poštom',
      'Enable cache': 'Omogući cache',
      'Enable edit permissions': 'Omogući dozvole za uređivanje',
      'Enable filter': 'Omogući filtar',
      Enabled: 'Omogućeno',
      'End date': 'Datum završetka',
      'End date:': 'Datum završetka:',
      'End of Billing Period': 'Kraj razdoblja naplate',
      'End of Project': 'Kraj projekta',
      'End of billing period': 'Kraj razdoblja naplate',
      'End of project': 'Kraj projekta',
      Ended: 'Završeno',
      'Ending On': 'Završetak uključivanja',
      'Energy Management Group Configuration':
        'Konfiguracija grupe za upravljanje energijom',
      'Energy Management Item': 'Sredstvo upravljanja energijom',
      'Energy Management Items': 'Sredstva upravljanja energijom',
      'Energy Manager': 'Energetski manager',
      'Energy Source Type': 'Vrsta izvora energije',
      'Energy Source Types': 'Vrste izvora energije',
      'Energy Sources Types Configuration':
        'Konfiguracija vrste izvora energije',
      'Energy management': 'Upravljanje energijom',
      'Energy management group': 'Grupa za upravljanje energijom',
      'Energy management groups': 'Grupe za upravljanje energijom',
      'Energy management item': 'Sredstvo upravljanja energijom',
      'Energy management system.': 'Sustav upravljanja energijom.',
      'Energy manager': 'Energetski manager',
      'Energy managment groups': 'Grupe energetskih managera',
      'Energy source type': 'Vrsta izvora energije',
      'Energy source type is missing physical data':
        'Vrsti izvora energije nedostaje fizikalna veličina',
      'Enter URL (/example/path)': 'Unesite URL (/example/path)',
      'Enter any string': 'Unesi bilo koji niz simbola',
      'Enter date': 'Unesite datum',
      'Enter decimal.': 'Unesite decimalni broj.',
      'Enter email and press enter or tab':
        'Unesite e-mail i pritisnite enter ili tab',
      'Enter integer': 'Unesite cijeli broj',
      'Enter time (hh:mm:ss)': 'Unesite vrijeme (ss: mm: ss)',
      'Enter your comment here.': 'Ovdje unesite komentar.',
      Entity: 'entiteta',
      'Entity Tags': 'Oznake entiteta',
      'Entity instances': 'Entitetske instance',
      Equality: 'Jednakost',
      Equals: 'Jednako',
      'Error at creating new task': 'Pogreška pri izradi novog zadatka',
      'Error loading': 'Pogreška pri učitavanju',
      'Error loading card': 'Pogreška pri učitavanju kartice',
      'Error loading task': 'Pogreška pri učitavanju zadatka',
      'Error on date initialization: Time Series is missing a data scheduler.':
        'Pogreška pri inicijalizaciji datuma: Vremenski niz nema rasporeditelj podataka.',
      'Error while fetching M&T analysis':
        'Pogreška pri učitavanju M&T analize',
      'Error while fetching chart data':
        'Pogreška pri učitavanju vrste grafikona',
      'Error: Missing template': 'Pogreška: nedostaje template',
      Evening: 'Večer',
      'Event type': 'Vrsta događaja',
      Every: 'Svaki',
      'Every day of the week': 'Svaki dan u tjednu',
      'Every month': 'Svaki mjesec',
      'Exact Interval': 'Točan interval',
      'Exact times': 'Točno vrijeme',
      'Exception Handler': 'Rukovatelj iznimkama',
      'Exception handler': 'Rukovatelj iznimkama',
      'Expected format dd.mm.yyyy': 'Očekivani format dd.mm.yyyy',
      'Expected format hh:mm:ss': 'Očekivani format hh: mm: ss',
      'Expected format: {{ msg }}.': 'Očekivani format: {{msg}}.',
      Expression: 'Izraz',
      'Expression *': 'Izraz *',
      'Expression is loading, this may take a while.':
        'Izraz se učitava, to može potrajati.',
      'Expression preview': 'Pregled izraza',
      'Expression:': 'Izraz:',
      Expressions: 'Izrazi',
      External: 'Vanjski',
      'External Code': 'Vanjski kod',
      'External Datasource': 'Vanjski izvor podataka',
      'External Datasource Type': 'Tip vanjskog izvora podatka',
      'External Datasource Version': 'Verzija vanjskog izvora podatka',
      'External Datasources': 'Vanjski izvori podataka',
      'External code': 'Vanjski kod',
      'External datasource': 'Vanjski izvor podataka',
      'External datasource Type': 'Tip vanjskog izvora podatka',
      'External datasource credentials':
        'Vjerodajnice vanjskog izvora podataka',
      'External datasource job': 'Posao vanjskog izvora podataka',
      'External properties': 'Vanjska svojstva',
      'External reader flow': 'Vanjski tijek čitanja',
      'External writer flow': 'Vanjski tijek pisanja',
      'Extrapolated count': 'Ekstrapolirano brojanje',
      FALSE: 'NETOČNO',
      FEB: 'veljača',
      'Factor K': 'Factor K',
      'Factor N': 'Factor N',
      'Failed to fetch current user':
        'Dohvaćanje trenutnog korisnika nije uspjelo',
      'Failed to fetch scheduler': 'Dohvaćanje rasporeda nije uspjelo',
      'Failed to fetch tariffs': 'Dohvaćanje tarifa nije uspjelo',
      'Failed to fetch time series types':
        'Dohvaćanje tijeka vremena nije uspjelo',
      False: 'lažan',
      Feb: 'veljača',
      February: 'veljača',
      'Field name': 'Naziv polja',
      'Field type': 'Vrsta polja',
      File: 'Datoteka',
      'File Uploader': 'Učitavač datoteka',
      'File connection configuration': 'Konfiguracija veze datoteke',
      'File type': 'Vrsta datoteke',
      'File was successfully deleted': 'Datoteka je uspješno izbrisana',
      'File was successfully updated': 'Datoteka je uspješno ažurirana',
      Files: 'datoteke',
      'Files were successfully uploaded': 'Datoteka je uspješno prenesena',
      'Fill in at least one field of email, notification or sms content.':
        'Ispunite barem jedno polje e-pošte, obavijesti ili sms sadržaja',
      Filter: 'filtar',
      'Filter enabled': 'Filter je omogućen',
      'Filter entities by name': 'Filtrirajte entitete po imenu',
      'Filter type': 'Vrsta filtera',
      'Filter type should be selected': 'Trebalo bi odabrati vrstu filtra',
      'Filter type:': 'Vrsta filtera:',
      Filters: 'filteri',
      'Final Counter read out': 'Očitanje finalnog brojača',
      'Final value': 'Zadnja vrijednost',
      'Finalize invoice': 'Završite račun',
      First: 'Prvi',
      Fixed: 'fiksni',
      'Fixed Filter': 'Fiksni filtar',
      Float: 'Float',
      'Flow Type': 'Tip tijeka',
      Font: 'krstionica',
      'Font color': 'Boja fonta',
      Forbidden: 'zabranjen',
      'Forecast temperature': 'Prognoza temperature',
      Format: 'Format',
      'Format mapping rule is required.':
        'Pravilo mapiranja formata je neophodno',
      'Format mapping rules': 'Pravila mapiranja formata',
      'Formula input': 'Unos formule',
      Fri: 'Pet',
      Friday: 'petak',
      'Friendly Name': 'Prijateljsko ime',
      'Friendly name': 'Prijateljsko ime',
      From: 'Iz',
      'From date can not be prior to start date of asset availability ()':
        'Od datuma ne može biti prije datuma početka raspoloživosti imovine ()',
      'From/to range should be within date limits of external reader flow configuration':
        'Raspon od / do trebao bi biti unutar datumskih granica vanjskog protoka čitača konfiguracija"',
      'Function to get connection config is missing':
        'Function to get connection config is missing',
      Functions: 'funkcije',
      Future: 'Budući',
      'Future input': 'Budući unos',
      'Future interval in days': 'Budući interval u danima',
      'Future interval in hours': 'Budući interval u satima',
      'Future interval in minutes': 'Budući interval u minutama',
      'Future interval in seconds': 'Budući interval u sekundama',
      GIS: 'GIS',
      'GIS Map': 'GIS karta',
      'GIS Maps': 'GIS karte',
      General: 'Općenito',
      Geolocation: 'Geolokacija',
      'Geolocation X': 'Geolokacija X',
      'Geolocation Y': 'Geolokacija Y',
      'Get total items': 'Pobroji ukupno elemente',
      'Gis Map': 'Gis karta',
      'Gis map': 'Gis karta',
      'Gis user groups were successfully updated.':
        'Gis korisničke grupe uspješno su ažurirane.',
      'Gis user was successfully created.': 'Gis korisnik je uspješno kreiran.',
      Good: 'Dobro',
      'Greater than': 'Veće od',
      'Greater than or equals': 'Veće ili jednako od',
      Group: 'Grupa',
      'Group Parent': 'Podrijetlo grupe',
      'Group Privilege Management': 'Grupno upravljanje povlasticama',
      'Group access': 'Pristup grupi',
      'Group by:': 'Grupa od:',
      'Group entity': 'Grupni entitet',
      Groups: 'Grupe',
      'Guaranteed Price': 'Zajamčena cijena',
      'Has address': 'Ima adresu',
      'Has usage': 'Ima upotrebu',
      'Header 1': 'Zaglavlje 1',
      'Header 2': 'Zaglavlje 2',
      'Heartbeat history': 'Povijest očitavanja',
      Hide: 'Skriji',
      'Hide chart': 'Sakrij grafikon',
      'Hide energy values': 'Sakrij vrijednosti energije',
      'Hide history': 'Sakrij povijest',
      'Hide seen notifications': 'Sakrij vidljive obavijesti',
      'Highcharts initialization failed.':
        'Inicijalizacija grafikona nije uspjela.',
      'History data job': 'Povijest podataka posla',
      'History flow': 'Povijest tijeka',
      'History flow mapping rule': 'Povijest tijeka mapiranja',
      'History flow tag': 'Povijest oznake tijeka',
      'History flow tango agent connection configuration':
        'Povijest tijeka konfiguracije konekcije tango agenta',
      'History job request successfully sent':
        'Povijest uspješno izvršenih zadataka',
      Hour: 'Sat',
      'Hour format is not right': 'Format sata nije u redu',
      'Hour interval quantity missing.': 'Nedostaje količina intervala sata.',
      Hours: 'Sati',
      'House number': 'kućni broj',
      'I have safely stored the secret': 'Sigurno sam sačuvao tajnu',
      IF: 'AKO',
      Icon: 'Ikona',
      Icons: 'Ikone',
      'If the format of received value is not appropriate, specify the appropriate format to remap it using a predefined function. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'Ako format primljene vrijednosti nije prikladan, navedite odgovarajući format za ponovno preslikavanje pomoću unaprijed definirane funkcije. Ako to pokrene pogrešku, alarm ili signalizira ručnu provjeru, označite je tako.',
      'If you think this is an error please contact your administrator.':
        'Ako mislite da je ovo pogreška, kontaktirajte svog administratora.',
      'Image was successfully removed.': 'Slika je uspješno uklonjena.',
      'Image was successfully uploaded.': 'Slika je uspješno prenesena.',
      Images: 'slike',
      'Implicit operation {{functionName2}} is not allowed.':
        'Implicitna operacija {{functionName2}} nije dopuštena',
      'Implicit operation {{functionName}} is not allowed.':
        'Implicitna operacija {{functionName}} nije dopuštena',
      'Import CSV': 'Uvoz CSV-a',
      'In order to add items, you have to select them using autocomplete or dialog selector':
        'Da biste dodali stavke, morate ih odabrati pomoću automatskog dovršavanja ili dijaloškog okvira selektor"',
      'In range': 'U rasponu',
      'Inactive Assets': 'Neaktivna sredstva',
      'Inactive Counter Types': 'Neaktivne vrste brojača',
      'Inactive Measuring Point Allocators': 'Neaktivni alokatori mjerne točke',
      'Inactive Time Series': [
        'Nektivna vremenska serija',
        'Neaktivni vlasnici',
        'Neaktivni vlasnici'
      ],
      'Inactive assets': 'Neaktivna sredstva',
      'Inactive business partners': 'Neaktivni poslovni partneri',
      'Inactive cost centres': 'Neaktivna mjesta troška',
      'Inactive locations': 'Neaktivne lokacije',
      'Inactive main cost centes': 'Neaktivni glavni troškovi',
      'Inactive measuring points': 'Neaktivne mjerne točke',
      'Inactive measuringpoints': 'Neaktivne mjerne točke',
      'Inactive owners': 'Neaktivni vlasnici',
      'Include end': 'Uključi kraj',
      'Include first month': 'Uključi prvi mjesec',
      'Include first year': 'Uključite prvu godinu',
      'Include last month': 'Uključi prošli mjesec',
      'Include last year': 'Uključite prošle godine',
      'Include start': 'Uključi početak',
      'Inclusion type': 'Vrsta uključivanja',
      'Incompatible data type': 'Nesukladan tip podataka',
      'Indicates order of execution.': 'Označava redoslijed izvršenja.',
      'Indirect Access': 'Neizravni pristup',
      'Indirect access items were successfully added.':
        'Stavke neizravnog pristupa uspješno su dodane.',
      'Ingestion type': 'Vrsta zakrčenja',
      'Initial value': 'Početna vrijednost',
      'Input Data Type': 'Vrsta ulaznih podataka',
      'Input Source': 'Izvor podataka',
      'Input Time Series': [
        'Izvor vremenske serije',
        'Izvor podataka',
        'Izvor podataka'
      ],
      'Input data type': 'Ulazni tip podataka',
      'Input expression': 'Ulazni izraz',
      'Input time series': [
        'Ulazna vremenska serija',
        'Neaktivna mjerenja',
        'Neaktivna mjerenja'
      ],
      'Input time series configurations':
        'Konfiguracija ulazne vremenske serije',
      'Input time series for X axis': 'Ulazne vremenske serije za X os',
      'Input time series for Y axis': 'Ulazne vremenske serije za Y os',
      'Input value': 'Ulazna vrijednost',
      'Input value represents a value received. Select a function to transform it to the desired output. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'Ulazna vrijednost predstavlja dobivenu vrijednost. Odaberite funkciju za pretvaranje željenog izlaza. Ako to pokrene pogrešku, alarm ili signalizira ručnu provjeru, označite je tako.',
      'Input will be saved as regular expression...':
        'Unos će biti spremljen kao regularni izraz ...',
      'Insert image': 'Umetnite sliku',
      'Installation Number': 'Broj instalacije',
      'Installation number': 'Broj instalacije',
      Integer: 'Integer',
      Integration: 'Integracija',
      Internal: 'interni',
      'Internal Order': 'Unutarnji redoslijed',
      Interval: 'Interval',
      'Interval from': 'Interval od',
      'Interval is already taken': 'Interval je već zauzet',
      'Interval quantity missing.': 'Nedostaje količina intervala.',
      'Interval to': 'Interval do',
      'Interval unit missing.': 'Nedostaje jedinica intervala.',
      'Invalid Data': 'Nevažeći podaci',
      'Invalid configuration.': 'Nevažeća konfiguracija',
      'Invalid date': 'Nevažeći datum',
      'Invalid filter configuration': 'Nevažeća konfiguracija filtera',
      'Invalid formatting at position {{x}}.':
        'Nevažeće oblikovanje na položaju {{x}}.',
      'Invalid number.': 'Pogrešan broj.',
      'Invalid phone number. (+386 12 312 312)':
        'Nevažeći telefonski broj. (+386 12 312 312)',
      'Invalid time': 'Nevažeće vrijeme',
      'Invalid value': 'Nevažeća vrijednost',
      'Inverse cosine (arccosine) function.':
        'Inverzna kosinusna (arkozinuzna) funkcija.',
      Invoice: 'Dostavnica',
      'Invoice Detail': 'Pojedinost dostavnice',
      'Invoice Details': 'Pojedinosti dostavnice',
      'Invoice Issuer': 'Izdavatelj računa',
      'Invoice Status': 'Stanje računa',
      'Invoice Type': 'Vrsta računa',
      'Invoice detail values': 'Vrijednosti pojedinosti dostavnice',
      'Invoice detail values successfully saved!':
        'Vrijednosti pojedinosti dostavnice uspješno su spremljene!',
      'Invoice for {{month}} is closed. Reopen invoice in order to edit values':
        'Račun za {{mjesec}} je zatvoren. Ponovo otvorite fakturu kako biste uredili vrijednosti',
      'Invoice has been successfully finalized':
        'Račun je uspješno pripremljen',
      'Invoice has been successfully opened': 'Račun je uspješno otvoren',
      'Invoice has no valid price list detail':
        'Račun nema valjani detalj cjenika',
      'Invoice is not closed': 'Račun nije zatvoren',
      'Invoice issuer': 'Izdavatelj računa',
      'Invoice issuer:': 'Izdavatelj računa:',
      'Invoice number': 'Broj računa',
      'Invoice overview': 'Pregled dostavnice',
      'Invoice status': 'Status računa',
      'Invoice type': 'Vrsta dostavnice',
      Invoices: 'Računi',
      'Invoices Overview': 'Pregled dostavnica',
      'Invoices overview': 'Pregled računa',
      IoT: 'IOT',
      'Irregular sampling type is not compatible with selected trigger type':
        'Nepravilna vrsta uzorkovanja nije kompatibilna s odabranom vrstom okidača',
      'Is standard': 'Is standard',
      Issuer: 'Izdavatelj',
      Italic: 'kurzivan',
      'Item group': 'Grupa predmeta',
      'Item has no connected entities for display.':
        'Stavka nema povezanih entiteta za prikaz',
      'Item was successfully created': 'Stavka je uspješno izrađena',
      'Item was successfully duplicated': 'Stavka je uspješno duplicirana',
      'Item was successfully removed': 'Stavka je uspješno uklonjena',
      'Item was successfully removed.': 'Stavka je uspješno uklonjena.',
      'Item was successfully updated': 'Stavka je uspješno ažurirana',
      Items: 'stavke',
      JAN: 'SIJ',
      JUL: 'srpnja',
      JUN: 'lipnja',
      Jan: 'Sij',
      January: 'siječanj',
      Job: 'Posao',
      'Job Results': 'Rezultati posla',
      'Job name': 'Naziv posla',
      'Job types': 'Vrste poslova',
      Jobs: 'Posao',
      Jul: 'srpnja',
      July: 'srpanj',
      Jun: 'lipnja',
      June: 'lipanj',
      K: 'K',
      Key: 'Ključ',
      'Key was copied to clipboard.': 'Ključ je kopiran u međuspremnik.',
      Kind: 'Ljubazan',
      Language: 'Jezik',
      Last: 'Zadnja vrijednost',
      'Last 3 months': 'Posljednja 3 mjeseca',
      'Last N month': 'Posljednji N mjesec',
      'Last N months': 'Posljednjih N mjeseci',
      'Last N years': 'Posljednjih N godina',
      'Last day': 'Posljednji dan',
      'Last half a year': 'Posljednjih pola godine',
      'Last heartbeat': 'Posljednje očitanje',
      'Last hour': 'Posljednji sat',
      'Last measurement time': 'Posljednje vrijeme mjerenja',
      'Last month': 'Posljednji mjesec',
      'Last value': 'Posljednja vrijednost',
      'Last value at': 'Posljednja vrijednost na',
      'Last week': 'Posljednji tjedan',
      'Last year': 'Posljednja godina',
      Lastname: 'Prezime',
      'Less than': 'Manje od',
      'Less than or equals': 'Manje ili jednako od',
      Limit: 'Ograničiti',
      'Limit history': 'Ograničite povijest',
      'Limit reached': 'Limit dosegnut',
      Link: 'Veza',
      'Link to dashboard': 'Veza na nadzornu ploču',
      'Linked Dashboard': 'Povezana upravljačka ploča',
      'Links to dashboard': 'Veze na nadzornu ploču',
      List: 'Popis',
      'Live Flow': 'Tijek uživo',
      'Live flow': 'Tijek uživo',
      'Live flow mapping rule': 'Pravilo mapiranja tijeka uživo',
      'Live flow read type': 'Vrsta očitanja tijeka uživo',
      'Live flow tag': 'Oznaka tijeka uživo',
      'Load more': 'Učitaj više',
      'Load more <span ng-if="vm.moreLeft">({{vm.moreLeft}})</span>':
        'Učitaj više <span ng-if="vm.moreLeft">({{vm.moreLeft}})</span>',
      Loading: 'Učitavam',
      'Loading ...': 'Učitavam ...',
      'Loading M&T analysis...': 'Učitavanje M&T analize ...',
      'Loading additional data ...': 'Učitavanje dodatnih podataka ...',
      'Loading location ...': 'Učitavanje lokacije ...',
      'Loading registers ...': 'Učitavanje registara ...',
      'Loading values': 'Učitavanje vrijednosti',
      Location: 'Mjesto',
      'Location Classification': 'Klasifikacije lokacije',
      'Location Classifications': 'Klasifikacije lokacija',
      'Location Cost Centres': 'Lokacija troškovnog centra',
      'Location Country': 'Zemlja lokacije',
      'Location Owner': 'Vlasnik lokacije',
      'Location Ownership': 'Vlasništvo lokacije',
      'Location Status': 'Status lokacije',
      'Location Statuses': 'Statusi lokacije',
      'Location Tree View': 'Prikaz stabla lokacija',
      'Location Type': 'Vrsta lokacije',
      'Location Types': 'Vrste lokacija',
      'Location classification': 'Klasifikacija lokacije',
      'Location external ID': 'Vanjski ID lokacije',
      'Location has no subsidiary locations': 'Lokacija nema podružnica',
      'Location link': 'Veza lokacije',
      'Location links': 'Veze lokacije',
      'Location map': 'Karta mjesta',
      'Location ownership': 'Vlasništvo lokacije',
      'Location status': 'Status lokacije',
      'Location type': 'Vrsta lokacije',
      'Location type attributes': 'Atributi vrste mjesta',
      Locations: 'Lokacije',
      'Locations Overview': 'Pregled lokacija',
      Lock: 'zaključati',
      Logout: 'Odjaviti se',
      Logs: 'zapisnici',
      'Looks like you have no main dashboard.':
        'Izgleda da nemate glavnu nadzornu ploču.',
      'Lower Limit %': 'Donja granica u%',
      'Lower Limit Alarm': 'Alarm donje granice',
      'Lower Limits': 'Donje granice',
      'M&T analysis flow': 'Tijek M&T analiza',
      MAJ: 'MAJ',
      MAR: 'OŽU',
      'MT timeSeries is missing date for calculation from value':
        'MT vremenskom tijeku nedostaje datum za izračun iz vrijednosti',
      'MT timeSeries is missing date for calculation to value':
        'MT vremenskom tijeku nedostaje datum za izračun u vrijednost',
      Main: 'Glavni',
      'Main Cost Centre': 'Glavni troškovni centar',
      'Main cost centre': 'Glavni troškovni centar',
      'Main dashboard': 'Glavna upravljačka ploča',
      'Maintenance flow': 'Tijek održavanja',
      'Maintenance flow type': 'Maintenance flow type',
      Maj: 'maj',
      'Make sure asset type is selected.':
        'Provjerite je li odabrana vrsta imovine.',
      'Make sure location type is selected.':
        'Provjerite je li odabrana vrsta lokacije.',
      'Make sure measuringpoint type is selected.':
        'Provjerite je li odabrana vrsta mjerne točke.',
      'Make sure time series type is selected.':
        'Provjerite da je odabran serija vremena.',
      'Manual Input': 'Ručni unos',
      'Manual Input -': 'Ručni unos -',
      'Manual input': 'Ručni unos',
      'Manual input form has no time series':
        'Oblik ručnog unosa nema seriju vremena',
      'Manual intervention': 'Ručna intervencija',
      'Manual reading cannot be entered because the invoice is already closed for the current billing period {{month}}.':
        'Nije moguće unijeti ručno čitanje jer je račun već zatvoren za trenutačno obračunsko razdoblje {{mjesec}}."',
      'Manual reading cannot be entered because the reading date cannot be greater than today\'s date - {{date | luxonDate:\'short\'}}':
        'Nije moguće unijeti ručno čitanje jer datum čitanja ne može biti veći od današnjeg datuma - {{datum | luxonDate: \'kratak\'}}}"',
      'Manual reading cannot be entered because the value of the entered reading is greater than the next reading.':
        'Ručno očitavanje nije moguće unijeti jer je vrijednost unesenog očitanja veći od sljedećeg čitanja".',
      'Manual reading cannot be entered because the value of the entered reading is less than the previous reading.':
        '"Ručno očitavanje nije moguće unijeti jer je vrijednost unesenog očitanjamanje od prethodnog čitanja."',
      'Manual reading cannot be entered because there is already a closed invoice for {{month}} {{year}}.':
        'Nije moguće unijeti ručno čitanje jer već postoji zatvorena faktura za {{mjesec}} {{godinu}}."',
      'Manual reading cannot be entered because there is no main billing measuring point at the current measuring point.':
        'Nije moguće unijeti ručno očitavanje jer nema glavnog mjernog računa točka na trenutnom mjernom mjestu.',
      'Manual reading cannot be entered since the difference between the consumption date and reading date should not exceed 1 month.':
        'Nije moguće unijeti ručno očitavanje jer je razlika između datum potrošnje i datum čitanja ne smiju biti duži od 1 mjeseca."',
      'Manual readings': 'Ručno očitanje',
      'Manual readings cannot be saved.':
        'Ručno očitanje ne može biti sačuvano',
      'Manual readings for {{measuringpointName}}':
        'Ručno očitanje za {{measuringpointName}}',
      'Mapping Rule': 'Pravilo mapiranja',
      'Mapping Rule Item': 'Mapiranje stavke pravila',
      'Mapping Rules': 'Pravila mapiranja',
      'Mapping functions': 'Funkcije mapiranja',
      'Mapping rule': 'Pravilo mapiranja',
      Maps: 'Karte',
      Mar: 'pokvariti',
      March: 'ožujak',
      'Master Invoice': 'Glavna faktura',
      'Master Price List': 'Novi cjenik',
      'Master invoice': 'Glavna faktura',
      'Match is required': 'Podudaranje je obavezno',
      'Math expression must contain at least one valid variable name.':
        'Matematički izraz mora sadržavati barem jedan važeći naziv varijable.',
      Max: 'Maksimum',
      'Max input length is {{vm.configuration.maxLength}}.':
        'Maksimalna ulazna duljina je {{data.maxLength}}.',
      'Max number of items is {{vm.configuration.maxItems}}.':
        'Maksimalni broj predmeta je {{vm.configuration.max}}.',
      'Max timeout': 'Alarm nakon isteka vremena',
      'Max value is {{configuration.max}}.':
        'Maksimalna vrijednost je {{configuration.max}}.',
      'Max value is {{historyItem.formConfig.max}}.':
        'Maksimalna vrijednost je {{historyItem.formConfig.max}}.',
      'Max value is {{vm.configuration.max}}.':
        'Maksimalna vrijednost je {{vm.configuration.max}}.',
      'Max value must be lower than {{vm.configuration.max}}.':
        'Maksimalna vrijednost mora biti manja od {{vm.configuration.max}}.',
      'Max year value is {{vm.maxYear}}.':
        'Maksimalna vrijednost godine je {{vm.maxYear}}.',
      'Maximum Capacity': 'Maksimalni kapacitet',
      'Maximum Seconds since last Heartbeat':
        'Najviše sekundi od zadnjeg očitanja',
      'Maximum capacity': 'Maksimalni kapacitet',
      'Maximum number of chips reached.': 'Dosegnut je najveći broj žetona.',
      'Maximum value': 'Maksimalna vrijednost',
      May: 'svibanj',
      'Measurement Unit': 'Mjerna jedinica',
      'Measurement Units': 'Mjerne jedinice',
      'Measurement unit': 'Mjerna jedinica',
      'Measurement units': 'Mjerne jedinice',
      'Measuring Point': 'Mjerna točka',
      'Measuring Point Allocators': 'Alokator mjerne točke',
      'Measuring Point Class': 'Klasa mjerne točke',
      'Measuring Point Kind': 'Mjerna točka Vrsta',
      'Measuring Point Mode': 'Način mjerne točke',
      'Measuring Point Type': 'Vrsta mjerne točke',
      'Measuring Point Types': 'Vrste mjernih točaka',
      'Measuring Points': 'Mjerne točke',
      'Measuring point': 'Mjerna točka',
      'Measuring point class': 'Klasa mjernih točaka',
      'Measuring point has no child measuring points.':
        'Imovina nema dijete mjernih točaka',
      'Measuring point kind': 'Vrsta mjerne točke',
      'Measuring point type': 'Vrsta mjerne točke',
      'Measuring point type attributes': 'Atributi tipa mjerne točke',
      'Measuring points': 'Mjerne točke',
      Measuringpoint: 'Measuringpoint',
      'Measuringpoint Class': 'Klasa mjerne točke',
      'Measuringpoint has no counter types': 'Mjerna točka nema tipove brojača',
      Member: 'Član',
      Members: 'Članovi',
      'Membership updated.': 'Članstvo je ažurirano.',
      'Meta data': 'Meta podaci',
      Min: 'Min',
      'Min 1, Max 10000': 'Minimalno 1, maksimalno 1000',
      'Min input length is {{vm.configuration.minLength}}.':
        'Minimalna ulazna duljina je {{data.minLength}}.',
      'Min number of items is {{vm.configuration.minItems}}.':
        'Minimalna vrijednost podataka je {{vm.configuration.min}}.',
      'Min value is {{configuration.min}}.':
        'Minimalna vrijednost je {{configuration.min}}.',
      'Min value is {{historyItem.formConfig.min}}.':
        'Minimalna vrijednost je {{historyItem.formConfig.min}}.',
      'Min value is {{vm.configuration.min}}.':
        'Minimalna vrijednost je {{vm.configuration.min}}.',
      'Min value must be higher than {{vm.configuration.min}}.':
        'Minimalna vrijednost mora biti veća od {{vm.configuration.min}}.',
      'Min year value is {{vm.minYear}}.':
        'Minimalna vrijednost godine je {{vm.minYear}}.',
      'Minimum value': 'Minimalna vrijednost',
      'Minute interval quantity missing.':
        'Nedostaje količina minuta intervala.',
      Minutes: 'minuta',
      'Missing inputTimeSeriesForXAxis': 'Nedostaje inputTimeSeriesForXAxis "',
      'Missing inputTimeSeriesForYAxis': 'Nedostaje inputTimeSeriesForYAxis "',
      'Missing item content.': 'Nedostaje sadržaj stavke',
      'Missing variable name.': 'Ime varijable nedostaje.',
      Model: 'Model',
      'Model number': 'Broj modela',
      Modules: 'moduli',
      Modulo: 'modulo',
      Mon: 'Pon',
      Monday: 'ponedjeljak',
      Monitoring: 'Nadgledanje',
      Month: 'Mjesec',
      'Month interval quantity missing.':
        'Nedostaje količina mjesečnog intervala.',
      Monthly: 'Mjesečno',
      Months: 'mjeseci',
      'More actions': 'Više radnji',
      Morning: 'Jutro',
      'Move time series from preview to schedule classifications':
        'Premjestite vremenske serije iz pregleda da biste zakazali klasifikacije',
      'Mt analysis flow': 'Tijek MT analize',
      Multiplication: 'Množenje',
      'Multiplies neighbouring expressions': 'Množi susjedne izraze',
      'Must be after previous date ""':
        'Mora biti nakon prethodnog datuma \\ "\\',
      'Must be before or todays date': 'Mora biti prije ili današnji datum',
      'Must be before todays date': 'Mora biti prije ili današnji datum',
      'Must be greater than X values from':
        'Mora biti veće od vrijednosti X iz ',
      'Must be greater than minimum value':
        'Mora biti veća od minimalne vrijednosti',
      'Must be less than X values to': 'Mora biti manje od X vrijednosti do',
      'Must be less than maximum value':
        'Mora biti manje od maksimalne vrijednosti',
      'Must be resolved by': 'Mora se riješiti',
      N: 'N',
      'N times': 'N puta',
      NO: 'NE',
      NOV: 'studeni',
      'NOx per kWh': 'NOx po kWh',
      Name: 'Ime',
      'Name or Description': 'Ime ili opis',
      'Name or last name': 'Ime ili prezime',
      'Name:': 'Ime:',
      'National Holiday': 'Nacionalni praznik',
      'National Holidays': 'Nacionalni praznici',
      'National holidays': 'Nacionalni praznici',
      Navigation: 'Navigacija',
      'New Action Status': 'Novi status',
      'New Aggregation': 'Nova agregacija',
      'New Alarm Definition': 'Nova definicija alarma',
      'New Alarm Type': 'Nova vrsta alarma',
      'New Analysis': 'Nova analiza',
      'New Asset': 'Nova imovina',
      'New Asset Brand': 'Novi brand imovine',
      'New Asset Group': 'Nova grupa imovine',
      'New Asset Model': 'Novi model imovine',
      'New Asset Status': 'Novi status imovine',
      'New Asset Type': 'Nova vrsta imovine',
      'New Billing Kind': 'Novi način naplate',
      'New Billing Type': 'Nova vrsta naplate',
      'New Business Partner': 'Novi poslovni partner',
      'New Business partner': 'Novi poslovni partner',
      'New Calculation': 'Novi izračun',
      'New Company Structure Node': 'Novi element strukture poduzeća',
      'New Cost Centre': 'Novi troškovni centar',
      'New Counter Type': 'Nova vrsta brojača',
      'New Dashboard': 'Nova nadzorna ploča',
      'New Database': 'Nova baza podataka',
      'New Dataset': 'Novi skup podataka',
      'New Document Classification': 'Nova klasifikacija dokumenta',
      'New Domain': 'Nova domena',
      'New Domain Attribute': 'Nova svojstva domene',
      'New Domain Collection': 'Nova zbirka domene',
      'New Domain Field': 'Novo polje domene',
      'New Energy Management Item': 'Novi element upravljanja energijom',
      'New Energy Source Type': 'Novi tip izvora energije',
      'New Expression': 'Novi izraz',
      'New External Datasource': 'Novi vanjski izvor podataka',
      'New File': 'Nova datoteka',
      'New Group': 'Nova grupa',
      'New Invoice': 'Novi račun',
      'New Invoice Detail': 'Novi pojedinost dostavnice',
      'New Job': 'Novi posao',
      'New Location': 'Nova lokacija',
      'New Location Classification': 'Nova klasifikacija lokacije',
      'New Location Status': 'Novi status lokacije',
      'New Location Type': 'Nova vrsta lokacije',
      'New Mapping Rule': 'Novo pravilo mapiranja',
      'New Measurement Unit': 'Nova jedinica za mjerenje',
      'New Measuring Point': 'Nova mjerna točka',
      'New Measuring Point Type': 'Nova vrsta mjerne točke',
      'New National Holiday': 'Novi nacionalni praznik',
      'New Notification': 'Nova obavijest',
      'New OPC': 'Novi OPC',
      'New Ownership': 'Novo vlasništvo',
      'New Physical Quantity': 'Nova fizička količina',
      'New Playlist': 'Novi popis pjesama',
      'New Price List': 'Novi cjenik',
      'New Price List Item': 'Novi cjenik',
      'New Profit Centre': 'Novi profitni centar',
      'New Project': 'Novi projekt',
      'New Project Field': 'Novo polje projekta',
      'New Project Type': 'Nova vrsta projekta',
      'New Rated Power': 'Nova nazivna snaga',
      'New Report': 'Novo izvješće',
      'New Response Interval': 'Novi interval odgovora',
      'New Rule': 'Novo pravilo',
      'New SOP': 'Novi SOP',
      'New Schedule': 'Novi raspored',
      'New Scheduler': 'Novi planer',
      'New Severity': 'Nova ozbiljnost',
      'New Step': 'Novi korak',
      'New Tango Agent Connection Config':
        'Nova konfiguracija veze tango agenta',
      'New Tango Agent Connection Configuration':
        'Nova konfiguracija veze tango agenta',
      'New Tariff': 'Nova tarifa',
      'New Task': 'Novi zadatak',
      'New Time Interval Filter': 'Novi filtar vremenskog intervala',
      'New Time Series': 'Novi vremenski tijek',
      'New Time Series Group Type': 'Novi tip grupe vremenskog tijeka',
      'New Time Series Type': 'Novi tip vremenskog tijeka',
      'New Time Series Value': 'Nova vrijednost vremenskog tijeka',
      'New Web Service': 'Nova web-usluga',
      'New action': 'Nova radnja',
      'New aggregation group': 'Nova grupa za agregiranje',
      'New alarm definition': 'Nova definicija alarma',
      'New analysis': 'Nova analiza',
      'New asset': 'Nova imovina',
      'New asset type': 'Nova vrsta imovine',
      'New assignee': 'Novi opunomoćenik',
      'New calculation': 'Novi izračun',
      'New column': 'Novi stupac',
      'New dashboard': 'Nova nadzorna ploča',
      'New dashboard playlist': 'Novi popis za reprodukciju nadzorne ploče',
      'New dataset': 'Novi skup podataka',
      'New dynamic attribute': 'Novi dinamički atribut',
      'New expression': 'Novi izraz',
      'New group': 'Nova grupa',
      'New invoice detail value': 'Stvorite vrijednost pojedinosti o računu',
      'New item': 'Nova stvar',
      'New location type': 'Novi tip lokacije',
      'New manual input': 'Novi ručni unos',
      'New mapping rule': 'Novo pravilo mapiranja',
      'New master invoice': 'Nova glavna faktura',
      'New measuring point': 'Nova mjerna točka',
      'New measuring point type': 'Novi tip mjerne točke',
      'New notification': 'Nova obavijest',
      'New password': 'Nova lozinka',
      'New price list item': 'Nova stavka cjenika',
      'New price list item group': 'Nova grupa stavki cjenika',
      'New report': 'Novo izvješće',
      'New standard operating procedure': 'Novi standardni operativni postupak',
      'New step': 'Novi korak',
      'New task': 'Novi zadatak',
      'New time interval filter': 'Novi filtar vremenskog intervala',
      'New time series type': 'Nova vrsta tijeka vremena',
      'New value': 'Nova vrijednost',
      Next: 'Sljedeći',
      'Next date': 'Slijedeći datum',
      'Next step': 'Slijedeći korak',
      Night: 'Noć',
      No: 'Ne',
      'No SOP are connected to this definition.':
        'SOP nije povezan s ovom definicijom.',
      'No address and geolocation found.':
        'Nije pronađena nijedna adresa i geolokacija.',
      'No address found at this location. Set it manually.':
        'Na ovoj lokaciji nije pronađena nijedna adresa. Postavite ga ručno.',
      'No attributes yet.': 'Još nema atributa.',
      'No connected attributes.': 'Nema povezanih atributa.',
      'No content available': 'Nema dostupnog sadržaja',
      'No data to display.': 'Nema podataka za prikaz.',
      'No date': 'Nema datum',
      'No elements yet.': 'Još nema elemenata.',
      'No entity found': 'Nije pronađena nijedna stavka.',
      'No gis token found': 'Nije pronađena niti gis oznaka',
      'No heartbeat yet': 'Još nema otkucaja srca',
      'No images found for this asset.':
        'Nije pronađena nijedna slika za ovaj sadržaj.',
      'No images found for this location.':
        'Nema pronađenih slika za ovu lokaciju.',
      'No item found.': 'Nije pronađena nijedna stavka.',
      'No item is currently selected': 'Nijedan element nije trenutno odabran',
      'No items found': 'Nema pronađenih stavki',
      'No items found.': 'Nema pronađenih stavki.',
      'No items were changed to update':
        'Nijedna stavka nije promijenjena u ažuriranje',
      'No location is currently selected':
        'Nijedna lokacija nije trenutno izbrisana',
      'No modules found.': 'Nije pronađen nijedan modul.',
      'No options available': 'Nema dostupnih opcija',
      'No options to select from': 'Nema opcija za odabir',
      'No path found.': 'Nije pronađena poveznica.',
      'No properties': 'Bez svojstava',
      'No properties.': 'Bez svojstava.',
      'No results': 'Bez rezultata',
      'No results found for your address. Set coordinates manually.':
        'Bez rezultata za vašu adresu. Ručno postavite koordinate.',
      'No rule selected': 'Bez odabranog pravila',
      'No tag is selected': 'Bez odabrane znake',
      'No value': 'Bez vrijednosti.',
      'No value to display': 'Nema vrijednosti za prikaz',
      'No value to display.': 'Nema vrijednosti za prikaz.',
      'No value.': 'Bez vrijednosti.',
      'No values': 'Bez vrijednosti',
      'No values on this interval': 'Nema vrijednosti u ovom intervalu.',
      None: 'nijedan',
      'None found.': 'Nije pronađeno.',
      'Not a positive number.': 'Nije pozitivan broj',
      'Not a whole number.': 'Nije cijeli broj',
      'Not equal': 'Nejednak',
      'Not periodic': 'Nije povremen',
      'Not-Found': 'Nije pronađen',
      'Notification Details': 'Pojedinosti obavijesti',
      'Notification content': 'Sadržaj obavijesti',
      'Notification services': 'Usluge obavijesti',
      'Notification via E-mail': 'Obavijest putem e-pošte',
      'Notification via SMS': 'Obavijest putem SMS-a',
      Notifications: 'Obavijesti',
      Nov: 'studeni',
      November: 'studeni',
      'Number of Tariffs': 'Broj tarifa',
      'Number of tariffs': 'Broj tarifa',
      'Number of values': 'Broj vrijednosti',
      OK: 'u redu',
      OKT: 'LIS',
      OPC: 'OPC',
      'OPC connection configuration': 'OPC konfiguracija veze',
      OPCs: 'OPCs',
      Oct: 'Lis',
      October: 'listopad',
      'Oh no! Something went wrong :(': 'O ne! Nešto je pošlo po zlu :(',
      'Oh no! The page you are trying to reach does not exist :(':
        'O ne! Stranica koju pokušavate otvoriti ne postoji :(',
      'Oh no! You do not have permissions to see this item :(':
        'O ne! Nemate dozvolu za prikaz ove stavke :(',
      Ok: 'U redu',
      Okt: 'Lis',
      'On duty': 'Na dužnosti',
      'Once per month': 'Jednom mjesečno',
      'Only one main schedule classification is allowed':
        'Samo jedna klasifikacija glavnog rasporeda je dozvoljena',
      'Open invoice': 'Otvori račun',
      'Open time series filters': 'Otvori filter vremenskog tijeka',
      'Opening counter read out': 'Očitanje brojača otvaranja',
      'Operation {{functionName2}} is not allowed.':
        'Operacija {{functionName2}} nije dopuštena.',
      'Operation {{functionName}} is not allowed.':
        'Operacija {{functionName2}} nije dopuštena.',
      'Operational Support Price': 'Cijena operativne podrške',
      Operations: 'operacije',
      Operator: 'Operater',
      Or: 'Ili',
      Order: 'Narudžba',
      'Ordered list': 'Naručeni popis',
      Organizations: 'Organizacije',
      'Output expression': 'Izlazni izraz',
      Owner: 'Vlasnik',
      Ownership: 'Vlasništvo',
      Ownerships: 'vlasništvom',
      Page: 'Stranica',
      Pair: 'Par',
      'Pair GIS user': 'Uparite GIS korisnika',
      'Paired time series type': 'Tip serije uparenog vremena',
      'Pairing settings': 'Postavke združivanja',
      Parent: 'Roditelj',
      'Parent Business Partner': 'Roditeljski poslovni partner',
      'Parent Company Structure Node': 'Struktura čvora roditeljskog poduzeća',
      'Parent Location': 'Lokacija roditelja',
      'Parent Node': 'Roditeljski čvor',
      'Parent business partner': 'Roditeljski poslovni partner',
      'Parent company structure node': 'Struktura poduzeća',
      'Parent group': 'Roditeljska skupina',
      'Parent node': 'Roditeljski čvor',
      'Parent:': 'Roditelj:',
      Parenthesis: 'Zagrada',
      'Parse error.': 'Pogreška analize.',
      'Parts per kWh': 'Dijelovi po kWh',
      Password: 'Zaporka',
      'Password confirmation': 'potvrda lozinke',
      'Password was successfully reset': 'Zaporka je uspješno poništena',
      'Password was successfully updated.': 'Zaporka je uspješno ažurirana.',
      'Passwords do not match.': 'Lozinke se ne podudaraju.',
      Path: 'Staza',
      'Pattern is not correct': 'Uzorak nije točan.',
      'Pattern is not correct. (04.2018)': 'Uzorak nije točan. (04.2018)',
      'Pattern is not correct. ({{configuration.patternExample}})':
        'Uzorak nije točan. ({{configuration.patternExample}})',
      'Pattern is not correct. ({{vm.configuration.patternExample}})':
        'Uzorak nije točan. ({{vm.configuration.patternExample}})',
      'Pattern is not correct. 22:00': 'Uzorak nije točan. 22:00',
      'Pattern is not correct. 23:59:59': 'Uzorak nije točan. 23:59:59',
      'Pattern set ({{from}} - {{to}})': 'Skup uzoraka ({{od}} - {{do}})',
      People: 'Ljudi',
      'Per page': 'Po stranici:',
      'Performs a logical AND of the two operands.':
        'Izvodi logički I od dva operanda.',
      'Performs a logical OR of the two operands.':
        'Izvodi logički ILI od dva operanda.',
      'Period inerval': 'Period inervala',
      'Period interval': 'Razdoblje razdoblja',
      'Period type': 'Vrsta razdoblja',
      Periodic: 'periodni',
      'Permissions were successfully updated.':
        'Autorizacije su uspješno ažurirane.',
      Person: 'Osoba',
      'Person responsible': 'Odgovorna osoba',
      Phase: 'Phase',
      Phone: 'Telefon',
      'Phone Number': 'Broj telefona',
      'Physical Quantities': 'Fizičke veličine',
      'Physical Quantity': 'Fizička količina',
      'Physical information': 'Fizičke informacije',
      'Physical quantity': 'Fizička količina',
      'Pick a job type': 'Odaberite vrstu posla',
      'Planned events cannot start prior to two hours from now.':
        'Planirani događaji ne mogu početi prije nego što prođu dva sata od sada.',
      'Please select energy source type before selecting order':
        'Odaberite tip izvora energije prije odabira narudžbe',
      'Post name': 'Naziv posta',
      'Postal number': 'Poštanski broj',
      Power: 'Vlast',
      Precision: 'Preciznost',
      Preset: 'Unaprijed',
      'Press ENTER to confirm input.': 'Pritisnite ENTER za potvrdu unosa.',
      Preview: 'Prikaz',
      Previous: 'prijašnji',
      'Previous Versions': 'Prethodne verzije',
      'Previous counter read out': 'Prethodno očitanje brojača',
      'Previous date': 'Prethodni datum',
      'Previous period': 'Prethodno razdoblje',
      'Previous state': 'Prethodno stanje',
      'Previous versions': 'Prethodne verzije',
      Price: 'Cijena',
      'Price List': 'Cjenik',
      'Price List Detail': 'Detalj cjenika',
      'Price List Details': 'Detalji cjenika',
      'Price List Item Group Configuration':
        'Cjenik Stavka Konfiguracija grupe',
      'Price List Item Group Type': 'Cjenik stavka Vrsta grupe',
      'Price List Item Groups': 'Grupe stavki cjenika',
      'Price Lists': 'Cjenici',
      'Price list': 'Cjenik',
      'Price list doesn\'t exist for this issuer, billing date and measuring point.':
        'Cjenik ne postoji za ovog izdavača, datum naplate i mjerno mjesto. "',
      'Price list doesn\'t exist for this supplier, billing date and measuring point.':
        'Cjenik ne postoji za ovog dobavljača, datum naplate i mjerno mjesto',
      'Price list item': 'Stavka cjenika',
      'Price list item groups': 'Grupe stavki cjenika',
      'Price list items': 'Stavke cjenika',
      Primary: 'osnovni',
      'Prior to choosing value field name and display field name, make sure to select a domain type':
        'Prije odabira naziva polja vrijednosti i naziva polja, svakako odaberite vrstu domene',
      Priority: 'Prioritet',
      'Privilege Management': 'Upravljanje povlasticama',
      'Privilege management': 'Upravljanje povlasticama',
      Procedure: 'Postupak',
      Profile: 'Profil',
      'Profit Centre': 'Profitni centar',
      'Profit Centre Tree View': 'Profitni centar Tree View',
      'Profit Centres': 'Profitni centri',
      'Profit centre': 'Profitni centar',
      'Profit centres': 'Profitni centri',
      Project: 'Projekt',
      'Project Field': 'Polje projekta',
      'Project Fields': 'Polja projekta',
      'Project Type': 'Vrsta projekta',
      'Project Types': 'Vrste projekata',
      'Project field': 'Polje projekta',
      'Project type': 'Vrsta projekta',
      Projects: 'Projekt',
      Properties: 'Nekretnine',
      Protocol: 'Protokol',
      'Provide a different interval': 'Navedite drugi interval',
      'Provide at least one day in the week.':
        'Osigurajte barem jedan dan u tjednu.',
      'Provide at least one day.': 'Osigurajte barem jedan dan.',
      'Provide at least one hour choice.': 'Osigurajte najmanje jedan sat.',
      'Provide at least one hour.': 'Osigurajte najmanje jedan sat.',
      'Provide at least one minute choice.':
        'Pružite barem jednu minutu izbora.',
      'Provide at least one minute.': 'Osigurajte najmanje jednu minutu.',
      'Provide at least one month.': 'Osigurajte najmanje mjesec dana.',
      'Provide at least one second.': 'Osigurajte najmanje jednu sekundu.',
      'Provide at least one weekday.': 'Osigurajte barem jedan radni dan.',
      'Provide day in month.': 'Dajte dan u mjesecu.',
      'Provide time in day.': 'Osigurajte vrijeme u danu.',
      Quantity: 'Količina',
      'Range from *': 'U rasponu od *',
      'Range to *': 'Raspon do *',
      'Rated Power': 'Nazivna snaga',
      'Rated power': 'Nazivna snaga',
      'Rated powers': 'Nazivne snage',
      'Raw field': 'Sirovo polje',
      'Raw field name': 'Neobrađeni naziv polja',
      Read: 'Čitati',
      'Read Query': 'Upit za čitanje',
      'Read Scheduler': 'Raspored čitanja',
      'Read type': 'Vrsta čitanja',
      'Reading Type': 'Vrsta čitanja',
      'Reading date': 'Datum čitanja',
      'Reading scheduler': 'Raspored čitanja',
      'Reading type': 'Vrsta čitanja',
      Recalculate: 'preračunati',
      'Received Notifications': 'Primljene obavijesti',
      Redirect: 'Preusmjeravanje',
      'Reduce method': 'Smanjite metodu',
      Refresh: 'Osvježiti',
      'Refresh interval': 'Interval osvježavanja',
      Refreshing: 'Osvježavajući',
      'Refreshing values': 'Vrijednosti osvježavanja ...',
      'Regardless of date': 'Bez obzira na datum',
      Regenerate: 'Regenerirati',
      'Regenerate external datasource credentials':
        'Obnovite vjerodajnice vanjskih podataka',
      Registers: 'Registri',
      'Regular Expression Mapping Rule': 'Pravilo mapiranja regularnih izraza',
      'Related company resources': 'Povezani resursi tvrtke',
      Remove: 'Ukloniti',
      'Remove Location': 'Ukloni lokaciju',
      'Remove Sort': 'Ukloni sortiranje',
      'Remove Step': 'Ukloni korak',
      'Remove Task': 'Ukloni zadatak',
      'Remove all direct access': 'Uklonite sav izravan pristup',
      'Remove all items from indirect access?':
        'Želite li ukloniti sve stavke iz neizravnog pristupa?',
      'Remove classification': 'Ukloni klasifikaciju',
      'Remove display field': 'Ukloni polje prikaza',
      'Remove group': 'Ukloni grupu',
      'Remove indirect access': 'Ukloni neizravan pristup',
      'Remove item': 'Ukloni stavku',
      'Remove layout': 'Ukloni izgled',
      'Remove mapping rule': 'Ukloni pravilo mapiranja',
      'Remove procedure': 'Ukloni proceduru',
      'Remove role.': 'Ukloni ulogu.',
      'Remove schedule classification': 'Ukloni raspored klasifikacije',
      'Remove selected direct access': 'Ukloni odabrani izravan pristup',
      'Remove series': 'Uklonite seriju',
      Reorder: 'Ponovna narudžba',
      Report: 'izvješće',
      'Report is loading, this may take a while.':
        'Izvješće se učitava, to može potrajati.',
      Reports: 'Izvještaji',
      'Request failed': 'Zahtjev nije uspio',
      Required: 'Potreban',
      Reselect: 'ponovni odabir',
      Reset: 'Reset',
      'Reset Password': 'Resetiranje lozinke',
      'Reset zoom': 'Resetiraj povećanje',
      'Resolution comment': 'Komentar rezolucije',
      'Resolution in': 'Rezolucija u',
      Resolve: 'Odlučnost',
      'Resolve Alarm': 'Rješavanje alarma',
      'Resolved at': 'Riješeno na',
      Response: 'Odgovor',
      'Response Time': 'Vrijeme odziva',
      'Response and resolution time': 'Vrijeme odziva i rezolucije',
      'Response time': 'Vrijeme odziva',
      Responses: 'odgovori',
      Responsibility: 'Odgovornost',
      'Responsible Person and notifications':
        'Odgovorna osoba i obavještavanje',
      'Responsible person': 'Odgovorna osoba',
      'Responsible personnel': 'Odgovorno osoblje',
      'Responsible persons and notifications':
        'Odgovorna osoba i obavještavanje',
      'Returns absolute value of input parameter.':
        'Vraća apsolutnu vrijednost ulaznog parametra.',
      'Returns degree.': 'Vraća stupanj.',
      'Returns radians.': 'Vraća radijane.',
      'Returns the avarage of last number of measurements.':
        'Vraća prosječni broj posljednjih mjerenja.',
      'Returns the largest number in arguments.':
        'Vraća najveći broj argumenata.',
      'Returns the last measurement.': 'Vraća posljednje mjerenje.',
      'Returns the remaining whole number after division.':
        'Vraća preostali cijeli broj nakon podjele.',
      'Returns the smallest number in arguments.':
        'Vraća najmanji broj u argumentima.',
      'Returns the square root of number.': 'Vraća kvadratni korijen broja.',
      'Returns true if both operands have the same value.':
        'Vraća na točno ako oba operanda imaju istu vrijednost.',
      'Returns true if the left operand is greater than or equal to the right operand.':
        'Vraća na točno ako je lijevi operand veći ili jednak desnom operand.',
      'Returns true if the operands don\'t have the same value.':
        'Vraća na točno ako operandi nemaju istu vrijednost.',
      'Returns true if the value on the left is greater than the value on the right.':
        'Vraća istinu ako je vrijednost s lijeve strane veća od vrijednosti s desne strane.',
      'Returns true if the value on the left is less than the value on the right.':
        'Vraća na točno ako je vrijednost s lijeve strane manja od vrijednosti s desne strane.',
      Role: 'Uloga',
      'Rounds down to nearest natural number.':
        'Zaokružuje se na najbliži prirodni broj.',
      'Rounds up to nearest natural number.':
        'Zaokružuje do najbližeg prirodnog broja.',
      'Rows per page': 'Redova po stranici',
      Rule: 'Pravilo',
      'Rule - {{ruleName}}': 'Pravilo - {{pravilo}}',
      'Rule Item': 'Stavka pravila',
      Rules: 'Pravila',
      'Rules and alarms': 'Pravila i alarmi',
      Run: 'Trčanje',
      'Run Analysis': 'Pokreni analizu',
      'Run Playlist': 'Pokreni popis za reprodukciju',
      SEP: 'rujna',
      'SO2 per kWh': 'SO2 po kWh',
      SOP: 'SOP',
      'SOP Step': 'Korak SOP',
      'SOP Steps': 'Koraci SOP-a',
      'SOP Task': 'SOP zadatak',
      'SOP Tasks': 'Zadaci SOP-a',
      Sat: 'Sat',
      Saturday: 'subota',
      Save: 'Snimi',
      'Save as': 'Snimi kao',
      'Save order': 'Snimi narudžbu',
      'Save time series group': 'Snimi grupu vremenske serije',
      Scale: 'Skala',
      Schedule: 'Raspored',
      'Schedule Classification': 'Zakaži klasifikaciju',
      'Schedule classification': 'Zakaži klasifikaciju',
      'Schedule from': 'Raspored od',
      'Schedule to': 'Raspored do',
      Scheduler: 'raspored',
      'Scheduler classification': 'Klasifikacija rasporeda',
      Schedules: 'Rasporedi',
      Search: 'traži',
      'Search tags by item name': 'Pretraži oznake po nazivu',
      'Search tags by name': 'Pretraži oznake po nazivu',
      'Search tags by tag id': 'Pretraži oznake po ID oznake',
      'Second interval quantity missing.':
        'Nedostaje druga količina intervala.',
      Seconds: 'sekunde',
      'Secret was copied to clipboard.': 'Tajna je kopirana u međuspremnik.',
      Seen: 'vidio',
      Select: 'Odaberi',
      'Select Aggregation Type': 'Odaberite Vrsta združivanja',
      'Select Alarm Type': 'Odaberite Vrsta alarma',
      'Select Allocator Kind': 'Odaberi vrstu alokatora',
      'Select Allocator Type': 'Odaberi tip alokatora',
      'Select Asset': 'Odaberite Aktiv',
      'Select Asset Brand': 'Odaberite Marka imovine',
      'Select Asset Group': 'Odaberite Grupa imovine',
      'Select Asset Status': 'Odaberite Status snimanja',
      'Select Asset Type': 'Odaberite Vrsta imovine',
      'Select Assignee': 'Odaberite Assignee',
      'Select Billing Kind': 'Odaberite način naplate',
      'Select Billing Type': 'Odaberite Vrsta naplate',
      'Select Business Partner': 'Odaberite Business Partner',
      'Select Connection': 'Odaberite vezu',
      'Select Cost Centre': 'Odaberite Mjesto troška',
      'Select Cost centre': 'Odaberite Mjesto troška',
      'Select Counter type': 'Odaberite vrstu brojača',
      'Select Country': 'Odaberi državu',
      'Select Currency': 'Odaberite valutu',
      'Select Dashboard': 'Odaberite nadzornu ploču',
      'Select Dataset': 'Odaberite Skup podataka',
      'Select Default Value Type': 'Odaberite Zadana vrsta vrijednosti',
      'Select Energy Source Type': 'Odaberite vrstu izvora energije',
      'Select Field type': 'Odaberite Vrsta polja',
      'Select Filter': 'Odaberite Filter',
      'Select Filter type': 'Odaberite Vrsta filtra',
      'Select Format': 'Odaberite Format',
      'Select GIS user': 'Odaberite korisnika GIS-a',
      'Select Gis User': 'Odaberite Gis User',
      'Select Location': 'Odaberite Mjesto',
      'Select Location Ownership': 'Odaberite Vlasništvo lokacije',
      'Select Location Status': 'Odaberite Status lokacije',
      'Select Location Type': 'Odaberite Vrsta lokacije',
      'Select Measurement Unit': 'Odaberite jedinicu mjerenja',
      'Select Owner': 'Odaberite Vlasnika',
      'Select Parent': 'Odaberite Roditelj',
      'Select Parent Document Classification':
        'Odaberite klasifikaciju roditeljskog dokumenta',
      'Select Parent Energy Source Type':
        'Odaberite vrstu izvornog izvora energije',
      'Select Physical Quantity': 'Odaberite Fizička količina',
      'Select Profit Centre': 'Odaberite profitno mjesto',
      'Select Project': 'Odaberite projekt',
      'Select Rated Power': 'Odaberite nazivnu snagu',
      'Select Raw field name': 'Odaberite ime polja sirovih podataka',
      'Select Severity': 'Odaberite Ozbiljnost',
      'Select Tariff': 'Odaberite Tarifu',
      'Select Time Interval': 'Odaberite Vremenski interval',
      'Select Time Series': 'Odaberite vremenske serije',
      'Select Time Series Type': 'Odaberite tip vremenske serije',
      'Select Time series': 'Odaberite vremenske serije',
      'Select Type': 'Odaberite tip',
      'Select Usage type': 'Odaberite vrstu korištenja',
      'Select User': 'Odaberite Korisnik',
      'Select Y axis value': 'Odaberite vrijednosti Y osi',
      'Select a Brand': 'Odaberite marku',
      'Select a Model': 'Odaberite model',
      'Select a cost centre': 'Odaberite mjesto troška',
      'Select a document classification': 'Odaberite klasifikaciju dokumenta',
      'Select a function': 'Odaberite funkciju',
      'Select a group': 'Odaberite grupu',
      'Select a person': 'Odaberite osobu',
      'Select absent user': 'Odaberite odsutnog korisnika',
      'Select action status': 'Odaberite status radnje',
      'Select aggregation group': 'Odaberite grupu za agregiranje',
      'Select alarm': 'Odaberite alarm',
      'Select alarms': 'Odaberite alarme',
      'Select alarms from the list below':
        'Odaberite alarme s popisa u nastavku',
      'Select all': 'Odaberite sve',
      'Select an Energy Source Type to display used order values.':
        'Odaberite tip izvora energije da bi prikazali vrijednosti narudžbe.',
      'Select an Ownership Type': 'Odaberite vrstu vlasništva',
      'Select an asset': 'Odaberite sredstvo',
      'Select analyses': 'Odaberite analize',
      'Select analyses from the list below':
        'Odaberite analize s popisa u nastavku',
      'Select another source value ID':
        'Odaberite drugi ID izvorne vrijednosti',
      'Select asset': 'Odaberite svojstvo',
      'Select asset from the list below':
        'Odaberite sredstvo s popisa u nastavku',
      'Select at least one': 'Odaberite minimalno jednu',
      'Select attribute': 'Odaberite atribut',
      'Select business partner': 'Odaberite poslovnog partnera',
      'Select business partner from the list below':
        'Odaberite poslovnog partnera s popisa u nastavku',
      'Select cache interval': 'Odaberite interval predmemorije',
      'Select calculation': 'Odaberite izračun',
      'Select card background color': 'Odaberite boju pozadine kartice',
      'Select chart color': 'Odaberite boju grafikona',
      'Select chart type': 'Odaberite vrstu grafikona',
      'Select column': 'Odaberite stupac',
      'Select company structure node': 'Odaberite čvor strukture tvrtke',
      'Select comparable column': 'Odaberite usporedivi stupac',
      'Select connected external datasource':
        'Odaberite spojene izvore vanjskih podataka',
      'Select content person': 'Odaberite sadržaj osobe',
      'Select cost centre': 'Odaberite mjesto troška',
      'Select cost centre from the list below':
        'Odaberite mjesto troška s popisa u nastavku',
      'Select counter type': 'Odaberite vrstu brojača',
      'Select counter type from the list below':
        'Odaberite vrstu brojača s popisa u nastavku',
      'Select country': 'Odaberi državu',
      'Select customer': 'Odaberite kupca',
      'Select dashboard *': 'Odaberite nadzornu ploču *',
      'Select dashboard card type': 'Odaberite vrstu kartice nadzorne ploče',
      'Select data types': 'Odaberite vrste podataka',
      'Select dataset': 'Odaberite skup podataka',
      'Select date types': 'Odaberite vrste datuma',
      'Select default chart type': 'Odaberite zadanu vrstu grafikona',
      'Select default physical quantity': 'Odaberite zadanu fizičku veličinu',
      'Select default unit of measurement': 'Odaberite zadanu mjernu jedinicu',
      'Select display type': 'Odaberite vrstu prikaza',
      'Select distributor': 'Odaberite distributera',
      'Select domain type': 'Odaberite vrstu domene',
      'Select element': 'Odaberite element',
      'Select energy source from the list below':
        'Odaberite izvor energije s popisa u nastavku',
      'Select energy source type': 'Odaberite vrstu izvora energije',
      'Select entity': 'Odaberite entitet',
      'Select expression': 'Odaberite izraz',
      'Select external datasource type':
        'Odaberite vrstu vanjskog izvora podataka',
      'Select filters': 'Odaberite filtre',
      'Select format': 'Odaberite format',
      'Select function': 'Odaberite funkciju',
      'Select group': 'Odaberite grupu',
      'Select group entity': 'Odaberite entitet grupe',
      'Select icon': 'Odaberite ikonu',
      'Select invoice issuer': 'Odaberite izdavatelja računa',
      'Select issuer': 'Odaberite izdavatelja',
      'Select item': 'Odaberite stavku',
      'Select language': 'Odaberite jezik',
      'Select last 100': 'Odaberite posljednjih 100',
      'Select location': 'Odaberite lokaciju',
      'Select location classification': 'Odaberite klasifikaciju lokacije',
      'Select location classification from the list below':
        'Na popisu u nastavku odaberite klasifikaciju lokacije',
      'Select location from the list below':
        'Odaberite lokaciju s popisa u nastavku',
      'Select main cost centre': 'Odaberite glavno mjesto troška',
      'Select measuring point': 'Odaberite mjernu točku',
      'Select measuring point from the list below':
        'Odaberite mjernu točku s popisa u nastavku',
      'Select measuringpoint': 'Odaberite mjernu točku',
      'Select menu': 'Odaberite izbornik',
      'Select month': 'Odaberite mjesec',
      'Select new valid dates': 'Odaberite nove važeće datume',
      'Select number of values': 'Odaberite broj vrijednosti',
      'Select or enter a new tag': 'Odaberite ili unesite novu oznaku',
      'Select organization': 'Odaberite organizaciju',
      'Select owner': 'Odaberite vlasnika',
      'Select paired time series type':
        'Odaberite uparenu vrstu vremenske serije',
      'Select parent': 'Odaberite nadređeni',
      'Select parent Location': 'Odaberite nadređenu lokaciju',
      'Select parent asset': 'Odaberite nadređeno sredstvo',
      'Select parent company structure node':
        'Odaberite čvor strukture matične tvrtke',
      'Select parent hierarchy node': 'Odaberite čvor nadređene hijerarhije',
      'Select parent measuring point': 'Odaberite roditeljsku točku mjerenja',
      'Select period interval': 'Odaberite interval',
      'Select physical quantity': 'Odaberite fizičku veličinu',
      'Select project field': 'Odaberite polje projekta',
      'Select project type': 'Odaberite vrstu projekta',
      'Select refresh Interval': 'Odaberite Interval osvježavanja',
      'Select report dates': 'Odaberite datume izvješća',
      'Select rule': 'Odaberite pravilo',
      'Select schedule classification': 'Odaberite klasifikaciju rasporeda',
      'Select slice entity': 'Odaberite entitet slice',
      'Select sort type': 'Odaberite vrstu sortiranja',
      'Select source value ID': 'Odaberite ID izvorne vrijednosti',
      'Select substitute user': 'Odaberite zamjenskog korisnika',
      'Select supplier': 'Odaberite dobavljača',
      'Select tag': 'Odaberite oznaku',
      'Select tag from the list below': 'Odaberite oznaku s popisa u nastavku',
      'Select tango agent connection configuration':
        'Odaberite konfiguraciju veze tango agenta',
      'Select tariff': 'Odaberite tarifu',
      'Select time series': 'Odaberite vremensku seriju',
      'Select time series to add to classification':
        'Odaberite vremenske serije za dodavanje u klasifikaciju',
      'Select time series type': 'Odaberite vrstu vremenske serije',
      'Select type': 'Odaberite vrstu',
      'Select unit of measurement': 'Odaberite mjernu jedinicu',
      'Select user': 'Odaberite korisnika',
      'Select value field': 'Odaberite polje vrijednosti',
      'Select visualization': 'Odaberite vizualizaciju',
      'Select visualization from the list below':
        'Odaberite vizualizaciju s popisa u nastavku',
      'Select weather station': 'Odaberite vremensku stanicu',
      'Select x axis values': 'Odaberite vrijednosti x osi',
      'Select year': 'Odaberite godinu',
      Selected: 'Odabran',
      'Selected Icon:': 'Odabrana ikona:',
      'Selected date does not correspond to valid External Reader Flow Configuration.':
        'Odabrani datum ne odgovara važećem tijeku vanjskog čitača Konfiguracija."',
      'Selected icon:': 'Odabrana ikona:',
      'Selected period': 'Odabrano razdoblje',
      'Selected series': 'Odabrana serija',
      'Selected theme': 'Odabrana tema',
      'Selected:': 'Odabran:',
      'Selector type': 'Tip selektora',
      'Send E-mail notifications': 'Slanje obavijesti e-poštom',
      'Send SMS notifications': 'Pošalji SMS obavijesti',
      Sep: 'rujna',
      September: 'rujan',
      'Serial number': 'Serijski broj',
      Series: 'Niz',
      'Series name': 'Naziv serije',
      'Server Error': 'Pogreška servera',
      'Service Date From': 'Datum usluge od',
      'Service Date To': 'Datum usluge do',
      'Set address': 'Postavite adresu',
      'Set coordinates': 'Postavite koordinate',
      Settings: 'postavke',
      Settlement: 'Naselje',
      'Settlement Name': 'Naziv naselja',
      'Settlement name': 'Naziv naselja',
      Severities: 'žestina',
      Severity: 'Ozbiljnost',
      'Share link': 'Podijeli poveznicu',
      'Share time series group': 'Podijelite grupu vremenskih serija',
      'Show Hierarchy': 'Prikaži hijerarhiju',
      'Show asset children': 'Prikažite značajnu djecu',
      'Show chart': 'Prikaži grafikon',
      'Show data table': 'Prikaži tablicu podataka',
      'Show energy values': 'Prikažite energetske vrijednosti',
      'Show seen notifications': 'Prikaži prikazane obavijesti',
      'Sine function.': 'Funkcija sinusa.',
      Size: 'Veličina',
      'Slice entity': 'Dio entiteta',
      'Smart City': 'Pametan grad',
      'Sms content': 'Sadržaj SMS-a',
      'Sms notification': 'SMS obavijest',
      'Sms receivers': 'Sms prijemnici',
      Solution: 'Riješenje',
      'Some items couldn\'t be removed': 'Neke se stavke nisu mogle ukloniti',
      'Some items couldn\'t be updated':
        'Neke stavke nije bilo moguće ažurirati',
      'Something went wrong during file {{fileName}} upload':
        'Nešto je pošlo po zlu tijekom prijenosa datoteke {{fileName}}. Neke stavke nije bilo moguće ažurirati',
      'Sop Type': 'Sop Type',
      Sort: 'Sortirati',
      'Sort type': 'Vrsta sortiranja',
      'Source value ID': 'ID izvorne vrijednosti',
      Stakeholder: 'Sudionik',
      Stakeholders: 'Sudionici',
      'Standard Op. Procedures': 'Standardne operativne procedure',
      'Standard Operating Procedure': 'Standardna operativna procedura',
      'Standard Operating Procedures': 'Standardne operativne procedure',
      'Standard operating procedures': 'Standardne operativne procedure',
      'Standard operational procedure step':
        'Korak standardnog operativnog postupka',
      'Standard operational procedure task':
        'Zadatak standardnog operativnog postupka',
      Start: 'Početak',
      'Start date': 'Početni datum',
      'Start date must be before End date.':
        'Datum početka mora biti prije datuma završetka.',
      'Start date must be before an end date.':
        'Datum početka mora biti prije datuma završetka.',
      'Start date:': 'Početni datum:',
      'Start of Billing Period *': 'Početak razdoblja naplate *',
      'Start of Project *': 'Početak projekta *',
      'Start of availability': 'Početak dostupnosti',
      'Start of billing period': 'Početak razdoblja naplate',
      'Start of project': 'Početak projekta',
      Started: 'počeo',
      'Starting On': 'Pokretanje na',
      Status: 'Status',
      Step: 'Korak',
      'Step Details': 'Pojedinosti o koraku',
      Steps: 'koraci',
      'Steps of the procedure': 'Koraci postupka',
      Stop: 'Stop',
      'Stream Archive Property': 'Vlasništvo arhiva strujanja',
      'Stream Archive property': 'Svojstvo arhiva streama',
      Street: 'Ulica',
      'Street name': 'ime ulice',
      String: 'Niz',
      'Strings are not allowed in expressions. (Regarding {{value}})':
        'Nizovi nisu dopušteni u izrazima. (Što se tiče {{vrijednosti}})',
      Subject: 'predmet',
      'Submit job': 'Pošaljite posao',
      Subscript: 'potpisan',
      'Subsidiary locations': 'Podružnice',
      Substraction: 'Substraction',
      'Substracts neighbouring expressions': 'Sažima susjedne izraze',
      Sum: 'Iznos',
      Sun: 'Sunce',
      Sunday: 'nedjelja',
      'Superior Location': 'Superior lokacija',
      Superscript: 'natpis',
      Supplier: 'Dobavljač',
      'Supplier Code': 'Nabavljačev kod',
      'Supplier code': 'Nabavljačev kod',
      'Supplier price list': 'Cjenik dobavljača',
      'Supported operators and functions': 'Podržani operatori i funkcije',
      Surname: 'Prezime',
      Symbol: 'Simbol',
      'Sync external datasource values':
        'Sinkronizirajte vrijednosti vanjskog izvora podataka',
      System: 'sistem',
      'System Tags': 'Oznake sustava',
      'System notification users': 'Sistemska obavijest korisnicima',
      'System responsible person': 'Odgovorna osoba za sistem',
      'System tags': 'Oznake sustava',
      TIS: 'TIS',
      TRUE: 'PRAVI',
      'Tag Selector': 'Izbornik oznaka',
      'Tag column': 'Stupac s oznakom',
      'Tag column name': 'Naziv stupca oznake',
      'Tag explorer': 'Pretraživač oznaka',
      'Tag on source': 'Oznaka izvora',
      Tags: 'Oznake',
      'Tangent function.': 'Tangentna funkcija.',
      'Tango Agent Configuration Type': 'Tip konfiguracije agenta Tango',
      'Tango Agent Connection': 'Tango agent veza',
      'Tango Agent Connection Config': 'Konfiguracija veze tango agenta',
      'Tango Agent Connection Configuration': 'Konfiguracija veze tango agenta',
      'Tango Agent Connection Configurations':
        'Konfiguracije veza tango agenta',
      'Tango agent config': 'Konfiguracija tango agenta',
      'Tango agent connection configuration': 'Konfiguracija veze tango agenta',
      Target: 'Cilj',
      'Target function': 'Ciljna funkcija',
      'Target {{equation}}': 'Ciljna {{equation}}',
      Tariff: 'Tarifa',
      'Tariff Configurations': 'Tarifne konfiguracije',
      'Tariff:': 'Tarifa:',
      Tariffs: 'tarife',
      Task: 'Zadatak',
      'Task description': 'Opis zadatka',
      'Task.': 'Zadatak.',
      Tasks: 'zadaci',
      TasksTitle: 'TasksTitle',
      'Tax Number': 'Porezni broj',
      'Tax number': 'Porezni broj',
      Temperature: 'Temperatura',
      Text: 'Tekst',
      'Text area': 'Tekstualno polje',
      'Text color': 'Boja teksta',
      'There are currently no order values selected for this Energy Source Type.':
        'Trenutno nisu odabrane vrijednosti narudžbe za ovu vrstu izvora energije.',
      'There are no Aggregation types to select from.':
        'Nema skupnih vrsta za odabir.',
      'There are no Cost centres to select.': 'Nema mjesta troška za odabir.',
      'There are no Cumulative Expressions.': 'Nema kumulativnih izraza.',
      'There are no Heartbeats bound to this object.':
        'Na ovaj objekt nema vezanih otkucaja srca.',
      'There are no action statuses to select from.':
        'Nema statusa radnje za odabir.',
      'There are no aggregation groups.': 'Nema grupa za agregiranje.',
      'There are no attributes selected': 'Nema odabranih atributa',
      'There are no authorized groups.': 'Nema ovlaštenih skupina.',
      'There are no authorized users.': 'Nema ovlaštenih korisnika.',
      'There are no calculations.': 'Nema izračuna.',
      'There are no columns.': 'Nema stupaca.',
      'There are no comparable columns yet': 'Još nema usporedivih stupaca',
      'There are no comparable columns.': 'Ne postoje usporedivi stupci.',
      'There are no elements to select.': 'Nema elemenata za odabir.',
      'There are no expressions yet': 'Još nema izraza',
      'There are no filters selected yet': 'Još nema odabranih filtara',
      'There are no formats to select.': 'Nema formata za odabir.',
      'There are no groups to select.': 'Nema grupa za odabir.',
      'There are no invoice detail values yet.':
        'Još nema vrijednosti pojedinosti o dostavnici.',
      'There are no issuers to select.': 'Nema izdavatelja za odabir.',
      'There are no items': 'Nema stavki',
      'There are no items selected': 'Nema odabranih stavki',
      'There are no items to select.': 'Nema stavki za odabir.',
      'There are no items yet': 'Nema stavki',
      'There are no items.': 'Nema stavki.',
      'There are no job types to select from.': 'Nema vrsta poslova za odabir.',
      'There are no layouts': 'Nema izgleda',
      'There are no manual readings': 'Nema ručnog očitanja',
      'There are no map id': 'Nema ID karte',
      'There are no more Rules to select': 'Nema više Pravila za odabir',
      'There are no options available': 'Nema dostupnih opcija',
      'There are no options selected': 'Nema odabranih opcija',
      'There are no organizations to select.':
        'Nema organizacija koje možete odabrati.',
      'There are no period intervals.': 'Nema vremenskih intervala.',
      'There are no previous values': 'Ne postoje prethodne vrijednosti',
      'There are no registers.': 'Nema registra.',
      'There are no schedule classifications':
        'Ne postoje klasifikacije rasporeda',
      'There are no selected items': 'Nema odabranih stavki',
      'There are no tags to select.': 'Nema oznaka za odabir.',
      'There are no tags.': 'Nema oznaka.',
      'There are no tasks': 'Nema zadataka.',
      'There are no tasks.': 'Nema zadataka.',
      'There are no time series selected': 'Nisu odabrane vremenske serije',
      'There are no time series type attributes yet':
        'Još nema atributa tipa vremenske serije',
      'There are no users to select.': 'Nema korisnika za odabir.',
      'There are no values': 'Nema vrijednosti',
      'There are no values.': 'Nema vrijednosti.',
      'There are no variable names': 'Nema naziva varijabli',
      'There are no x axis values.': 'Nema vrijednosti x osi.',
      'There are too many values for selected period try to reduce selected interval':
        'Za odabrani period ima previše vrijednosti, pokušajte smanjiti odabrani interval',
      'There is no billing data': 'Nema podataka o naplati',
      'There is no configuration': 'Nema konfiguracije',
      'There is no currency to select.': 'Nema valute za odabir.',
      'There is no gis data': 'ema podataka o GIS-u',
      'There is no price list': 'Još nema cjenika.',
      'There is no selected item': 'Nema odabranih stavki',
      'There is no tag selected': 'Nema odabranih oznaka',
      'There is no time series to generate report':
        'Ne postoje vremenske serije za generiranje izvještaja',
      'There is one standard operating procedure bound to this alarm': [
        'There is {{vm.sops.length}} standard operating procedure bound to this\r\n          alarm.',
        'There are {{vm.sops.length}} standard operating procedures bound to this alarm.',
        'Postoje {{vm.sops.length}} standardni operativni postupci vezani uz ovo alarm."'
      ],
      'There should only be 6 sections of cron.':
        'Trebalo bi postojati samo 6 dijelova cron-a.',
      'There was an error for the following reason: {{status}}':
        'There was an error for the following reason: {{status}}',
      'There were no activities yet.': 'Još nije bilo aktivnosti.',
      'These credentials are required for installation of TIS client on your device. Make sure you have stored them safely. Device secret will never be shown anymore!':
        'Ove vjerodajnice potrebne su za instaliranje TIS klijenta na vaš uređaj. Provjerite jeste li ih sigurno pohranili. Tajna uređaja nikada se više neće prikazivati!',
      'These credentials are required for installation of TIS client on your external datasource. Make sure you have stored them safely. External datasource secret will never be shown anymore!':
        'Ove vjerodajnice potrebne su za instaliranje TIS klijenta na vaš vanjski izvor podataka. Provjerite jeste li ih sigurno pohranili. Tajna uređaja nikada se više neće prikazivati!',
      'This attribute hasn\'t been filled in yet.':
        'Ovaj atribut još nije popunjen.',
      'This boundary overlaps with another.':
        'Ova se granica preklapa s drugom',
      'This field is required': 'ovo polje je obavezno',
      'This field is required.': 'Ovo polje je obavezno.',
      'This item has no rules yet.': 'Ova stavka još nema pravila.',
      'This manual input has no groups yet': 'Ovaj ručni unos još nema grupe',
      'This procedure has no steps.': 'Ovaj postupak nema koraka.',
      'This value is already taken for the specified energy source type. Please select a different value.':
        'Ova je vrijednost već uzeta za navedenu vrstu izvora energije. Molim odaberite drugu vrijednost."',
      Thu: 'Čet',
      Thursday: 'Četvrtak',
      Time: 'Vrijeme',
      'Time Interval': 'Vremenski interval',
      'Time Interval Filter': 'Filter vremenskog intervala',
      'Time Interval Filters': 'Filtri vremenskog intervala',
      'Time Series': [
        'Vremenske serije',
        'Vremenske serije',
        'Vremenske serije'
      ],
      'Time Series - External Writer Flows': [
        'Vremenska serija - Nova analitička točka',
        'Nova analitička točka',
        'Nova analitička točka'
      ],
      'Time Series - History': [
        'Vremenske serije - povijesni podaci',
        'Povijest vremenskih serija',
        'Povijest vremenskih serija'
      ],
      'Time Series - Live': [
        'Vremenske serije - uživo',
        'Vremenske serije - uživo',
        'Vremenske serije - uživo'
      ],
      'Time Series Group Sandbox': 'Sandbox grupe vremenskih serija',
      'Time Series Group Type': 'Vrsta grupe vremenskih serija',
      'Time Series Group Types': 'Vrste grupa vremenskih serija',
      'Time Series Groups': 'Grupe vremenskih serija',
      'Time Series Type': 'Tip vremenske serije',
      'Time Series Types': 'Tipovi vremenskih serija',
      'Time Series active flow is not mt analysis flow':
        'Aktivni protok vremenskih serija nije tok mt analize',
      'Time Series value was filled with default values':
        'Vrijednost vremenskog niza popunjena je zadanim vrijednostima',
      'Time Series were successfully created':
        'Uspješno su stvorene vremenske serije',
      'Time To Trigger': 'Vrijeme za okidanje',
      'Time Zone': 'Vremenska zona',
      'Time from': 'Vrijeme od',
      'Time in day (14:20)': 'Vrijeme u danu (14:20)',
      'Time interval filter': 'Filter vremenskog intervala',
      'Time interval filter type': 'Vrsta filtra vremenskog intervala',
      'Time intervals': 'Vremenski intervali',
      'Time is not valid': 'Vrijeme nije validno',
      'Time period': 'Vremenski period',
      'Time series': [
        'Vremenska serija',
        'Vremenska serija',
        'Vremenska serija'
      ],
      'Time series are in different time zones.':
        'Vremenske serije nalaze se u različitim vremenskim zonama.',
      'Time series card': 'Kartica vremenske serije',
      'Time series group preview': 'Pregled vremenske serije',
      'Time series group type': 'Tip vremenske serije',
      'Time series groups': 'Grupe vremenskih serija',
      'Time series has no configurations yet':
        'Vremenske serije još nemaju konfiguracije',
      'Time series name': 'Naziv vremenske serije',
      'Time series status': 'Status vremenske serije',
      'Time series successfully created!':
        'Uspješno stvorene vremenske serije!',
      'Time series successfully updated!':
        'Vremenske serije uspješno ažurirane!',
      'Time series type': 'Tip vremenske serije',
      'Time series type attributes': 'Atributi tipa vremenskih serija',
      'Time series type:': 'Tip vremenske serije:',
      'Time series value was successfully created!':
        'Vrijednost vremenske serije uspješno je stvorena!',
      'Time to': 'Vrijeme za',
      'Time to trigger': 'Vrijeme je za okidanje',
      'Time until alarm should be resolved': 'Vrijeme do alarma',
      'Time zone': 'Vremenska zona',
      Timeout: 'Pauza',
      'Timeout Alarm': 'Alarm prekida',
      'Timeout action': 'Isteklo vrijeme',
      'Timeout alarm': 'Alarm prekida',
      'Timeout settings': 'Postavke vremenskog ograničenja',
      Title: 'Titula',
      To: 'Do',
      'To continue plase log in.': 'Za nastavak rada prijavite se.',
      'To value should me less than Value from':
        'Vrijednost bi trebala biti manja od Vrijednost iz pregleda vremenske serije',
      Today: 'Danas',
      'Toggle main dashboard': 'Prebaci glavnu nadzornu ploču',
      Total: 'ukupno',
      'Total with VAT': 'Ukupno s PDV-om',
      'Total with VAT:': 'Ukupno s PDV-om:',
      'Total without VAT': 'Ukupno bez PDV',
      'Total without VAT:': 'Ukupno bez PDV:',
      'Total {{total}}{{currency}}. Total amount left {{amountLeft}}{{currency}}':
        'Ukupno {{ukupno}} {{valuta}}. Ukupan preostali iznos {{amountLeft}} {{currency}}',
      'Trailing zeroes are not allowed.': 'Prateće nule nisu dopuštene.',
      'Transfer To Next Month': 'Prijelaz na sljedeći mjesec',
      'Transfer to next month': 'Prijelaz na sljedeći mjesec',
      'Transformation To kWh': 'Transformacija u kWh',
      'Transformation to kWh': 'Transformacija u kWh',
      'Translations are missing!': 'Prijevodi nedostaju!',
      Triggered: 'Pokrenute',
      'Triggered Alarm': 'Pokrenut alarm',
      'Triggered Alarms': 'Aktivirani alarmi',
      'Triggered alarm': 'Aktivirani alarm',
      'Triggered at': 'Pokrenut u',
      'Triggered at {{entityName}}': 'Pokrenut u {{entityName}}',
      'Triggers an alarm': 'Pokreće alarm',
      'Triggers an error': 'Pokreće pogrešku',
      'Triggers calculation': 'Izračun okidača',
      'Triggers on Holidays': 'Pokreće se na praznicima',
      True: 'Istina',
      Tue: 'Uto',
      Tuesday: 'utorak',
      Type: 'Tip',
      'Type:': 'Tip:',
      URL: 'URL',
      'URL Document': 'URL Document',
      'URL document': 'URL document',
      Undefined: 'Nedefiniran',
      Underline: 'Naglasiti',
      'Unexpected end of expression (character at position {{char}}).':
        'Neočekivani kraj izraza (znak na položaju {{char}})',
      'Unique Number': 'Jedinstveni broj',
      'Unique number': 'Jedinstveni broj',
      Unknown: 'Nepoznata',
      'Unknown Alarm': 'Nepoznati alarm',
      'Unknown Tariff': 'Nepoznata tarifa',
      'Unknown Time Series': 'Nepoznata vremenska serija',
      'Unknown action for {{measuringpointName}}':
        'Nepoznata radnja za {{measuringpointName}}',
      'Unknown allocator type': 'Nepoznata vrsta alokatora',
      'Unknown attribute type': 'Nepoznata vrsta atributa',
      'Unknown data type': 'Nepoznata vrsta podataka',
      'Unknown file type': 'Nepoznata vrsta datoteke',
      'Unknown maintenance flow type': 'Nepoznata vrsta protoka održavanja',
      'Unknown schedule classification': 'Nepoznata klasifikacija rasporeda',
      'Unknown scheduler': 'Nepoznati planer',
      'Unknown tag': 'Nepoznata oznaka',
      'Unknown time series status': 'Nepoznat status vremenske serije',
      'Unknown time zone': 'Nepoznata vremenska zona',
      'Unknown type': 'Nepoznata vrsta',
      'Unknown user': 'Nepoznati korisnik',
      'Unknown value': 'Nepoznata vrijednost',
      Unpair: 'Raspari',
      Unseen: 'neviđen',
      Update: 'ažuriranje',
      'Update Action': 'Ažuriraj akciju',
      'Update Rated Power': 'Ažurirajte nazivnu snagu',
      'Update an absence': 'Ažurirajte odsutnost',
      'Update an assignment': 'Ažurirajte zadatak',
      'Update domain': 'Ažurirajte domenu',
      'Update groups': 'Ažurirajte grupe',
      'Update value': 'Ažurirajte vrijednost',
      Updated: 'obnovljeno',
      Upload: 'Prijenos',
      'Upload CSV file': 'Prenesite CSV datoteku',
      'Upload file': 'Prenesite datoteku',
      'Upload image': 'Učitaj sliku',
      Uploaded: 'Prenešeno',
      Uploading: 'Prijenos',
      'Upper Limit %': 'Gornja granica u %',
      'Upper Limit Alarm': 'Alarm gornje granice',
      'Upper Limits': 'Gornja granica',
      'Usage Type': 'Vrsta upotrebe',
      'Use aggregation group as x axis': 'Koristite skupnu skupinu kao x os',
      'Use current time series as input parameter':
        'Koristite trenutnu vremensku seriju kao ulazni parametar',
      'Use security': 'Koristite sigurnost',
      'Used order values': 'Korištene vrijednosti narudžbe',
      'Used order values for {{name}}': 'Used order values for {{name}}',
      User: 'Korisnik',
      'User Entities': 'Korisničke jedinice',
      'User Group Modules': 'Moduli korisničke grupe',
      'User Modules': 'Korisnički moduli',
      'User Privilege Management': 'Upravljanje povlasticama korisnika',
      'User access': 'Pristup korisnika',
      Username: 'Korisničko ime',
      Users: 'Korisnici',
      'VALIDATION ERRORS': 'POGREŠKE VALIDACIJE',
      'Valid from': 'Vrijedi od',
      'Valid from *': 'Vrijedi od *',
      'Valid from can not be prior to start date of asset availability':
        'Valjanost od ne može biti prije početnog datuma dostupnosti imovine',
      'Valid from date must be before valid to date.':
        'Valjan od datuma mora biti prije valjanosti do danas.',
      'Valid from should not intersect with previous valid to':
        'Valid from should not intersect with previous valid to',
      'Valid to': 'Vrijedi do',
      'Valid to *': 'Vrijedi do *',
      Value: 'Vrijednost',
      'Value card was successfully updated':
        'Vrijednost kartice uspješno je ažurirana',
      'Value column': 'Stupac Vrijednost',
      'Value column name': 'Naziv stupca vrijednosti',
      'Value details': 'Detalji vrijednosti',
      'Value field name': 'Naziv polja vrijednosti',
      'Value is not a number': 'Vrijednost nije broj',
      'Value must be an integer': 'Vrijednost mora biti cijeli broj',
      'Value should be an integer.': 'Vrijednost treba biti cijeli broj.',
      'Value:': 'Vrijednost:',
      Values: 'vrijednosti',
      'Values from': 'Vrijednosti od',
      'Values to': 'Vrijednosti do',
      'Values were successfully uploaded': 'Vrijednosti su uspješno prenesene',
      'Variable name': 'Naziv varijable',
      'Variable names must be surrounded by double quotes {{name}}.':
        'Imena varijabli moraju biti okružena dvostrukim navodnicima {{name}}.',
      'Variable {{name}} is not defined': 'Varijabla {{name}} nije definirana',
      'Variable {{name}} is not defined.':
        'Varijabla {{name}} nije definirana.',
      'Variable {{value}} is not defined':
        'Varijabla {{vrijednost}} nije definirana',
      Version: 'Verzija',
      'View Counter Type': 'Prikaz vrste brojača',
      Viewer: 'Preglednik',
      Visualization: 'Vizualizacija',
      Visualizations: 'vizualizacije',
      WARNING: 'UPOZORENJE',
      Warning: 'Upozorenje',
      'Weather item is loading ...': 'Učitava se vremenska stavka ...',
      'Weather station': 'Meteorološka stanica',
      'Weather station code is missing': 'Nedostaje kôd meteorološke stanice',
      'Web Service': 'Web-usluga',
      'Web Services': 'Web usluge',
      'Webservice connection configuration': 'Konfiguracija veze za web-uslugu',
      Wed: 'sri',
      Wednesday: 'srijeda',
      Week: 'Tjedan',
      'Weekday interval quantity missing.':
        'Nedostaje količina intervala dana u tjednu.',
      Weekdays: 'Radnim danom',
      'When expression below will evaluate to true, this rule will trigger notices and / or alarms.':
        'Kad se izraz u nastavku ocijeni istinitim, ovo će pravilo pokrenuti obavijesti i / ili alarme.',
      'When reading data (according to read scheduler), system reads all data between the time of last read data and the current time of reading data. Timestamp of such data is copied from the source data. When reading data for the first time, length of time interval is defined with attribute “First Batch Interval”.':
        'Prilikom očitavanja podataka (prema rasporedu čitanja), sustav čita sve podatke između vremena zadnjeg pročitanog podatka i trenutačnog vremena očitavanja podataka. Vremenska oznaka takvih podataka kopira se iz izvornih podataka. Prilikom prvog čitanja podataka, duljina vremenskog intervala definirana je atributom “First Batch Interval”.',
      'When reading data (according to read scheduler), system reads all data between the time of read and the time interval defined with attribute “Future interval”. Timestamp of such data is copied from the source data.':
        'Prilikom očitavanja podataka (prema rasporedu čitanja), sustav čita sve podatke između vremena čitanja i vremenskog intervala definiranog s atributom "Future interval". Vremenska oznaka takvih podataka kopira se iz izvornih podataka.',
      'When reading data (according to read scheduler), system reads current value at the source (OPC, database, …). Timestamp of such data is always time of read (according to read scheduler).':
        'Kada čitate podatke (prema raspoređivaču čitanja), sustav čita trenutnu vrijednost na izvoru (OPC, baza podataka,…). Vremenska oznaka takvih podataka je uvijek vrijeme čitanja (prema rasporedu čitanja).',
      'When the input value is not in the specified data point format and  none of the above rules apply, use this to remap it to a custom value. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'Kada ulazna vrijednost nije u navedenom formatu podatkovne točke i ne primjenjuje se nijedno od gornjih pravila, upotrijebite ovo za ponovno mapiranje na prilagođenu vrijednost. Ako bi ovo moglo pokrenuti pogrešku, alarmirati ili signalizirati ručnu provjeru, označite to.',
      'When time series is a trigger, expression below will be evaluated when a new time series value arrives.':
        'Kad je vremenska serija okidač, donji izraz će se procijeniti kada stigne nova vrijednost vremenske serije.',
      'Would you like to remove value': 'Želite li ukloniti vrijednost',
      'Would you like to return to your main dashboard?':
        'Želite li se vratiti na svoju glavnu nadzornu ploču?',
      'Write Query': 'Napišite upit',
      'Write back rules': 'Napišite pravila',
      'Write cancel rules': 'Napišite pravila otkazivanja',
      'Wrong cron format.': 'Pogrešan format cron',
      'Wrong data format': 'Pogrešan format podataka',
      'Wrong date format': 'Pogrešan format datuma',
      'Wrong next date format': 'Pogrešan format sljedećeg datuma',
      'Wrong selected date format': 'Pogrešan odabrani format datuma',
      'X Coordinate': 'X koordinate',
      'X axis': 'X os',
      'X values from': 'Vrijednosti X od',
      'X values to': 'Vrijednosti X do',
      'Y Coordinate': 'Y koordinate',
      'Y axis': 'Y os',
      'Y axis value': 'Vrijednost Y osi',
      YES: 'DA',
      Year: 'Godina',
      'Year from': 'Godina od',
      'Year to': 'Godina do',
      Yearly: 'Godišnje',
      Yes: 'Da',
      'You have no new notifications': 'Nemate novih obavijesti',
      'You have to add at least one of the following filters:':
        'Morate dodati barem jedan od sljedećih filtara',
      'You have to use at least one variable':
        'Morate upotrijebiti barem jednu varijablu',
      'You need to run analysis': 'Morate pokrenuti analizu',
      'Your are not allowed to edit time series values for this date':
        'Nije vam dopušteno uređivati vrijednosti vremenskih serija za ovaj datum',
      'Zoom to last:': 'Zumirajte do zadnjeg',
      active: 'aktivan',
      add: 'dodati',
      'add new': 'Dodaj novi',
      'add users to group': 'dodajte korisnike u grupu',
      'alarm definition': 'definicija alarma',
      all: 'svi',
      'all day': 'cijeli dan',
      'assign gis pair': 'dodijeliti gis par',
      at: 'na',
      bad: 'pogreška',
      'billing ASC': 'naplata ASC',
      'billing DESC': 'naplata DESC-a',
      cancel: 'otkazati',
      circle: 'krug',
      close: 'Zatvoriti',
      'confirm seen': 'potvrdili ste',
      connection: 'veza',
      'cost centre': 'mjesto troška',
      create: 'stvoriti',
      'created ASC': 'stvorio ASC',
      'created DESC': 'stvorio DESC',
      d: 'd',
      database: 'baza podataka',
      'date should be within external reader flow date limits':
        'datum bi trebao biti unutar granica datuma vanjskog čitača toka',
      day: 'dan',
      days: 'dana',
      delete: 'izbrisati',
      'device contacts': 'kontakti uređaja',
      display: 'prikaz',
      'displayed horizontally': 'prikazana vodoravno',
      'displayed vertically': 'prikazano okomito',
      'does not EQUAL': 'nije jednako',
      duplicate: 'duplicirajte',
      edit: 'Uredi',
      entity_name: 'ENTITY_NAME',
      'external datasource': 'vanjski izvor podataka',
      'fetch failed': 'dohvaćanje nije uspjelo',
      'file settings': 'postavke datoteke',
      filter: 'filter',
      freehand: 'crtan slobodnom rukom',
      'get values': 'dobiti vrijednosti',
      'gis groups': 'gis grupe',
      good: 'dobro',
      'group name': 'grupno ime',
      'hh:mm:ss': 'ss:mm:ss',
      'hide address': 'sakrij adresu',
      hour: 'sat',
      hours: 'sati',
      icon: 'ikona',
      if: 'ako',
      'in progress': 'u tijeku',
      inactive: 'neaktivan',
      input: 'ulazni',
      'is LESS than': 'je manje od',
      'is LESS than OR equals': 'je manje od OR jednako',
      'is MORE than': 'je VIŠE nego',
      'is MORE than OR equals': 'je VIŠE od ILI jednako',
      kWh: 'kWh',
      'last N months': 'posljednjih N mjeseci',
      'last N years': 'posljednjih N godina',
      lastname: 'prezime',
      'less than a second': 'manje od sekunde',
      list: 'popis',
      litres: 'litara',
      'load more ...': 'Učitaj više ...',
      location: 'mjesto',
      login: 'prijaviti se',
      'mapping rule': 'pravilo mapiranja',
      min: 'min',
      minute: 'minuta',
      month: 'mjesec',
      months: 'mjeseci',
      'name ASC': 'ime ASC',
      'name DESC': 'ime DESC',
      'new component': 'nova komponenta',
      'new visualization': 'nova vizualizaciju',
      'number ASC': 'broj ASC',
      'number DESC': 'broj DESC',
      'occured ASC': 'dogodio se ASC',
      'occured DESC': 'desc',
      of: 'od',
      ok: 'u redu',
      'open dialog': 'otvorite dijalog',
      'open location tree': 'otvoreno stablo lokacije',
      'out of': 'od',
      page: 'stranica',
      'per page': 'po stranici',
      'person Responsible': 'osoba odgovorna',
      point: 'točka',
      polygon: 'poligon',
      'price list': 'Cjenik',
      properties: 'nekretnine',
      read: 'čitati',
      remove: 'ukloniti',
      'remove file': 'ukloni datoteku',
      return: 'povratak',
      'returns true if the left operand is less than or equal to the right operand.':
        'vraća istinu ako je lijevi operand manji ili jednak desnom operandu.',
      root: 'korijen',
      rule: 'Pravilo',
      'rule expression': 'izraz pravila',
      s: 'a',
      save: 'uštedjeti',
      search: 'traži',
      sec: 'sek',
      second: 'drugi',
      'seen first': 'prvo vidi',
      'select alarm': 'odaberite alarm',
      'select rule': 'odaberite pravilo',
      'set from address': 'postavljeno s adrese',
      settings: 'postavke',
      stakeholderName: 'ime sudionika',
      'standard operating procedure': 'standardni radni postupak',
      step: 'korak',
      submeterMeasuringPointName: 'Mjerna točka',
      substitute: 'zamjena',
      'tango agent connection configurations':
        'konfiguracije veze tango agenta',
      translate: 'Prevedi',
      'tree view': 'prikaz stabla',
      unknown: 'nepoznata',
      'unseen first': 'prvo neviđeno',
      update: 'ažuriranje',
      username: 'korisničko ime',
      'value format must be hh:mm:ss (23:59:59)':
        'format vrijednosti mora biti hh: mm: ss (23:59:59)',
      week: 'tjedan',
      'works at': 'radi na',
      year: 'godina',
      years: 'godine',
      'yyyy-mm-dd': 'gggg-mm-dd',
      '{{alarmName}} at {{timeSeriesName}}':
        '{{Naziv alarma}} u {{Naziv vremenske serije}}',
      '{{elementType}} unknown parameter':
        '{{Tip elementa}} nepoznati parametar',
      '{{functionName}} is not a valid function.':
        '{{Naziv funkcije}} nije valjana funkcija.',
      '{{notCreated}} out of {{invoiceListDetailsNumber}} detail invoices couldn\'t be duplicated.':
        '{{nije stvoreno}} od {{broj pojedinosti popisa faktura}} pojedine fakture nisu mogle biti dupliciran.',
      '{{options.empty | translate}}': '{{mogućnosti. prazno | Prevedi}}',
      '{{pos}} should be on interval [0 - 23].':
        '{{pos}} treba biti na intervalu [0 - 23].',
      '{{pos}} should be on interval [0 - 31].':
        '{{pos}} should be on interval [0 - 31].',
      '{{pos}} should be on interval [0 - 59].':
        '{{pos}} treba biti na intervalu [0 - 59].',
      '{{pos}} should be on interval [0 - 7].':
        '{{pos}} treba biti na intervalu [0 - 7].',
      '{{pos}} should be on interval [1 - 12].':
        '{{pos}} treba biti na intervalu [1 - 12].',
      '{{timeSeriesId}} Some values couldn\'t be saved. Values Inserted - {{valuesInserted}}, Values Discarded {{valuesDiscarded}}':
        '{{Id vremenske serije}} Neke vrijednosti nije moguće spremiti. Vrijednosti umetnute - {{vrijednosti umetnute}}, vrijednosti odbačene {{vrijednosti odbačene}}"',
      '{{value}} last months': '{{value}} prošli mjeseci',
      '{{value}} last years': '{{value}} prošla godina',
      '{{vm.limits[key].title | translate}}': '{{vm.title | translate}}'
    })
    gettextCatalog.setStrings('sl_SI', {
      alarm: {
        alarmCard: ['alarm', 'alarma', 'alarmi', 'alarmov'],
        $$noContext: 'alarm'
      },
      '(0 - 23)': '(0 - 23)',
      '(0 - 59)': '(0 - 59)',
      '(1 - 12)': '(1 - 12)',
      '(1 - 31)': '(1 - 31)',
      '(1 - 7)': '(1 - 7)',
      '(Active)': '(Aktiven)',
      '(Drag and drop to select an element)':
        '(Povlecite in spustite, da izberete element)',
      '(Variable names must be surrounded by quotes)':
        '(Spremenljivke naj bodo napisane v narekovajih)',
      '(default)': '(privzeto)',
      '+ (0 - 23)': '+ (0 - 23)',
      '+ (0 - 59)': '+ (0 - 59)',
      '+ (1 - 31)': '+ (1 - 31)',
      '+ (1 - 7)': '+ (1 - 7)',
      '+ Add': '+ Dodaj',
      '+ Add Arithmetic Expression': '+ Dodaj aritmetičen izraz',
      '+ Add Asset': '+ Dodaj sredstvo',
      '+ Add Boundary': '+ Dodaj mejo',
      '+ Add Cost centre': '+ Dodaj stroškovno mesto',
      '+ Add Counter Type': '+ Dodaj tip merilnika',
      '+ Add Cumulative Expression': '+ Dodaj kumulativni izraz',
      '+ Add Location': '+ Dodaj lokacijo',
      '+ Add Owner': '+ Dodaj lastnika',
      '+ Add a Rule': '+ Dodaj pravilo',
      '+ Add a comparable column': '+ Dodaj stolpec za primerjavo',
      '+ Add alarm': '+ Dodaj alarm',
      '+ Add attribute': '+ Dodaj atribut',
      '+ Add column': '+ Dodaj stolpec',
      '+ Add default mapping rule': '+ Dodaj privzeto preslikovalno pravilo',
      '+ Add display field': '+ Dodaj polje za prikaz',
      '+ Add format mapping rule': '+ Dodaj pravilo za preslikavo formata',
      '+ Add layout': '+ Dodaj razporeditev',
      '+ Add procedure': '+ Dodaj proceduro',
      '+ Add register': '+ Dodaj register',
      '+ Add regular expression mapping rule':
        '+ Dodaj preslikovalno pravilo glede na regularni izraz',
      '+ Add time series': '+ Dodaj časovno vrsto',
      '+ Add variable': '+ Dodaj spremenljivko',
      '+ Hour (0 - 23)': '+ Ura (0 - 23)',
      '+ Minute (0 - 59)': '+ Minute (0 - 59)',
      '+ add a new group': '+ Dodaj skupino',
      '+ add arithmetic expression': '+ Dodaj aritmetični izraz',
      '+ add time series': '+ Dodaj časovno vrsto',
      '0 seconds': '0 sekund',
      '1 day': ['{{days}} dan', '{{days}} dni', '{{days}} dni', '{{days}} dni'],
      '1 hour': [
        '{{hours}} ura',
        '{{hours}} uri',
        '{{hours}} ure',
        '{{hours}} ur'
      ],
      '1 minute': [
        '{{minutes}} minuta',
        '{{minutes}} minuti',
        '{{minutes}} minute',
        '{{minutes}} minut'
      ],
      '1 month': [
        '{{months}} mesec',
        '{{months}} meseca',
        '{{months}} meseci',
        '{{months}} mesecev'
      ],
      '1 second': [
        '{{seconds}} sekunda',
        '{{seconds}} sekundi',
        '{{seconds}} sekunde',
        '{{seconds}} sekund'
      ],
      '1 year': [
        '{{years}} leto',
        '{{years}} leti',
        '{{years}} leta',
        '{{years}} let'
      ],
      ':': ':',
      '<b>active</b>': '<b>aktivno</b>',
      '<b>inactive</b>': '<b>neaktivno</b>',
      '<span class="badge triggers-an-error" ng-class="{\'float-right\' : mappingRule.isManualIntervention}">Triggers an error</span>':
        '<span class="badge triggers-an-error" ng-class="{\'float-right\' : mappingRule.isManualIntervention}">Sproži napako</span>',
      '<span translate>+ Add a Rule</span>':
        '<span translate="">+ Dodaj pravilo</span>',
      '<span translate>+ Add alarm</span>':
        '<span translate="">+ Dodaj alarm</span>',
      '<span translate>Add a Limit Alarm</span>':
        '<span translate="">+ Dodaj alarm</span>',
      '<span translate>Add a Rule</span>':
        '<span translate="">+ Dodaj pravilo</span>',
      '<span translate>Error loading card</span>':
        '<span translate="">Napaka pri nalaganju kartice</span>',
      '<strong>Total without VAT</strong>': '<strong>Skupaj brez DDV</strong>',
      '?': '?',
      APR: 'APR',
      AVG: 'AVG',
      Absence: 'Odsotnost',
      'Absent Personnel': 'Odsotno osebje',
      'Absent personnel': 'Odsotno osebje',
      Accept: 'Sprejmi',
      'Accepted at': 'Sprejeto ob',
      Accumulators: 'Akumulatorji',
      Action: 'Ukrep',
      'Action Status': 'Status ukrepa',
      'Action Statuses': 'Statusi ukrepa',
      'Action status': 'Status ukrepa',
      Actions: 'Ukrepi',
      'Active Comparable column': 'Aktiven stolpec za primerjavo',
      Activity: 'Aktivnost',
      Actual: 'Trenutna',
      Add: 'Dodaj',
      'Add Asset Availability': 'Dodaj razpoložljivost sredstva',
      'Add Child Node': 'Dodaj podrejeno vozlišče',
      'Add Location': 'Dodaj lokacijo',
      'Add Lower Limit': 'Dodaj spodnjo mejo',
      'Add M & T Analysis': 'Dodaj kartico za M & T analizo',
      'Add Manual Input Time Series': 'Dodaj časovno vrsto na ročni vnos',
      'Add Rated Power': 'Dodaj nazivno moč',
      'Add Sort': 'Dodaj sortiranje',
      'Add Step': 'Dodaj korak',
      'Add Task': 'Dodaj nalogo',
      'Add Times Series': 'Dodaj časovno vrsto',
      'Add Upper Limit': 'Dodaj zgornjo mejo',
      'Add User': 'Dodaj uporabnika',
      'Add Value Card': 'Dodaj kartico za prikaz vrednosti',
      'Add a Limit Alarm': 'Dodaj alarm',
      'Add a Rule': 'Dodaj pravilo',
      'Add a boundary': 'Dodaj mejo',
      'Add a filter': 'Dodaj filter',
      'Add alarms': 'Dodaj kartico za alarm',
      'Add an alarm': 'Dodaj alarm',
      'Add analysis': 'Dodaj kartico za analizo',
      'Add asset to measuring point': 'Dodajte sredstvo merilnemu mestu',
      'Add attribute': '+ Dodaj atribut',
      'Add card': 'Dodaj kartico',
      'Add cost centre to asset': 'Dodeli stroškovno mesto sredstvu',
      'Add cost centre to measuring point':
        'Dodajte stroškovno mesto merilnemu',
      'Add counter type to measuring point':
        'Merilnemu mestu dodajte tip merilnika',
      'Add date': 'Dodaj datum',
      'Add element': 'Dodaj element',
      'Add flow': 'Dodaj konfiguracijo',
      'Add group': 'Dodaj skupino',
      'Add iframe': 'Dodaj iframe kartico',
      'Add image': 'Dodaj kartico za sliko',
      'Add invoice overview': 'Dodaj kartico za pregled računov',
      'Add location': 'Dodaj kartico za lokacijo',
      'Add location to asset': 'Dodeli lokacijo sredstvu',
      'Add locations by cost centre map':
        'Dodaj kartico za zemljevid stroškovnih mest glede na lokacijo',
      'Add locations map': 'Dodaj kartico za zemljevid lokacij',
      'Add main cost centre to location':
        'Dodajte glavno stroškovno mesto lokaciji',
      'Add new attribute': 'Dodaj nov atribut',
      'Add new configuration': 'Dodaj novo konfiguracijo',
      'Add new user emails': 'Dodaj uporabnika',
      'Add owner to location': 'Dodajte lastnika na lokacijo',
      'Add owner to measuring point': 'Dodajte lastnika merilnemu mestu',
      'Add schedule classification': 'Dodaj klasifikacijo urnika',
      'Add series': 'Dodaj niz',
      'Add tab': 'Dodaj zavihek',
      'Add time series': 'Dodaj časovno vrsto',
      'Add time series group': 'Dodaj skupino časovnih vrst',
      'Add to group': 'Dodaj skupini',
      'Add user': 'Dodaj uporabnika',
      'Add values': 'Dodaj kartico za vrednosti',
      'Add variable': 'Dodaj spremenljivko',
      'Add visualization': 'Dodaj kartico za vizualizacijo',
      'Add weather': 'Dodaj kartico za vreme',
      Addition: 'Seštevanje',
      'Additional properties': 'Dodatne lastnosti',
      Address: 'Naslov',
      'Adds neighbouring expressions': 'Doda sosednje izraze',
      Advanced: 'Napredno',
      Afternoon: 'Popoldne',
      Aggregation: 'Agregacija',
      'Aggregation Group': 'Agregacijska skupina',
      'Aggregation Groups': 'Agregacijske skupine',
      'Aggregation Type': 'Tip agregacije',
      'Aggregation group': 'Agregacijska skupina',
      'Aggregation type': 'Tip agregacije',
      Alarm: 'Alarm',
      'Alarm Category': 'Kategorija alarma',
      'Alarm Data': 'Podatki alarma',
      'Alarm Definition': 'Definicija alarmov',
      'Alarm Definitions': 'Definicije alarmov',
      'Alarm Type': 'Tip alarma',
      'Alarm Types': 'Tipi alarmov',
      'Alarm after timeout': 'Alarm ob poteku časovne omejitve',
      'Alarm configuration': 'Konfiguracija alarmov',
      'Alarm settings': 'Nastavitve alarma',
      'Alarm types': 'Tipi alarmov',
      'Alarm was successfully accepted!': 'Alarm je bil uspešno sprejet.',
      'Alarm was successfully assigned!': 'Alarm je bil uspešno dodeljen.',
      'Alarm was successfully resolved!': 'Alarm je bil uspešno rešen!',
      Alarms: 'Alarmi',
      'Alarms and Rules': 'Alarmi in pravila',
      'Alarms and rules': 'Alarmi in pravila',
      'Alarms overview': 'Pregled alarmov',
      'Alarms:': 'Alarmi:',
      Aligment: 'Poravnava',
      'All series': 'Vse serije',
      Allocation: 'Delitev',
      'Allocator Kind': 'Način delitve',
      'Allocator Type': 'Vrsta delilnika',
      'Allocator value': 'Vrednost v enoti',
      'Allowed entities': 'Dovoljene entitete',
      'Allowed varaible names': 'Dovoljena imena spremenljivk',
      'Always valid': 'Vedno veljavno',
      Amount: 'Znesek',
      'Amount left': 'Preostali znesek',
      Amounts: 'Zneski',
      Analyses: 'Analize',
      Analysis: 'Analiza',
      'Analysis Type': 'Tip analize',
      'Analysis doesn\'t have any required filters defined':
        'Analiza nima definiranih filtrov',
      Analytics: 'Analitika',
      And: 'In',
      Application: 'Aplikacija',
      'Apply power of right-side neighbouring number (or expression) to the left side neighbouring number (or expression).':
        'Leva številka oz. izraz na desno številko oz. izraz.',
      'Apply to all entities:': 'Nastavi vsem entitetam:',
      Apr: 'Apr',
      April: 'April',
      'Are you sure that you want to change dashboard card size?':
        'Ali ste prepričani, da želite spremeniti velikost kartice?',
      'Are you sure that you want to remove layout?':
        'Ali ste prepričani, da želite izbrisati to razporeditev?',
      'Are you sure you want to cancel?':
        'Ali ste prepričani, da želite preklicati spremembe?',
      'Are you sure you want to continue? All unsaved data will be lost.':
        'Ali ste prepričani, da želite nadaljevati? Vsi neshranjeni podatki bodo izgubljeni.',
      'Are you sure you want to delete':
        'Ali ste prepričani, da želite izbrisati spremebe',
      'Are you sure you want to delete Manual Input Time Series?':
        'Ali ste prepričani, da želite izbrisati to ročni vnos za časovno vrsto?',
      'Are you sure you want to delete this item?':
        'Ali ste prepričani, da želite izbrisati to postavko?',
      'Are you sure you want to regenerate credentials?':
        'Ali ste prepričani, da želite regenerirati poverilnice?',
      'Are you sure you want to reload values? All unsaved data will be lost.':
        'Ali ste prepričani, da želite nadaljevati? Vsi neshranjeni podatki bodo izgubljeni.',
      'Are you sure you want to remove all direct accesses?':
        'Ali ste prepričani, da želite izbrisati vse neposredne dostope?',
      'Are you sure you want to remove all items?':
        'Ali ste prepričani, da želite izbrisati vse postavke?',
      'Are you sure you want to remove the item?':
        'Ali ste prepričani, da želite izbrisati postavke?',
      'Are you sure you want to remove this classification?':
        'Ali ste prepričani, da želite odstranitii to klasifikacijo?',
      'Are you sure you want to remove this direct access?':
        'Ali ste prepričani, da želite izbrisati izbran neposredni dostop?',
      'Are you sure you want to remove this group?':
        'Ali ste prepričani, da želite izbrisati to skupino?',
      'Are you sure you want to remove this mapping rule?':
        'Ali ste prepričani, da želite izbrisati to preslikovalno pravilo?',
      'Are you sure you want to remove this {{name}}?':
        'Ali ste prepričani, da želite izbrisati {{name}}?',
      'Are you sure you want to remove user from group?':
        'Ali ste prepričani, da želite odstraniti uporabnika iz skupine?',
      'Are you sure you want to reset password? User will have to set new password at the next login.':
        'Ali ste prepričani, da želite ponastaviti geslo? Uporabnik bo moral nastaviti novo geslo ob naslednji prijavi.',
      'Are you sure you want to unpair measurement and GIS object?':
        'Ali ste prepričani, da želite odupariti meritev in GIS objekt?',
      'Are your sure that you want to relist values? All changed date will be lost':
        'Ali ste prepričani, da želite izbrisati to razporeditev',
      Argument: 'Argument',
      'Argument value': 'Argument',
      'Arithmetic expressions': 'Aritmetični izrazi',
      'As it was stored on the date': 'Kot je bilo shranjeno na datum',
      Asset: 'Sredstvo',
      'Asset Availability': 'Razpoložljivost sredstva',
      'Asset Availability Event': 'Dogodek razpoložljivosti sredstva',
      'Asset Brand': 'Blagovna znamka sredstva',
      'Asset Brands': 'Blagovna znamka sredstva',
      'Asset Group': 'Skupina sredstev',
      'Asset Groups': 'Skupine sredstev',
      'Asset Model': 'Model sredstva',
      'Asset Models': 'Modeli sredstev',
      'Asset Parent': 'Nadrejeno sredstvo',
      'Asset Status': 'Status sredstva',
      'Asset Statuses': 'Statusi sredstev',
      'Asset Type': 'Tip sredstva',
      'Asset Types': 'Tip sredstva',
      'Asset brand': 'Blagovna znamka sredstva',
      'Asset doesn\'t have availability yet': 'Sredstvo nima razpoložljivosti.',
      'Asset group': 'Skupina sredstev',
      'Asset has no subsidiary assets': 'Sredstvo nima podrejenih sredstev',
      'Asset owner': 'Lastnik sredstva',
      'Asset ownership': 'Lastništvo sredstva',
      'Asset type': 'Tip sredstva',
      'Asset type attributes': 'Atributi tipa sredstva',
      Assets: 'Sredstva',
      'Assets configuration': 'Konfiguracija sredstev',
      Assign: 'Dodeli',
      'Assign Alarm': 'Dodeli alarm',
      'Assigned Personnel': 'Dežurstva',
      'Assigned personnel': 'Dežurstva',
      Assignee: 'Dodeljenec',
      'Assignment is not allowed': 'Pripis ni dovoljen',
      'Assignment is not allowed.': 'Pripis ni dovoljen.',
      'At least one default filter must be selected':
        'Izbran mora biti vsaj en privzet filter',
      'At least one trigger has to be enabled':
        'Vsaj en sproži izračun mora biti izbran.',
      Attribute: 'Atribut',
      'Attribute {{ $index + 1 }}': 'Atribut {{ $index + 1 }}',
      Attributes: 'Atributi',
      August: 'Avgust',
      Author: 'Avtor',
      'Authorized Groups': 'Pooblaščene skupine',
      'Authorized Users': 'Pooblaščeni uporabniki',
      'Authorized personnel': 'Pooblaščeno osebje',
      Available: 'Na voljo',
      Avg: 'Avg',
      Back: 'Nazaj',
      Bad: 'Slabo',
      'Bad-Request': 'Napačna zahteva',
      'Base line': 'Osnovna premica',
      Baseline: 'Osnovna premica',
      'Baseline function': 'Osnovna premica',
      'Baseline {{equation}}': 'Osnovna premica {{equation}}',
      Baselines: 'Osnovne premice',
      'Basic Map': 'Osnovni zemljevid',
      'Basic Map filters': 'Filtri za osnovni zemljevid',
      Batch: 'Paketni',
      'Batch interval in days': 'Paketni interval v dnevih',
      'Batch interval in hours': 'Paketni interval v urah',
      'Batch interval in minutes': 'Paketni interval v minutah',
      'Batch interval in seconds': 'Paketni interval v sekundah',
      'Billing Kind': 'Način obračuna',
      'Billing Kind Configurations': 'Konfiguracija načina obračuna',
      'Billing Kinds': 'Načini obračuna',
      'Billing Type': 'Tip obračuna',
      'Billing Type Configurations': 'Konfiguracija tipa obračuna',
      'Billing Types': 'Tipi obračuna',
      'Billing data': 'Obračunske postavke',
      'Billing date': 'Datum obračuna',
      'Billing date *': 'Datum obračuna',
      'Billing date from': 'Datum obračuna od',
      'Billing date from must occur before billing date to.':
        'Datum obračuna od mora biti manjši od datuma obračuna do.',
      'Billing date time range must be less then two years.':
        'Obdobje datuma obračuna mora biti manj kot dve leti.',
      'Billing date to': 'Datum obračuna do',
      'Billing date:': 'Datum obračuna:',
      'Billing kinds': 'Načini obračuna',
      Bold: 'Krepko',
      'Book value': 'Vrednost za knjiženje',
      'Book value was successfully distributed':
        'Vrednost za knjiženje je bila uspešno porazdeljena',
      'Book value: {{bookValue}}': 'Še odprto: {{bookValue}}',
      Boolean: 'Logična vrednost',
      'Both coordinates must be selected in order to save them.':
        'Potrebno je nastaviti obe koordinati, da se shrani geolokacija.',
      Boundaries: 'Meje',
      'Boundary Type': 'Vrsta meje',
      Brand: 'Blagovna znamka',
      'Browse Query': 'Poizvedba za zajem značk',
      Buffer: 'Buffer',
      'Bullet list': 'Seznam',
      'Business Partner': 'Poslovni partner',
      'Business Partner Tree View': 'Poslovni partner - drevesni pogled',
      'Business Partners': 'Poslovni partnerji',
      'Business partner': 'Poslovni partner',
      'Business partner billing item detail':
        'Podroben pregled obračuna za poslovnega partnerja',
      'Business partners': 'Poslovni partnerji',
      'CO per kWh': 'CO na kWh',
      'CO2 per kWh': 'CO2 na kWh',
      'CRON EXPRESSION': 'ČASOVNI IZRAZ',
      'CSV was successfully uploaded': 'CSV datoteka je bila uspešno naložena',
      'Calculate baseline': 'Izračunaj osnovno premico',
      'Calculated Value': 'Izračunana vrednost',
      'Calculated count': 'Število izvedenih vrednosti',
      'Calculated flow': 'Izračunana časovna vrsta',
      'Calculated value': 'Izračunana vrednost',
      Calculation: 'Izračun',
      Calculations: 'Izračuni',
      'Can\t update detail invoice because there is no detail price list id':
        'Podrobnosti računa ni mogoče posodobiti, ker manjka id podrobnosti cenika',
      'Can\'t find method for entity {{entity}} and method {{method}}':
        'Can\'t find method for entity {{entity}} and method {{method}}',
      Cancel: 'Prekliči',
      'Cancel updating time series group':
        'Prekinite posodobitev skupine za časovno vrsto',
      'Card size changed': 'Velikost kartice je bila spremenjena.',
      Center: 'Sredina',
      'Change Password': 'Spremeni geslo',
      'Change Price List': 'Spremeni cenik',
      'Change counter': 'Sprememba števca',
      'Change counter for {{measuringpointName}}':
        'Menjava števca za {{measuringpointName}}',
      'Change password': 'Spremeni geslo',
      'Changes were successfully changed': 'Spremembe so bile uspešne.',
      'Chart To Table Ratio': 'Razmerje med grafom in tabelo',
      'Chart Type': 'Tip grafa',
      'Chart filters': 'Filtri za grafe',
      'Chart settings': 'Nastavitve grafa',
      'Chart type': 'Tip grafa',
      'Check box': 'Check box',
      'Check your URL / link and try again.':
        'Preverite URL povezavo in poskusite znova.',
      'Child assets': 'Podrejena sredstva',
      'Child measuring points': 'Podrejena merilna mesta',
      'Choose Step': 'Izberite korak',
      'Choose Task': 'Izberite nalogo',
      'Choose a time interval': 'Izberite časovni interval',
      'Choose an element type': 'Izberite tip elementa',
      'Choose database type': 'Izberite tip podatkovne baze',
      'Choose display field': 'Izberite polje za prikaz',
      'Choose file type': 'Izberite tip datoteke',
      'Choose opc type': 'Izberite tip OPC-ja',
      'Choose sop procedure': 'Izberite proceduro SOP',
      'Choose sop type': 'Izberite tip SOP',
      'Choose webservice type': 'Izberite tip spletne storitve',
      Class: 'Razred',
      Clear: 'Počisti',
      'Clear all ({{vm.items ? vm.items.length : \'0\'}})':
        'Počisti vse ({{vm.items ? vm.items.length : \'0\'}})',
      'Clear filters': 'Izbriši filtre',
      'Click to upload': 'Kliknite za nalaganje',
      'Client ID': 'ID odjemalca',
      'Close interval': 'Zapri interval',
      Code: 'Šifra',
      'Code:': 'Šifra:',
      'Collection Friendly Name': 'Prijazno ime zbirke',
      'Collection friendly name': 'Prijazno ime zbirke',
      Color: 'Barva',
      Columns: 'Stolpci',
      'Columns number': 'Število stolpcev',
      Comment: 'Komentar',
      Community: 'Občina',
      'Community Name': 'Ime občine',
      'Community name': 'Občina',
      Company: 'Podjetje',
      'Company Resources': 'Viri podjetja',
      'Company Structure': 'Struktura podjetja',
      'Company Structure Node': 'Vozlišče strukture podjetja',
      'Company Structure Node Parent': 'Nadrejeno vozlišče strukture podjetja',
      'Company Structure Node Tree View':
        'Drevesni pogled vozlišč strukture podjetja',
      'Company Structure Nodes': 'Vozlišča strukture podjetja',
      'Company resources': 'Viri podjetja',
      'Comparable column': 'Stolpec za primerjavo',
      'Comparable columns': 'Stolpci za primerjavo',
      'Compare Operations': 'Primerjalni operatorji',
      Completed: 'Končano',
      'Component creator': 'Ustvarjalec komponente',
      'Computes the power of a number.': 'Vrne potenco števila.',
      Conditions: 'Pogoji',
      'Configuration to create new tag is missing.':
        'Manjka konfiguracija za kreiranje nove značke.',
      'Configuration updated.': 'Konfiguracija uspešno posodobljena.',
      'Configurations updated.': 'Konfiguracija uspešno posodobljena.',
      'Confirm new password': 'Potrdi novo geslo',
      Confirmation: 'Potrditev',
      'Connected external datasources': 'Povezan zunanji podatkovni vir',
      Connection: 'Povezava',
      'Connection config is missing': 'Manjka konfiguracija povezave',
      'Connection configuration connection id':
        'Konfiguracija konektorja Tango Agenta',
      'Connection configuration id': 'Zunanji podatkovni vir',
      'Connection configuration tag': 'Tag konfiguracije Tango Agenta',
      'Connection configuration type': 'Vrsta zunanjega podatkovnega vira',
      'Connection settings': 'Nastavitve povezave',
      'Connection type': 'Tip povezave',
      Connectors: 'Konektorji',
      Consistency: 'Zanesljivost',
      Consumption: 'Poraba',
      'Consumption date': 'Datum porabe',
      'Content notification': 'Vsebina obvestila',
      'Content notification users': 'Prejemniki obvestil za vsebino',
      'Content responsible person': 'Odgovorna oseba za vsebino',
      'Copy history flow to live flow': 'Kopiraj v trenutne podatke',
      'Copy link to clipboard and close':
        'Kopirajte povezavo do odložišča in zaprite',
      'Copy live flow to history flow': 'Kopiraj v zgodovinske podatke',
      'Cosine function.': 'Funkcija kosinus',
      'Cost Centre': 'Stroškovno mesto',
      'Cost Centre Tree View': 'Drevesni pogled stroškovnih mest',
      'Cost Centre Type': 'Vrsta stroškovnega mesta',
      'Cost Centres': 'Stroškovna mesta',
      'Cost centre': 'Stroškovno mesto',
      'Cost centre type': 'Vrsta stroškovnega mesta',
      'Cost centres': 'Stroškovna mesta',
      'Could not calculate the input.': 'Vnosa ni bilo mogoče izračunati.',
      'Couldn\'t duplicate invoice dates':
        'Datumov računa ni bilo mogoče podvojiti.',
      'Couldn\'t fetch organization': 'Organizacija ni bila najdena.',
      'Couldn\'t init GIS sdk': 'GIS SDK-ja ni bilo mogoče inicializirati.',
      'Couldn\'t initialize card. Dashboard card type doesn\'t exist.':
        'Tip kartice nadzorne plošče ne obstaja.',
      'Couldn\'t remove main Dashboard':
        'Glavne nadzorne plošče ni bilo mogoče odstraniti.',
      'Couldn\'t reset gis token': 'GIS token ni bil uspešno ponastavljen.',
      'Couldn\'t set main Dashboard':
        'Ni bilo mogoče nastaviti glavne nadzorne plošče.',
      'Couldn\'t update next reading consumption try to update it manually.':
        'Poraba ni bila uspešno izračunana. Poskusite jo vnesti ročno.',
      'Couldn\'t upload values for': 'Vrednosti ni bilo mogoče naložiti za',
      Count: 'Število',
      'Count with events': 'Število vrednosti z dogodki',
      'Counter Type': 'Tipi merilnikov',
      'Counter Type Configuration': 'Konfiguracija tipa merilnika',
      'Counter Types': 'Tipi merilnikov',
      'Counter read out': 'Novo stanje števca',
      'Counter replacement date': 'Datum menjave števca',
      'Counter type has no registers': 'Tip merilnika nima registrov.',
      'Counter types': 'Tipi merilnikov',
      Country: 'Država',
      Create: 'Ustvari',
      'Create GIS user': 'Ustvari uporabnika GIS',
      'Create M&T analysis flow': 'Ustvari M&T analizo',
      'Create an absence': 'Ustvari odsotnost',
      'Create an assignment': 'Nova dodelitev',
      'Create calculated flow': 'Ustvari izračunano časovno vrsto',
      'Create domain': 'Nova domena',
      'Create external reader flow': 'Ustvari zajem iz zunanjega vira',
      'Create external writer flow': 'Ustvari zapisovalno časovno vrsto',
      'Create maintenance flow': 'Ustvari vzdrževanje časovne vrste',
      'Create new base line target function':
        'Ustvari novo ciljno premico za osnovno premico',
      'Create new item': 'Ustvari novo postavko',
      'Create one.': 'Ustvarite nadzorno ploščo',
      'Create time series': [
        'Ustvari časovne vrste',
        'Ustvari časovne vrste',
        'Ustvari časovne vrste',
        'Ustvari časovne vrste'
      ],
      'Create value': 'Ustvari vrednost',
      Created: 'Ustvarjeno',
      'Created Notification': 'Ustvarjeno obvestilo',
      'Created Notifications': 'Ustvarjena obvestila',
      'Creation type': 'Način nastanka',
      'Cron expression': 'Časovni izraz',
      'Cron expression literal': 'Točna vrednost časovnega izraza',
      'Cumulative expressions': 'Izrazi',
      Currency: 'Valuta',
      'Current Assignee': 'Trenutno dodeljena oseba',
      'Current Version': 'Trenutna verzija',
      'Current assignee': 'Trenutno dodeljena oseba',
      'Current item': 'Trenutno aktivno:',
      Custom: 'Po meri',
      Customer: 'Stranka',
      'Customer:': 'Stranka:',
      DEC: 'DEC',
      'Daily setup': 'Dnevna nastavitev',
      Dashboard: 'Nadzorna plošča',
      'Dashboard Playlist': 'Seznam predvajanj',
      'Dashboard Playlists': 'Seznami predvajanj',
      'Dashboard card successfully saved.':
        'Kartica je bila uspešno shranjena.',
      'Dashboard card was successfully added to the dashboard.':
        'Kartica nadzorne plošče je bila uspešno dodana na nadzorno ploščo.',
      'Dashboard does not exist': 'Nadzorna plošča ne obstaja',
      'Dashboard updated': 'Nadzorna plošča posodobljena',
      Dashboards: 'Nadzorne plošče',
      Data: 'Podatki',
      'Data Approximation': 'Nadomeščanje podatkov',
      'Data Interpolation Type': 'Vrsta interpretacije',
      'Data Sampling Type': 'Vrsta vzorčenja',
      'Data Type': 'Tip podatkov',
      'Data Visualization': 'Vizualizacije podatkov',
      'Data approximation': 'Nadomeščanje podatkov',
      'Data is loading': 'Nalaganje podatkov',
      'Data is loading ...': 'Nalaganje podatkov ...',
      'Data point format': 'Format podatkovne točke',
      'Data point value should be indicated as':
        'Vrednost podatkovne točke mora biti označena kot ',
      'Data type': 'Tip podatkov',
      'Data visualization': 'Vizualizacija podatkov',
      Database: 'Baza podatkov',
      'Database connection configuration':
        'Konfiguracija povezave z bazo podatkov',
      'Database name': 'Ime baze podatkov',
      'Database type': 'Tip baze podatkov',
      Databases: 'Podatkovne baze',
      Dataset: 'Nabor podatkov',
      'Dataset doesn\'t have any default filters. Select another dataset':
        'Nabor podatkov nima privzetih filtrov. Izberite drug nabor podatkov.',
      Datasets: 'Nabor podatkov',
      Date: 'Datum',
      'Date Filter Type': 'Vrsta časovnega filtra',
      'Date Finished': 'Datum zaključka',
      'Date Range': 'Datumsko območje',
      'Date Time Filter Type': 'Vrsta časovnega filtra',
      'Date column': 'Datumski stolpec',
      'Date column name': 'Ime polja s časovno značko',
      'Date does not match cron expression': 'Datum se ne ujema s cronom',
      'Date finished': 'Datum zaključka',
      'Date for calculation of regression': 'Datum za izračun regresije',
      'Date for calculation of regression function from':
        'Datum za izračun regresijske funkcije od',
      'Date for calculation of regression function to':
        'Datum za izračun regresijske funkcije do',
      'Date is not valid': 'Datum ni veljaven',
      'Date of data reading': 'Datum branja podatka',
      'Date of data recording': 'Datum zapisa podatka',
      'Date of data recording:': 'Datum zapisa podatkov:',
      Dates: 'Datumi',
      Day: 'Dan',
      'Day and time in month': 'Dan in čas v mesecu',
      'Day format is not right.': 'Dnevni format ni pravilen.',
      'Day interval quantity missing.': 'Manjka vrednost dnevnega intervala.',
      'Day of month (1 - 31)': 'Dan v mesecu (1 - 31)',
      Days: 'Dnevi',
      Dec: 'Dec',
      December: 'December',
      'Default Value Type': 'Privzet tip vrednosti',
      'Default display type': 'Privzet tip prikaza',
      'Default mapping rule': 'Privzeto preslikovalno pravilo',
      'Default mapping rule is required.':
        'Privzeto preslikovalno pravilo je obvezno',
      'Default physical quantity': 'Privzeta fizikalna količina',
      'Default specification': 'Privzeta klasifikacija urnika',
      'Default value': 'Privzeta vrednost',
      Delete: 'Izbriši',
      'Delete absence': 'Izbriši odsotnost',
      'Delete assignment': 'Izbriši dodelitev',
      'Delete element': 'Izbriši element',
      Delimiter: 'Ločilo',
      Delta: 'Delta',
      'Demo Editor': 'Demo urejevalnik',
      'Demo Preview': 'Predogled',
      'Depreciation Period': 'Amortizacijsko obdobje',
      'Depreciation period': 'Amortizacijsko obdobje',
      Description: 'Opis',
      'Deselect all': 'Odznači vse',
      'Detail invoice': 'Podrobnost računa',
      'Detail invoice service date to': 'Datum storitve do',
      'Detail invoice total': 'Znesek podrobnosti računa',
      'Detail invoice values order was successfully updated':
        'Vrstni red postavk računa je bil uspešno posodobljen.',
      'Detail invoice values sum must be less than detail invoice book value.':
        'Vsota vrednosti podrobnosti računa mora biti manjša od vrednosti kniženja.',
      'Detailed view': 'Podroben pogled',
      'Device heartbeats': 'Srčni utrip naprave IoT',
      'Direct Access': 'Neposreden dostop',
      'Disable cache': 'Onemogoči predpomnjenje',
      Display: 'Prikaži',
      'Display field': 'Polje za prikaz',
      'Display field name': 'Ime polja za prikaz',
      'Display horizontally': 'Prikaži vodoravno',
      'Display type': 'Prikaz podatkov',
      'Display types': 'Tipi prikaza',
      Distribute: 'Porazdeli',
      Distributer: 'Distributer',
      'Distributer price list': 'Distributerjev cenik',
      'Distributer/Dobavitelj': 'Distributer / Dobavitelj energenta',
      Distributor: 'Distributer',
      'Distributor Code': 'Distributerjeva šifra',
      'Distributor code': 'Distributerjeva šifra MM',
      'Distributor/Supplier': 'Distributer/Dobavitelj',
      'Divides neighbouring expressions': 'Deli sosednja izraza.',
      Division: 'Deljenje',
      'Do you want to delete absence?':
        'Ali ste prepričani, da želite izbrisati odsotnost?',
      'Do you want to delete assignment?':
        'Ali ste prepričani, da želite izbrisati izbrano dodelitev?',
      'Document Classification': 'Klasifikacija dokumentacije',
      'Document Classifications': 'Klasifikacije dokumentacije',
      Domain: 'Domena',
      'Domain Collection': 'Domenska zbirka',
      'Domain Collections': 'Domenske zbirke',
      'Domain Configuration Form': 'Obrazec za konfiguracijo domene',
      'Domain Configurations': 'Konfiguracija domen',
      'Domain Field': 'Domensko polje',
      'Domain Fields': 'Domenska polja',
      'Domain Fields Configuration': 'Konfiguracija domenskih polj',
      'Domain Fields Configuration Form':
        'Obrazec za konfiguracijo domenskih polj',
      'Domain attribute': 'Dinamični atribut',
      Domains: 'Domene',
      Double: 'Decimalno število',
      Download: 'Prenesi',
      'Drag Item Here': 'Element povlecite sem',
      'Drag a time series into this field or add it manually to begin with arranging':
        'Povlecite časovno vrsto ali pa jo dodajte ročno za začetek urejanja.',
      'Drag file here or': 'Datoteko povlecite sem ali',
      Duplicate: 'Podvoji',
      'Duplicate Analysis': 'Podvoji analizo',
      'Duplicate Business partner': 'Podvoji poslovnega partnerja',
      'Duplicate Invoice': 'Podvoji račun',
      'Duplicate Location': 'Podvoji lokacijo',
      'Duplicate M&T analysis flow': 'Podvoji M&T analizo',
      'Duplicate Measuring Point': 'Podvoji merilno mesto',
      'Duplicate Price List': 'Podvoji cenik',
      'Duplicate Project': 'Podvoji projekt',
      'Duplicate Rule': 'Podvoji pravilo',
      'Duplicate analysis': 'Podvoji analizo',
      'Duplicate asset': 'Podvoji sredstvo',
      'Duplicate calculated flow': 'Podvoji izračunano časovno vrsto',
      'Duplicate external reader flow': 'Podvoji zajem iz zunanjega vira',
      'Duplicate external writer flow': 'Podvoji zapisovalno časovno vrsto',
      'Duplicate maintenance flow': 'Podvoji vzdrževanje časovne vrste',
      'Duplicate mapping rule': 'Podvoji preslikovalno pravilo',
      'Duplicate master invoice': 'Podvoji račun',
      'Duplicate measuring point': 'Podvoji merilno mesto',
      'Duplicate price list': 'Podvoji cenik',
      'Duplicate report': 'Podvoji poročilo',
      'Duplicate step': 'Podvoji korak',
      'Duplicate task': 'Podvoji nalogo',
      'Duplicate time series': 'Podvoji časovno vrsto',
      'Duplicate time series configuration':
        'Podvoji konfiguracijo časovne vrste',
      'Duplicate time series data from selected time series properties':
        'Podvoji časovno vrsto iz izbranih podatkov časovne vrste',
      'Duplicating alarm definition': 'Podvoji definicijo alarma',
      'Duplicating standard operating procedure':
        'Podvoji standardno operativno proceduro',
      Dynamic: 'Dinamično',
      'Dynamic Attribute': 'Dinamični atribut',
      'Dynamic Attributes': 'Dinamični atributi',
      'Dynamic attributes': 'Dinamični atributi',
      'E-mail': 'E-pošta',
      'EOM Code': 'Šifra EOM',
      EQUALS: 'Enako',
      'EXPRESSION PREVIEW': 'PREDOGLED IZRAZA',
      Edit: 'Urejanje',
      'Edit / Delete': 'Urejanje / Izbris',
      'Edit Action Status': 'Urejanje statusa ukrepa',
      'Edit Alarm Type': 'Urejanje tipa alarma',
      'Edit Asset Brand': 'Urejanje blagovne znamka sredstva',
      'Edit Asset Group': 'Urejanje skupine sredstva',
      'Edit Asset Model': 'Urejanje modela sredstva',
      'Edit Asset Status': 'Urejanje statusa sredstva',
      'Edit Billing Kind': 'Urejanje načina obračuna',
      'Edit Billing Type': 'Urejanje tipa obračuna',
      'Edit Business partner': 'Urejanje poslovnega partnerja',
      'Edit Company Structure Node': 'Urejanje vozlišča strukture podjetja',
      'Edit Cost Centre': 'Urejanje stroškovnega mesta',
      'Edit Counter Type': 'Urejanje tipa merilnika',
      'Edit Database': 'Urejanje baze podatkov',
      'Edit Document Classification': 'Urejanje klasifikacije dokumentacije',
      'Edit Domain': 'Urejanje domene',
      'Edit Domain Collection': 'Urejanje domenske zbirke',
      'Edit Domain Field': 'Urejanje domenskega polja',
      'Edit Energy Management Item':
        'Urejanje postavke za energetsko upravljanje',
      'Edit Energy Source Type': 'Urejanje tipa energenta',
      'Edit External Datasource': 'Urejanje zunanjega podatkovnega vira',
      'Edit File': 'Urejanje datoteke',
      'Edit Invoice Detail': 'Urejanje podrobnosti računa',
      'Edit Location': 'Urejanje lokacije',
      'Edit Location Classification': 'Urejanje klasifikacije lokacije',
      'Edit Location Status': 'Urejanje statusa lokacije',
      'Edit M & T Analysis': 'Urejanje M & T analize',
      'Edit Manual Input Time Series': 'Uredi ročnega vnosa časovne vrste',
      'Edit Measurement Unit': 'Urejanje merske enote',
      'Edit National Holiday': 'Urejanje državnega praznika',
      'Edit OPC': 'Urejanje OPC-ja',
      'Edit Ownership': 'Urejanje lastništva',
      'Edit Physical Quantity': 'Urejanje fizikalne količine',
      'Edit Price List': 'Urejanje cenika',
      'Edit Price List Item': 'Urejanje postavk cenika',
      'Edit Privileges': 'Uredi pravice',
      'Edit Profit Centre': 'Urejanje profitnega centra',
      'Edit Project': 'Urejanje projekta',
      'Edit Project Field': 'Urejanje področja projekta',
      'Edit Project Type': 'Urejanje tipa projekta',
      'Edit Rated Power': 'Urejanje nazivne moči',
      'Edit Response Interval': 'Urejanje odzivnega intervala',
      'Edit Rule': 'Urejanje pravila',
      'Edit Scheduler': 'Urejanje urnika',
      'Edit Tango Agent Connection Config':
        'Urejanje konfiguracije konektorja Tango Agenta',
      'Edit Tariff': 'Urejanje tarife',
      'Edit Time Series': 'Urejanje časovne vrste',
      'Edit Time Series Group Type': 'Urejanje tipa skupine časovnih vrst',
      'Edit Value Card': 'Urejanje kartice za vrednost',
      'Edit Web Service': 'Urejanje spletne storitve',
      'Edit aggregation group': 'Urejanje agregacijske skupine',
      'Edit alarm definition': 'Urejanje definicije alarma',
      'Edit alarms': 'Urejanje kartice za alarm',
      'Edit analysis': 'Urejanje analize',
      'Edit asset': 'Urejanje sredstva',
      'Edit asset type': 'Urejanje tipa sredstva',
      'Edit calculation': 'Urejanje izračuna',
      'Edit card': 'Urejanje kartice',
      'Edit column': 'Urejanje stolpca',
      'Edit dashboard': 'Urejanje nadzorne plošče',
      'Edit dashboard playlist': 'Urejanje seznama predvajanja',
      'Edit dataset': 'Urejanje nabora podatkov',
      'Edit dynamic attribute': 'Urejanje dinamičnega atributa',
      'Edit expression': 'Urejanje izraza',
      'Edit group': 'Urejanje skupine',
      'Edit iframe': 'Urejanje iframe kartice',
      'Edit image': 'Urejanje slike',
      'Edit invoice detail value': 'Urejanje postavke podrobnega računa',
      'Edit invoice overview': 'Urejanje kartice za pregled računov',
      'Edit location': 'Urejanje kartice za lokacijo',
      'Edit location type': 'Urejanje tipa lokacije',
      'Edit locations by cost centre map':
        'Urejanje kartice za lokacijo glede na zemljevid stroškovnega mesta',
      'Edit locations map': 'Urejanje kartice za zemljevid lokacije',
      'Edit manual input': 'Urejanje naprednega ročnega vnosa',
      'Edit mapping rule': 'Urejanje preslikovalnega pravila',
      'Edit master invoice': 'Urejanje zbirnega računa',
      'Edit measuring point': 'Urejanje merilnega mesta',
      'Edit measuring point type': 'Urejanje tipa merilnega mesta',
      'Edit notification': 'Urejanje obvestila',
      'Edit order': 'Urejanje vrstnega reda',
      'Edit price list': 'Urejanje cenika',
      'Edit price list item': 'Urejanje postavk cenika',
      'Edit price list item group': 'Urejanje skupine postavk na ceniku',
      'Edit report': 'Urejanje poročila',
      'Edit settings': 'Urejanje nastavitev',
      'Edit severity': 'Urejanje stopnje pomembnosti',
      'Edit standard operating procedure':
        'Urejanje standardne operativne procedure',
      'Edit step': 'Urejanje koraka',
      'Edit task': 'Urejanje naloge',
      'Edit template': 'Urejanje predloge',
      'Edit time interval filter': 'Urejanje filtra za časovni interval',
      'Edit time series group': 'Urejanje skupine časovnih vrst',
      'Edit time series type': 'Urejanje tipa časovne vrste',
      'Edit user': 'Urejanje uporabnika',
      'Edit values': 'Urejanje kartice za vrednosti',
      'Edit visualization': 'Urejanje vizualizacije',
      'Edit weather': 'Urejanje kartice za vreme',
      Editor: 'Urejevalnik',
      Effort: 'Trud',
      'Element type': 'Tip elementa',
      'Element type not recognized': 'Tip elementa neznan',
      Email: 'E-pošta',
      'Email content': 'Vsebina e-pošte',
      'Email notification': 'Vsebina elektronske pošte',
      'Enable cache': 'Omogoči predpomnjenje',
      'Enable edit permissions': 'Omogoči urejanje pravic',
      'Enable filter': 'Omogoči filter',
      Enabled: 'Omogočeno',
      'End date': 'Datum konca',
      'End date:': 'Datum konca:',
      'End of Billing Period': 'Datum konca obračunskega obdobja',
      'End of Project': 'Datum konca projekta',
      'End of billing period': 'Konec obračunskega obdobja',
      'End of project': 'Konec projekta',
      Ended: 'Končano',
      'Ending On': 'Konec',
      'Energy Management Group Configuration':
        'Konfiguracija postavke za energetsko upravljanje',
      'Energy Management Item': 'Postavka za energetsko upravljanje',
      'Energy Management Items': 'Postavke za energetsko upravljanje',
      'Energy Manager': 'Energetski menedžer',
      'Energy Source Type': 'Tip energenta',
      'Energy Source Types': 'Tipi energenta',
      'Energy Sources Types Configuration': 'Konfiguracija tipov energentov',
      'Energy management': 'Energetsko upravljanje',
      'Energy management group': 'Skupina za energetsko upravljanje',
      'Energy management groups': 'Skupina za energetsko upravljanje',
      'Energy management item': 'Postavka za energetsko upravljanje',
      'Energy management system.': 'Sistem za energetsko upravljanje ',
      'Energy manager': 'Energetski menedžer',
      'Energy managment groups': 'Skupine za energetsko upravljanje',
      'Energy source type': 'Tip energenta',
      'Energy source type is missing physical data':
        'Energentu manjkajo fizikalni podatki.',
      'Enter URL (/example/path)': 'Vnesite URL (/primer/pot)',
      'Enter any string': 'Vnesite katerikoli niz',
      'Enter date': 'Vnesite datum',
      'Enter decimal.': 'Vnesite decimalno število.',
      'Enter email and press enter or tab':
        'Vpišite email in pritisnite enter ali tabulator',
      'Enter integer': 'Vnesite celo število',
      'Enter time (hh:mm:ss)': 'Vnesite čas (hh: mm: SS)',
      'Enter year': 'Vnesite leto',
      'Enter your comment here.': 'Svoj komentar vnesite tukaj.',
      Entity: 'Entiteta',
      'Entity Tags': 'Značke entitete',
      'Entity instances': 'Instance entitete',
      'Equal to': 'Enako',
      Equality: 'Enakost',
      Equals: 'Enako',
      'Error at creating new task': 'Napaka pri ustvarjanju nove naloge',
      'Error loading': 'Napaka pri nalaganju',
      'Error loading card': 'Napaka pri nalaganju kartice',
      'Error loading task': 'Napaka pri nalaganju naloge',
      'Error on date initialization: Time Series is missing a data scheduler.':
        'Časovni vrsti manjka urnik podatkov.',
      'Error while fetching M&T analysis':
        'Napaka pri pridobivanju M&T analize.',
      'Error while fetching chart data': 'Napaka pri pridobivanju podatkov.',
      'Error: Missing template': 'Napaka: manjka template',
      Evening: 'Zvečer',
      'Event type': 'Vrsta dogodka',
      Every: 'Vsak',
      'Every day of the week': 'Vsak dan v tednu',
      'Every month': 'Vsak mesec',
      'Exact Interval': 'Točen interval',
      'Exact times': 'Točno ob',
      'Exception Handler': 'Nadomestna vrednost v primeru napake',
      'Exception handler': 'Nadomestna vrednost v primeru napake',
      'Expected format dd.mm.yyyy': 'Pričakovani format je dd.mm.yyyy',
      'Expected format hh:mm:ss': 'Pričakovani format je hh:mm:ss',
      'Expected format: {{ msg }}.': 'Pričakovani format: {{ msg }}.',
      Expression: 'Izraz',
      'Expression *': 'Izraz *',
      'Expression Preview': 'Predogled izraza',
      'Expression is loading, this may take a while.':
        'Predogled izraza se nalaga. Prosimo počakajte trenutek.',
      'Expression preview': 'Predogled izraza',
      'Expression:': 'Izraz:',
      Expressions: 'Izrazi',
      External: 'Zunanji',
      'External  datasource': 'Zunanji podatkovni vir',
      'External Code': 'Zunanja šifra',
      'External Datasource': 'Zunanji podatkovni vir',
      'External Datasource Type': 'Tip zunanjega podatkovnega vira',
      'External Datasource Version': 'Verzija zunanjega podatkovnega vira',
      'External Datasources': 'Zunanji podatkovni viri',
      'External Reader flow': 'Zajem iz zunanjega vira',
      'External code': 'Zunanja šifra',
      'External datasource': 'Zunanji podatkovni vir',
      'External datasource Type': 'Tip zunanjega podatkovnega vira',
      'External datasource credentials':
        'Poverilnice za zunanji podatkovni vir',
      'External datasource job': 'Opravilo zunanjega podatkovnega vira',
      'External properties': 'Zunanje lastnosti',
      'External reader flow': 'Zajem iz zunanjega vira',
      'External writer flow': 'Zapisovalna časovna vrsta',
      'Extrapolated count': 'Število ekstrapoliranih vrednosti',
      Extrapolation: 'Ekstrapolacija',
      'Extrapolation Functions': 'Metoda ekstrapolacije',
      FALSE: 'FALSE',
      FEB: 'FEB',
      'Factor K': 'Faktor K',
      'Factor N': 'Faktor N',
      'Failed to fetch current user':
        'Pridobivanje trenutnega uporabnika ni bilo uspešno',
      'Failed to fetch scheduler': 'Napaka pri pridobivanju urnika',
      'Failed to fetch tariffs': 'Napaka pri pridobivanju tarif',
      'Failed to fetch time series types':
        'Napaka pri pridobivanju tipov časovne vrste',
      False: 'False',
      Feb: 'Feb',
      February: 'Februar',
      'Field name': 'Ime polja',
      'Field type': 'Tip polja',
      File: 'Datoteke',
      'File Uploader': 'Naloži datoteko',
      'File connection configuration': 'Konfiguracija povezave datoteke',
      'File type': 'Tip datoteke',
      'File was successfully deleted': 'Datoteka je bila uspešno izbrisana',
      'File was successfully updated': 'Datoteka je bila uspešno posodobljena',
      Files: 'Datoteke',
      'Files were successfully uploaded': 'Datoteka je bila uspešno naložena',
      'Fill in at least one field of email, notification or sms content.':
        'Potrebno je izpolniti vsaj eno izmed polj: email, obvestilo ali vsebino SMS sporočila.',
      Filter: 'Filter',
      'Filter enabled': 'Filter je omogočen',
      'Filter entities by name': 'Išči po imenu entitete',
      'Filter type': 'Tip filtra',
      'Filter type should be selected': 'Tip filtra mora biti izbran',
      'Filter type:': 'Tip filtra:',
      Filters: 'Filtri',
      'Final Counter read out': 'Končno stanje števca',
      'Final value': 'Končno stanje',
      'Finalize invoice': 'Zaključi račun',
      First: 'Prvi',
      Fixed: 'Fiksni',
      'Fixed Filter': 'Fiksni filter',
      Float: 'Število v obliki zapisa s plavajočo vejico',
      'Flow Type': 'Tip konfiguracije',
      Font: 'Pisava',
      'Font color': 'Barva pisave',
      Forbidden: 'Prepovedano',
      'Forecast temperature': 'Napoved temperature',
      Format: 'Format',
      'Format mapping rule is required.':
        'Preslikovalno pravilo za mapiranje podatkovnih tipov  je obvezno',
      'Format mapping rules': 'Preslikovalno pravilo za mapiranje formatov',
      'Formula input': 'Vnos formule',
      Fri: 'Pet',
      Friday: 'Petek',
      'Friendly Name': 'Prijazno ime',
      'Friendly name': 'Prijazno ime',
      From: 'Od',
      'From date can not be prior to start date of asset availability ()':
        'Datum od ne sme biti pred začetkom razpoložljivosti sredstva',
      'From date must be prior valid to date':
        'Datum od mora biti pred datumom do',
      'From/to range should be within date limits of external reader flow configuration':
        'Datum od/do mora biti znotraj konfiguracije Zajem iz zunanjega vira',
      'Function to get connection config is missing':
        'Manjka funkcija za pridobivanje konfiguracije povezave',
      Functions: 'Funkcije',
      Future: 'Napoved',
      'Future input': 'Vnos v prihodnosti',
      'Future interval': 'Interval v prihodnosti',
      'Future interval in days': 'Interval za naslednje dneve',
      'Future interval in hours': 'Interval za naslednje ure',
      'Future interval in minutes': 'Interval za naslednje minute',
      'Future interval in seconds': 'Interval za naslednje sekunde',
      GIS: 'GIS',
      'GIS Map': 'GIS zemljevid',
      'GIS Maps': 'GIS zemljevidi',
      General: 'Splošno',
      Geolocation: 'Geolokacija',
      'Geolocation X': 'Geolokacija X',
      'Geolocation Y': 'Geolokacija Y',
      'Get total items': 'Pridobi število vseh postavk',
      'Gis Map': 'GIS zemljevid',
      'Gis map': 'GIS zemljevid',
      'Gis user groups were successfully updated.':
        'GIS uporabniška skupina je bila uspešno posodobljena.',
      'Gis user was successfully created.':
        'GIS uporabnik je bil uspešno ustvarjen.',
      Good: 'Dobro',
      'Greater than': 'Večji',
      'Greater than or equal': 'Večje ali enako',
      'Greater than or equals': 'Večji ali enak',
      Group: 'Skupina',
      'Group Parent': 'Nadrejena skupina',
      'Group Privilege Management': 'Upravljanje pravic skupine',
      'Group access': 'Dostop uporabniških skupin',
      'Group by:': 'Grupirano po:',
      'Group entity': 'Grupacija',
      Groups: 'Skupine',
      'Guaranteed Price': 'Zagotovljena cena',
      'Has address': 'Ima naslov',
      'Has usage': 'Ima rabo',
      'Header 1': 'Header 1',
      'Header 2': 'Header 2',
      'Heartbeat history': 'Zgodovina srčnega utripa',
      Hide: 'Skrij',
      'Hide chart': 'Skrij graf',
      'Hide energy values': 'Skrij energijska števila',
      'Hide history': 'Skrij zgodovino',
      'Hide seen notifications': 'Skrij videna obvestila',
      'Highcharts initialization failed.':
        'Inicializacija highcharts ni uspela.',
      'History data job': 'Zahtevek za zgodovinske podatke',
      'History flow': 'Zgodovinski zajem',
      'History flow mapping rule': 'Preslikovalno pravilo za zgodovinski zajem',
      'History flow tag': 'Značka za zgodovinski zajem',
      'History flow tango agent connection configuration':
        'Konfiguracija za zgodovinski zajem konektorja Tango Agenta',
      'History job request successfully sent':
        'Zahtevek za zgodovinske podatke je bil uspešno poslan.',
      'History time count': 'Število vrednosti zajetih zgodovinsko',
      Hour: 'Ura',
      'Hour format is not right': 'Urni format ni pravilen.',
      'Hour interval quantity missing.': 'Vrednost urnega intervala manjka.',
      Hours: 'Ure',
      'House number': 'Hišna številka',
      'Hyperbolic cosine function.': 'Hiperbolični kosinus',
      'Hyperbolic sine function.': 'Hiperbolični sinus',
      'Hyperbolic tangent function.': 'Hiperbolični tangens',
      'I have safely stored the secret': 'Secret sem varno shranil',
      IF: 'IF',
      Icon: 'Ikona',
      Icons: 'Ikone',
      'If the format of received value is not appropriate, specify the appropriate format to remap it using a predefined function. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'Če format zajete vrednosti ni pravilen, definirajte ustrezno pravilo za preslikavo podatka. Ustrezno označite, če mora tak podatek sprožiti napako, alarm ali pa ga je potrebno ročno preveriti.',
      'If you think this is an error please contact your administrator.':
        'Če mislite, da gre za napako, prosimo kontaktirajte administratorja.',
      'Image was successfully removed.': 'Slika je bila uspešno odstranjena.',
      'Image was successfully uploaded.': 'Slika je bila uspešno naložena.',
      Images: 'Slike',
      'Implicit operation {{functionName2}} is not allowed.':
        'Implicitna operacija {{functionName2}} ni dovoljena.',
      'Implicit operation {{functionName}} is not allowed.':
        'Implicitna operacija {{functionName}} ni dovoljena.',
      'Import CSV': 'Uvozi CSV',
      'In order to add items, you have to select them using autocomplete or dialog selector':
        'Postavke izberete s pomočjo gradnika autocomplete ali preko lupice za iskanje',
      'In range': 'V intervalu',
      'Inactive Assets': 'Neaktivna sredstva',
      'Inactive Counter Types': 'Neaktivni tipi merilnikov',
      'Inactive Measuring Point Allocators':
        'Neaktivni delilniki za merilna mesta',
      'Inactive Time Series': [
        'Neaktivna časovna vrsta',
        'Neaktivni časovni vrsti',
        'Neaktivne časovne vrste',
        'Neaktivnih časovnih vrst'
      ],
      'Inactive assets': 'Neaktivna sredstva',
      'Inactive business partners': 'Neaktivni poslovni partnerji',
      'Inactive cost centres': 'Neaktivna stroškovna mesta',
      'Inactive locations': 'Neaktivne lokacije',
      'Inactive main cost centes': 'Neaktivna glavna stroškovna mesta',
      'Inactive measuring points': 'Neaktivna merilna mesta',
      'Inactive measuringpoints': 'Neaktivna merilna mesta',
      'Inactive owners': 'Neaktivni lastniki',
      'Include end': 'Vključi konec',
      'Include first month': 'Vključi prvi mesec',
      'Include first year': 'Vključi prvo leto',
      'Include last month': 'Vključi zadnji mesec',
      'Include last year': 'Vključi zadnje leto',
      'Include start': 'Vključi začetek',
      'Include value on the edge': 'Vključi vrednost na robu',
      'Inclusion type': 'Tip vključitve',
      'Incompatible data type': 'Nezdružljiv tip podatkov',
      'Indicates order of execution.': 'Kaže vrstni red izvajanja.',
      'Indirect Access': 'Posreden dostop',
      'Indirect access items were successfully added.':
        'Postavke s posrednim dostopom so bile uspešno dodane.',
      'Ingestion type': 'Vrsta zajema',
      'Initial batch interval': 'Začetni interval pri paketnem zajemu',
      'Initial value': 'Začetno stanje',
      'Input Data Type': 'Tip vhodnih podatkov',
      'Input Source': 'Izvor podatkov',
      'Input Time Series': [
        'Vhodna časovna vrsta',
        'Vhodni časovni vrsti',
        'Vhodne časovne vrste',
        'Vhodnih časovnih vrst'
      ],
      'Input data type': 'Tip vhodnih podatkov',
      'Input expression': 'Vhodni izraz',
      'Input source': 'Izvor podatkov',
      'Input time series': [
        'Vhodna časovna vrsta',
        'Vhodna časovna vrsta',
        'Vhodne časovne vrste',
        'Vhodnih časovnih vrst'
      ],
      'Input time series configurations': 'Aktivna konfiguracija časovne vrste',
      'Input time series for X axis': 'Vhodna časovna vrsta za X os',
      'Input time series for Y axis': 'Vhodna časovna vrsta za Y os',
      'Input value': 'Vhodna vrednost',
      'Input value represents a value received. Select a function to transform it to the desired output. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'Vhodna vrednost predstavlja prejeto vrednost. Ustrezno označite, če mora tak podatek sprožiti napako, alarm ali pa ga je potrebno ročno preveriti.',
      'Input will be saved as regular expression...':
        'Vnos bo shranjen kot regularni izraz.',
      'Insert image': 'Dodaj sliko',
      'Installation Number': 'Številka namestitve',
      'Installation number': 'Številka namestitve',
      Integer: 'Celo število',
      Integration: 'Integracija',
      'Integration Methods': 'Metoda integracije',
      'Integration timestamp':
        'Čas pošiljanja podatka na zunanji podatkovni vir',
      Integrity: 'Verodostojnost',
      'Integrity value': 'Verodostojnost',
      Internal: 'Notranji',
      'Internal Order': 'Interni nalog',
      'Interpolated count': 'Število interpoliranih vrednosti',
      Interpolation: 'Interpolacija',
      'Interpolation Functions': 'Metoda interpolacije',
      'Interpretation type': 'Vrsta interpretacije',
      Interval: 'Interval',
      'Interval from': 'Od',
      'Interval is already taken': 'Interval je že uporabljen',
      'Interval quantity missing.': 'Manjka vrednost v polju intervala.',
      'Interval to': 'Do',
      'Interval unit missing.': 'Manjka enota intervala.',
      'Invalid Data': 'Neveljavni podatki',
      'Invalid configuration.': 'Neveljavna nastavitev.',
      'Invalid date': 'Neveljaven datum',
      'Invalid filter configuration': 'Neveljavna nastavitev filtra',
      'Invalid formatting at position {{x}}.':
        'Napačno formatiranje cron izraza na {{x}}.',
      'Invalid number.': 'Neveljavno število.',
      'Invalid phone number. (+386 12 312 312)':
        'Neveljavna telefonska številka. (+ 386 12 312 312)',
      'Invalid time': 'Neveljaven čas',
      'Invalid value': 'Neveljavna vrednost',
      'Inverse cosine (arccosine) function.': 'Arkus kosinus',
      'Inverse sine (arcsine) function.': 'Arkus sinus',
      'Inverse tangent (arctan) function.': 'Arkus tangens',
      Invoice: 'Račun',
      'Invoice Detail': 'Podrobnosti računa',
      'Invoice Details': 'Podrobnosti računa',
      'Invoice Issuer': 'Izdajatelj računa',
      'Invoice Status': 'Status računa',
      'Invoice Type': 'Tip računa',
      'Invoice detail values': 'Postavke',
      'Invoice detail values successfully saved!':
        'Postavke računa so bile uspešno shranjene.',
      'Invoice for {{month}} is closed. Reopen invoice in order to edit values':
        'Račun za {{month}} je že zaprt. Za urejanje vrednosti ponovno odprite račun.',
      'Invoice has been successfully finalized':
        'Račun je bil uspešno zaključen.',
      'Invoice has been successfully opened': 'Račun je bil uspešno odprt',
      'Invoice has no valid price list detail':
        'Račun nima veljavne podrobnosti cenika.',
      'Invoice is not closed': 'Račun ni zaprt',
      'Invoice issuer': 'Izdajatelj računa',
      'Invoice issuer:': 'Izdajatelj računa:',
      'Invoice number': 'Številka računa',
      'Invoice overview': 'Pregled računov',
      'Invoice status': 'Status računa',
      'Invoice type': 'Tip računa',
      'Invoice validity': 'Veljavnost računa',
      Invoices: 'Računi',
      'Invoices Overview': 'Pregled računov',
      'Invoices overview': 'Pregled računov',
      IoT: 'IoT',
      'Irregular sampling type is not compatible with selected trigger type':
        'Način proženja ni primeren za iregularno časovno vrsto.',
      'Is standard': 'Standarden',
      Issuer: 'Izdajatelj',
      Italic: 'Ležeče',
      'Item group': 'Skupina postavk',
      'Item has no connected entities for display.':
        'Element nima povezanih entitet.',
      'Item was successfully created': 'Zapis je bil uspešno ustvarjen',
      'Item was successfully duplicated': 'Zapis je bil uspešno podvojen',
      'Item was successfully removed': 'Zapis je bil uspešno odstranjen',
      'Item was successfully removed.': 'Postavka je bila uspešno odstranjena.',
      'Item was successfully updated': 'Zapis je bil uspešno posodobljen',
      Items: 'Postavke',
      JAN: 'JAN',
      JUL: 'JUL',
      JUN: 'JUN',
      Jan: 'Jan',
      January: 'Januar',
      Job: 'Opravila',
      'Job Results': 'Rezultati opravila',
      'Job name': 'Ime opravila',
      'Job types': 'Tipi opravila',
      Jobs: 'Opravila',
      Jul: 'Jul',
      July: 'Julij',
      Jun: 'Jun',
      June: 'Junij',
      K: 'K',
      Key: 'Ključ',
      'Key was copied to clipboard.': 'Ključ je bil kopiran v odložišče.',
      Kind: 'Način',
      Language: 'Jezik',
      Last: 'Zadnjih',
      'Last 3 months': 'Zadnji 3 meseci',
      'Last N month': 'Zadnjih N mesecev',
      'Last N months': 'Zadnjih N mesecev',
      'Last N years': 'Zadnjih N let',
      'Last day': 'Zadnji dan',
      'Last half a year': 'Zadnje pol leta',
      'Last heartbeat': 'Zadnji srčni utrip',
      'Last hour': 'Zadnja ura',
      'Last measurement time': 'Časovna značka zadnje vrednosti časovne vrste',
      'Last month': 'Zadnji mesec',
      'Last value': 'Zadnja vrednost',
      'Last value at': 'Zadnja vrednost ob',
      'Last week': 'Zadnji teden',
      'Last year': 'Zadnje leto',
      Lastname: 'Priimek',
      'Layout was successfully removed':
        'Razporeditev je bila uspešno odstranjena.',
      'Less than': 'Manjši',
      'Less than or equal': 'Manjše ali enako',
      'Less than or equals': 'Manjši ali enak',
      Limit: 'Meja',
      'Limit history': 'Omejitev zgodovine',
      'Limit reached': 'Dosežena limita',
      Link: 'Povezava',
      'Link to dashboard': 'Povezava na drugo nadzorno ploščo',
      'Linked Dashboard': 'Povezava na drugo nadzorno ploščo',
      'Links to dashboard': 'Povezava na drugo nadzorno ploščo',
      List: 'Seznam',
      'Live Flow': 'Trenutni zajem',
      'Live flow': 'Trenutni zajem',
      'Live flow mapping rule': 'Preslikovalno pravilo za trenutni zajem',
      'Live flow read type': 'Vrsta branja za trenutni zajem',
      'Live flow tag': 'Značka za trenutni zajem',
      'Load more': 'Naloži več',
      'Load more <span ng-if="vm.moreLeft">({{vm.moreLeft}})</span>':
        'Naloži več <span ng-if="vm.moreLeft">({{vm.moreLeft}})</span>',
      Loading: 'Nalaganje',
      'Loading ...': 'Nalaganje ...',
      'Loading M&T analysis...': 'Nalaganje M&T analize ...',
      'Loading additional data ...': 'Nalaganje dodatnih podatkov ...',
      'Loading location ...': 'Nalaganje lokacije ...',
      'Loading registers ...': 'Nalaganje registrov ...',
      'Loading values': 'Nalaganje vrednosti',
      Location: 'Lokacija',
      'Location Classification': 'Klasifikacija lokacije',
      'Location Classifications': 'Klasifikacije lokacije',
      'Location Cost Centres': 'Stroškovno mesto lokacije',
      'Location Country': 'Država lokacije',
      'Location Owner': 'Lastnik lokacije',
      'Location Ownership': 'Lastništvo lokacije',
      'Location Status': 'Status lokacije',
      'Location Statuses': 'Statusi lokacije',
      'Location Tree View': 'Drevesni pogled lokacije',
      'Location Type': 'Tip lokacije',
      'Location Types': 'Tipi lokacije',
      'Location classification': 'Klasifikacija lokacije',
      'Location external ID': 'Zunanji ID lokacije',
      'Location has no subsidiary locations':
        'Lokacija nima podrejenih lokacij',
      'Location link': 'Povezava do lokacije',
      'Location links': 'Povezave do lokacij',
      'Location map': 'Zemljevid lokacij',
      'Location ownership': 'Lastništvo lokacije',
      'Location status': 'Status lokacije',
      'Location type': 'Tip lokacije',
      'Location type attributes': 'Atributi tipa lokacije',
      Locations: 'Lokacije',
      'Locations Overview': 'Pregled lokacij',
      Lock: 'Ne dovoli urejati',
      'Logical Operations': 'Logični operatorji',
      Logout: 'Odjava',
      Logs: 'Logi',
      'Looks like you have no main dashboard.':
        'Nimate še glavne nadzorne plošče',
      'Lower Limit %': 'Spodnja meja v %',
      'Lower Limit Alarm': 'Alarm za spodnjo mejo',
      'Lower Limits': 'Spodnje meje',
      'M&T analysis flow': 'M&T analiza',
      MAJ: 'MAJ',
      MAR: 'MAR',
      'MT timeSeries is missing date for calculation from value':
        'Na M&T analizi manjka datum od za izračun vrednosti',
      'MT timeSeries is missing date for calculation to value':
        'Na M&T analizi manjka datum do za izračun vrednosti',
      Main: 'Glavno',
      'Main Cost Centre': 'Glavno stroškovno mesto',
      'Main cost centre': 'Glavno stroškovno mesto',
      'Main dashboard': 'Glavna nadzorna plošča',
      'Maintenance Flow': 'Vzdrževanje časovne vrste',
      'Maintenance flow': 'Vzdrževanje časovne vrste',
      'Maintenance flow type': 'Tip vzdrževanja časovne vrste',
      Maj: 'Maj',
      'Make sure asset type is selected.':
        'Prepričajte se, da je tip sredstva izbran. ',
      'Make sure location type is selected.':
        'Prepričajte se, da je tip lokacije izbran.',
      'Make sure measuringpoint type is selected.':
        'Prepričajte se, da je tip merilnega mesta izbran.',
      'Make sure time series type is selected.':
        'Prepričajte se, da je tip časovne vrste izbran.',
      'Manual Input': 'Napredni ročni vnos',
      'Manual Input -': 'Napredni ročni vnos -',
      'Manual input': 'Napredni ročni vnos',
      'Manual input form has no time series':
        'Forma za napredni ročni vnos nima časovne vrste.',
      'Manual intervention': 'Ročni pregled',
      'Manual reading cannot be entered because the invoice is already closed for the current billing period {{month}}.':
        'Vnos ročnega odčitka ni mogoč, ker je za to obračunsko obdobje račun že zaključen.',
      'Manual reading cannot be entered because the reading date cannot be greater than today\'s date - {{date | luxonDate:\'short\'}}':
        'Vnos ročnega odčitka ni mogoč, ker datum odčitka ne sme biti večji od današnjega datuma.',
      'Manual reading cannot be entered because the value of the entered reading is greater than the next reading.':
        'Vnos ročnega odčitka ni mogoč, ker je vrednost vnesenega odčitka večja od naslednjega že odčitanega odčitka. ',
      'Manual reading cannot be entered because the value of the entered reading is less than the previous reading.':
        'Vnos ročnega odčitka ni mogoč, ker je vrednost vnesenega odčitka manjša od predhodnega odčitka.',
      'Manual reading cannot be entered because there is already a closed invoice for {{month}} {{year}}.':
        'Vnos ročnega odčitka ni mogoč, ker obstaja že zaključen račun za obdobje {{mesec.leto}}.',
      'Manual reading cannot be entered because there is no main billing measuring point at the current measuring point.':
        'Vnos ročnega odčitka ni mogoč, ker ni glavnega obračunskega merilnega mesta na trenutnem merilnem mestu.',
      'Manual reading cannot be entered since the difference between the consumption date and reading date should not exceed 1 month.':
        'Vnos ročnega odčitka ni mogoč, ker razlika med datumom porabe in datumom odčitka ne sme biti večja od 1 mesca.',
      'Manual readings': 'Ročni odčitki',
      'Manual readings cannot be saved.': 'Ročnih odčitkov ni mogoče shraniti.',
      'Manual readings for {{measuringpointName}}':
        'Ročni odčitki za {{measuringpointName}}',
      'Mapping Function': 'Funkcija preslikave',
      'Mapping Rule': 'Preslikovalno pravilo',
      'Mapping Rule Item': 'Preslikovalno pravilo',
      'Mapping Rules': 'Preslikovalna pravila',
      'Mapping Type': 'Vrsta ',
      'Mapping functions': 'Funkcija preslikave',
      'Mapping rule': 'Preslikovalno pravilo',
      Maps: 'Zemljevidi',
      Mar: 'Mar',
      March: 'Marec',
      'Master Invoice': 'Račun',
      'Master Price List': 'Cenik',
      'Master invoice': 'Zbirni račun',
      'Match is required': 'Ujemanje je obvezno',
      'Math expression must contain at least one valid variable name.':
        'Matematični izraz mora vsebovati vsaj eno ime veljavne spremenljivke.',
      Max: 'Maks',
      'Max input length is {{vm.configuration.maxLength}}.':
        'Največja dovoljena dolžina je {{vm.configuration.maxLength}}.',
      'Max number of items is {{vm.configuration.maxItems}}.':
        'Največja vrednost je {{vm.configuration.max}}.',
      'Max timeout': 'Alarm ob poteku časovne omejitve',
      'Max value is {{configuration.max}}.':
        'Največja vrednost je {{configuration.max}}.',
      'Max value is {{historyItem.formConfig.max}}.':
        'Največja vrednost je {{historyItem.formConfig.max}}.',
      'Max value is {{vm.configuration.max}}.':
        'Največja vrednost je {{vm.configuration.max}}.',
      'Max value must be lower than {{vm.configuration.max}}.':
        'Največja vrednost mora biti manjša kot {{vm.configuration.max}}.',
      'Max year value is {{vm.maxYear}}.':
        'Največja dovoljena vrednost je {{data.max}}.',
      'Maximum Capacity': 'Priključna moč',
      'Maximum Seconds since last Heartbeat':
        'Išči po največjem številu sekund od zadnjega srčnega utripa',
      'Maximum allowed value': 'Največja dovoljena vrednost',
      'Maximum capacity': 'Priključna moč',
      'Maximum number of chips reached.':
        'Maksimalna količina značk je dosežena.',
      'Maximum value': 'Največja vrednost',
      May: 'Maj',
      'Measurement Unit': 'Merska enota',
      'Measurement Units': 'Merske enote',
      'Measurement unit': 'Merska enota',
      'Measurement units': 'Merske enote',
      'Measuring Point': 'Merilno mesto',
      'Measuring Point Allocators': 'Delilniki za merilna mesta',
      'Measuring Point Class': 'Razred merilnega mesta',
      'Measuring Point Kind': 'Način merilnega mesta',
      'Measuring Point Mode': 'Način merilnega mesta',
      'Measuring Point Type': 'Tip merilnega mesta',
      'Measuring Point Types': 'Tipi merilnega mesta',
      'Measuring Points': 'Merilna mesta',
      'Measuring point': 'Merilno mesto',
      'Measuring point class': 'Razred merilnega mesta',
      'Measuring point has no child measuring points.':
        'Merilno mesto nima podrejenih merilnih mest.',
      'Measuring point kind': 'Način merilnega mesta',
      'Measuring point type': 'Tip merilnega mesta',
      'Measuring point type attributes': 'Atributi tipa merilnega mesta',
      'Measuring points': 'Merilna mesta',
      Measuringpoint: 'Merilno mesto',
      'Measuringpoint Class': 'Razred merilnega mesta',
      'Measuringpoint has no counter types':
        'Merilno mesto nima tipov merilnikov.',
      Member: 'Član',
      Members: 'Člani',
      'Membership updated.': 'Članstvo je bilo uspešno posodobljeno.',
      'Meta data': 'Meta podatki',
      Metrics: 'Metrike',
      Min: 'Min',
      'Min 1, Max 10000': 'Najmanj 1, največ 10000',
      'Min input length is {{vm.configuration.minLength}}.':
        'Najmanjša vrednost je {{vm.configuration.minLength}}.',
      'Min number of items is {{vm.configuration.minItems}}.':
        'Najmanjša vrednost je {{vm.configuration.min}}.',
      'Min value is {{configuration.min}}.':
        'Najmanjša vrednost je {{configuration.min}}.',
      'Min value is {{historyItem.formConfig.min}}.':
        'Najmanjša vrednost je {{historyItem.formConfig.min}}.',
      'Min value is {{vm.configuration.min}}.':
        'Najmanjša vrednost je {{vm.configuration.min}}.',
      'Min value must be higher than {{vm.configuration.min}}.':
        'Najmanjša vrednost mora biti višja kot {{vm.configuration.min}}.',
      'Min year value is {{vm.minYear}}.':
        'Najmanjša dovoljena vrednost je {{data.min}}.',
      'Minimum allowed value': 'Najmanjša dovoljena vrednost',
      'Minimum value': 'Najmanjša vrednost',
      'Minute interval quantity missing.':
        'Manjka vrednost minutnega intervala. ',
      Minutes: 'Minute',
      'Missing inputTimeSeriesForXAxis': 'Manjka vhodna časovna vrsta za X os',
      'Missing inputTimeSeriesForYAxis': 'Manjka vhodna časovna vrsta za Y os',
      'Missing item content.': 'Manjka vsebina.',
      'Missing validity from date.': 'Manjka obdobje veljavnosti od.',
      'Missing validity to date.': 'Manjka obdobje veljavnosti do.',
      'Missing variable name.': 'Manjka ime spremenljivke.',
      Model: 'Model',
      'Model number': 'Številka modela',
      Modules: 'Moduli',
      Modulo: 'Modulo',
      Mon: 'Pon',
      Monday: 'Ponedeljek',
      Monitoring: 'Nadziranje',
      Month: 'Mesec',
      'Month interval quantity missing.':
        'Manjka vrednost mesečnega intervala.',
      Monthly: 'Mesečno',
      Months: 'Meseci',
      'More actions': 'Več akcij',
      Morning: 'Zjutraj',
      'Move time series from preview to schedule classifications':
        'Premaknite časovno vrsto iz predogleda v klasifikacijo urnika',
      'Mt analysis flow': 'MT analiza',
      Multiplication: 'Množenje',
      'Multiplies neighbouring expressions': 'Pomnoži sosednje izraze.',
      'Must be after previous date ""': 'Mora biti večje kot pretekli datum “”',
      'Must be before or todays date':
        'Mora biti manjše ali enako kot današnji datum',
      'Must be before todays date': 'Mora biti pred današnjim datumom',
      'Must be greater than X values from':
        'Vrednost mora biti večja kot vrednost X od',
      'Must be greater than minimum value':
        'Mora biti večje kot najmanjša vrednost',
      'Must be less than X values to':
        'Vrednost mora biti manjša kot vrednost X do',
      'Must be less than maximum value': 'Mora biti manj kot najvišja vrednost',
      'Must be resolved by': 'Mora biti rešeno do',
      N: 'N',
      'N times': 'N krat',
      NO: 'NE',
      NOV: 'NOV',
      'NOx per kWh': 'NOx na kWh',
      Name: 'Naziv',
      'Name or Description': 'Naziv ali opis',
      'Name or last name': 'Ime ali priimek',
      'Name:': 'Naziv:',
      'National Holiday': 'Državni praznik',
      'National Holidays': 'Državni prazniki',
      'National holidays': 'Državni prazniki',
      Navigation: 'Navigacija',
      'New Action Status': 'Nov status ukrepa',
      'New Aggregation': 'Nova agregacija',
      'New Alarm Definition': 'Nova definicija alarma',
      'New Alarm Type': 'Nov tip alarma',
      'New Analysis': 'Nova analiza',
      'New Asset': 'Novo sredstvo',
      'New Asset Brand': 'Nova blagovna znamka sredstva',
      'New Asset Group': 'Nova skupina sredstva',
      'New Asset Model': 'Nov model sredstva',
      'New Asset Status': 'Nov status sredstva',
      'New Asset Type': 'Nov tip sredstva',
      'New Billing Kind': 'Nov način obračuna',
      'New Billing Type': 'Nov tip obračuna',
      'New Business Partner': 'Nov poslovni partner',
      'New Business partner': 'Nov poslovni partner',
      'New Calculation': 'Nov izračun',
      'New Company Structure Node': 'Novo vozlišče strukture podjetja',
      'New Cost Centre': 'Novo stroškovno mesto',
      'New Counter Type': 'Nov tip merilnika',
      'New Dashboard': 'Nova nadzorna plošča',
      'New Database': 'Nova baza podatkov',
      'New Dataset': 'Nov nabor podatkov',
      'New Date': 'Nov datum',
      'New Document Classification': 'Nova klasifikacija dokumentacije',
      'New Domain': 'Nova domena',
      'New Domain Attribute': 'Nov domenski atribut',
      'New Domain Collection': 'Nova domenska zbirka',
      'New Domain Field': 'Novo domensko polje',
      'New Energy Management Item': 'Nova postavka za energetsko upravljanje',
      'New Energy Source Type': 'Nov tip energenta',
      'New Expression': 'Nov izraz',
      'New External Datasource': 'Nov zunanji podatkovni vir',
      'New File': 'Nova datoteka',
      'New Group': 'Nova uporabniška skupina',
      'New Invoice': 'Nov račun',
      'New Invoice Detail': 'Nov podrobni račun',
      'New Job': 'Novo opravilo',
      'New Location': 'Nova lokacija',
      'New Location Classification': 'Nova klasifikacija lokacije',
      'New Location Status': 'Nov status lokacije',
      'New Location Type': 'Nov tip lokacije',
      'New Mapping Rule': 'Novo preslikovalno pravilo',
      'New Measurement Unit': 'Nova merska enota',
      'New Measuring Point': 'Novo merilno mesto',
      'New Measuring Point Type': 'Nov tip merilnega mesta',
      'New National Holiday': 'Nov državni praznik',
      'New Notification': 'Novo obvestilo',
      'New OPC': 'Nov OPC',
      'New Ownership': 'Novo lastništvo',
      'New Physical Quantity': 'Nova fizikalna količina',
      'New Playlist': 'Nov seznam predvajanja',
      'New Price List': 'Nov cenik',
      'New Price List Item': 'Nova postavka cenika',
      'New Profit Centre': 'Nov profitni center',
      'New Project': 'Nov projekt',
      'New Project Field': 'Novo področje projekta',
      'New Project Type': 'Nov tip projekta',
      'New Rated Power': 'Nova nazivna moč',
      'New Report': 'Novo poročilo',
      'New Response Interval': 'Nov odzivni interval',
      'New Rule': 'Novo pravilo',
      'New SOP': 'Nov SOP',
      'New Schedule': 'Nov urnik',
      'New Scheduler': 'Nov urnik',
      'New Severity': 'Nova stopnja pomembnosti',
      'New Step': 'Nov korak',
      'New Tango Agent Connection Config':
        'Nova konfiguracija konektorja Tango Agenta',
      'New Tango Agent Connection Configuration':
        'Nova konfiguracija konektorja Tango Agenta',
      'New Tariff': 'Nova tarifa',
      'New Task': 'Nova naloga',
      'New Time Interval Filter': 'Nov filter časovnega intervala',
      'New Time Series': 'Nova časovna vrsta',
      'New Time Series Group Type': 'Novi tip skupine časovnih vrst',
      'New Time Series Type': 'Nov tip časovne vrste',
      'New Time Series Value': 'Nova vrednost časovne vrste',
      'New Web Service': 'Nova spletna storitev',
      'New action': 'Nov ukrep',
      'New aggregation group': 'Nova agregacijska skupina',
      'New alarm definition': 'Nova definicija alarma',
      'New analysis': 'Nova analiza',
      'New asset': 'Novo sredstvo',
      'New asset type': 'Nov tip sredstva',
      'New assignee': 'Dodeljena oseba',
      'New calculation': 'Nov izračun',
      'New column': 'Nov stolpec',
      'New dashboard': 'Nova nadzorna plošča',
      'New dashboard playlist': 'Nov seznam predvajanja',
      'New dataset': 'Nov nabor podatkov',
      'New dynamic attribute': 'Nov dinamični atribut',
      'New expression': 'Nov izraz',
      'New group': 'Nova uporabniška skupina',
      'New invoice detail value': 'Nova vrednost postavke računa',
      'New item': 'Nova vrednost',
      'New location type': 'Nov tip lokacije',
      'New manual input': 'Nov napredni ročni vnos',
      'New mapping rule': 'Novo preslikovalno pravilo',
      'New master invoice': 'Nov račun',
      'New measuring point': 'Novo merilno mesto',
      'New measuring point type': 'Nov tip merilnega mesta',
      'New notification': 'Novo obvestilo',
      'New password': 'Novo geslo',
      'New price list item': 'Nova postavka cenika',
      'New price list item group': 'Nova skupina postavk cenika',
      'New report': 'Novo poročilo',
      'New standard operating procedure':
        'Nova standardna operativna procedura',
      'New step': 'Nov korak',
      'New task': 'Nova naloga',
      'New time interval filter': 'Nov filter časovnega intervala',
      'New time series type': 'Nov tip časovne vrste',
      'New value': 'Nova vrednost',
      Next: 'Naslednjih',
      'Next date': 'Naslednji datum',
      'Next step': 'Naslednji korak',
      Night: 'Ponoči',
      No: 'Ne',
      'No SOP are connected to this definition.':
        'Na to definicijo ni povezan noben SOP.',
      'No address and geolocation found.':
        'Naslova in geolokacije ni bilo mogoče najti.',
      'No address found at this location. Set it manually.':
        'Za to lokacijo ni bil najden noben naslov. Nastavite ga ročno.',
      'No alarms': 'Brez alarmov',
      'No attributes yet.': 'Ni atributov.',
      'No connected attributes.': 'Povezani atributi ne obstajajo.',
      'No content available': 'Ni razpoložljive vsebine',
      'No data to display.': 'Ni podatkov za prikaz.',
      'No date': 'Ni datuma',
      'No elements yet.': 'Brez elementov.',
      'No entity found': 'Zapisa ni bilo mogoče najti.',
      'No gis token found': 'GIS token ni bil najden.',
      'No heartbeat yet': 'Srčni utrip še ni zaznan.',
      'No images found for this asset.':
        'Za to sredstvo ni bila najdena nobena slika.',
      'No images found for this location.':
        'Za to lokacijo ni bila najdena nobena slika.',
      'No item found.': 'Zapisa ni bilo mogoče najti.',
      'No item is currently selected': 'Trenutno ni izbrana nobena postavka.',
      'No items found': 'Zapisov ni bilo mogoče najti.',
      'No items found.': 'Zapisov ni bilo mogoče najti.',
      'No items were changed to update':
        'Za posodobitev morate spremeniti vsaj eno postavko.',
      'No location is currently selected':
        'Trenutno ni izbrana nobena lokacija.',
      'No modules found.': 'Noben modul ni bil najden.',
      'No options available': 'Ni razpoložljivih možnosti',
      'No options to select from': 'Za izbiranje ni na voljo nobena možnost',
      'No path found.': 'Poti ni bilo mogoče najti.',
      'No properties': 'Ni lastnosti',
      'No properties.': 'Ni lastnosti.',
      'No results': 'Ni zadetkov',
      'No results found for your address. Set coordinates manually.':
        'Za vpisan naslov ni rezultatov. Koordinate nastavite ročno.',
      'No rule selected': 'Trenutno ni izbrano nobeno pravilo.',
      'No tag is selected': 'Trenutno ni izbrana nobena značka.',
      'No value': 'Ni vrednosti.',
      'No value to display': 'Ni vrednosti za prikaz.',
      'No value to display.': 'Ni vrednosti za prikaz.',
      'No value.': 'Ni vrednosti.',
      'No values': 'Ni vrednosti.',
      'No values on this interval': 'Na izbranem intervalu ni vrednosti.',
      None: 'Brez',
      'None found.': 'Ni zapisov.',
      'Normalized Service date from': 'Obdobje veljavnosti od',
      'Normalized Service date to': 'Obdobje veljavnosti do',
      'Not a positive number.': 'Vrednost mora biti pozitivno število.',
      'Not a whole number.': 'Vrednost mora biti celo število.',
      'Not equal': 'Neenako',
      'Not equal to': 'Neenako',
      'Not periodic': 'Neponavljajoče',
      'Not-Found': 'Ni mogoče najti',
      Notice: 'Obvestilo',
      'Notification Details': 'Podrobnosti obvestila',
      'Notification content': 'Vsebina obvestila',
      'Notification services': 'Storitve obveščanja',
      'Notification via E-mail': 'Obvestilo preko E-pošte',
      'Notification via SMS': 'Obvestilo preko SMS-a',
      Notifications: 'Obvestila',
      'Notify on all triggers': 'Obvesti ob vsaki sprožitvi',
      Nov: 'Nov',
      November: 'November',
      'Number of Tariffs': 'Število tarif',
      'Number of tariffs': 'Število tarif',
      'Number of values': 'Število vrednosti',
      'Number should be an integer': 'Vrednost mora biti celo število.',
      OK: 'OK',
      OKT: 'OKT',
      OPC: 'OPC',
      'OPC connection configuration': 'Konfiguracija povezave OPC',
      OPCs: 'OPC-ji',
      Oct: 'Okt',
      October: 'Oktober',
      'Offset value': 'Vrednost odmika',
      'Oh no! Something went wrong :(': 'Nekaj je šlo narobe.',
      'Oh no! The page you are trying to reach does not exist :(':
        'Stran, do katere želite dostopati, ne obstaja.',
      'Oh no! You do not have permissions to see this item :(':
        'Nimate pravic za ogled strani.',
      Ok: 'Ok',
      Okt: 'Okt',
      'On duty': 'Dežuren',
      'Once per month': 'Enkrat mesečno',
      'Only one main schedule classification is allowed':
        'Dovoljena je samo ena glavna klasifikacija urnika',
      'Open invoice': 'Ponovno odpri',
      'Open time series filters': 'Odpri filtre za časovne vrste',
      'Opening counter read out': 'Začetno stanje števca',
      'Operation {{functionName2}} is not allowed.':
        'Operacija {{functionName2}} ni dovoljena.',
      'Operation {{functionName}} is not allowed.':
        'Operacija {{functionName}} ni dovoljena.',
      'Operational Support Price': 'Obratovalna podpora',
      Operations: 'Operacije',
      Operator: 'Operator',
      Or: 'Ali',
      Order: 'Vrstni red',
      'Ordered list': 'Urejen seznam',
      Organizations: 'Organizacije',
      'Output expression': 'Izhodni izraz',
      Owner: 'Lastnik',
      Ownership: 'Lastništvo',
      Ownerships: 'Lastništva',
      Page: 'Stran',
      Pair: 'Upari',
      'Pair GIS user': 'Upari GIS uporabnika',
      'Paired time series type': 'Uparjen tip časovne vrste',
      'Pairing settings': 'Nastavitve za uparjenje',
      Parent: 'Nadrejeni',
      'Parent Business Partner': 'Nadrejeni poslovni partner',
      'Parent Company Structure Node': 'Nadrejeno vozlišče strukture podjetja',
      'Parent Location': 'Nadrejena lokacija',
      'Parent Node': 'Nadrejeno vozlišče',
      'Parent business partner': 'Izberite poslovnega partnerja',
      'Parent company structure node': 'Nadrejeno vozlišče strukture podjetja',
      'Parent group': 'Nadrejena skupina',
      'Parent node': 'Nadrejeno vozlišče',
      'Parent:': 'Nadrejeni:',
      Parenthesis: 'Oklepaji',
      'Parse error.': 'Napaka pri parsanju.',
      'Parts per kWh': 'Delcev na kWh',
      Password: 'Geslo',
      'Password confirmation': 'Potrditev gesla',
      'Password was successfully reset': 'Geslo je bilo uspešno ponastavljeno.',
      'Password was successfully updated.':
        'Geslo je bilo uspešno posodobljeno.',
      'Passwords do not match.': 'Gesli se ne ujemata.',
      Path: 'Pot',
      'Pattern is not correct': 'Vzorec ni pravilen.',
      'Pattern is not correct. (04.2018)': 'Vzorec ni pravilen. (04.2018)',
      'Pattern is not correct. ({{configuration.patternExample}})':
        'Vzorec ni pravilen. ({{configuration.patternExample}})',
      'Pattern is not correct. ({{vm.configuration.patternExample}})':
        'Vzorec ni pravilen. ({{vm.configuration.patternExample}})',
      'Pattern is not correct. 22:00': 'Vzorec ni pravilen. 22:00',
      'Pattern is not correct. 23:59:59': 'Vzorec ni pravilen. 23:59:59',
      'Pattern set ({{from}} - {{to}})': 'Učna množica ({{from}}-{{to}})',
      People: 'Ljudje',
      'Per page': 'Vrstic na stran:',
      'Performs a logical AND of the two operands.':
        'Izvede se logični IN dveh operandov',
      'Performs a logical OR of the two operands.':
        'Izvede se logični ALI dveh operandov',
      'Period inerval': 'Časovni interval',
      'Period interval': 'Časovni interval',
      'Period type': 'Tip periode',
      Periodic: 'Ponavljajoče',
      'Permissions were successfully updated.':
        'Urejanje pravic je bilo uspešno posodobljeno.',
      Person: 'Oseba',
      'Person responsible': 'Odgovorna oseba',
      Phase: 'Faza',
      Phone: 'Telefon',
      'Phone Number': 'Telefonska številka',
      'Physical Quantities': 'Fizikalne količine',
      'Physical Quantity': 'Fizikalna količina',
      'Physical information': 'Fizikalne informacije',
      'Physical quantity': 'Fizikalna količina',
      'Pick a job type': 'Izberite tip opravila',
      'Planned events cannot start prior to two hours from now.':
        'Planirani dogodki se ne smejo začeti pred trenutnim časom + 2 uri.',
      'Please select energy source type before selecting order':
        'Prosimo izberite tip energenta preden določite vrstni red postavke',
      'Post name': 'Naziv pošte',
      'Postal number': 'Poštna številka',
      Power: 'Potenciranje',
      Precision: 'Natančnost',
      Preset: 'Nastavljeno vnaprej',
      'Press ENTER to confirm input.': 'Pritisnite Enter za potrditev vnosa.',
      Preview: 'Predogled',
      Previous: 'Nazaj',
      'Previous Versions': 'Predhodne verzije',
      'Previous counter read out': 'Prejšnjo stanje števca',
      'Previous date': 'Prejšnji datum',
      'Previous period': 'Predhodno časovno obdobje',
      'Previous state': 'Prejšnje stanje',
      'Previous versions': 'Predhodne verzije',
      Price: 'Cena',
      'Price List': 'Cenik',
      'Price List Detail': 'Podrobnost cenika',
      'Price List Details': 'Podrobnosti cenika',
      'Price List Item Group Configuration':
        'Konfiguracija skupine postavk na ceniku',
      'Price List Item Group Type': 'Tip skupine postavk na ceniku',
      'Price List Item Groups': 'Skupine postavk na ceniku',
      'Price Lists': 'Ceniki',
      'Price list': 'Cenik',
      'Price list doesn\'t exist for this issuer, billing date and measuring point.':
        'Cenik ne obstaja za izbranega dobavitelja, datum obračuna in merilno mesto.',
      'Price list doesn\'t exist for this supplier, billing date and measuring point.':
        'Cenik ne obstaja za izbranega dobavitelja, datum obračuna in merilno mesto.',
      'Price list item': 'Postavka cenika',
      'Price list item groups': 'Skupine postavk na ceniku',
      'Price list items': 'Postavke cenika',
      Primary: 'Osnoven',
      'Prior to choosing value field name and display field name, make sure to select a domain type':
        'Preden lahko izbirate polje, izberite domeno, ki predstavlja vrednost in polje za prikaz.',
      Priority: 'Prioriteta',
      'Privilege Management': 'Upravljanje pravic',
      'Privilege management': 'Upravljanje pravic',
      Procedure: 'Procedura',
      Profile: 'Profil',
      'Profit Centre': 'Profitni center',
      'Profit Centre Tree View': 'Drevesni pogled profitnih centrov',
      'Profit Centres': 'Profitni centri',
      'Profit centre': 'Profitni center',
      'Profit centres': 'Profitni centri',
      Project: 'Projekt',
      'Project Field': 'Področje projekta',
      'Project Fields': 'Področja projekta',
      'Project Type': 'Tip projekta',
      'Project Types': 'Tipi projekta',
      'Project field': 'Področje projekta',
      'Project type': 'Tip projekta',
      Projects: 'Projekti',
      Properties: 'Lastnosti',
      Protocol: 'Protokol',
      'Provide a different interval': 'Podajte drug interval',
      'Provide at least one day in the week.': 'Podajte vsaj en dan v tednu.',
      'Provide at least one day.': 'Podajte vsaj en dan.',
      'Provide at least one hour choice.': 'Podajte izbor vsaj ene ure.',
      'Provide at least one hour.': 'Podajte vsaj eno uro.',
      'Provide at least one minute choice.': 'Podajte izbor vsaj ene minute.',
      'Provide at least one minute.': 'Podajte vsaj eno minuto.',
      'Provide at least one month.': 'Podajte vsaj en mesec.',
      'Provide at least one second.': 'Podajte vsaj eno sekundo.',
      'Provide at least one weekday.': 'Podajte vsaj en dan v tednu.',
      'Provide day in month.': 'Podajte dan v mesecu.',
      'Provide time in day.': 'Podajte čas v dnevu.',
      Quantity: 'Količina',
      'Range from *': 'Od *',
      'Range to *': 'Do *',
      'Rated Power': 'Nazivna moč',
      'Rated power': 'Nazivna moč',
      'Rated powers': 'Nazivne moči',
      'Raw field': 'Polje v bazi',
      'Raw field name': 'Ime polja v bazi',
      Read: 'Branje',
      'Read Query': 'Poizvedba za zajem podatkov',
      'Read Scheduler': 'Urnik branja',
      'Read count': 'Število prebranih vrednosti',
      'Read type': 'Vrsta branja',
      'Reading Type': 'Vrsta branja',
      'Reading date': 'Datum odčitka',
      'Reading scheduler': 'Urnik zajema',
      'Reading type': 'Vrsta branja',
      'Real time count': 'Število vrednosti zajetih v realnem času',
      Recalculate: 'Ponovni izračun',
      'Received Notifications': 'Prejeta obvestila',
      Redirect: 'Preusmeri',
      'Reduce method': 'Zmanjševalna metoda',
      Refresh: 'Osveži',
      'Refresh interval': 'Osveži interval',
      Refreshing: 'Osveževanje',
      'Refreshing values': 'Osveževanje vrednosti ...',
      'Regardless of date': 'Ne glede na datum',
      Regenerate: 'Regeneriraj',
      'Regenerate external datasource credentials':
        'Regeneriraj poverilnice zunanjega podatkovnega vira',
      'Regenerate normalized dates': 'Obdobje veljavnosti',
      Registers: 'Registri',
      'Regular Expression Mapping Rule':
        'Preslikovalno pravilo glede na regularni izraz',
      'Related company resources': 'Povezani viri podjetja',
      'Reload manual input values': 'Osveži vrednosti naprednega ročnega vnosa',
      Remove: 'Odstrani',
      'Remove Location': 'Odstrani lokacijo',
      'Remove Sort': 'Odstrani sortiranje',
      'Remove Step': 'Odstrani korak',
      'Remove Task': 'Odstrani nalogo',
      'Remove all direct access': 'Odstrani vse dinamične pravice',
      'Remove all items from indirect access?':
        'Želite odstraniti vse elemente iz posrednega dostopa?',
      'Remove all on page': 'Odstrani vse na strani',
      'Remove classification': 'Odstrani klasifikacijo',
      'Remove display field': 'Odstrani polje za prikaz',
      'Remove group': 'Odstrani skupino',
      'Remove indirect access': 'Odstrani posreden dostop',
      'Remove item': 'Odstrani zapis',
      'Remove layout': 'Odstrani razporeditev',
      'Remove mapping rule': 'Odstrani preslikovalno pravilo',
      'Remove procedure': 'Odstrani proceduro',
      'Remove role.': 'Odstrani vlogo',
      'Remove schedule classification': 'Odstrani klasifikacijo urnika',
      'Remove selected direct access':
        'Odstrani izbrane pravice za neposredni dostop',
      'Remove series': 'Odstrani niz',
      Reorder: 'Spremeni vrstni red',
      Report: 'Poročilo',
      'Report is loading, this may take a while.':
        'Predogled se nalaga. Prosimo počakajte trenutek.',
      Reports: 'Poročila',
      'Request failed': 'Pridobivanje ni uspelo',
      Required: 'Obvezno',
      Reselect: 'Znova izberite',
      Reset: 'Ponastavi',
      'Reset Password': 'Ponastavi geslo',
      'Reset zoom': 'Ponastavi',
      'Resolution comment': 'Komentar rešitve',
      'Resolution in': 'Rešiti v ',
      Resolve: 'Reši',
      'Resolve Alarm': 'Reši alarm',
      'Resolved at': 'Rešeno ob',
      Response: 'Odziv',
      'Response Time': 'Odzivni čas',
      'Response and resolution time': 'Čas odziva in rešitve',
      'Response time': 'Odzivni čas',
      Responses: 'Odzivi',
      Responsibility: 'Odgovornost',
      'Responsible Person and notifications': 'Odgovorno osebje in obvestila',
      'Responsible person': 'Odgovorna oseba',
      'Responsible personnel': 'Odgovorno osebje',
      'Responsible persons and notifications': 'Odgovorno osebje in obvestila',
      'Returns absolute value of input parameter.':
        'Vrne absolutno vrednost vhodnega parametra.',
      'Returns day of month.': 'Vrne dan v mesecu.',
      'Returns day of week.': 'Vrne dan v tednu.',
      'Returns day of year.': 'Vrne dan v letu.',
      'Returns degree.': 'Vrne stopinje.',
      'Returns hours.': 'Vrne ure.',
      'Returns minutes.': 'Vrne minute.',
      'Returns month.': 'Vrne mesec.',
      'Returns radians.': 'Vrne radiane.',
      'Returns seconds.': 'Vrne sekunde.',
      'Returns the arc tangent of the two numbers x and y.':
        'Vrne kot v radianih med pozitivno x osjo in premico skozi točki (0,0) in (x,y).',
      'Returns the avarage of last number of measurements.':
        'Vrne povprečno vrednost zadnjih meritev.',
      'Returns the largest number in arguments.':
        'Vrne največje število v argumentih.',
      'Returns the last measurement.': 'Vrne zadnjo meritev.',
      'Returns the natural exponential of a number.': 'Vrne potenco števila e.',
      'Returns the natural logarithm of a number to the base 10.':
        'Vrne logaritem vrednosti z osnovo 10.',
      'Returns the natural logarithm of a number to the base e (e = 2.71828).':
        'Vrne naravni logaritem vrednosti.',
      'Returns the remaining whole number after division.':
        'Vrne preostalo (ostanek) celo število po deljenju.',
      'Returns the smallest number in arguments.':
        'Vrne najmanjše število v argumentih.',
      'Returns the square root of number.': 'Vrne kvadratni koren števila.',
      'Returns the value of a number rounded to the nearest integer.':
        'Vrne zaokroženo vrednost na najbližje celo število.',
      'Returns true if both operands have the same value.':
        'Vrne True, če sta vrednosti enaki',
      'Returns true if the left operand is greater than or equal to the right operand.':
        'Vrne True, če je leva vrednost večja ali enaka desni vrednosti',
      'Returns true if the operands don\'t have the same value.':
        'Vrne True, če vrednosti nista enaki',
      'Returns true if the value on the left is greater than the value on the right.':
        'Vrne True, če je leva vrednost večja od desne vrednosti',
      'Returns true if the value on the left is less than the value on the right.':
        'Vrne True, če je leva vrednost manjša od desne vrednosti',
      'Returns year.': 'Vrne leto.',
      Role: 'Vloga',
      'Rounds down to nearest natural number.':
        'Zaokroži navzdol na najbližje naravno število.',
      'Rounds up to nearest natural number.':
        'Zaokroži navzgor na najbližje naravno število.',
      'Rows per page': 'Vrstic na stran',
      Rule: 'Pravilo',
      'Rule - {{ruleName}}': 'Pravilo - {{ruleName}}',
      'Rule Item': 'Postavka pravila',
      'Rule variables': 'Spremenljivke pravila',
      'Rule {{index}}': 'Pravilo {{index}}',
      Rules: 'Pravila',
      'Rules and alarms': 'Pravila in alarmi',
      Run: 'Zaženi',
      'Run Analysis': 'Poženi analizo',
      'Run Playlist': 'Predvajaj seznam',
      SEP: 'SEP',
      'SO2 per kWh': 'SO2 na kWh',
      SOP: 'SOP',
      'SOP Step': 'Korak SOP',
      'SOP Steps': 'Koraki SOP',
      'SOP Task': 'Naloga SOP',
      'SOP Tasks': 'Naloge SOP',
      'Sampling type': 'Vrsta vzorčenja',
      Sat: 'Sob',
      Saturday: 'Sobota',
      Save: 'Shrani',
      'Save All': 'Shrani vse',
      'Save as': 'Shrani',
      'Save order': 'Shrani vrstni red',
      'Save time series group': 'Shrani skupino časovne vrste',
      Scale: 'Spremenite velikost',
      Schedule: 'Urnik',
      'Schedule Classification': 'Klasifikacija urnika',
      'Schedule classification': 'Klasifikacija urnika',
      'Schedule from': 'Urnik od',
      'Schedule to': 'Urnik do',
      Scheduler: 'Urnik',
      'Scheduler classification': 'Klasifikacija urnika',
      Schedules: 'Urniki',
      Search: 'Išči',
      'Search tags by item name': 'Iskanje značk po imenu postavke',
      'Search tags by name': 'Iskanje značk po imenu',
      'Search tags by tag id': 'Iskanje značk po ID-ju značke',
      'Second interval quantity missing.':
        'Manjka vrednost sekundnega intervala.',
      Seconds: 'Sekunde',
      'Secret was copied to clipboard.':
        'Secret je bil uspešno kopiran v odložišče.',
      Seen: 'Viden',
      Select: 'Izberite',
      'Select Aggregation Type': 'Izberite tip agregacije',
      'Select Alarm Type': 'Izberite tip alarma',
      'Select Allocator Kind': 'Izberite način delitve',
      'Select Allocator Type': 'Izberite vrsto delilnika',
      'Select Asset': 'Izbrano sredstvo',
      'Select Asset Brand': 'Izberite blagovno znamko sredstva',
      'Select Asset Group': 'Izberite skupino sredstva',
      'Select Asset Status': 'Izberite status sredstva',
      'Select Asset Type': 'Izberite tip sredstva',
      'Select Assignee': 'Izberite dodeljeno osebo',
      'Select Billing Kind': 'Izberite način obračuna',
      'Select Billing Type': 'Izberite tip obračuna',
      'Select Business Partner': 'Izberite poslovnega partnerja',
      'Select Connection': 'Izberite povezavo',
      'Select Cost Centre': 'Izberite stroškovno mesto',
      'Select Cost centre': 'Izberite stroškovno mesto',
      'Select Counter type': 'Izberite tip merilnika',
      'Select Country': 'Izberite državo',
      'Select Currency': 'Izberite valuto',
      'Select Dashboard': 'Izberite nadzorno ploščo',
      'Select Dataset': 'Izberite nabor podatkov',
      'Select Default Value Type': 'Izberite tip vrednosti',
      'Select Energy Source Type': 'Izberite tip energenta',
      'Select Field type': 'Izberite tip polja',
      'Select Filter': 'Izberite filter',
      'Select Filter type': 'Izberite tip filtra',
      'Select Format': 'Izberite podatkovni tip',
      'Select GIS user': 'Izberite GIS uporabnika',
      'Select Gis User': 'Izberite GIS uporabnika',
      'Select Location': 'Izberite lokacijo',
      'Select Location Ownership': 'Izberite lastništvo lokacije',
      'Select Location Status': 'Izberite status lokacije',
      'Select Location Type': 'Izberite tip lokacije',
      'Select Measurement Unit': 'Izberite mersko enoto',
      'Select Owner': 'Izberite lastnika',
      'Select Parent': 'Izberite nadrejenega',
      'Select Parent Document Classification':
        'Izberite nadrejeno klasifikacijo dokumentacije',
      'Select Parent Energy Source Type': 'Izberite nadrejeni energent',
      'Select Physical Quantity': 'Izberite fizikalno količino',
      'Select Physical quantity': 'Izberite fizikalno količino',
      'Select Profit Centre': 'Izberite profitni center',
      'Select Project': 'Izberite projekt',
      'Select Rated Power': 'Izberite nazivno moč',
      'Select Raw field name': 'Ime polja v bazi',
      'Select Severity': 'Izberite stopnjo pomembnosti',
      'Select Tariff': 'Izberite tarifo',
      'Select Time Interval': 'Izberite časovni interval',
      'Select Time Series': 'Izberite časovno vrsto',
      'Select Time Series Type': 'Izberite tip časovne vrste',
      'Select Time series': 'Izberite časovno vrsto',
      'Select Type': 'Izberite tip',
      'Select Usage type': 'Izberite vrsto porabe',
      'Select User': 'Izberite uporabnika',
      'Select Y axis value': 'Izberite meritev na osi Y',
      'Select a Brand': 'Izberite blagovno znamko',
      'Select a Model': 'Izberite model',
      'Select a cost centre': 'Izberite stroškovno mesto',
      'Select a document classification':
        'Izberite klasifikacijo dokumentacije',
      'Select a function': 'Izberite funkcijo',
      'Select a group': 'Izberite uporabniško skupino',
      'Select a person': 'Izberite osebo',
      'Select absent user': 'Izberite odsotno osebo',
      'Select action status': 'Status ukrepa',
      'Select aggregation group': 'Izberite agregacijsko skupino',
      'Select alarm': 'Izberite alarm',
      'Select alarms': 'Izberite alarme',
      'Select alarms from the list below':
        'Izberite alarme iz spodnjega seznama',
      'Select all': 'Izberite vse',
      'Select an Energy Source Type to display used order values.':
        'Izberite energent za prikaz trenutnega vrstnega reda.',
      'Select an Ownership Type': 'Izberite tip lastništva',
      'Select an asset': 'Izberite sredstvo',
      'Select analyses': 'Izberito analizo',
      'Select analyses from the list below':
        'Izberite analizo s spodnjega seznama',
      'Select another source value ID':
        'Izberite drug ID vrednosti podatkovnega vira',
      'Select asset': 'Izberite sredstvo',
      'Select asset from the list below':
        'Izberite sredstvo s spodnjega seznama',
      'Select at least one': 'Izberite vsaj eno',
      'Select attribute': 'Izberite atribut',
      'Select business partner': 'Izberite poslovnega partnerja',
      'Select business partner from the list below':
        'Izberite poslovnega partnerja s spodnjega seznama',
      'Select cache interval': 'Izberite interval predpomnilnika',
      'Select calculation': 'Izberite izračun',
      'Select card background color': 'Izberite barvo ozadja kartice',
      'Select chart color': 'Izberite barvo krivulje na grafu',
      'Select chart type': 'Izberite tip grafa',
      'Select column': 'Izberite stolpec',
      'Select company structure node': 'Vozlišče hierarhije podjetja',
      'Select comparable column': 'Izberite stolpec za primerjavo',
      'Select connected external datasource':
        'Izberite povezan zunanji podatkovni vir',
      'Select content person': 'Izberite osebo za vsebino',
      'Select cost centre': 'Izberite stroškovno mesto',
      'Select cost centre from the list below':
        'Izberite stroškovno mesto s spodnjega seznama',
      'Select counter type': 'Izberite tip merilnika',
      'Select counter type from the list below':
        'Izberite tip merilnika s spodnjega seznama',
      'Select country': 'Izberite državo',
      'Select customer': 'Izberite stranko',
      'Select dashboard *': 'Izberite nadzorno ploščo *',
      'Select dashboard card type': 'Izberite tip kartice',
      'Select data types': 'Izberite format podatka',
      'Select dataset': 'Izberite nabor podatkov',
      'Select date types': 'Izberite vrsto datumskega podatka',
      'Select day': 'Izberite dan',
      'Select default chart type': 'Izberite tip grafa',
      'Select default physical quantity':
        'Izberite privzeto fizikalno količino',
      'Select default unit of measurement': 'Izberite privzeto mersko enoto',
      'Select display type': 'Izberite tip prikaza',
      'Select distributor': 'Izberite distributerja',
      'Select domain type': 'Izberite tip domene',
      'Select element': 'Izberi element',
      'Select energy source from the list below':
        'Izberite energent s spodnjega seznama',
      'Select energy source type': 'Izberite tip energenta',
      'Select entity': 'Izberite entiteto',
      'Select expression': 'Izberite izraz',
      'Select external datasource type':
        'Izberite tip zunanjega podatkovnega vira',
      'Select filters': 'Izberite filtre',
      'Select format': 'Izberite podatkovni tip',
      'Select function': 'Izberite funkcijo',
      'Select group': 'Izberite uporabniško skupino',
      'Select group entity': 'Izberite entiteto za grupacijo',
      'Select icon': 'Izberite ikono',
      'Select invoice issuer': 'Izberite izdajatelja računa',
      'Select issuer': 'Izberite izdajatelja',
      'Select item': 'Izberite',
      'Select language': 'Izberite jezik',
      'Select last 100': 'Prenesi zadnjih 100',
      'Select location': 'Izberite lokacijo',
      'Select location classification': 'Izberite klasifikacijo lokacije',
      'Select location classification from the list below':
        'Izberite klasifikacijo lokacije s spodnjega seznama.',
      'Select location from the list below':
        'Izberite lokacijo s spodnjega seznama',
      'Select main cost centre': 'Izberite glavno stroškovno mesto',
      'Select measuring point': 'Izberite merilno mesto',
      'Select measuring point from the list below':
        'Izberite merilno mesto s spodnjega seznama.',
      'Select measuringpoint': 'Izberite merilno mesto',
      'Select menu': 'Izbirni menu',
      'Select month': 'Izberite mesec',
      'Select new valid dates': 'Izberite nove veljavne datume',
      'Select number of values': 'Izberite število vrednosti',
      'Select or enter a new tag': 'Izberite ali vnesite novo značko',
      'Select organization': 'Izberite organizacijo',
      'Select owner': 'Izberite lastnika',
      'Select paired time series type': 'Izberite tip uparjene časovne vrste',
      'Select parent': 'Izberite nadrejenega',
      'Select parent Location': 'Izberite nadrejeno lokacijo',
      'Select parent asset': 'Izberite nadrejeno sredstvo',
      'Select parent company structure node':
        'Izberite nadrejeno vozlišče strukture podjetja',
      'Select parent hierarchy node': 'Izberite nadrejeno vozlišče',
      'Select parent measuring point': 'Izberite nadrejeno merilno mesto',
      'Select period interval': 'Izberite časovni interval',
      'Select physical quantity': 'Izberite fizikalno količino',
      'Select project field': 'Izberite področje projekta',
      'Select project type': 'Izberite tip projekta',
      'Select refresh Interval': 'Izberite interval osveževanja',
      'Select report dates': 'Izberite časovno obdobje poročila',
      'Select rule': 'Izberi pravilo',
      'Select schedule classification': 'Izberite klasifikacijo urnika',
      'Select slice entity':
        'Izberite entiteto za katero bo narejen ločen graf',
      'Select sort type': 'Izberite vrsto razdelitve',
      'Select source value ID': 'Izberite ID vrednosti podatkovnega vira',
      'Select status': 'Rezultat',
      'Select substitute user': 'Izberite nadomestno osebo',
      'Select supplier': 'Izberite dobavitelja',
      'Select tag': 'Izberite značko',
      'Select tag from the list below': 'Izberite značko s spodnjega seznama',
      'Select tango agent connection configuration':
        'Izberite konfiguracijo konektorja Tango Agenta',
      'Select tariff': 'Izberite tarifo',
      'Select time series': 'Izberite časovno vrsto',
      'Select time series to add to classification':
        'Izberite časovno vrsto, ki jo želite dodati na klasifikacijo urnika',
      'Select time series type': 'Izberite tip časovne vrste',
      'Select time unit': 'Izberite časovno enoto',
      'Select type': 'Izberite tip',
      'Select unit of measurement': 'Izberite mersko enoto',
      'Select user': 'Izberite uporabnika',
      'Select value field': 'Izberite polje z vrednostjo',
      'Select visualization': 'Izberite vizualizacijo',
      'Select visualization from the list below':
        'Izberite vizualizacijo s spodnjega seznama',
      'Select weather station': 'Izberite vremensko postajo',
      'Select x axis values': 'Izberite meritev na osi X',
      'Select year': 'Izberite leto',
      Selected: 'Izbrano',
      'Selected Icon:': 'Izbrana ikona',
      'Selected date does not correspond to valid External Reader Flow Configuration.':
        'Izbran datum ne ustreza veljavni konfiguraciji Zajem iz zunanjega vira',
      'Selected icon:': 'Izbrana ikona:',
      'Selected period': 'Izbrano obdobje',
      'Selected series': 'Izbrane vrste',
      'Selected theme': 'Izbrana tema',
      'Selected:': 'Izbrano:',
      'Selector type': 'Tip izbirnika',
      'Send E-mail notifications': 'Pošlji obvestila prek E-pošte',
      'Send SMS notifications': 'Pošlji SMS obvestila',
      Sep: 'Sep',
      September: 'September',
      'Serial number': 'Serijska številka',
      Series: 'Serije',
      'Series name': 'Ime serije',
      'Server Error': 'Strežniška napaka',
      'Service Date From': 'Datum storitve od',
      'Service Date To': 'Datum storitve do',
      'Service date from': 'Datum storitve od',
      'Service date to': 'Datum storitve do',
      'Set address': 'Nastavi naslov',
      'Set coordinates': 'Nastavi koordinate',
      'Set date time interval': 'Izberite časovni interval',
      'Set validity interval': 'Nastavi obdobje veljavnosti',
      Settings: 'Nastavitve',
      Settlement: 'Naselje',
      'Settlement Name': 'Ime naselja',
      'Settlement name': 'Naselje',
      Severities: 'Pomembnosti',
      Severity: 'Pomembnost',
      'Share link': 'Delite povezavo',
      'Share time series group': 'Shrani skupino časovnih vrst',
      'Show Hierarchy': 'Pokaži strukturo',
      'Show asset children': 'Pokaži podrejena sredstva',
      'Show chart': 'Prikaži graf',
      'Show data table': 'Pokaži tabelo podatkov',
      'Show energy values': 'Prikaži energijska števila',
      'Show seen notifications': 'Prikaži videna obvestila',
      'Sine function.': 'Funkcija sinus',
      Size: 'Velikost',
      'Slice entity': 'Ločitev po',
      'Smart City': 'Pametno mesto',
      'Sms content': 'Vsebina SMS',
      'Sms notification': 'Sms obvestilo',
      'Sms receivers': 'Seznam prejemnikov SMS',
      Solution: 'Rešitev',
      'Some items couldn\'t be removed':
        'Nekaj zapisov ni bilo mogoče odstraniti.',
      'Some items couldn\'t be updated':
        'Nekaj zapisov ni bilo mogoče posodobiti.',
      'Something went wrong during file {{fileName}} upload':
        'Napaka pri nalaganju datoteke {{fileName}}',
      'Something went wrong loading analysis data.':
        'Napaka pri nalaganju analize.',
      'Sop Type': 'Tip SOP',
      Sort: 'Razvrsti',
      'Sort type': 'Vrsta razdelitve',
      'Source value ID': 'ID vrednosti podatkovnega vira',
      Stakeholder: 'Deležnik',
      Stakeholders: 'Deležniki',
      'Standard Op. Procedures': 'Standardne operativne procedure',
      'Standard Operating Procedure': 'Standardna operativna procedura',
      'Standard Operating Procedures': 'Standardne operativne procedure',
      'Standard operating procedures': 'Standardne operativne procedure',
      'Standard operational procedure step':
        'Korak standardne operativne procedure',
      'Standard operational procedure task':
        'Naloga standardne operativne procedure',
      Start: 'Začni',
      'Start date': 'Datum začetka',
      'Start date must be before End date.':
        'Datum začetka mora biti pred datumom konca.',
      'Start date must be before an end date.':
        'Datum začetka mora biti pred datumom konca.',
      'Start date:': 'Datum začetka:',
      'Start of Billing Period *': 'Datum začetka obračunskega obdobja *',
      'Start of Project *': 'Začetek projekta *',
      'Start of availability': 'Začetek razpoložljivosti sredstva',
      'Start of billing period': 'Začetek obračunskega obdobja',
      'Start of project': 'Začetek projekta',
      Started: 'Zagnano',
      'Starting On': 'Začetek',
      Statistics: 'Statistika',
      Status: 'Status',
      Step: 'Korak',
      'Step Details': 'Podrobnosti koraka',
      Steps: 'Koraki',
      'Steps of the procedure': 'Koraki v proceduri',
      Stop: 'Ustavi',
      'Stream Archive Property': 'Atribut podatka',
      'Stream Archive property': 'Atribut podatka',
      Street: 'Ulica',
      'Street name': 'Ulica',
      String: 'Niz',
      'Strings are not allowed in expressions. (Regarding {{value}})':
        'Nizi znakov niso dovoljeni v izrazih. ({{value}})',
      Subject: 'Zadeva',
      'Submit job': 'Predloži opravilo',
      Subscript: 'Potpisan',
      'Subsidiary locations': 'Podrejene lokacije',
      Substraction: 'Odštevanje',
      'Substracts neighbouring expressions': 'Odšteje sosednja izraza.',
      Sum: 'Vsota',
      Sun: 'Ned',
      Sunday: 'Nedelja',
      'Superior Location': 'Nadrejena lokacija',
      Superscript: 'Superscript',
      Supplier: 'Dobavitelj',
      'Supplier Code': 'Dobaviteljeva šifra',
      'Supplier code': 'Dobaviteljeva šifra MM',
      'Supplier price list': 'Dobaviteljev cenik',
      'Supported operators and functions': 'Podprti operatorji in funkcije',
      Surname: 'Priimek',
      Symbol: 'Simbol',
      'Sync external datasource values': 'Zgodovinski zahtevek',
      System: 'Sistem',
      'System Tags': 'Sistemske značke',
      'System notification users': 'Prejemniki obvestil za sistem',
      'System responsible person': 'Odgovorna oseba za sistem',
      'System tags': 'Sistemske značke',
      TIS: 'TIS',
      TRUE: 'TRUE',
      'Tag Selector': 'Izbirnik značk',
      'Tag column': 'Stolpec značke',
      'Tag column name': 'Ime stolpca značke',
      'Tag explorer': 'Izbirnik značk',
      'Tag on source': 'Značka na viru',
      Tags: 'Značke',
      'Tangent function.': 'Funkcija tangente',
      'Tango Agent Configuration Type': 'Tip konfiguracije Tango Agenta',
      'Tango Agent Connection': 'Konfiguracija konektorja Tango Agenta',
      'Tango Agent Connection Config': 'Konfiguracija konektorja Tango Agenta',
      'Tango Agent Connection Configuration':
        'Konfiguracija konektorja Tango Agenta',
      'Tango Agent Connection Configurations':
        'Konfiguracije konektorja Tango Agenta',
      'Tango agent config': 'Konfiguracija konektorja Tango Agenta',
      'Tango agent connection configuration':
        'Konfiguracija konektorja Tango Agenta',
      Target: 'Ciljna premica',
      'Target function': 'Ciljna premica',
      'Target {{equation}}': 'Ciljna premica {{equation}}',
      Tariff: 'Tarifa',
      'Tariff Configurations': 'Konfiguracija tarife',
      'Tariff:': 'Tarifa:',
      Tariffs: 'Tarife',
      Task: 'Naloga',
      'Task description': 'Opis naloge',
      'Task.': 'Naloga',
      Tasks: 'Naloge',
      TasksTitle: 'Naloge',
      'Tax Number': 'Davčna številka',
      'Tax number': 'Davčna številka',
      Temperature: 'Temperatura',
      Text: 'Besedilo',
      'Text area': 'Besedilo',
      'Text color': 'Barva pisave',
      'There are currently no order values selected for this Energy Source Type.':
        'Vrstni red za izbran tip energenta trenutno še ni definiran. ',
      'There are no Aggregation types to select from.':
        'Ni tipov agregacije za izbor.',
      'There are no Cost centres to select.': 'Ni stroškovnih mest.',
      'There are no Cumulative Expressions.': 'Ni kumulativnih izrazov.',
      'There are no Heartbeats bound to this object.':
        'Na ta objekt ni vezan noben srčni utrip.',
      'There are no action statuses to select from.':
        'Ni statusov ukrepov za izbor.',
      'There are no aggregation groups.': 'Ni agregacijskih skupin. ',
      'There are no attributes selected': 'Ni atributov za izbor',
      'There are no authorized groups.': 'Ni pooblaščenih skupin.',
      'There are no authorized users.': 'Ni pooblaščenih uporabnikov.',
      'There are no calculations.': 'Ni izračunov.',
      'There are no columns.': 'Ni stolpcev.',
      'There are no comparable columns yet': 'Ni stolpcev za primerjavo.',
      'There are no comparable columns.': 'Ni stolpcev za primerjavo.',
      'There are no data to be saved!': 'Ni podatkov.',
      'There are no elements to select.': 'Ni elementov za izbor.',
      'There are no expressions yet': 'Ni izrazov.',
      'There are no filters selected yet': 'Ni izbranih filtrov.',
      'There are no formats to select.': 'Ni formatov za izbor.',
      'There are no groups to select.': 'Ni skupin za izbor.',
      'There are no invoice detail values yet.': 'Ni postavk računa.',
      'There are no issuers to select.':
        'Za izbiro ni na voljo nobenega izdajatelja.',
      'There are no items': 'Ni zapisov.',
      'There are no items selected': 'Ni izbranih zapisov.',
      'There are no items to select.': 'Elementi za izbiro niso na voljo.',
      'There are no items yet': 'Trenutno ni zapisov',
      'There are no items.': 'Ni zapisov.',
      'There are no job types to select from.': 'Ni tipov opravil za izbor.',
      'There are no layouts': 'Ni razporeditev.',
      'There are no manual readings': 'Ni ročnih odčitkov.',
      'There are no map id': 'Id zemljevida ne obstaja.',
      'There are no more Rules to select': 'Ni več pravil za izbor. ',
      'There are no options available': 'Ni razpoložljivih možnosti.',
      'There are no options selected': 'Ni izbranih zapisov.',
      'There are no organizations to select.': 'Ni organizacij za izbor.',
      'There are no period intervals.': 'Ni časovnih intervalov.',
      'There are no previous values': 'Ni predhodnih vrednosti',
      'There are no registers.': 'Ni registrov.',
      'There are no schedule classifications': 'Neznana klasifikacija urnika',
      'There are no selected items': 'Ni izbranih postavk.',
      'There are no tags to select.': 'Ni značk za izbiro.',
      'There are no tags.': 'Ni značk.',
      'There are no tasks': 'Ni definiranih nalog.',
      'There are no tasks yet': 'Ni definiranih nalog.',
      'There are no tasks.': 'Ni definiranih nalog.',
      'There are no time series': 'Ni izbranih časovnih vrst.',
      'There are no time series selected': 'Ni izbranih časovnih vrst.',
      'There are no time series type attributes yet':
        'Ni atributov tipa časovnih vrst',
      'There are no users to select.': 'Ni uporabnikov za izbor.',
      'There are no values': 'Ni vrednosti.',
      'There are no values.': 'Ni vrednosti.',
      'There are no variable names': 'Ni spremenljivk',
      'There are no variables': 'Ni spremenljivk',
      'There are no x axis values.': 'Ni vrednosti za os X.',
      'There are too many values for selected period try to reduce selected interval':
        'V izbranem obdobju je prevelika količina vrednosti. Poskusite zmanjšati časovni interval.',
      'There are {{vm.steps.length}} steps bound to this sop.': [
        'Na ta SOP so povezani koraki.',
        'Na ta SOP sta povezana {{sop.step.length}} koraka.',
        'Na ta SOP so povezani {{sop.step.length}} koraki.',
        'Na ta SOP so povezani {{sop.step.length}} koraki.'
      ],
      'There is no billing data': 'Ni podatkov za obračun',
      'There is no configuration': 'Ni konfiguracije',
      'There is no currency to select.': 'Ni valute za izbor.',
      'There is no gis data': 'Ni podatkov za gis',
      'There is no price list': 'Ni cenikov.',
      'There is no selected item': 'Ni izbranih postavk.',
      'There is no tag selected': 'Ni značk',
      'There is no time series to generate report':
        'Graf nima časovnih vrst na podlagi katerih bi se lahko zgeneriralo poročilo.',
      'There is one standard operating procedure bound to this alarm': [
        'Na ta alarm je povezanih {{count}} SOP.',
        'Na ta alarm sta povezana {{count}} SOP-a.',
        'Na ta alarm so povezani {{count}} SOP-i.',
        'Na ta alarm so povezani {{count}} SOP-i.'
      ],
      'There should only be 6 sections of cron.':
        'Cron izraz sestoji iz 6ih znakov.',
      'There was an error for the following reason: {{status}}':
        'Prišlo je do napake zaradi: {{status}}',
      'There were no activities yet.': 'Ni dejavnosti.',
      'These credentials are required for installation of TIS client on your device. Make sure you have stored them safely. Device secret will never be shown anymore!':
        'Sledeče poverilnice so potrebne za namestitev TIS klienta na vašo napravo. Varno jih shranite, saj \'skrivnost\' (secret) naprave ne bo več prikazana!',
      'These credentials are required for installation of TIS client on your external datasource. Make sure you have stored them safely. External datasource secret will never be shown anymore!':
        'Sledeče poverilnice so potrebne za namestitev TIS klienta na zunanji podatkovni vir. Varno jih shranite, saj poverilnice za zunanje podatkovne vire ne bo več mogoče prikazati.',
      'This attribute hasn\'t been filled in yet.':
        'Vrednost še ni bila vnešena.',
      'This boundary overlaps with another.': 'Ta meja se prekriva z drugo.',
      'This field is required': 'To polje je obvezno.',
      'This field is required.': 'To polje je obvezno.',
      'This item has no rules yet.': 'Ta postavka še nima vrednosti.',
      'This manual input has no groups yet':
        'Ta napredni ročni vnos še nima skupin.',
      'This procedure has no steps.': 'Ta procedura nima korakov.',
      'This value is already taken for the specified energy source type. Please select a different value.':
        'Vrednost je že uporabljena za izbran energent. Prosimo izberite drugo vrednost.',
      Thu: 'Čet',
      Thursday: 'Četrtek',
      Time: 'Čas',
      'Time Filters': 'Filtri',
      'Time Interval': 'Časovni interval',
      'Time Interval Filter': 'Filter za časovni interval',
      'Time Interval Filters': 'Časovni intervali',
      'Time Series': [
        'Časovna vrsta',
        'Časovni vrsti',
        'Časovne vrste',
        'Časovnih vrst'
      ],
      'Time Series - External Writer Flows': [
        'Zapisovalne časovne vrste',
        'Zapisovalne časovne vrste',
        'Zapisovalne časovne vrste',
        'Zapisovalne časovne vrste'
      ],
      'Time Series - History': [
        'Časovna vrsta - zgodovinski zajem',
        'Časovne vrste - zgodovinski zajem',
        'Časovne vrste - zgodovinski zajem',
        'Časovnih vrst - zgodovinski zajem'
      ],
      'Time Series - Live': [
        'Časovna vrsta - trenutni zajem',
        'Časovni vrsti - trenutni zajem',
        'Časovne vrste - trenutni zajem',
        'Časovnih vrst - trenutni zajem'
      ],
      'Time Series Group Sandbox': 'Predogled skupine časovnih vrst',
      'Time Series Group Type': 'Tip skupine časovnih vrst',
      'Time Series Group Types': 'Tipi skupine časovnih vrst',
      'Time Series Groups': 'Skupine časovnih vrst',
      'Time Series Type': 'Tip časovne vrste',
      'Time Series Types': 'Tipi časovne vrste',
      'Time Series active flow is not mt analysis flow':
        'Aktivna konfiguracija časovne vrste ni M&T analiza',
      'Time Series value was filled with default values':
        'Vrednost časovne vrste je bila napolnjena s privzetimi vrednostmi',
      'Time Series were successfully created':
        'Časovna vrsta je bila uspešno ustvarjena.',
      'Time To Trigger': 'Čas do sprožitve',
      'Time Zone': 'Časovni pas',
      'Time from': 'Čas od',
      'Time in day (14:20)': 'Čas (14:20)',
      'Time interval filter': 'Filter za časovni interval',
      'Time interval filter type': 'Tip filtra za časovni interval',
      'Time intervals': 'Časovni intervali',
      'Time is not valid': 'Čas ni veljaven',
      'Time offset': 'Odmik',
      'Time period': 'Časovna perioda',
      'Time series': [
        'Časovna vrsta',
        'Časovni vrsti',
        'Časovne vrste',
        'Časovnih vrst'
      ],
      'Time series are in different time zones.':
        'Časovne vrste so v različnih časovnih pasovih',
      'Time series card': 'Kartica časovne vrste',
      'Time series group preview': 'Predogled skupine časovnih vrst',
      'Time series group type': 'Tip skupine časovnih vrst',
      'Time series groups': 'Skupine časovnih vrst',
      'Time series has no configurations yet':
        'Časovna vrsta še nima konfiguracij',
      'Time series name': 'Naziv časovne vrste',
      'Time series status': 'Status časovne vrste',
      'Time series successfully created!':
        'Časovna vrsta je bila uspešno ustvarjena.',
      'Time series successfully updated!':
        'Časovna vrsta je bila uspešno posodobljena.',
      'Time series type': 'Tip časovne vrste',
      'Time series type attributes': 'Atributi tipa časovne vrste',
      'Time series type:': 'Tip časovne vrste:',
      'Time series value was successfully created!':
        'Časovna vrsta je bila uspešno ustvarjena.',
      'Time to': 'Čas do',
      'Time to trigger': 'Čas do sprožitve',
      'Time unit': 'Časovna enota',
      'Time until alarm should be resolved':
        'Čas, v katerem mora biti alarm razrešen',
      'Time zone': 'Časovni pas',
      Timeliness: 'Vrsta procesiranja',
      Timeout: 'Časovna omejitev',
      'Timeout Alarm': 'Alarm s časovno omejitvijo',
      'Timeout action': 'Nastavitve časovne omejitve',
      'Timeout alarm': 'Alarm s časovno omejitvijo',
      'Timeout settings': 'Nastavitve časovne omejitve',
      Title: 'Ime',
      To: 'Do',
      'To continue plase log in.': 'Za nadaljevanje se prosimo prijavite.',
      'To value should me less than Value from':
        'Vrednost do mora biti večja od vrednosti od',
      Today: 'Danes',
      'Toggle main dashboard': 'Glavna nadzorna plošča',
      Total: 'Skupaj',
      'Total count': 'Število vseh vrednosti',
      'Total with VAT': 'Skupaj z DDV',
      'Total with VAT:': 'Skupaj z DDV:',
      'Total with Vat max': 'Skupaj z DDV do',
      'Total with Vat min': 'Skupaj z DDV od',
      'Total without VAT': 'Skupaj brez DDV',
      'Total without VAT:': 'Skupaj brez DDV:',
      'Total {{total}}{{currency}}. Total amount left {{amountLeft}}{{currency}}':
        'Vsota {{total}}{{currency}}. Preostali znesek {{amountLeft}}{{currency}}',
      'Trailing zeroes are not allowed.':
        'Ničle na koncu števila niso dovoljene.',
      'Transfer To Next Month': 'Prenos v naslednji mesec',
      'Transfer to next month': 'Prenos v naslednji mesec',
      'Transformation To kWh': 'Konverzija v kWh',
      'Transformation to kWh': 'Konverzija v kWh',
      'Translations are missing!': 'Prevodi manjkajo',
      'Trigger type': 'Način proženja izračuna',
      Triggered: 'Sprožen',
      'Triggered Alarm': 'Alarmi v teku',
      'Triggered Alarms': 'Sproženi alarmi',
      'Triggered alarm': 'Sprožen alarm',
      'Triggered at': 'Sproženo ob',
      'Triggered at {{entityName}}': 'Sprožen na {{entityName}}',
      'Triggers an alarm': 'Sproži alarm',
      'Triggers an error': 'Sproži napako',
      'Triggers calculation': 'Sproži izračun',
      'Triggers on Holidays': 'Sproži ob praznikih',
      True: 'True',
      Tue: 'Tor',
      Tuesday: 'Torek',
      Type: 'Tip',
      'Type:': 'Tip:',
      URL: 'URL',
      'URL Document': 'URL dokument',
      'URL document': 'URL dokument',
      Undefined: 'Nedefiniran',
      Underline: 'Podčrtaj',
      'Unexpected end of expression (character at position {{char}}).':
        'Nepričakovano končanje izraza (znak na poziciji {{char}}).',
      'Unique Number': 'Unikatna številka',
      'Unique number': 'Unikatna številka',
      Unknown: 'Neznano',
      'Unknown Alarm': 'Neznan alarm',
      'Unknown Flow': 'Neznana konfiguracija časovne vrste',
      'Unknown Tariff': 'Neznana tarifa',
      'Unknown Time Series': 'Neznana časovna vrsta',
      'Unknown action for {{measuringpointName}}':
        'Neznana akcija za {{measuringpointName}}',
      'Unknown allocator type': 'Neznana vrsta delilnika',
      'Unknown attribute type': 'Neznan tip datoteke',
      'Unknown data type': 'Neznana vrsta podatka',
      'Unknown energy source type': 'Neznan tip energenta',
      'Unknown file type': 'Neznan tip datoteke',
      'Unknown interpretation type': 'Neznana vrsta interpretacije',
      'Unknown invoice type': 'Neznan tip računa',
      'Unknown maintenance flow type': 'Neznan tip vzdrževanja časovne vrste',
      'Unknown measuring point': 'Neznano merilno mesto',
      'Unknown sampling type': 'Neznana vrsta vzorčenja',
      'Unknown schedule classification': 'Neznana klasifikacija urnika',
      'Unknown scheduler': 'Neznan urnik',
      'Unknown tag': 'Neznana značka',
      'Unknown time series status': 'Neznan status časovne vrste',
      'Unknown time zone': 'Neznana časovna cona',
      'Unknown trigger type': 'Neznan način proženja izračuna',
      'Unknown type': 'Neznan tip',
      'Unknown user': 'Neznan uporabnik',
      'Unknown value': 'Neznana vrednost',
      Unpair: 'Odupari',
      Unseen: 'Unseen',
      Update: 'Posodobi',
      'Update Action': 'Urejanje ukrepov',
      'Update Asset Availability': 'Posodobi razpoložljivost sredstva',
      'Update M&T analysis flow': 'Urejanje M&T analize',
      'Update Rated Power': 'Urejanje nazivne moči',
      'Update an absence': 'Posodobite odsotnost',
      'Update an assignment': 'Posodobite dodelitev',
      'Update calculated flow': 'Urejanje izračunane časovne vrste',
      'Update domain': 'Urejanje domene',
      'Update external reader flow': 'Urejanje zajema iz zunanjega vira',
      'Update external writer flow': 'Urejanje zapisovalne časovne vrste',
      'Update groups': 'Posodobite skupine',
      'Update maintenance flow': 'Urejanje vzdrževanja časovne vrste',
      'Update value': 'Posodobi vrednost',
      Updated: 'Posodobljeno',
      Upload: 'Naloži',
      'Upload CSV file': 'Naloži CSV datoteko',
      'Upload file': 'Naloži datoteko',
      'Upload image': 'Naloži sliko',
      Uploaded: 'Naloženo',
      Uploading: 'Nalaganje',
      'Upper Limit %': 'Zgornja meja %',
      'Upper Limit Alarm': 'Alarm za zgornjo mejo',
      'Upper Limits': 'Zgornja meja',
      'Usage Type': 'Vrsta porabe',
      'Use aggregation group as x axis':
        'Na osi X se prikazuje agregacijska skupina',
      'Use current time series as input parameter':
        'Uporabi trenutno časovno vrsto kot vhodni parameter',
      'Use security': 'Uporabi varnost',
      'Use timeout': 'Uporabi časovno omejitev',
      'Used order values': 'Trenutni vrstni red',
      'Used order values for {{name}}': 'Trenutni vrstni red za {{name}}',
      User: 'Uporabnik',
      'User Entities': 'Entitete uporabnika',
      'User Group Modules': 'Moduli uporabniških skupin',
      'User Modules': 'Moduli uporabnika',
      'User Privilege Management': 'Upravljanje pravic uporabnika',
      'User access': 'Uporabniški dostop',
      Username: 'Uporabniško ime',
      Users: 'Uporabniki',
      'VALIDATION ERRORS': 'NAPAKA PRI PREVERJANJU',
      'Valid From': 'Veljavno od',
      'Valid To': 'Veljavno do',
      'Valid from': 'Veljavno od',
      'Valid from *': 'Veljavno od *',
      'Valid from can not be prior to start date of asset availability':
        'Veljavno od ne sme biti pred začetkom razpoložljivosti sredstva',
      'Valid from date must be before valid to date.':
        'Vrednost za Veljavno od datuma mora biti pred Veljavno do datuma.',
      'Valid from should not intersect with previous valid to':
        'Datum veljavno od mora biti za datumom veljavno do prejšnje konfiguracije.',
      'Valid to': 'Veljavno do',
      'Valid to *': 'Veljavno do *',
      'Validation Fail': 'Neuspešno preverjanje',
      'Validation Success': 'Uspešno preverjanje',
      'Validity date from should be prior to validity date to.':
        'Obdobje veljavnosti od mora biti pred obdobjem veljavnosti do. ',
      'Validity interval from': 'Obdobje veljavnosti od',
      'Validity interval to': 'Obdobje veljavnosti do',
      Value: 'Vrednost',
      'Value card was successfully updated':
        'Kartica za vrednost je bila uspešno posodobljena.',
      'Value column': 'Stolpec z vrednostmi',
      'Value column name': 'Ime stolpca z vrednostmi',
      'Value details': 'Podrobnosti vrednosti',
      'Value field name': 'Ime polja z vrednostmi',
      'Value is not a number': 'Vrednost ni število',
      'Value must be an integer': 'Vrednost mora biti celo število',
      'Value must be between  and': 'Vrednost mora biti med in .',
      'Value must be between -100 and 100':
        'Vrednost mora biti med -100 in 100.',
      'Value must be between 0 and 23': 'Vrednost mora biti med 0 in 23.',
      'Value must be between 0 and 52': 'Vrednost mora biti med 0 in 52.',
      'Value must be between 0 and 59': 'Vrednost mora biti med 0 in 59.',
      'Value must be between 1 and 12': 'Vrednost mora biti med 1 in 12',
      'Value must be between 1 and 31': 'Vrednost mora biti med 1 in 31.',
      'Value must be between 1970 and 2099':
        'Vrednost mora biti med 1970 in 2099.',
      'Value must be equal or greater than':
        'Vrednost mora biti večja ali enaka od',
      'Value must be equal or less than':
        'Vrednost mora biti manjša ali enaka od',
      'Value should be an integer.': 'Vrednost mora biti celo število.',
      'Value:': 'Vrednost:',
      Values: 'Vrednosti',
      'Values from': 'Vrednosti od',
      'Values to': 'Vrednosti do',
      'Values were successfully uploaded':
        'Vrednosti so bile uspešno naložene.',
      'Variable name': 'Ime spremenljivke',
      'Variable names must be surrounded by double quotes {{name}}.':
        'Imena spremenljivk naj bodo napisana v dvojnih narekovajih.',
      'Variable {{name}} is not defined':
        'Spremenljivka {{name}} ni definirana.',
      'Variable {{name}} is not defined.':
        'Spremenljivka {{name}} ni definirana.',
      'Variable {{value}} is not defined':
        'Spremenljivka {{value}} ni definirana.',
      Variables: 'Spremenljivke',
      Version: 'Različica',
      'View Counter Type': 'Poglej tip merilnika',
      Viewer: 'Prikazovalnik',
      Visualization: 'Vizualizacija',
      Visualizations: 'Vizualizacije',
      WARNING: 'OPOZORILO',
      Warning: 'Opozorilo',
      'Weather item is loading ...': 'Nalaganje vremenske postaje ...',
      'Weather station': 'Vremenska postaja',
      'Weather station code is missing': 'Vremenska postaja nima šifre.',
      'Web Service': 'Spletna storitev',
      'Web Services': 'Spletne storitve',
      'Webservice connection configuration':
        'Konfiguracija povezave spletne storitve',
      Wed: 'Sre',
      Wednesday: 'Sreda',
      Week: 'Teden',
      'Weekday interval quantity missing.':
        'Manjka vrednost v polju intervala dneva v tednu.',
      Weekdays: 'Dnevi v tednu',
      'When expression below will evaluate to true, this rule will trigger notices and / or alarms.':
        'Ko bo spodnji izraz veljaven, se bodo sprožili alarmi vezani na to pravilo.',
      'When reading data (according to read scheduler), system reads all data between the time of last read data and the current time of reading data. Timestamp of such data is copied from the source data. When reading data for the first time, length of time interval is defined with attribute “First Batch Interval”.':
        'Ob sprožitvi zajema podatkov (glede na urnik zajema), se preberejo vsi podatki od zadnje prebrane vrednosti do časa, ko se je sprožil zajem podatkov.\nČasovna značka tako zajetih podatkov je vedno enaka časovni znački podatka na viru.\nOb prvem zajemu podatkov se za dolžino prvega časovnega intervala upošteva vrednost atributa “Začetni interval pri paketnem zajemu”.',
      'When reading data (according to read scheduler), system reads all data between the time of read and the time interval defined with attribute “Future interval”. Timestamp of such data is copied from the source data.':
        'Ob sprožitvi zajema podatkov (glede na urnik zajema), se preberejo vsi podatki od časa sprožitve v obdobju določenem z vrednostjo atributa “Interval v prihodnosti”.\nČasovna značka tako zajetih podatkov je vedno enaka časovni znački podatka na viru.',
      'When reading data (according to read scheduler), system reads current value at the source (OPC, database, …). Timestamp of such data is always time of read (according to read scheduler).':
        'Ob sprožitvi urnika zajema podatkov, se zajame trenutna vrednost, ki je na viru (OPC, podatkovna baza, …).\nČasovna značka tako zajete točke je vedno čas, ob katerem se je (glede na urnik) sprožil zajem vrednosti.',
      'When the input value is not in the specified data point format and  none of the above rules apply, use this to remap it to a custom value. If this should trigger an error, alarm or signal a manual check, flag it so.':
        'Če zajeta vrednost ni pravilnega formata in vrednost ni sprožila nobenega zgornjega pravila, se bo vrednost preslikala glede na privzeto pravilo.',
      'When time series is a trigger, expression below will be evaluated when a new time series value arrives.':
        'Če je označen Sproži izračun, se bo ob prihodu vrednosti vhodne časovne vrste nova vrednost izračunala glede na spodnji izraz. ',
      'Would you like to remove value': 'Ali želite odstraniti vrednost?',
      'Would you like to return to your main dashboard?':
        'Ali se želite vrniti na glavno nadzorno ploščo?',
      'Write Query': 'Poizvedba za zapis podatkov',
      'Write back rules': 'Pravila, ki preprečijo pisanje nazaj',
      'Write cancel rules': 'Pravila, ki preprečijo pisanje nazaj',
      'Wrong cron format.': 'Napačen format crona.',
      'Wrong data format': 'Napačen format podatka',
      'Wrong date format': 'Napačen format datuma',
      'Wrong next date format': 'Napačen format datuma',
      'Wrong selected date format': 'Napačen format datuma',
      'X Coordinate': 'X koordinata',
      'X axis': 'X os',
      'X values from': 'X vrednosti od',
      'X values to': 'X vednosti do',
      'Y Coordinate': 'Y koordinata',
      'Y axis': 'Y os',
      'Y axis value': 'Vrednost na Y osi',
      YES: 'DA',
      Year: 'Leto',
      'Year from': 'Leto od',
      'Year to': 'Leto do',
      Yearly: 'Letno',
      Yes: 'Da',
      'You are not allowed to select more than 300 items':
        'Niste pooblaščeni za izbor za več kot 300 postavk.',
      'You can\'t delete last baseline':
        'Brisanje zadnje osnovne premice je onemogočeno.',
      'You have no menu items': 'Nimate pravic za prikaz elementov v menuju',
      'You have no new notifications': 'Ni novih obvestil',
      'You have to add at least one of the following filters:':
        'Dodati morate vsaj enega izmed sledečih filtrov:',
      'You have to use at least one variable':
        'Uporabiti morate vsaj eno spremenljivko.',
      'You need to run analysis': 'Analizo je potrebno zagnati.',
      'Your are not allowed to edit time series values for this date':
        'Za izbrani datum nimate dovoljenja spreminjati vrednosti časovne vrste.',
      'Zoom to last:': 'Približaj zadnje:',
      active: 'aktivno',
      add: 'dodaj',
      'add new': 'dodaj nov',
      'add users to group': 'dodaj uporabnika v skupino',
      'alarm definition': 'definicija alarma',
      all: 'izberi vse',
      'all day': 'ves dan',
      'assign gis pair': 'dodeli GIS par',
      at: 'pri',
      avg: 'Povprečna vrednost',
      bad: 'Slabo',
      'billing ASC': 'način obračuna naraščajoče',
      'billing DESC': 'način obračuna padajoče',
      cancel: 'prekliči',
      circle: 'krog',
      close: 'zapri',
      'confirm seen': 'potrdi prebrano',
      connection: 'povezava',
      'cost centre': 'stroškovno mesto',
      create: 'ustvari',
      'created ASC': 'po nastanku rastoče',
      'created DESC': 'po nastanku padajoče',
      d: 'd',
      database: 'baza podatkov',
      'date should be within external reader flow date limits':
        'Izbran datum mora biti znotraj veljavne konfiguracije Zajem iz zunanjega vira',
      day: 'dan',
      days: 'dni',
      delete: 'izbriši',
      'device contacts': 'kontaki naprave',
      display: 'prikaži',
      'displayed horizontally': 'prikaži vodoravno',
      'displayed vertically': 'prikaži navpično',
      'does not EQUAL': 'ni enako',
      duplicate: 'podvoji',
      edit: 'urejanje',
      entity_name: 'entity_name',
      'external datasource': 'zunanji podatkovni vir',
      'fetch failed': 'pridobivanje ni uspelo',
      'file settings': 'nastavitve datoteke',
      filter: 'filter',
      freehand: 'prostoročno',
      'get values': 'pridobitev vrednosti',
      'gis groups': 'gis skupine',
      good: 'Dobro',
      'group name': 'ime skupine',
      'hh:mm:ss': 'hh:mm:ss',
      'hide address': 'skrij naslov',
      hour: 'ura',
      hours: 'ur',
      icon: 'Ikona',
      if: 'if',
      'in progress': 'v teku',
      inactive: 'neaktivno',
      input: 'ročni vnos',
      'is LESS than': 'manj kot',
      'is LESS than OR equals': 'manj ali enako',
      'is MORE than': 'več kot',
      'is MORE than OR equals': 'več ali enako kot',
      kWh: 'kWh',
      'last N months': 'zadnjih N mesecev',
      'last N years': 'zadnjih N let',
      lastname: 'priimek',
      'less than a second': 'manj kot sekundo',
      list: 'seznam',
      litres: 'litrov',
      'load more ...': 'naloži več ...',
      location: 'lokacija',
      login: 'prijava',
      'mapping rule': 'preslikovalno pravilo',
      max: 'Največja vrednost',
      min: 'Najmanjša vrednost',
      minute: 'minuta',
      month: 'mesec',
      months: 'meseci',
      'name ASC': 'po imenu rastoče',
      'name DESC': 'po imenu padajoče',
      'new component': 'nova komponenta',
      'new visualization': 'nova vizualizacija',
      'number ASC': 'po številki računa rastoče',
      'number DESC': 'po številki računa padajoče',
      'occured ASC': 'po pojavitvi rastoče',
      'occured DESC': 'po pojavitvi padajoče',
      of: 'od',
      ok: 'ok',
      'open dialog': 'odpri dialog',
      'open location tree': 'odpri hierarhijo lokacij',
      'out of': 'izmed',
      page: 'stran',
      'per page': 'na stran',
      'person Responsible': 'odgovorna oseba',
      point: 'točka',
      polygon: 'poligon',
      'price list': 'cenik',
      properties: 'lastnosti',
      read: 'beri',
      remove: 'odstrani',
      'remove file': 'odstrani datoteko',
      return: 'return',
      'returns true if the left operand is less than or equal to the right operand.':
        'Vrne True, če je leva vrednost manjša ali enaka od desne vrednosti',
      root: 'koren',
      rule: 'pravilo',
      'rule expression': 'izraz pravila',
      s: ' ',
      save: 'shrani',
      search: 'išči',
      sec: 'sec',
      second: 'sekunda',
      'seen first': 'najprej prebrana',
      'select alarm': 'izberite alarm',
      'select rule': 'izberi pravilo',
      'set from address': 'nastavite iz naslova',
      settings: 'nastavitve',
      stakeholderName: 'stakeholderName',
      'standard operating procedure': 'standardna operativna procedura',
      step: 'korak',
      submeterMeasuringPointName: 'submeterMeasuringPointName',
      substitute: 'namestnik',
      'tango agent connection configurations':
        'konfiguracija konektorja Tango Agenta',
      translate: 'prevedi',
      'tree view': 'drevesni pogled',
      unknown: 'neznano',
      'unseen first': 'najprej neprebrana',
      update: 'posodobi',
      username: 'uporabniško ime',
      'value format must be hh:mm:ss (23:59:59)':
        'vrednost mora biti v obliki hh: mm: SS (23:59:59)',
      week: 'teden',
      'works at': 'dela v podjetju',
      year: 'leto',
      years: 'leta',
      'yyyy-mm-dd': 'yyyy-mm-dd',
      '{{alarmName}} at {{timeSeriesName}}':
        '{{alarmName}} na {{timeSeriesName}}',
      '{{elementType}} unknown parameter': '{{elementType}} neznan parameter',
      '{{entityName}}': '{{entityName}}',
      '{{functionName}} is not a valid function.':
        '{{functionName}} ni veljavno ime funkcije.',
      '{{notCreated}} out of {{invoiceListDetailsNumber}} detail invoices couldn\'t be duplicated.':
        '{{notCreated}} izmed {{invoiceListDetailsNumber}} računov ni bilo mogoče kopirati.',
      '{{options.empty | translate}}': '{{options.empty | translate}}',
      '{{pos}} should be on interval [0 - 23].':
        '{{pos}} mora biti znotraj intervala [0 - 23].',
      '{{pos}} should be on interval [0 - 31].':
        '{{pos}} mora biti znotraj intervala [0 - 31].',
      '{{pos}} should be on interval [0 - 59].':
        '{{pos}} mora biti znotraj intervala [0 - 23].',
      '{{pos}} should be on interval [0 - 7].':
        '{{pos}} mora biti znotraj intervala [0 - 23].',
      '{{pos}} should be on interval [1 - 12].':
        '{{pos}} mora biti znotraj intervala [1 - 12].',
      '{{timeSeriesId}} Some values couldn\'t be saved. Values Inserted - {{valuesInserted}}, Values Discarded {{valuesDiscarded}}':
        '{{timeSeriesId}} Nekaj vrednosti ni bilo mogoče shraniti. Shranjene vrednosti - {{valuesInserted}}, Zavržene vrednosti - {{valuesDiscarded}}',
      '{{value}} last months': '{{value}} zadnjih mesecev.',
      '{{value}} last years': '{{value}} zadnjih let.',
      '{{vm.limits[key].title | translate}}': '{{vm.title | translate}}'
    })
    /* jshint +W100 */
  }
])
