NewAlarmDefinitionController.$inject = [
  '$stateParams',
  '$state',
  '$mdConstant',
  'AlertingService',
  'gettext',
  'MultiLanguage',
  'MetadataService',
  'LocalizationService',
  'createOrUpdateService',
  'SfeHeader',
  'alarm',
  '$timeout'
];

function NewAlarmDefinitionController(
  $stateParams,
  $state,
  $mdConstant,
  AlertingService,
  gettext,
  MultiLanguage,
  MetadataService,
  LocalizationService,
  createOrUpdateService,
  SfeHeader,
  alarm,
  $timeout
) {
  const vm = this;
  const alarmDefinitonId = $stateParams.id;
  const semicolon = 186;
  vm.isValid = isValid;

  init();

  function init() {
    vm.editMode = !!alarmDefinitonId;
    vm.duplicateMode =
      $state.current.name === 'alarms-and-rules-alarm-definitions-duplicate';
    vm.dataConfig = {};
    vm.alarmTags = [];
    vm.persons = [];
    vm.seperator = [
      $mdConstant.KEY_CODE.ENTER,
      $mdConstant.KEY_CODE.COMMA,
      semicolon
    ];

    vm.responsiblePersonnel = {
      primary: {},
      secondary: {}
    };

    vm.dataConfig.action = {
      ctrlFn: true,
      title:
        vm.editMode && !vm.duplicateMode
          ? gettext('Update')
          : gettext('Create'),
      fn: createOrUpdate
    };
    vm.dataConfig.edit = vm.editMode;
    // DO BASED UPON WHETHER IS EDITING OR NEW
    if (vm.editMode) {
      MetadataService.Loading(true);
      if (vm.duplicateMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicating alarm definition'),
          'alarms-and-rules-alarm-definitions-view',
          { id: alarmDefinitonId }
        );
      } else {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit alarm definition'),
          'alarms-and-rules-alarm-definitions-view',
          { id: alarmDefinitonId }
        );
      }

      vm.dataConfig.dataObj = GetAlarm(alarm);
      let selectedLang = LocalizationService.GetSelectedLangNetworkCode();
      if (vm.duplicateMode) {
        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Duplicate ' + alarm.name[selectedLang],
            alarm.description[selectedLang]
          );
        });
      } else {
        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Edit ' + alarm.name[selectedLang],
            alarm.description[selectedLang]
          );
        });
      }
      $timeout(() => {
        MetadataService.Loading(false);
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New alarm definition'),
        'alarms-and-rules-alarm-definitions-list'
      );
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        enabled: true,
        resolutionTime: null,
        responseTime: null,
        days: 0,
        hours: 0,
        minutes: 10,
        seconds: 0,
        timeToRespond: {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        },
        timeToClose: {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        },
        timeout: '',
        sops: [],
        sms: false,
        email: false,
        triggersOnHolidays: true
      };
    }
    let responseAndResolutionTimeConfig = {
      query: {
        entity: 'response-resolution-times',
        method: 'read'
      },
      entity: 'response-resolution-times',
      dialogConfiguration: {
        multiple: false,
        title: gettext('Choose a time interval')
      },
      floatingLabel: gettext('Choose a time interval'),
      searchParamName: 'filter',
      required: true,
      createRedirect: {
        state: 'configurations-alarm-configuration-responses-list'
      }
    };
    vm.dataConfig.data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        type: 'text',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        type: 'text',
        required: false,
        componentType: 'multilanguage'
      },
      {
        componentType: 'title',
        title: gettext('Alarm settings')
      },
      {
        configuration: {
          query: {
            entity: 'alarm-types',
            method: 'read'
          },
          entity: 'alarm-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Alarm Type')
          },
          floatingLabel: gettext('Select Alarm Type'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-alarm-configuration-alarm-types-list'
          }
        },
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        name: 'type'
      },
      {
        configuration: {
          query: {
            entity: 'severities',
            method: 'read'
          },
          entity: 'severities',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Severity')
          },
          floatingLabel: gettext('Select Severity'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-alarm-configuration-severities-list'
          }
        },
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        name: 'severity'
      },
      {
        componentType: 'checkBox',
        label: gettext('Response and resolution time'),
        name: 'responseTimeRequired'
      },
      {
        title: gettext('Response time'),
        hide: true,
        showParam: 'responseTimeRequired',
        componentType: 'title'
      },
      {
        configuration: responseAndResolutionTimeConfig,
        edit: vm.editMode,
        componentType: 'autocompleteDialog',
        name: 'responseTime',
        showParam: 'responseTimeRequired',
        hide: true
      },
      {
        title: gettext('Time until alarm should be resolved'),
        hide: true,
        showParam: 'responseTimeRequired',
        componentType: 'title'
      },
      {
        configuration: responseAndResolutionTimeConfig,
        edit: vm.editMode,
        componentType: 'autocompleteDialog',
        name: 'resolutionTime',
        showParam: 'responseTimeRequired',
        hide: true
      },
      {
        title: gettext('Time to trigger'),
        componentType: 'title'
      },
      {
        componentType: 'fourInRow',
        subData: [
          {
            placeholder: 'Days',
            name: 'days',
            pattern: new RegExp('^([0-9])*$'),
            patternExample: '14',
            type: 'number',
            required: true
          },
          {
            placeholder: 'Hours',
            name: 'hours',
            type: 'number',
            min: 0,
            max: 23,
            required: true
          },
          {
            placeholder: 'Minutes',
            name: 'minutes',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          },
          {
            placeholder: 'Seconds',
            name: 'seconds',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          }
        ]
      },
      {
        componentType: 'title',
        title: 'Standard operating procedures'
      },
      {
        componentType: 'multiSelectList',
        name: 'sops',
        removeTitle: gettext('Remove procedure'),
        addLabel: gettext('+ Add procedure'),
        groupLabel: gettext('Procedure'),
        selectConfigs: [
          {
            componentType: 'autocomplete',
            entity: 'sops',
            searchParamName: 'filter',
            selectedParam: '_id',
            required: true,
            query: {
              entity: 'sops',
              method: 'read'
            },
            filterFn: items => {
              if (
                vm.dataConfig &&
                vm.dataConfig.dataObj &&
                Array.isArray(vm.dataConfig.dataObj.sops)
              ) {
                const alreadySelectedItems = vm.dataConfig.dataObj.sops;
                const filteredItems = items.filter(
                  sop =>
                    !alreadySelectedItems.some(alreadySelectedSop => {
                      if (alreadySelectedSop[0]) {
                        return alreadySelectedSop[0]._id === sop._id;
                      }
                    })
                );
                return filteredItems;
              }
              return items;
            },
            createRedirect: {
              state: 'alarms-and-rules-sop-new'
            },
            placeholder: gettext('Choose sop procedure'),
            floatingLabel: gettext('Procedure'),
            reset: true
          }
        ]
      },
      {
        componentType: 'title',
        title: gettext('Notification services')
      },
      {
        componentType: 'checkBox',
        label: gettext('Send E-mail notifications'),
        name: 'email'
      },
      {
        componentType: 'checkBox',
        label: gettext('Send SMS notifications'),
        name: 'sms'
      },
      {
        componentType: 'checkBox',
        label: gettext('Triggers on Holidays'),
        name: 'triggersOnHolidays'
      },
      {
        componentType: 'checkBox',
        label: gettext('Notify on all triggers'),
        name: 'notifyOnAllTriggers'
      }
    ];
  }

  function GetAlarm(alarm) {
    let selectedLang = LocalizationService.GetSelectedLangNetworkCode();
    if (Array.isArray(alarm.sops)) {
      alarm.sops = alarm.sops.map(function(item) {
        return [
          {
            name: item.name[selectedLang],
            _id: item._id
          }
        ];
      });
    }

    let sec_num = alarm.timeToTrigger / 1000;
    alarm.days = Math.floor(sec_num / 3600 / 24);
    alarm.hours = Math.floor(sec_num / 3600) - alarm.days * 24;
    alarm.minutes = Math.floor(
      (sec_num - alarm.hours * 3600) / 60 - alarm.days * 24 * 60
    );
    alarm.seconds =
      sec_num -
      alarm.hours * 3600 -
      alarm.minutes * 60 -
      alarm.days * 24 * 3600;
    return alarm;
  }

  function isValid() {
    return vm.AlarmForm.$valid;
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'alarms',
          method: vm.editMode && !vm.duplicateMode ? 'update' : 'create'
        },
        vm.editMode,
        vm.duplicateMode ? null : alarmDefinitonId,
        dataToSave(),
        'alarms-and-rules-alarm-definitions-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    const data = MultiLanguage.constructForm(
      vm.dataConfig.data,
      vm.dataConfig.dataObj
    );
    const toRespond =
      data.seconds * 1 +
        data.minutes * 60 +
        data.hours * 60 * 60 +
        data.days * 60 * 60 * 24 || 0;
    let alarm = {
      name: data.name,
      description: data.description,
      type: data.type._id,
      severity: data.severity._id,
      responseTimeRequired:
        typeof data.responseTimeRequired === 'undefined'
          ? false
          : data.responseTimeRequired,
      timeToTrigger: toRespond * 1000,
      email: data.email,
      sms: data.sms,
      triggersOnHolidays:
        typeof data.triggersOnHolidays === 'undefined'
          ? false
          : data.triggersOnHolidays,
      responseTime: null,
      resolutionTime: null,
      notifyOnAllTriggers: !!data.notifyOnAllTriggers
    };
    if (data.responseTimeRequired) {
      alarm.responseTime = data.responseTime._id;
      alarm.resolutionTime = data.resolutionTime._id;
    }
    if (Array.isArray(data.sops) && data.sops.length > 0) {
      alarm = {
        ...alarm,
        sops: data.sops.map(item => item[0]._id)
      };
    } else {
      alarm = {
        ...alarm,
        sops: []
      };
    }
    return alarm;
  }
}

export default NewAlarmDefinitionController;
